import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import RouteButton from '../Route/RouteButton/index.js';

const CtaButton = ({
    fields,
    params,
    sitecoreContext,
    t,
    fullWidth,
    isSmall
}) => {
    const linkHref = fields?.ctaLink?.value?.href;
    const linkTarget = fields?.ctaLink?.value?.target;
    const linkText = fields?.ctaLink?.value?.text ?? '';
    const full = fullWidth === 'true';
    const style = params?.rank ?? 'primary';
    if (sitecoreContext.pageEditing && !linkHref) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    }
    if (!linkHref) {
        return '';
    }
    return (
        <RouteButton
            text={linkText}
            href={linkHref}
            target={linkTarget}
            use-as-link="true"
            rank={style}
            full-width-on-mobile="true"
            toggle-full-width-on-mobile="true"
            full-width={full}
            size={isSmall && 'small'}
        ></RouteButton>
    );
};

export default withTranslation()(withSitecoreContext()(CtaButton));
