import { FieldTypes as SitecoreFieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';

const FieldTypes = {
    defaultFields: SitecoreFieldTypes,
    executeScript: '{B227C8BF-54B6-4061-A32D-001AA89CC702}',
    gtm: '{DD08E527-7B06-491A-9F91-74939698BA97}',
    step: '{3FFB60AE-6CB5-4906-8067-AD977BF0DE77}',
    agreement: '{506E91E1-12DC-4AB4-B9D8-83E1DC62EFC0}',
    infoObligation: '{78C3BE18-2DF9-43C3-83C3-744818B87AD1}',
    genericFormParameters: '{8ED57B48-D99C-440A-98E6-868D9062A3D5}',
    placeholder: '{F81D80FF-057F-4D39-8C1F-B01CED4AE575}',
    qmaticVisitLocation: '{A2085A4E-F863-4D37-A7DC-C774FB984E3A}',
    qmaticVisitClientType: '{6AD4C148-FA8A-44C7-A19A-6969D81A937E}',
    qmaticVisitSubject: '{C2ADA9E6-7A89-4D62-AB8C-A3382D7E1284}',
    qmaticVisitDate: '{9BC619BC-5C17-4456-9E27-95F19569A2CE}',
    qmaticVisitHour: '{96B2E344-DBEA-4836-8E29-075E1BDF4119}',
    qmaticParameters: '{93610220-A7FD-4E71-95FF-8AF500861AE5}',
    qmaticParametersField: '{93610220-A7FD-4E71-95FF-8AF500861AE5}',
    parametersField: '{8ED57B48-D99C-440A-98E6-868D9062A3D5}',
    googleRecaptcha: '{D4CB7853-E428-4B5D-90BF-95600B9BD5F8}'
};

export default FieldTypes;
