import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ImageOnBackground = ({ rendering, params, sitecoreContext, t }) => {
    const fields = rendering.fields;

    if (sitecoreContext.pageEditing && !fields) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    }
    return (
        <div className="image-on-background">
            <eon-ui-background
                color={params.backgroundColor}
                full-height={true}
            >
                <div className="image-on-background-image">
                    <img
                        src={fields.image.value?.src}
                        alt={fields.image.value?.alt}
                    />
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(ImageOnBackground);
