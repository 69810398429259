import React from 'react';
import SingleBid from './SingleBid.jsx';

const PublicBids = ({ title, isLoading, bids }) => {
    return (
        <div className="customer-bids">
            <div className="bids-title">
                <eon-ui-headline size="h3" text={title} />
            </div>

            <div className="bids-list">
                {isLoading && (
                    <div className="bids-list-spinner">
                        <eon-ui-preloader-circle />
                    </div>
                )}
                {!isLoading && bids && (
                    <eon-ui-accordion mode="single">
                        {bids.map((bid) => (
                            <SingleBid key={bid.number} bid={bid} />
                        ))}
                    </eon-ui-accordion>
                )}
            </div>
        </div>
    );
};

export default PublicBids;
