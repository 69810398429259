import MatchingTypes from './matchingTypes.js';

const operations = {
    [MatchingTypes.all]: function (operand1, operand2) {
        return operand1 && operand2;
    },
    [MatchingTypes.any]: function (operand1, operand2) {
        return operand1 || operand2;
    }
};

const accumulate = (list, operator) => {
    return list.reduce(operations[operator]);
};

const Matching = {
    match: (matchTypeId, operationResults) => {
        return accumulate(operationResults, matchTypeId);
    }
};

export default Matching;
