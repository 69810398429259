import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import RouteLink from '../Route/RouteLink/index.js';

const Link = ({ fields, sitecoreContext, t, slot, size, hideIcon, scheme }) => {
    if (fields?.link?.value?.href) {
        let href;
        if (fields.link.value.href.startsWith('http://tel:')) {
            href = fields.link.value.href.slice(7);
        } else {
            href = fields.link.value.href;
        }
        return (
            <RouteLink
                href={href}
                text={fields.link.value.text}
                target={fields.link.value.target}
                slot={slot}
                size={size}
                hide-icon={hideIcon}
                scheme={scheme}
            ></RouteLink>
        );
    } else if (sitecoreContext.pageEditing) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    } else {
        return '';
    }
};

export default withTranslation()(withSitecoreContext()(Link));
