import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from '../../utils/debounce';

const NavigationTabs = ({ fields }) => {
    const history = useHistory();
    const elementRef = useRef();

    const tabs = fields.tabs;

    useEffect(() => {
        const element = elementRef?.current;

        const ChangeTab = debounce((value) => {
            const tab = tabs[value.detail];
            history.push(tab.href);
        }, 200);

        if (element) {
            element.addEventListener('tabChanged', ChangeTab);

            return () => {
                element.removeEventListener('tabChanged', ChangeTab);
            };
        }
    }, [elementRef.current]);

    return (
        <div>
            <eon-ui-tabs
                active-tab-index={fields.indexOfCurrent}
                ref={elementRef}
            >
                {tabs
                    .map((c) => c.title)
                    .map((p, key) => (
                        <eon-ui-tab key={key} header={p} />
                    ))}
            </eon-ui-tabs>
        </div>
    );
};

export default NavigationTabs;
