import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import config from '../../../../../temp/config';

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const QmaticVisitDate = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    stepHandler,
    qmaticHandler,
    tracker,
    errors,
    onChange
}) => {
    useEffect(() => {
        ref.current.addEventListener(valueChangedEvent, handleValueChange);
        return () => {
            ref.current.removeEventListener(
                valueChangedEvent,
                handleValueChange
            );
        };
    }, [ref]);

    useEffect(() => {
        ref.current.addEventListener(blurEvent, (e) => handleBlur(e));

        return () => {
            ref.current.removeEventListener(blurEvent, handleValueChange);
        };
    }, [ref]);
    useEffect(() => {
        ref.current.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            ref.current.removeEventListener(focusEvent, handleValueChange);
        };
    }, [ref]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s),
            data
        );
    }, [data]);

    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);

    const handleValueChange = (e) => {
        e.preventDefault();

        let date = (e.target.value > 0) && new Date(parseInt(e.target.value)).toISOString();
        let valid = true;
        const errorMessages = [];
        field.model.value = date;

        if (field.model.required && !date) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }
        onChange(field.valueField.name, date, valid, errorMessages);

        field.qmaticSettings = {
            ...field.qmaticSettings,
            date: field.model.value
        };
        field.qmaticSummary = {
            ...field.qmaticSummary,
            date: field.model.value
        };
        qmaticHandler(field);
        conditionsHandler(field);
        if (typeof field.model.nextStep !== 'undefined' && field?.model?.navigateOnValueChange) {
            stepHandler(field.model.nextStep);
        }
    };

    const handleBlur = () => {
        tracker.onBlurField(field, data.value, errors);
    };

    const handleFocus = () => {
        tracker.onFocusField(field, data.value, errors);
    };

    const ref = useRef();

    useLayoutEffect(() => {
        handleLoadData();
    }, [data]);
    const handleLoadData = () => {
        if (data.qmatic) {
            field.qmaticSettings = data.qmaticSettings;
            field.qmaticSummary = data.qmaticSummary;
            FieldStateHelper.sendStateToForm(
                field,
                (d) => setData(d),
                (s) => stateHandler(s),
                { ...data, items: [] }
            );
            conditionsHandler(field);
            fetch(
                `${config.sitecoreApiHost}/api/eon/qmaticcalendar/dates?branchid=${data.qmaticSettings.branchId}&serviceId=${data.qmaticSettings.serviceId}&sc_apikey=${config.sitecoreApiKey}`
            )
                .then((response) => response.json())
                .then((response) => {
                    if (response.data === null) {
                        return;
                    } else {
                        var newSelectItems = response.data.map((x) => ({
                            text: x,
                            value: x
                        }));
                        field.model.items = newSelectItems;
                        FieldStateHelper.sendStateToForm(
                            field,
                            (d) => setData(d),
                            (s) => stateHandler(s),
                            { ...data, items: newSelectItems }
                        );
                        conditionsHandler(field);
                    }
                });
        } else {
            //wyczysc form?
        }
    };

    const selected =
        (data &&
            field.model.items
                .filter((item) => item.value === data.value)
                .map((item) => item.value)[0]) ??
        field.model.items
            .filter((item) => item.selected)
            .map((item) => item.value)[0];

    let allowedDates = field.model?.items?.map((item) => item.value).join('|');
    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <eon-ui-date-picker
                ref={ref}
                name={field.model.name}
                label={`${field.model.title} ${
                    field.model.required ? '*' : ''
                    }`}
                selected={selected}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
                weekends="false"
                allowed-dates={allowedDates}
                lang="pl"
            ></eon-ui-date-picker>
        </div>
    );
};
export default QmaticVisitDate;
