import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import config from '../../../../../temp/config';

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const QmaticVisitHour = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    stepHandler,
    tracker,
    errors,
    onChange,
    qmaticSummaryHandler
}) => {
    useEffect(() => {
        ref.current.addEventListener(valueChangedEvent, handleValueChange);
        return () => {
            ref.current.removeEventListener(
                valueChangedEvent,
                handleValueChange
            );
        };
    }, [ref]);

    useEffect(() => {
        ref.current.addEventListener(blurEvent, (e) => handleBlur(e));

        return () => {
            ref.current.removeEventListener(blurEvent, handleValueChange);
        };
    }, [ref]);
    useEffect(() => {
        ref.current.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            ref.current.removeEventListener(focusEvent, handleValueChange);
        };
    }, [ref]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s),
            data
        );
    }, []);

    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [apiItems, setApiItems] = useState([]);
    useLayoutEffect(() => {
        handleLoadData();
    }, [data]);

    const handleValueChange = (e) => {
        e.preventDefault();
        field.model.value = e.target.value;
        const errorMessages = [];
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }
        onChange(field.valueField.name, e.target.value, valid, errorMessages);

        var selectedItem = field.model.items.filter(
            (x) => x.value == field.model.value
        );

        field.qmaticSummary = {
            ...field.qmaticSummary,
            hour: selectedItem[0].text
        };
        qmaticSummaryHandler(field.qmaticSummary);
        conditionsHandler(field);
        if (typeof field.model.nextStep !== 'undefined' && field?.model?.navigateOnValueChange) {
            stepHandler(field.model.nextStep);
        }
    };

    const handleBlur = () => {
        tracker.onBlurField(field, data.value, errors);
    };

    const handleFocus = () => {
        tracker.onFocusField(field, data.value, errors);
    };

    const ref = useRef();

    const handleLoadData = () => {
        if (data.qmatic) {
            field.qmaticSummary = data.qmaticSummary;
            FieldStateHelper.sendStateToForm(
                field,
                (d) => setData(d),
                (s) => stateHandler(s),
                { ...data, items: [] }
            );
            conditionsHandler(field);
            setApiItems([]);
            fetch(
                `${config.sitecoreApiHost}/api/eon/qmaticcalendar/Times?branchid=${data.qmaticSettings.branchId}&serviceId=${data.qmaticSettings.serviceId}&date=${data.qmaticSettings.date}&sc_apikey=${config.sitecoreApiKey}`
            )
                .then((response) => response.json())
                .then((response) => {
                    if (response.data === null) {
                        return;
                    } else {
                        var newSelectItems = response.data.map((x) => ({
                            text: `${x}`,
                            value: x
                        }));
                        field.model.items = newSelectItems;
                        setApiItems(newSelectItems);
                        FieldStateHelper.sendStateToForm(
                            field,
                            (d) => setData(d),
                            (s) => stateHandler(s),
                            { ...data, items: newSelectItems }
                        );
                        qmaticSummaryHandler(field.qmaticSummary);
                        conditionsHandler(field);
                    }
                });
        } else {
        }
    };
    const selected =
        (apiItems &&
            apiItems
                .filter((item) => item.value === data.value)
                .map((item) => item.value)[0]) ??
        apiItems.filter((item) => item.selected).map((item) => item.value)[0];

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <eon-ui-dropdown
                ref={ref}
                name={field.model.name}
                label={`${field.model.title} ${
                    field.model.required ? '*' : ''
                    }`}
                selected={selected}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
                trailing-icon="true"
                trailing-icon-name="search"
                height="250px"
            >
                {apiItems &&
                    apiItems.map((item, i) => (
                        <eon-ui-dropdown-option
                            key={`item${i}`}
                            value={item.value}
                            label={item.text}
                        ></eon-ui-dropdown-option>
                    ))}
            </eon-ui-dropdown>
        </div>
    );
};
export default QmaticVisitHour;
