import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TextTeaserTile = ({ rendering, params, fields }) => {
    const icon = fields.icon?.value ?? '';
    const title = fields.title?.value ?? '';
    const description = fields.description?.value ?? '';
    const tileColor = params.tileColor ?? params.tilesColor;
    const iconScheme = params.iconScheme ?? params.iconsScheme;
    const hasLink = !!rendering.placeholders['eon-links']?.length;

    return (
        <div className="text-teaser-tile">
            <eon-ui-background color={tileColor} full-height>
                <div className="text-teaser-tile-content">
                    <eon-ui-background
                        color={params.leftHighlightColor || tileColor}
                    >
                        <div className="text-teaser-tile-left-highlight" />
                    </eon-ui-background>
                    <div className="text-teaser-tile-main">
                        <div className="text-teaser-tile-icon">
                            <eon-ui-icon
                                name={icon}
                                size="custom"
                                scheme={iconScheme}
                            />
                        </div>
                        <div className="text-teaser-tile-title">
                            <eon-ui-headline text={title} size="h3" />
                        </div>
                        <div className="text-teaser-tile-description">
                            <eon-ui-rte-renderer
                                rte-source="quill"
                                content={description}
                            />
                        </div>
                        {hasLink && (
                            <div className="text-teaser-tile-links">
                                <Placeholder
                                    name="eon-links"
                                    rendering={rendering}
                                    params={params}
                                />
                            </div>
                        )}
                    </div>

                    <eon-ui-background
                        color={params.rightStripeColor || tileColor}
                    >
                        <div className="text-teaser-tile-right-stripe">
                            <div className="text-teaser-tile-side-main" />
                        </div>
                    </eon-ui-background>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default TextTeaserTile;
