import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ScrollMagics from './scrollMagics.js';

const VerticalSlider = ({ rendering, params, sitecoreContext, props }) => {
    const detailColor = params.activeSlideDetailColor || 'eon-limeyellow';
    const activeSlideColor = params.activeSlideColor || 'eon-turquoise-dark';
    const backgroundColor = params.backgroundColor || 'eon-turquoise';

    useEffect(() => {
        if (!sitecoreContext.pageEditing) {
            const { destroy } = ScrollMagics.scrollMagicsInit(
                rendering,
                props.backgroundColor,
                sitecoreContext.itemPath
            );

            return () => {
                destroy();
            };
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return sitecoreContext.pageEditing ? (
        <div className="ee-mode">
            <eon-ui-grid-control
                grid="grid__d8-4__t8-4"
                content-position-col-2="center"
            >
                <eon-ui-xx slot="column-1">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </eon-ui-xx>
                <eon-ui-xx slot="column-2">
                    <Placeholder name="eon-eyecatcher" rendering={rendering} />
                </eon-ui-xx>
            </eon-ui-grid-control>
            <Placeholder
                name="eon-vertical-slides"
                rendering={rendering}
                params={{
                    rendering: rendering,
                    color: props.backgroundColor,
                    detailColor: detailColor,
                    activeSlideColor: activeSlideColor
                }}
                props={{
                    slides: rendering.placeholders['eon-vertical-slides'] ?? []
                }}
            />
            <div className="cta-button">
                <Placeholder name="eon-ctabutton" rendering={rendering} />
            </div>
        </div>
    ) : (
        <div className="vertical-slider">
            <div className="sticky">
                <div
                    className="afterdiv"
                    style={{ backgroundColor: `var(--${backgroundColor})` }}
                ></div>
                <eon-ui-grid-control
                    grid="grid__d4-8__t4-8"
                    content-position-col-2="center"
                >
                    <eon-ui-xx slot="column-1">
                        <Placeholder
                            name="eon-headline"
                            rendering={rendering}
                        />
                    </eon-ui-xx>
                </eon-ui-grid-control>
                <Placeholder
                    name="eon-vertical-slides"
                    rendering={{
                        placeholders: {
                            'eon-vertical-slides': [
                                rendering.placeholders['eon-vertical-slides'][0]
                            ]
                        }
                    }}
                    props={{
                        rendering: rendering,
                        detailColor: detailColor,
                        activeSlideColor: activeSlideColor,
                        slides:
                            rendering.placeholders['eon-vertical-slides'] ?? []
                    }}
                    key={`ph-0`}
                />
            </div>
        </div>
    );
};
export default withSitecoreContext()(VerticalSlider);
