import { useState, useEffect, useRef } from 'react';
import { formatDate, fetchData } from './utils';

export const useDayAheadMarket = () => {
    const dateRef = useRef(null);
    const [date, setDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setError(false);
            try {
                const response = await fetchData(date);
                const result = (await response.json()).rates;
                setMinDate(result.MinDataDate);
                setMaxDate(result.MaxDataDate);
                setData((prevData) => ({
                    ...prevData,
                    [result.SessionDate]: result
                }));
                if (!date) {
                    setDate(result.SessionDate);
                }
            } catch (error) {
                console.error(error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (!date || !data[date]) {
            loadData();
        }
    }, [date]);

    useEffect(() => {
        const datePicker = dateRef.current;

        const handleInput = (event) => {
            const dateFromDatePicker = formatDate(event.detail, true);
            setDate(dateFromDatePicker);
        };

        if (datePicker) {
            datePicker.addEventListener('valueChanged', handleInput);

            return () => {
                datePicker.removeEventListener('valueChanged', handleInput);
            };
        }
    }, []);

    const handlePreviousSession = () => {
        if (date) {
            const currentDate = new Date(date);
            currentDate.setDate(currentDate.getDate() - 1);
            const newDate = formatDate(currentDate, true);
            setDate(newDate);
        }
    };

    const handleNextSession = () => {
        if (date) {
            const currentDate = new Date(date);
            currentDate.setDate(currentDate.getDate() + 1);
            const newDate = formatDate(currentDate, true);
            setDate(newDate);
        }
    };

    const currentData = data[date];
    const isDatePickerDisabled = loading || !date;
    const isNextSessionDisabled = loading || date === maxDate;
    const isPrevSessionDisabled = loading || date === minDate;

    return {
        dateRef,
        date,
        minDate,
        maxDate,
        data: currentData,
        isLoading: loading,
        isError: error,
        isDatePickerDisabled,
        isNextSessionDisabled,
        isPrevSessionDisabled,
        handleNextSession,
        handlePreviousSession
    };
};
