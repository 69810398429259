import React, { useEffect, useRef, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const InputField = ({
    field,
    fieldState,
    inputType,
    textArea,
    conditionsHandler,
    stateHandler,
    onChange,
    tracker,
    errors
}) => {
    const ref = useRef();
    let maskRegEx = '';
    let phoneMask = '';
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        const element = ref.current;

        element.addEventListener(valueChangedEvent, (e) =>
            handleValueChange(e)
        );
        element.addEventListener(blurEvent, (e) => handleBlur(e));
        element.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            element.removeEventListener(valueChangedEvent, (e) =>
                handleValueChange(e)
            );
            element.addEventListener(blurEvent, (e) => handleBlur(e));
            element.addEventListener(focusEvent, (e) => handleFocus(e));
        };
    }, [ref.current]); //eslint-disable-line react-hooks/exhaustive-deps

    if (field.model?.isRegex) {
        maskRegEx = field.model?.maskValue;
    } else if (field.model?.maskName === 'TelephoneNumber') {
        phoneMask = field.model?.maskValue;
    } else {
        maskRegEx = /^[^<>]*$/;
    }

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, stateHandler]);

    useEffect(() => {
        if (firstLoad) {
            setErrors([]);
            setFirstLoad(false);
            return;
        }

        setErrors(errors);
    }, [errors]);

    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);

    const handleValueChange = (e) => {
        e.preventDefault();
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            setErrors('To pole jest wymagane.');
        }
        onChange(field.valueField.name, e.target.value, valid);
        field.model.value = e.target.value;

        conditionsHandler(field);
    };

    const useMask = () => {
        // return 'true';
        if (maskRegEx !== null && maskRegEx !== '') {
            return 'true';
        } else {
            return 'false';
        }
    };

    const handleBlur = () => {
        tracker.onBlurField(field, data.value, errors);
    };

    const handleFocus = () => {
        tracker.onFocusField(field, data.value, errors);
    };

    useEffect(() => {
        field.model.value = data.value;
    }, [data]);

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <eon-ui-input
                ref={ref}
                id={field.valueField.id}
                name={field.model.name}
                type={inputType}
                label={`${field.model.title} ${
                    field.model.required ? '*' : ''
                }`}
                regex-mask={useMask()}
                phone-mask={phoneMask}
                regex-format={maskRegEx}
                info-message={field.model.placeholderText}
                info={field.model.placeholderText}
                value={data.value}
                disabled={data.disabled ?? false}
                textArea={textArea}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
                cols={80}
            ></eon-ui-input>
        </div>
    );
};

export default InputField;
