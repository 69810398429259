import React from 'react';

const Spacer = ({ params }) => {
    const heightValues = {
        height: `${params.height}`
    };
    return <div style={heightValues}></div>;
};

export default Spacer;
