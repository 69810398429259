import React, { useEffect, useState } from 'react';
import {
  Placeholder,
  withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const ProductDetailsTabPillGroup = ({ rendering, sitecoreContext, t }) => {
  var placeholders = Object.keys(rendering.placeholders);

  const initPills = rendering.fields?.pills?.map((x, index) => {
    return {
      selected: index == 0,
      name: x.Item2,
      key: x.Item1
    }
  });

  const [pills, setPills] = useState(initPills);
  const [changed, setchanged] = useState(false)

  const handleSelection = (e) => {
    pills.forEach((pill) => {
      pill.selected = pill.name === e.detail;
    });
    //This is added to change state so it will rerender
    setchanged(!changed)
  }

  useEffect(() => {
    document.addEventListener('tagPillClick', handleSelection);

    return () => {
      document.removeEventListener('tagPillClick', handleSelection);
    };
  });

  if (initPills < 1) {
    if (sitecoreContext.pageEditing) {
      return (<div>{t('Editor|EmptyDatasource')}</div>)
    }
    else {
      return (<></>)
    }
  }
  return (
    <>
      <div className="product-tag-pill-group">
        <eon-ui-tag-pill-group
          wrap="true"
          as-radio={true}>
          {pills ? pills
            .filter((x) => x)
            .map((p) => (
              <eon-ui-tag-pill
                key={rendering.uid}
                text={p.name}
                selected={p.selected}
              ></eon-ui-tag-pill>
            )) : ""}
        </eon-ui-tag-pill-group>
      </div>
      <div className="product-tag-pill-content-wrapper">
        {placeholders ? placeholders
          .filter((x) => x.includes('dynamic'))
          .map((p, key) => {
            let pill = pills.filter(x => p.includes(x.key));
            let currentPill = pill[0];
            return (
              <div
                className={`product-tag-pill-content ${currentPill.selected ? "" : "hidden"}`}
                key={key}
              >
                <Placeholder name={p} rendering={rendering} />
              </div>)
          }) : ""}
      </div>
    </>
  )
};

export default withTranslation()(withSitecoreContext()(ProductDetailsTabPillGroup));
