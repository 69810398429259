/* eslint-disable */
import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import ImageResponsive from "../ImageResponsive";

const PageHeaderBuilder = ({ sitecoreContext, fields, params }) => {
    const isMobile = useMobileCheck();
    const link = fields?.link?.value;
    const imageDescriptionText = fields?.descriptionFirstSection?.value ?? '';
    const descriptionSecondSection =
        fields?.descriptionSecondSection?.value ?? '';
    const linkRank = params?.linkRank ?? 'primary';
    const imageDescriptionTextSize = params?.imageDescriptionTextSize ?? 'h4';
    const headlineClassLeft = isMobile && (params.mobileHeaderPosition === 'left' || params.mobileHeaderPosition === 'right');

    return (
        <div className="home-page-header-slide">
            {sitecoreContext && sitecoreContext.pageEditing && (
                <div>Page Header Slide Component</div>
            )}
            <eon-ui-page-header
                scheme={params.scheme}
                show-content={true}
                headline-position="bottom"
                is-slider-item={params.slider === 'true'}
                is-subheadline-bottom="true"
                wide-shade={!!(params.wideShade * 1)}
                narrow-shade={!!(params.narrowShade * 1)}
                class="home-page-header-slide"
            >
                <ImageResponsive fields={fields}/>
                <eon-ui-headline
                    slot="header-headline"
                    scheme="white"
                    size={imageDescriptionTextSize}
                    text={imageDescriptionText}
                    class={headlineClassLeft ? "page-headline-left hydrated" : "page-headline-right hydrated"}
                    alignment={isMobile && params.mobileHeaderPosition === 'left' ? "left" : "right"}
                />
                <eon-ui-text
                    slot="header-subheadline"
                    text-style="abstract"
                    class="sub-headline"
                    dangerouslySetInnerHTML={{
                        __html: descriptionSecondSection
                        }}
                />
                {link.href && (
                    <eon-ui-button
                        slot="header-cta"
                        text={link.text}
                        href={link.href}
                        target={link.target}
                        use-as-link="true"
                        rank={linkRank}
                        full-width-on-mobile="true"
                        toggle-full-width-on-mobile="true"
                    />
                )}
            </eon-ui-page-header>
            {params.colorBarOffset && (
                <eon-ui-background color={params.colorBar}>
                    <div
                        className="color-bar-right"
                        style={
                            params.colorBar
                                ? {
                                      backgroundColor: `var(--${params.colorBar})`
                                  }
                                : null
                        }
                    ></div>
                </eon-ui-background>
            )}
        </div>
    );
};

const Slider = ({ fields, params }) => (
    <eon-ui-carousel-item>
        <PageHeaderBuilder fields={fields} params={params} />
    </eon-ui-carousel-item>
);

const PageHeaderSlide = ({ fields, params }) => {
    if (params?.slider && params.slider === 'true')
        return <Slider fields={fields} params={params} />;
    else return <PageHeaderBuilder fields={fields} params={params} />;
};

export default withSitecoreContext()(PageHeaderSlide);
