import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ProcessTiles = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }
    return (
        <div className="process-tiles">
            <div className="process-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <eon-ui-grid-control grid="grid__d3-3-3-3__t6-6-6-6">
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) =>
                        rendering?.placeholders?.[p].length > 0 ? (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                    params={params}
                                />
                            </eon-ui-xx>
                        ) : null
                    )}
            </eon-ui-grid-control>
        </div>
    );
};

export default ProcessTiles;
