import React from 'react';

import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const ProductDetailsTab = ({ rendering, sitecoreContext, params }) => {
    const columns = params.columns;
    const threeColumns = columns === '3';
    return (
        <>
            <div className={`product-details-tab${threeColumns ? '-3' : ''}`}>
                {threeColumns ? <div className={`product-details-tab-header`}>
                    <Placeholder
                        name="eon-offer-product-details-tab-header"
                        rendering={rendering}
                    />
                </div> : ""}
                <div className="product-details-tab-content">
                    <div className={`product-details-tab-left${threeColumns ? '-3' : ''}`}>
                        <Placeholder
                            name="eon-offer-product-details-tab-left"
                            rendering={rendering}
                        />
                        {threeColumns && (
                            <div className="benefits-container">
                                <Placeholder
                                    name="eon-offer-benefits-left"
                                    rendering={rendering}
                                />
                            </div>
                        )}
                    </div>
                    {threeColumns && (
                        <div className="product-details-tab-center">
                            <Placeholder
                                name="eon-offer-product-details-tab-center"
                                rendering={rendering}
                            />
                            <div className="benefits-container">
                                <Placeholder
                                    name="eon-offer-benefits-center"
                                    rendering={rendering}
                                />
                            </div>
                        </div>
                    )}
                    <div className={`product-details-tab-right${threeColumns ? '-3' : ''}`}>
                        <Placeholder
                            name="eon-offer-product-details-tab-right"
                            rendering={rendering}
                        />
                        <div className="benefits-container">
                            <Placeholder
                                name="eon-offer-benefits"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(ProductDetailsTab);
