const DataLayer = {
    initDataLayer: function () {
        if (typeof window !== 'undefined' && !window.dataLayer) {
            const htmlElement = 'dataLayerScript';

            const scriptSection = document.getElementById(htmlElement);

            let scriptTag;
            scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';

            let scriptValue = `window.dataLayer = window.dataLayer || [];`;
            const script = document.createTextNode(scriptValue);

            scriptTag.appendChild(script);
            if (scriptSection?.parentNode) {
                scriptSection.parentNode.insertBefore(scriptTag, scriptSection);
            }
        }
    },
    pushToDataLayer: function (dataLayerEvent) {
        this.initDataLayer();
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push(dataLayerEvent);
        }
    }
};

export default DataLayer;
