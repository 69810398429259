import React, { useEffect, useState } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import { withTranslation } from 'react-i18next';

const QmaticSuccess = ({ t, stateHandler }) => {
    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            { model: { conditionSettings: { fieldKey: 'qmatic-summary' } } },
            (d) => setData(d),
            (s) => stateHandler(s),
            data
        );
    }, [stateHandler]);

    useEffect(() => {
        setRescheduleQueryParam(
            new URLSearchParams(window.location.search).get('rescheduleId')
        );
    });

    const getDayName = (dateStr, locale) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    };

    const [data, setData] = useState();
    const [rescheduleQueryParam, setRescheduleQueryParam] = useState();

    return data ? (
        <div className="qmatic-success">
            {rescheduleQueryParam ? (
                <eon-ui-headline
                    size="h1"
                    scheme="red"
                    text={t('Content|Forms|Qmatic|VisitRescheduleLabel')}
                ></eon-ui-headline>
            ) : (
                <eon-ui-headline
                    size="h1"
                    scheme="red"
                    text={t('Content|Forms|Qmatic|VisitConfirmLabel')}
                    style={{ position: 'absolute' }}
                ></eon-ui-headline>
            )}

            <div className="qmatic-subtitle">
                <eon-ui-headline
                    size="h3"
                    text={data.location}
                ></eon-ui-headline>
                <eon-ui-headline
                    size="h3"
                    text={`${getDayName(data.date, 'pl-PL')}, godzina ${
                        data.hour
                    }`}
                ></eon-ui-headline>
            </div>
        </div>
    ) : (
        ''
    );
};
export default withTranslation()(QmaticSuccess);
