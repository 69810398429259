import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const IFrame = ({ fields, params, sitecoreContext, t }) => {
    if (!fields) {
        return '';
    }

    const src = fields.src?.value ?? '';
    const height = params.height ?? '300';

    if (src && fields?.enabled.value)
        return (
            <>
                {sitecoreContext.pageEditing && (
                    <div>
                        <eon-ui-text text-style="bold">
                            IFrame Component
                        </eon-ui-text>
                    </div>
                )}
                <iframe
                    src={src}
                    title={src}
                    width="100%"
                    height={height}
                    style={{ border: 'none' }}
                ></iframe>
            </>
        );
    else if (sitecoreContext.pageEditing) {
        return (
            <>
                <eon-ui-text text-style="bold">IFrame Component</eon-ui-text>
                <eon-ui-text text-style="copy">
                    {t('Editor|EmptyDatasource')}
                </eon-ui-text>
            </>
        );
    } else {
        return '';
    }
};

export default withTranslation()(withSitecoreContext()(IFrame));
