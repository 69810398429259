import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ProductDetailsWithGrid = ({ rendering, sitecoreContext }) => {
    const pageEditor = sitecoreContext.pageEditing;
    const placeholders = rendering.placeholders[
        'eon-product-details-rows'
    ].filter((x) => x.componentName === 'ProductDetailsWithGridRow');

    return (
        <div className="product-details-with-grid">
            <div className="headline-wrapper">
                <div className="product-details-with-grid__headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>

                <div className="product-details-with-grid__cta">
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
            </div>

            <div className="product-details-with-grid__rows">
                {rendering.placeholders['eon-product-details-rows'].map(
                    (subRow, index) => (
                        <React.Fragment key={index}>
                            <Placeholder
                                name="eon-product-details-rows"
                                rendering={{
                                    componentName: 'ProductDetailsWithGrid',
                                    dataSource: rendering.dataSource,
                                    params: rendering.params,
                                    uid: rendering.uid,
                                    placeholders: {
                                        'eon-product-details-rows': [subRow]
                                    }
                                }}
                                params={{
                                    pos:
                                        index === 0
                                            ? 'first'
                                            : placeholders.length - 1 === index
                                            ? 'last'
                                            : 'middle'
                                }}
                            />
                            {placeholders.length > index + 1 && !pageEditor && (
                                <div className="product-detail-row-border" />
                            )}
                        </React.Fragment>
                    )
                )}
            </div>
            <div
                className={
                    pageEditor
                        ? 'product-details-with-grid__cta'
                        : 'product-details-with-grid__cta-mobile'
                }
            >
                {pageEditor ? 'Place mobile CTA button here:' : ''}
                <Placeholder
                    name="eon-ctabutton-mobile"
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductDetailsWithGrid);
