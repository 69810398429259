import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const CategoryTeaser = ({ rendering }) => {
    return (
        <div className="category-teaser">
            <eon-ui-grid-control grid="grid__d12__t12">
                <eon-ui-grid-control-column slot="column-1">
                    <eon-ui-xx>
                        <div className="category-teaser-headline">
                            <Placeholder
                                name="eon-headline"
                                rendering={rendering}
                            />
                        </div>

                        <div className="category-teaser-container">
                            <div className="category-teaser-container-left">
                                <Placeholder
                                    name="eon-category-teaser-tiles-columns"
                                    rendering={rendering}
                                    ratio="ratio-16x9"
                                />
                            </div>

                            <div className="category-teaser-container-right">
                                <Placeholder
                                    name="eon-category-teaser-image"
                                    rendering={rendering}
                                />
                            </div>
                        </div>
                    </eon-ui-xx>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>
        </div>
    );
};

export default withSitecoreContext()(CategoryTeaser);
