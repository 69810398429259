import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import getGridStyle from '../../lib/GridControlStyleResolver.js';

const SideBySide = ({ rendering, sitecoreContext }) => {
    const gridStyle = getGridStyle(2);
    const renderFunction = (components) =>
        components.map((component, index) => (
            <div
                className={
                    components.length === index + 1
                        ? ''
                        : 'side-by-side-placeholder'
                }
                key={index}
            >
                {component}
            </div>
        ));

    return (
        <div className="side-by-side-main headline">
            <div>
                {sitecoreContext.pageEditing ? (
                    <div className="headline">
                        <Placeholder
                            name="eon-side-by-side-column-left"
                            rendering={rendering}
                        />
                    </div>
                ) : (
                    <Placeholder
                        name="eon-side-by-side-column-left"
                        rendering={rendering}
                        render={renderFunction}
                    />
                )}
            </div>
            <div>
                {sitecoreContext.pageEditing ? (
                    <Placeholder
                        name="eon-side-by-side-column-right"
                        rendering={rendering}
                    />
                ) : (
                    <Placeholder
                        name="eon-side-by-side-column-right"
                        rendering={rendering}
                        render={renderFunction}
                    />
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(SideBySide);
