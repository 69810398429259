import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import CardTeaserSlide from '../CardTeaserSlide/index.js';

const CardTeaserSlider = ({ rendering, sitecoreContext }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        swipe: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    swipe: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    swipe: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    swipe: true
                }
            }
        ]
    };
    return (
        <div className="card-teaser-slider">
            {sitecoreContext.pageEditing && (
                <div className="card-teaser-editing">
                    <Placeholder
                        name="card-teaser-slides"
                        rendering={rendering}
                    />
                </div>
            )}
            {!sitecoreContext.pageEditing && (
                <Slider {...settings}>
                    {rendering.placeholders['card-teaser-slides'].map(
                        (slide, key) => (
                            <div key={`card-teaser-slide-${key}`}>
                                <CardTeaserSlide rendering={slide} />
                            </div>
                        )
                    )}
                    <div className="ghost-card"></div>
                </Slider>
            )}
        </div>
    );
};

export default withSitecoreContext()(CardTeaserSlider);
