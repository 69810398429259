import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const TextTile = ({ rendering, sitecoreContext, fields, params }) => {
    const tileColor = params.scheme || 'white';
    const hasLinks = !!rendering.placeholders['eon-links'].length;

    return (
        <div className="text-tile">
            <eon-ui-background color={`eon-${tileColor}`} full-height>
                <div className="text-tile-content">
                    {sitecoreContext.pageEditing && <p>TextTile Component</p>}

                    <div className="text-tile-headline">
                        <eon-ui-headline
                            size="h3"
                            text={fields?.header?.value}
                        />
                    </div>

                    <div className="text-tile-description">
                        <eon-ui-rte-renderer
                            rte-source="aem"
                            content={fields?.description?.value}
                        />
                    </div>

                    {hasLinks && (
                        <div className="text-tile-links-container">
                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    )}
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(TextTile);
