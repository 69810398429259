import React, { useRef, useEffect } from 'react';

const FormErrors = (props) => {
    const ref = useRef();

    useEffect(() => {
        if (ref.current && props.formErrors && props.formErrors.length > 0) {
            ref.current.visible = true;
        }
    }, [ref, props.formErrors]);

    return (
        <eon-ui-modal
            ref={ref}
            modal-id="errors-modal-window"
            headline-text="Formularz nie został wysłany"
            full-width="true"
            show-closing-x="true"
            full-width-width="584px"
        >
            {props.formErrors.map((error, i) => (
                <p key={`error-${i}`}>{error}</p>
            ))}
        </eon-ui-modal>
    );
};

export { FormErrors };
