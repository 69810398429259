import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const GoogleRecaptcha = ({ field }) => {
    const [errorMessage, setErrorMessage] = useState(false);
    const recaptcha = useRef(null);

    const handleClick = useCallback((e) => {
        const captchaValue = recaptcha.current?.getValue();
        if (!captchaValue) {
            setErrorMessage(true);
            if (recaptcha.current) {
                recaptcha.current.reset();
            }
            e.preventDefault();
        }
    }, []);

    const handleRecaptchaChange = (value) => {
        if (value) {
            setErrorMessage(false);
        }
    };

    useEffect(() => {
        const formButton = recaptcha.current?.closest('form')?.querySelector('[type="submit"]');

        if (!formButton) {
            return;
        }

        formButton.addEventListener('click', handleClick);

        return () => {
            formButton.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    return (
        <>
            <ReCAPTCHA
                sitekey={field?.model?.publicKey}
                onChange={handleRecaptchaChange}
                ref={recaptcha}
            />
            {errorMessage && <eon-ui-form-message
                class="eonui-error-message"
                type="error"
                icon="error"
                message={field?.model?.errorMessage}
            ></eon-ui-form-message>}
        </>
    );
};

export default GoogleRecaptcha;