import React, { useState, useEffect } from 'react';
import { useMobileCheck } from '../../../hooks/useMobileCheck';
import RouteLink from '../../Route/RouteLink/index.js';
import config from '../../../temp/config';

const PromoArticle = ({ rendering, article, tagsList, promoIndex }) => {
    const isMobile = useMobileCheck();

    const img =
        article.imageHref ||
        `${config.sitecoreApiHost}/-/media/Feature/Articles/ArticleTeaserDefaultImage.ashx?h=506&iar=0&w=800&hash=2FF280A3AD55242A224690166C16290B`;
    const show = article.showTags && article.tags;
    const badgeScheme = rendering.params.badgeBackgroundColor ?? 'limeyellow';
    const badgeHeadline = rendering.params.badgeText ?? 'EON';
    const badgeTextColor = rendering.params.badgeTextColor ?? '';
    const defaultBackgroundColor =
        promoIndex % 2 !== 0 ? 'eon-turquoise' : 'eon-bordeaux';

    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if (tagsList && tagsList.length > 0) {
            if (article.tags?.length > 0) {
                let flag = false;
                article.tags.forEach((tag) => {
                    if (tagsList.indexOf(tag) > -1) {
                        flag = true;
                    }
                });
                setDisplay(flag);
            } else {
                setDisplay(false);
            }
        } else {
            setDisplay(true);
        }
    }, [tagsList, article.tags]);

    return (
        <div className={`promo-article ${!display ? 'hide' : ''}`}>
            <eon-ui-background
                color={
                    article.promotedBackgroundColor ?? defaultBackgroundColor
                }
                full-height
            >
                <div className="promo-article-content">
                    <div className="image-wrapper">
                        <eon-ui-image
                            aspect-ratio={isMobile ? 'ratio-2x1' : ''}
                            height={isMobile ? 'auto' : '100%'}
                            width={isMobile ? '100%' : '276px'}
                            src={img}
                            alt={article.imgAlt}
                        />
                    </div>

                    <div className="promo-article-badge">
                        <eon-ui-eyecatcher
                            scheme={badgeScheme}
                            size="medium"
                            divider="false"
                            align="left"
                            textcolor={badgeTextColor}
                            headline={badgeHeadline}
                        />
                    </div>
                    <div className="promo-article-content-wrapper">
                        {show && (
                            <div className="tags-container">
                                {article.tags.map((tag, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="offer-teaser-tag"
                                        >
                                            <eon-ui-text>{tag}</eon-ui-text>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <eon-ui-headline text={article.title} size="h4" />
                        <div className="promo-article-description">
                            <eon-ui-text text-style="copy">
                                {article.description}
                            </eon-ui-text>
                        </div>
                        <RouteLink
                            href={article.href}
                            text="Zobacz"
                        ></RouteLink>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default PromoArticle;
