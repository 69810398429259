import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const IndustryTilesRow = ({ rendering, sitecoreContext }) => {
    const placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    let wrapperClass = 'industry-tiles-column-wrapper';

    if (sitecoreContext.pageEditing) {
        wrapperClass += ' industry-tiles-editing';
    }

    return (
        <div className={wrapperClass}>
            {placeholders
                .filter((x) => x.includes('column'))
                .map((p, key) => (
                    <div className="industry-tiles-column-grow" key={key}>
                        <Placeholder name={p} rendering={rendering} />
                    </div>
                ))}
        </div>
    );
};

export default withSitecoreContext()(IndustryTilesRow);
