import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { FieldEditFrame } from '../FieldEditFrame/FieldEditFrame.jsx';

const OfferTeaserThree = ({ rendering, sitecoreContext, t }) => {
    const itemFields = sitecoreContext.route.fields;
    if (
        itemFields.teaserImage === undefined ||
        itemFields.teaserDescription === undefined ||
        itemFields.teaserTitle === undefined ||
        itemFields.tags === undefined
    ) {
        if (sitecoreContext.pageEditing) {
            return (
                <eon-ui-text text-style="copy">
                    {t('Editor|OfferPageComponent')}
                </eon-ui-text>
            );
        } else {
            return '';
        }
    }
    const imagesrc = itemFields.teaserImage.value.src;
    const imageAltText = itemFields.teaserImage.value.alt;
    const description = itemFields.teaserDescription.value;
    const headline = itemFields.teaserTitle.value;
    const tags = itemFields.tags.map((x) => x.fields.value.value);
    //const fieldNames = Object.keys(itemFields)
    const fieldNames = [
        'teaserImage',
        'teaserTitle',
        'teaserDescription',
        'tags'
    ];
    return (
        <div className="offer-teaser-three">
            <FieldEditFrame itemId={sitecoreContext.itemId} fields={fieldNames}>
                <div className="offer-teaser-headline">
                    <Placeholder
                        name="eon-headline"
                        rendering={rendering}
                        props={{ headline: headline }}
                    />
                </div>
                <eon-ui-offset-teaser
                    scheme="ultralightgrey"
                    variant="variant_three"
                    inner-background="eon-ultralightgrey"
                    image-aspect-ratio="ratio-1x1"
                >
                    <eon-ui-image
                        slot="image"
                        src={imagesrc}
                        alt={imageAltText}
                        title="Image title"
                    />
                    {tags && (
                        <div slot="content" className="offer-teaser-tags">
                            {tags.map((x, key) => (
                                <div key={key} className="offer-teaser-tag">
                                    {x}
                                </div>
                            ))}
                        </div>
                    )}

                    <eon-ui-rte-renderer
                        slot="content"
                        rte-source="aem"
                        content={description}
                    />
                    <div className="offer-teaser-link">
                        <Placeholder name="eon-links" rendering={rendering} />
                    </div>
                </eon-ui-offset-teaser>
            </FieldEditFrame>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(OfferTeaserThree));
