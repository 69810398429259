import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const RelatedOfferTeasers = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    return (
        <div className="related-offer-teasers">
            <div className="related-offer-teasers-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <div className="related-offer-teasers-wrapper">
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <Placeholder
                            key={key}
                            name={p}
                            rendering={rendering}
                            props={{ backgroundColor: params.backgroundColor }}
                        />
                    ))}
            </div>
        </div>
    );
};

export default RelatedOfferTeasers;
