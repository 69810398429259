import React, { useEffect, useRef, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const FileUpload = (props) => {
    const ref = useRef();

    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener(valueChangedEvent, (e) =>
                handleValueChange(e)
            );
            element.addEventListener(blurEvent, (e) => handleBlur(e));
            element.addEventListener(focusEvent, (e) => handleFocus(e));

            return () => {
                element.removeEventListener(valueChangedEvent, (e) =>
                    handleValueChange(e)
                );
                element.addEventListener(blurEvent, (e) => handleBlur(e));
                element.addEventListener(focusEvent, (e) => handleFocus(e));
            };
        }
    }, [ref.current]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            props.field,
            (d) => setData(d),
            (s) => props.stateHandler(s)
        );
    }, [props.field, props.stateHandler]);

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    const [data, setData] = useState(props.fieldState);
    const [errorMsgs, setErrors] = useState(props.errors);

    const handleValueChange = (e) => {
        e.preventDefault();
        var files = e.detail;
        var list = [];
        let valid = true;

        if (files) {
            Array(files.length)
                .fill(null)
                .forEach(function (_, idx) {
                    if (props.field.model.required && !e.target.value) {
                        valid = false;
                    }
                    if (files[idx].error) {
                        valid = false;
                    }
                    list.push(files[idx].file);
                });
        }
        props.field.model.files = list.length > 0 ? list : null;
        props.field.originalValue = list.length > 0 ? list : undefined;
        props.onChange(props.field.valueField.name, list, valid);
    };

    const handleBlur = () => {
        props.tracker.onBlurField(props.field, props.value, props.errors);
    };

    const handleFocus = () => {
        props.tracker.onFocusField(props.field, props.value);
    };

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <eon-ui-file-upload
                ref={ref}
                show-upload-button={false}
                field-id={props.field.valueField.id}
                name={props.field.valueField.name}
                single-upload="false"
                multiple-files="true"
                is-form-component
                class="hydrated"
                lang="pl"
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
            ></eon-ui-file-upload>
        </div>
    );
};

export default FileUpload;
