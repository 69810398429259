import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ColorBarFooter = ({ sitecoreContext }) => (
    <>
        {sitecoreContext.pageEditing && (
            <>
                <p>Color Bar Footer Component</p>
            </>
        )}

        <div className="footer-color-bar">
            <div className="turquoise"></div>
        </div>
    </>
);

export default withSitecoreContext()(ColorBarFooter);
