import DataLayer from '../../DataLayer';

const ScrollMagics = {
    scrollMagicsInit: function (rendering, color, itemPath) {
        var ScrollMagic = require('scrollmagic');
        let pos = 0;
        let recentProgress = 0;
        var dataLayerEventCount = 0;
        var slideLength = rendering.placeholders['eon-vertical-slides'].length;
        var slides = rendering.placeholders['eon-vertical-slides'];
        var controller = new ScrollMagic.Controller();
        var scene = new ScrollMagic.Scene({
            triggerElement: '.vertical-slider',
            triggerHook: 'onLeave',
            duration: 1200
        })
            .setPin('.sticky')
            .addTo(controller);

        const tempOffset = (1200 - 64 - 762 - 130) / 2;

        if (window.innerWidth > 900) {
            scene.offset(-tempOffset);
        } else {
            scene.offset(-90);
        }
        const updateScene = (progress) => {
            recentProgress = progress; 
            document.body.style.setProperty(
                '--vertical-slide-text-background-pos',
                (progress * 100 * 2) / 3 + '%'
            );
            document.body.style.setProperty(
                '--vertical-slide-text-mobile-pos',
                progress * -192 + 'px'
            );
            document.body.style.setProperty(
                '--vertical-slide-linear-color',
                `linear-gradient(180deg, rgba(38, 165, 180, 0) 12.19%, var(--${color}) 84.7%)`
            );
            checkPos(progress);
            var img = document.getElementsByClassName('desktop-pc-top-main')[0];
            if (img && img.style)
                img.style.opacity = pos === 0 ? 1 :
                    ((progress - pos / slideLength) * slideLength) / 2 +
                    0.66;
        }
        const onInitialMouseMove = () => {
            updateScene(recentProgress);
            document.removeEventListener('mousemove',onInitialMouseMove, false);
        }
        document.addEventListener('mousemove', onInitialMouseMove, false);

        scene.on('progress', function (event) {
            updateScene(event.progress);
        });
        updateScene(0);
        function checkPos(progress) {
            var temppos =
                Math.floor(slideLength * progress) > slideLength - 1
                    ? slideLength - 1
                    : Math.floor(slideLength * progress);
            if (temppos !== pos) {
                var img = document.querySelector(
                    '.desktop-pc-top-main eon-ui-image'
                );
                if (img) {
                    img.src = slides[temppos]?.fields?.image?.value?.src ?? '';
                }

                if (dataLayerEventCount < slideLength - 1) {
                    const currentPercentage = Math.floor(
                        (100 / slideLength) * (temppos + 1)
                    );

                    const dataLayer = {
                        event: 'sub_scroll',
                        eventCategory: 'sub_scroll',
                        eventAction: `scrollu (progi: ${currentPercentage}%)`,
                        eventLabel: itemPath
                    };

                    DataLayer.pushToDataLayer(dataLayer);
                    dataLayerEventCount++;
                }
            }
            pos = temppos;
        }
        let textList = document.getElementById('vertical-slide-text-list');
        let textslides = textList.querySelectorAll('.vertical-slide-text');

        function initPrimaryListFunctionality() {
            for (let i = 0; i < textslides.length; i++) {
                textslides[i].addEventListener('click', function () {
                    controller.scrollTo(scene);
                    var newScrollPos =
                        controller.scrollPos() + (1200 / (slideLength - 1)) * i;
                    controller.scrollTo(newScrollPos);
                });
            }
        }

        initPrimaryListFunctionality();

        function destroy() {
            scene.destroy(true);
            controller.destroy(true);
        }

        return { destroy };
    }
};
export default ScrollMagics;
