import React from 'react';
import Slider from 'react-slick';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const RealizationsCardTeasers = ({ sitecoreContext, rendering }) => {
    const isMobile = useMobileCheck();

    const placeholdersKeys = Object.keys(rendering.placeholders);
    if (!placeholdersKeys) {
        return '';
    }

    const columns = placeholdersKeys.filter((placeholder) =>
        placeholder.includes('column')
    );
    const settings = {
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        swipe: true,
        arrows: false
    };

    return (
        <>
            {sitecoreContext.pageEditing && <p>Realizations Card Teasers</p>}

            <div className="realizations-card-teasers">
                <div className="realizations-card-teasers-headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>

                {isMobile && !sitecoreContext.pageEditing ? (
                    <div className="realizations-card-teasers-slider">
                        <Slider {...settings}>
                            {columns.map((slide, key) => (
                                <div key={`realizations-card-teasers-${key}`}>
                                    <Placeholder
                                        name={slide}
                                        rendering={rendering}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : (
                    <div className="realizations-card-teasers-tiles">
                        {columns.map((p, key) => (
                            <div
                                key={key}
                                className="realizations-card-teasers-tile"
                            >
                                <Placeholder name={p} rendering={rendering} />
                            </div>
                        ))}
                    </div>
                )}

                <div className="realizations-card-teasers-cta">
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(RealizationsCardTeasers);
