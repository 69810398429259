import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const ProductDetailsTile = ({ rendering, props, sitecoreContext }) => {
    const pageEditor = sitecoreContext.pageEditing;
    const fields = rendering.fields;
    const header = fields.header?.value ?? '';
    const subTitle = fields.subtitle?.value ?? '';
    const description = fields.description?.value ?? '';
    let productDetailTileClass = 'product-details-tile__wrapper';

    productDetailTileClass = productDetailTileClass.concat(
        props.border ? ' border-tile ' : ''
    );
    productDetailTileClass = productDetailTileClass.concat(
        props.borderMobile ? ' border-mobile-tile ' : ''
    );
    productDetailTileClass = productDetailTileClass.concat(
        header === '' && subTitle === '' ? ' empty-content ' : ''
    );

    return (
        <div className={productDetailTileClass}>
            {pageEditor && <p> product Detail Tile </p>}
            <div className="product-details-tile__title">
                <eon-ui-headline text={header} size="h1" alignment="center" />
            </div>
            <div className="product-details-tile__subtitle">
                <eon-ui-headline text={subTitle} size="h3" alignment="center" />
            </div>
            <div className="product-details-tile__description">
                <eon-ui-text text-style="copy" alignment="center">
                    <eon-ui-rte-renderer
                        rte-source="quill"
                        content={description}
                    />
                </eon-ui-text>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ProductDetailsTile));
