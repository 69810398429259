import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const ContactIllustrationsTile = ({
    fields,
    rendering,
    params,
    sitecoreContext
}) => {
    const isMobile = useMobileCheck();

    const backgroundColor = params?.backgroundColor;
    const titleColor = params?.titleColor;
    const hidePhotoOnMobile = params?.hidePhotoOnMobile;
    if (!fields) {
        return '';
    }

    const image = fields.image?.value?.src;
    const imageAlt = fields.image?.value?.alt ?? '';
    const description = fields.description?.value;

    const title = fields.title?.value;
    const TileImage = () => {
        return (
            <div className="contact-illustration-icon">
                {fields?.image?.value?.svg !== undefined && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: fields?.image?.value?.svg
                        }}
                    />
                )}
                {fields?.image?.value?.svg === undefined && (
                    <img
                        className="contact-illustration-image"
                        alt={imageAlt}
                        src={image}
                    />
                )}
            </div>
        );
    };
    return (
        <div className="contact-illustration-tile">
            {sitecoreContext.pageEditing && <p>Contact Illustrations Tile</p>}
            <div className="contact-illustration-tile-wrapper">
                <eon-ui-background color={backgroundColor} class="hydrated">
                    <div className="contact-illustration-body">
                        <div className="contact-illustration-content">
                            <div className="contact-illustration-title">
                                <eon-ui-headline
                                    text={title}
                                    size="h3"
                                    scheme={titleColor}
                                />
                            </div>
                            {isMobile && !hidePhotoOnMobile && <TileImage />}
                            <div className="contact-illustration-link-text">
                                <div className="contact-illustration-link">
                                    <Placeholder
                                        name="eon-links"
                                        rendering={rendering}
                                    />
                                </div>
                                {description && (
                                    <div className="contact-illustration-text">
                                        <eon-ui-text
                                            text-style="copy-small"
                                            dangerouslySetInnerHTML={{
                                                __html: description
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {!isMobile && <TileImage />}
                    </div>
                </eon-ui-background>
            </div>
        </div>
    );
};

export default withSitecoreContext()(ContactIllustrationsTile);
