import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import TableColumn from '../TableColumn/index';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const Table = ({ rendering, sitecoreContext }) => {
    const mobileLeftRow = [];
    const mobileRendering = rendering;

    rendering.placeholders['eon-table-column'].map((column) =>
        mobileLeftRow.push(column?.placeholders?.['eon-table-cell-rows-1']?.[0])
    );
    const mobileMapArray =
        rendering.placeholders['eon-table-column'][0].placeholders;

    const isMobile = useMobileCheck();

    if (sitecoreContext.pageEditing) {
        return (
            <div className="table">
                <p>Table Component</p>
                <div className="headline-wrap">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="table-wrap">
                    <Placeholder
                        name="eon-table-column"
                        rendering={rendering}
                    />
                </div>
                <p>Cta placeholder</p>
                <div className="cta-container-wrapper">
                    <Placeholder
                        name="eon-ctabutton"
                        rendering={rendering}
                        toggle-full-width-on-mobile="true"
                        full-width-on-mobile="true"
                        full-width="true"
                    />
                    <div className="cta-link-wrapper">
                        <p>{rendering.fields.linkDescription.value}</p>
                        {rendering.fields.tableLink.value.href !== '' && (
                            <eon-ui-link
                                text={
                                    rendering.fields.tableLink.value.text
                                        ? rendering.fields.tableLink.value.text
                                        : 'Zobacz'
                                }
                                href={rendering.fields.tableLink.value.href}
                                target={rendering.fields.tableLink.value.target}
                            ></eon-ui-link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    if (!isMobile) {
        return (
            <div className="table">
                <div className="headline-wrap">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="table-wrap">
                    <Placeholder
                        name="eon-table-column"
                        rendering={rendering}
                    />
                </div>
                <div className="cta-container-wrapper">
                    <Placeholder
                        name="eon-ctabutton"
                        rendering={rendering}
                        toggle-full-width-on-mobile="true"
                        full-width-on-mobile="true"
                    />
                    <div className="cta-link-wrapper">
                        <p>{rendering.fields.linkDescription.value}</p>
                        {rendering.fields.tableLink.value.href !== '' && (
                            <eon-ui-link
                                text={
                                    rendering.fields.tableLink.value.text
                                        ? rendering.fields.tableLink.value.text
                                        : 'Zobacz'
                                }
                                href={rendering.fields.tableLink.value.href}
                                target={rendering.fields.tableLink.value.target}
                            ></eon-ui-link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    if (isMobile) {
        return (
            <div className="table">
                <div className="headline-wrap">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="table-wrap">
                    {Object.keys(mobileMapArray).map((col, index) =>
                        index > 0 ? (
                            <TableColumn
                                key={`${col}-${index}`}
                                rendering={rendering}
                                mobileLeftRow={mobileLeftRow}
                                colIndex={index + 1}
                                mobileRendering={mobileRendering}
                            />
                        ) : null
                    )}
                </div>
                <div className="cta-container-wrapper">
                    <Placeholder
                        name="eon-ctabutton"
                        rendering={rendering}
                        toggle-full-width-on-mobile="true"
                        full-width-on-mobile="true"
                    />
                    <div className="cta-link-wrapper">
                        <p>{rendering.fields.linkDescription.value}</p>
                        {rendering.fields.tableLink.value.href !== '' && (
                            <eon-ui-link
                                text={
                                    rendering.fields.tableLink.value.text
                                        ? rendering.fields.tableLink.value.text
                                        : 'Zobacz'
                                }
                                href={rendering.fields.tableLink.value.href}
                                target={rendering.fields.tableLink.value.target}
                            ></eon-ui-link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default withSitecoreContext()(Table);
