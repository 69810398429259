import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ArticleHub = ({ rendering, sitecoreContext }) => {
    if (sitecoreContext.pageEditing)
        return (
            <div className="ArticleHub">
                <p>ArticleHub Component</p>
                <Placeholder
                    name="eon-article-hub-content"
                    rendering={rendering}
                />
            </div>
        );
    else
        return (
            <div className="ArticleHub">
                <Placeholder
                    name="eon-article-hub-content"
                    rendering={rendering}
                />
            </div>
        );
};

export default withSitecoreContext()(ArticleHub);
