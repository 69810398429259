import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import gridStyle from '../../lib/GridControlStyleResolver.js';

const PreviewAndDownloadTilesRow = ({ params, rendering }) => {
    const placeholdersKeys = Object.keys(rendering.placeholders);
    const columns = placeholdersKeys?.filter((x) => x.includes('column')) || [];

    return (
        <div className="preview-and-download-tiles-row">
            <eon-ui-grid-control grid={gridStyle(params.columns)} same-height>
                {columns.map((p, key) => (
                    <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                        <Placeholder
                            name={p}
                            rendering={rendering}
                            params={params}
                        />
                    </eon-ui-xx>
                ))}
            </eon-ui-grid-control>
        </div>
    );
};

export default PreviewAndDownloadTilesRow;
