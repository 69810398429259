import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const ProcessStepsSectionTwo = ({ rendering, sitecoreContext }) => {
    const pageEditor = sitecoreContext.pageEditing;
    const fields = rendering.fields;
    const stepNumberField = fields.stepNumber?.value ?? '';
    const stepNumber = `${stepNumberField}. `;
    const stepName = fields.stepName?.value ?? '';
    const stepDescription = fields.description?.value ?? '';

    return (
        <div className="process-steps-section-two">
            {pageEditor && <p>Process Step Section Two</p>}
            <div className="process-steps-section-two__step-section">
                <div className="process-steps-section-two__number">
                    <eon-ui-text color="eon-darkgrey" text-style="subheadline">
                        {stepNumber}
                    </eon-ui-text>
                </div>
                <div className="process-steps-section-two__name">
                    <eon-ui-text color="eon-darkgrey" text-style="subheadline">
                        {stepName}
                    </eon-ui-text>
                </div>
            </div>

            <div className="process-steps-section-two__description">
                <eon-ui-text
                    color="eon-darkgrey"
                    text-style="subheadline"
                    font-weight="400"
                >
                    {stepDescription}
                </eon-ui-text>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ProcessStepsSectionTwo));
