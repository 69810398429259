import React from 'react';
import Slider from 'react-slick';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import PreviewAndDownloadTile from '../PreviewAndDownloadTile';

const PreviewAndDownloadTiles = ({ rendering, params }) => {
    const isMobile = useMobileCheck();

    const placeholdersKeys = Object.keys(rendering.placeholders);
    if (!placeholdersKeys) {
        return '';
    }

    const rows = rendering.placeholders['eon-preview-and-download-tiles-rows'];
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipe: true,
        dots: true,
        responsive: [
            {
                breakpoint: 675,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="preview-and-download-tiles">
            <div>
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            {isMobile && rows?.length && (
                <Slider {...settings}>
                    {rows
                        .flatMap((row) =>
                            Object.values(row?.placeholders)?.flatMap(
                                (row) => row
                            )
                        )
                        .map((tile, key) => (
                            <div key={`content-agregator-slide-${key}`}>
                                <PreviewAndDownloadTile
                                    {...tile}
                                    params={params}
                                />
                            </div>
                        ))}
                    <div className="ghost-card" />
                </Slider>
            )}

            {!isMobile && (
                <div>
                    <Placeholder
                        name="eon-preview-and-download-tiles-rows"
                        params={params}
                        rendering={rendering}
                    />
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(PreviewAndDownloadTiles);
