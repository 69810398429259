import React, { useEffect, useRef } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { debounce } from '../../utils/debounce';

const ArticleCategoriesTabs = ({ fields, sitecoreContext, t }) => {
    const history = useHistory();
    const elementRef = useRef();

    const articleCategories = fields?.articleCategories.filter((category) => {
        const cat = category;

        if (category.name.includes('[')) {
            cat.name = 'Content|ArticleAllCategories';
        }

        return cat;
    });

    useEffect(() => {
        const element = elementRef?.current;

        const ChangeTab = debounce((value) => {
            const category = articleCategories[value.detail];
            if (category.isLinkExternal) {
                window.location.href = category.href;
            }
            else {
                history.push(category.href);
            }
        }, 200);

        if (element) {
            element.addEventListener('tabChanged', ChangeTab);

            return () => {
                element.removeEventListener('tabChanged', ChangeTab);
            };
        }
    }, [elementRef.current]);

    if (sitecoreContext.pageEditing) {
        return (
            <div className="article-categories-tabs">
                <p>ArticleCategoriesTabs Component</p>
                <div>
                    <eon-ui-tabs
                        active-tab-index={fields.indexOfCurrent}
                        ref={elementRef}
                    >
                        {articleCategories
                            .map((c) => c.name)
                            .map((p, key) => (
                                <eon-ui-tab key={key} header={t(p)} />
                            ))}
                    </eon-ui-tabs>
                </div>
            </div>
        );
    }

    return (
        <div className="article-categories-tabs">
            <eon-ui-tabs
                active-tab-index={fields.indexOfCurrent}
                ref={elementRef}
            >
                {articleCategories
                    .map((c) => c.name)
                    .map((p, key) => (
                        <eon-ui-tab key={key} header={t(p)} />
                    ))}
            </eon-ui-tabs>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ArticleCategoriesTabs));
