import React, { useEffect, useState, useRef } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import { scrollTo } from '../../utils/scrollTo.js';
import { useInterval } from '../../hooks/useInterval.js';

const AccordionPane = ({ fields, rendering, sitecoreContext, params }) => {
    const title = fields?.title?.value ?? '';

    const [isScrolled, setScrolled] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [offset, setOffset] = useState();
    const [expanded, setExpanded] = useState(false);

    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        setScrolled(false);
        if (
            !document ||
            !window ||
            !params?.elementId ||
            sitecoreContext.pageEditing ||
            !ref?.current
        ) {
            return;
        }
        const url = window?.location.href || '';
        if (url.includes('#') && url.split('#').pop() === params.elementId) {
            setIsActive(true);
            return;
        }
        setIsActive(false);
        setExpanded(false);
    }, [location]);

    useEffect(() => {
        if (isActive && offset && !isScrolled) {
            scrollTo(offset, window).then(() => {
                setTimeout(() => setExpanded(true), 300);
                setTimeout(() => setScrolled(true), 1000);
            });
        }
    });

    useInterval(
        () => {
            var newOffset =
                ref.current.getBoundingClientRect().top + window.scrollY;

            //prevents to set offset when just header is rendered
            if (newOffset < 150) {
                return;
            }
            if (!offset || Math.abs(offset - newOffset) > 10) {
                setOffset(newOffset);
            }
        },
        300,
        isActive
    );

    return (
        <>
            {sitecoreContext.pageEditing && (
                <div className="accordion-pane-ee-wrapper">
                    <p>Accordion Pane</p>
                    <h3>{title}</h3>
                    <Placeholder
                        name="eon-pane-content"
                        rendering={rendering}
                    />
                    <br />
                </div>
            )}

            {!sitecoreContext.pageEditing && (
                <eon-ui-accordion-pane
                    ref={ref}
                    pane-title={title}
                    slot={`Pane${rendering.dataSource}`}
                    data-anchor-id={params.elementId}
                    is-active={expanded}
                >
                    <Placeholder
                        name="eon-pane-content"
                        rendering={rendering}
                    />
                </eon-ui-accordion-pane>
            )}
        </>
    );
};

export default withSitecoreContext()(AccordionPane);
