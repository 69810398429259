import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const Accordion = ({ rendering, sitecoreContext, params }) => {
    return (
        <div className="eon-accordion">
            {sitecoreContext.pageEditing && (
                <>
                    <p>Accordion</p>
                    <div>
                        <Placeholder
                            name="eon-accordion-headline"
                            rendering={rendering}
                        />
                    </div>
                    <div>
                        <Placeholder
                            name="eon-accordion-items"
                            rendering={rendering}
                        />
                    </div>
                </>
            )}

            {!sitecoreContext.pageEditing && (
                <>
                    <Placeholder
                        name="eon-accordion-headline"
                        rendering={rendering}
                        params={{ parentStyleClass: 'accordion-headline' }}
                    />
                    <eon-ui-accordion mode="single" size={params.size}>
                        <Placeholder
                            name="eon-accordion-items"
                            rendering={rendering}
                        />
                    </eon-ui-accordion>
                </>
            )}
        </div>
    );
};

export default withSitecoreContext()(Accordion);
