import React from 'react';
import Slider from 'react-slick';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ContentAgregatorTile from '../ContentAgregatorTile';

const ContentAgregator = ({ rendering, sitecoreContext }) => {
    var placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return null;
    }

    const tiles = rendering.placeholders?.['eon-content-agregator-tiles'] || [];
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipe: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                    swipe: true
                }
            },
            {
                breakpoint: 730,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    swipe: true
                }
            }
        ]
    };

    return (
        <div className="content-agregator">
            <div className="content-agregator_header">
                <div className="content-agregator__headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
            </div>
            <div className="content-agregator_container">
                {sitecoreContext.pageEditing && (
                    <div className="content-agregator-tiles">
                        <Placeholder
                            name="eon-content-agregator-tiles"
                            rendering={rendering}
                        />
                    </div>
                )}
                {!sitecoreContext.pageEditing && (
                    <Slider {...settings}>
                        {tiles.map((tile, key) => (
                            <div key={`content-agregator-slide-${key}`}>
                                <ContentAgregatorTile fields={tile.fields} />
                            </div>
                        ))}
                        <div className="ghost-card" />
                    </Slider>
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(ContentAgregator);
