import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import OfferTeaserMain from './OfferTeaserMain/index.js';
import OfferTeaserSecondary from './OfferTeaserSecondary/index.js';

const OfferTeasersList = ({
    rendering,
    params,
    fields,
    hubRef,
    hubTags,
    t,
    sitecoreContext
}) => {
    const handleMainTeaser = () => {
        let main = {};
        if (params.mainTeaser) {
            fields.offerTeasers.forEach((teaser) => {
                if (`{${teaser.id.toUpperCase()}}` === params.mainTeaser)
                    main = { ...teaser };
            });
        } else {
            main = undefined;
        }
        return main;
    };

    if (!fields.offerTeasers) {
        if (sitecoreContext.pageEditing) {
            return (
                <eon-ui-text text-style="copy">
                    {t('Editor|MissingOfferLandingPages')}
                </eon-ui-text>
            );
        } else {
            return '';
        }
    }

    const offerTeasers = fields.offerTeasers.filter(
        (field) => `{${field.id.toUpperCase()}}` !== params.mainTeaser
    );
    const mainOfferTeaser = handleMainTeaser();

    return (
        <div className="offer-teaser-list">
            <div className="offer-teaser-list-wrapper">
                {mainOfferTeaser && (
                    <OfferTeaserMain
                        rendering={rendering}
                        item={mainOfferTeaser}
                        hubTags={hubTags}
                    ></OfferTeaserMain>
                )}
                <div className="offer-teaser-secondary-wrapper">
                    <div className="bg"></div>
                    {offerTeasers.map((x, index) => (
                        <OfferTeaserSecondary
                            key={index}
                            rendering={rendering}
                            item={x}
                            hubTags={hubTags}
                        ></OfferTeaserSecondary>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(OfferTeasersList));
