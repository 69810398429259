import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const NestedAccordion = ({ rendering, sitecoreContext, params }) => {
    return (
        <div className="eon-accordion">
            {sitecoreContext.pageEditing && (
                <>
                    <p>Nested Accordion</p>
                    <div>
                        <Placeholder
                            name="eon-accordion-headline"
                            rendering={rendering}
                        />
                    </div>
                    <div>
                        <Placeholder
                            name="eon-nested-accordion-items"
                            rendering={rendering}
                        />
                    </div>
                </>
            )}

            {!sitecoreContext.pageEditing && (
                <>
                    <Placeholder
                        name="eon-accordion-headline"
                        rendering={rendering}
                        params={{ parentStyleClass: 'accordion-headline' }}
                    />
                    <eon-ui-accordion
                        scheme="whiteTurquoiseOnUltralightgrey"
                        mode="single"
                        size={params.size}
                        nested-accordion={true}
                    >
                        <Placeholder
                            name="eon-nested-accordion-items"
                            rendering={rendering}
                        />
                    </eon-ui-accordion>
                </>
            )}
        </div>
    );
};

export default withSitecoreContext()(NestedAccordion);
