import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import NavigationItem from '../NavigationItem/index.js';

const FlyoutMenu = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);

    return (
        <>
            <div id={params.flyoutId} className="secondary-nav">
                <div className="flyout-header-wrapper">
                    <div className="flyout-container"></div>
                </div>

                <div className="secondary-nav-wrapper">
                    {placeholders
                        .filter((x) => x.includes('column'))
                        .map((p, key) => (
                            <div
                                className="secondary-nav-column one-fourth"
                                key={`flyoutMenu-${key}`}
                            >
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                    key={key}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};
export default FlyoutMenu;
