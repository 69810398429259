import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const OfferTagPillGroup = ({
    rendering,
    fields,
    hubRef,
    tagAction,
    hubTags,
    t,
    sitecoreContext
}) => {
    const tags = fields.tags ? fields.tags : hubTags;
    const filterByText = t('Content|OfferTagPillGroup|FilterBy');
    if (sitecoreContext.pageEditing) {
        return (
            <>
                <p>OfferTagPillGroup</p>
                {!tags && (
                    <eon-ui-text text-style="copy">
                        {t('Editor|MissingOfferLandingPages')}
                    </eon-ui-text>
                )}
            </>
        );
    }

    if (!tags) {
        return '';
    }

    return (
        <div className="offer-tag-pill-group">
            {tags && tags.length > 0 && (
                <span className="filter-with-text">{filterByText}</span>
            )}
            <eon-ui-tag-pill-group scheme="eon-red">
                {tags.map((item, i) => (
                    // eslint-disable-next-line react/jsx-key
                    <eon-ui-tag-pill
                        key={i}
                        filter-name={`link${i}`}
                        text={item}
                        onClick={() => {
                            tagAction(item);
                        }}
                        is-selected={hubTags.indexOf(item) > -1}
                    ></eon-ui-tag-pill>
                ))}
            </eon-ui-tag-pill-group>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(OfferTagPillGroup));
