import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const Profile = ({ fields, sitecoreContext, rendering }) => {
    const isMobile = useMobileCheck();
    const altText = fields?.image?.value?.alt ?? '';
    const imageUrl = fields?.image?.value?.src ?? '';

    return (
        <div className="profile">
            {sitecoreContext && sitecoreContext.pageEditing && (
                <div>Profile Component</div>
            )}

            <div className="profile__wrapper">
                <div className="profile__wrapper-image">
                    {imageUrl && (
                        <eon-ui-image
                            aspect-ratio="ratio-1x1"
                            width={isMobile ? '148px' : '173px'}
                            height={isMobile ? '148px' : '173px'}
                            src={imageUrl}
                            alt={altText}
                        />
                    )}
                </div>

                <div className="profile__wrapper-content">
                    {fields?.name?.value && (
                        <div className="profile__wrapper-name">
                            <eon-ui-headline
                                size="h4"
                                text={fields?.name?.value}
                            />
                        </div>
                    )}

                    {fields?.title?.value && (
                        <div className="profile__wrapper-title">
                            <eon-ui-text text-style="copy" font-weight="bold">
                                {fields.title.value}
                            </eon-ui-text>
                        </div>
                    )}

                    {fields?.description?.value && (
                        <div className="profile__wrapper-description">
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={fields.description.value}
                            />
                        </div>
                    )}

                    <div className="profile__wrapper-links">
                        <Placeholder name="eon-links" rendering={rendering} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(Profile);
