import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const CustomerBids = ({ t, bid }) => {
    const downloadAllRef = useRef();

    useEffect(() => {
        const element = downloadAllRef?.current;

        const handleDownloadAll = (e) => {
            e.preventDefault();

            var temporaryDownloadLink = document.createElement('a');
            temporaryDownloadLink.style.display = 'none';
            document.body.appendChild(temporaryDownloadLink);

            bid.documents.forEach((document) => {
                temporaryDownloadLink.setAttribute('href', document.href);
                temporaryDownloadLink.setAttribute('download', document.name);
                temporaryDownloadLink.click();
            });

            document.body.removeChild(temporaryDownloadLink);
        };

        if (element) {
            element.addEventListener('linkClick', handleDownloadAll);
            return () => {
                element.removeEventListener('linkClick', handleDownloadAll);
            };
        }
    }, []);

    return (
        <eon-ui-accordion-pane
            pane-title={`${bid.number} ${bid.title ?? ''}`}
            slot={`bid-${bid.number}`}
        >
            <div className="single-bid">
                {bid.info1 && (
                    <div className="info-box">
                        <eon-ui-background color="eon-limeyellow">
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={bid.info1}
                            />
                        </eon-ui-background>
                    </div>
                )}

                {bid.info2 && (
                    <div className="info-box">
                        <eon-ui-background color="eon-turquoise">
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={bid.info2}
                            />
                        </eon-ui-background>
                    </div>
                )}

                <div className="description">
                    <eon-ui-text
                        text-style="copy"
                        dangerouslySetInnerHTML={{
                            __html: bid.description
                        }}
                    />
                </div>

                {bid.documents.length > 0 && (
                    <>
                        <div className="download-documents">
                            <eon-ui-headline
                                size="h4"
                                text={t('Content|Bids|DocsToDownload')}
                            />

                            {bid.documents.length > 1 && (
                                <eon-ui-link
                                    ref={downloadAllRef}
                                    text={`${t('Content|Bids|DownloadAll')} ${
                                        bid.documents.length
                                    } ${
                                        bid.documents.length < 5
                                            ? t('Content|Bids|Documents1')
                                            : t('Content|Bids|Documents2')
                                    }`}
                                    hide-line-on-hover={true}
                                    hide-text-mobile={true}
                                    icon="download"
                                    download
                                />
                            )}
                        </div>

                        <div className="documents">
                            {bid.documents.map((doc, docIndex) => (
                                <div
                                    key={`document-${bid.number}-${docIndex}`}
                                    className="document"
                                >
                                    <div className="document-description">
                                        <eon-ui-text text-style="subheadline">
                                            {doc.name}
                                        </eon-ui-text>
                                        <eon-ui-text text-style="copy-small">
                                            {doc.publicationDate}
                                        </eon-ui-text>
                                    </div>
                                    <div>
                                        <eon-ui-link
                                            text={t('Content|Bids|Download')}
                                            hide-line-on-hover={true}
                                            hide-text-mobile={true}
                                            href={doc.href}
                                            download
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </eon-ui-accordion-pane>
    );
};

export default withTranslation()(withSitecoreContext()(CustomerBids));
