import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const TableColumn = ({
    rendering,
    sitecoreContext,
    mobileLeftRow,
    mobileRendering,
    colIndex
}) => {
    const isMobile = useMobileCheck();

    const rows = rendering.placeholders;

    if (!isMobile) {
        return (
            <div className="table-column">
                {sitecoreContext.pageEditing && <p>TableColumn Component</p>}

                {Object.keys(rows).map((row, index) => {
                    const color = rendering.params.columnColor
                        ? rendering.params.columnColor
                        : null;
                    const thickness =
                        rendering.params.columnThickness == 'large'
                            ? '3px'
                            : null;
                    const cellStyle = {};
                    if (color) {
                        cellStyle['borderBottomColor'] = `var(--${color})`;
                    }
                    if (thickness) {
                        cellStyle['borderBottomWidth'] = thickness;
                    }
                    return (
                        <div
                            key={`${row}-${index}`}
                            className={`table-column-row row-${index + 1}`}
                            style={cellStyle}
                        >
                            <Placeholder
                                name={`eon-table-cell-rows-${index + 1}`}
                                rendering={rendering}
                                index={index}
                            />
                        </div>
                    );
                })}
            </div>
        );
    } else if (isMobile && !sitecoreContext.pageEditing) {
        return (
            <div className="table-column">
                {sitecoreContext.pageEditing && <p>TableColumn Component</p>}
                {mobileLeftRow?.map((row, index) => {
                    const data =
                        mobileRendering?.placeholders?.['eon-table-column'][
                            index
                        ];

                    let type = 'text';
                    let value = '';

                    const color = data.params.columnColor
                        ? data.params.columnColor
                        : null;
                    const thickness =
                        data.params.columnThickness == 'large' ? '3px' : null;

                    const cellStyle = {};
                    if (color) {
                        cellStyle['borderBottomColor'] = `var(--${color})`;
                    }
                    if (thickness) {
                        cellStyle['borderBottomWidth'] = thickness;
                    }

                    if (
                        data?.placeholders?.[`eon-table-cell-rows-${colIndex}`]
                    ) {
                        if (
                            data?.placeholders?.[
                                `eon-table-cell-rows-${colIndex}`
                            ]?.[0]?.componentName !== 'Link'
                        ) {
                            value = data?.placeholders?.[
                                `eon-table-cell-rows-${colIndex}`
                            ][0]?.fields.cellContent.value
                                ? data?.placeholders?.[
                                      `eon-table-cell-rows-${colIndex}`
                                  ][0]?.fields.cellContent.value
                                : '';
                            if (index === 0) {
                                value = `<strong>${value}</strong>`;
                            }
                            type = 'text';
                        } else {
                            value =
                                data?.placeholders?.[
                                    `eon-table-cell-rows-${colIndex}`
                                ][0];
                            type = 'link';
                        }
                    } else {
                        value = '';
                    }

                    return (
                        <div
                            className="table-column-mobile-wrapper"
                            key={index}
                        >
                            <div
                                className={`table-cell-content left`}
                                style={cellStyle}
                            >
                                <eon-ui-rte-renderer
                                    rte-source="aem"
                                    content={
                                        mobileLeftRow[index]?.fields
                                            ?.cellContent?.value
                                            ? mobileLeftRow[index].fields
                                                  .cellContent.value
                                            : ''
                                    }
                                ></eon-ui-rte-renderer>
                            </div>
                            {type !== 'link' ? (
                                <div
                                    className={`table-cell-content right`}
                                    style={cellStyle}
                                >
                                    <eon-ui-rte-renderer
                                        rte-source="aem"
                                        content={value}
                                    ></eon-ui-rte-renderer>
                                </div>
                            ) : (
                                <div
                                    className={`table-cell-content right`}
                                    style={cellStyle}
                                >
                                    <eon-ui-link
                                        href={value?.fields?.link?.value?.href}
                                        text={value?.fields?.link?.value?.text}
                                        target={
                                            value?.fields?.link?.value?.target
                                        }
                                    ></eon-ui-link>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
};

export default withSitecoreContext()(TableColumn);
