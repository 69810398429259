import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

const IndustryTile = ({ rendering }) => {
    if (!rendering) {
        return '';
    }
    const cardTitle = rendering.fields?.header?.value ?? '';
    const cardImage = rendering.fields?.image?.value?.src ?? '';
    const cardImageAlt = rendering.fields?.image?.value?.alt ?? '';
    const cta = rendering.fields?.link?.value?.href ?? '';
    const ctaTarget = rendering.fields?.link?.value?.target ?? '';
    const ctaText = rendering.fields?.link?.value?.text ?? '';

    const Card = () => (
        <div className="industry-tile-background">
            <div className="industry-tile-image">
                {cardImage !== undefined && (
                    <eon-ui-image
                        slot="background-image"
                        src={cardImage}
                        alt={cardImageAlt}
                        class="hydrated image"
                    />
                )}
                <eon-ui-headline size="h3" text={cardTitle} scheme="white" />
                <div className="industry-tile-point-link">
                    <eon-ui-link
                        scheme="white"
                        text={ctaText}
                        class="hydrated"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="industry-tile">
            {cta.startsWith('/') && (
                <Link to={cta} target={ctaTarget}>
                    <Card />
                </Link>
            )}
            {!cta.startsWith('/') && (
                <a href={cta} target={ctaTarget}>
                    <Card />
                </a>
            )}
        </div>
    );
};

export default withSitecoreContext()(IndustryTile);
