import React from 'react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const LogoutMessage = ({ fields }) => {
    const imageUrl = fields?.image?.value?.src ?? '';
    const altText = fields?.image?.value?.alt ?? '';
    const infoText = fields?.description?.value ?? '';

    const isMobile = useMobileCheck();

    return (
        <div className="logout-message">
            {fields?.image?.value?.svg !== undefined && (
                <div
                    className="logout-message__image-wrapper"
                    dangerouslySetInnerHTML={{
                        __html: fields?.image?.value?.svg
                    }}
                />
            )}
            {fields?.image?.value?.src !== undefined && (
                <div className="logout-message__image-wrapper">
                    <img
                        className="logout-message__image-wrapper--image"
                        alt={altText}
                        src={imageUrl}
                    />
                </div>
            )}
            <div className="logout-message-description">
                <eon-ui-headline
                    text={infoText}
                    size={isMobile ? 'h4' : 'h3'}
                />
            </div>
        </div>
    );
};

export default LogoutMessage;
