import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const RelatedArticleTeaser = ({
    params,
    fields,
    rendering,
    sitecoreContext,
    t
}) => {
    if (!fields) {
        return null;
    }

    if (
        sitecoreContext.pageEditing &&
        (!fields.teaserTitle ||
            !fields.teaserDescription ||
            !fields.teaserImage)
    ) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|IncorrectDatasourceForRelatedArticleTeaser')}
            </eon-ui-text>
        );
    }

    const title = fields.teaserTitle?.value;
    const showDescription = params?.showDescription;
    const description = fields.teaserDescription?.value;
    const image = fields.teaserImage?.value?.src;
    const imageAlt = fields.teaserImage?.value?.alt;
    const tags = fields.relatedArticleTags;
    const showTags = fields.showTags.value && tags;

    if (sitecoreContext.pageEditing && !image) {
        return <eon-ui-text text-style="copy">Article is empty</eon-ui-text>;
    }

    if (!image) {
        return '';
    }

    return (
        <div className="related-article-teaser">
            <eon-ui-background class="hydrated" full-height>
                <div className="related-article-teaser_article">
                    <div className="related-article-teaser_image">
                        <eon-ui-image
                            src={image}
                            title={title}
                            alt={imageAlt}
                            aspect-ratio="ratio-2x1"
                            lazy
                        />
                    </div>

                    <div className="related-article-teaser_content">
                        {showTags && tags && (
                            <div className="tags-container">
                                {tags.map((tag, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="offer-teaser-tag"
                                        >
                                            {tag}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className="related-article-teaser_header">
                            <eon-ui-headline text={title} size="h4" />
                        </div>
                        {showDescription && (
                            <div className="related-article-teaser_description">
                                <eon-ui-text text-style="copy">
                                    {description}
                                </eon-ui-text>
                            </div>
                        )}
                        <div className="related-article-teaser_link">
                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(RelatedArticleTeaser));
