import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../temp/config';
import CustomerBidsStatus from './CustomerBidsStatus.js';
import Bids from './Bids.jsx';
import PublicBids from './PublicBids.jsx';

const CustomerBids = ({ sitecoreContext, fields, t, params }) => {
    const initialStatus = params?.showPublicBids
        ? CustomerBidsStatus.PUBLIC
        : CustomerBidsStatus.ACTUAL;

    const [status, setStatus] = useState(initialStatus);
    const [data, setData] = useState(fields?.bids);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!fields?.bidsContainer || params?.showPublicBids) {
            return;
        }

        setIsLoading(true);

        const queryParams = {
            bidsId: fields?.bidsContainer?.id || '',
            lang: sitecoreContext.language,
            sc_apikey: config.sitecoreApiKey,
            status: status
        };

        var query = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join('&');

        fetch(`${config.sitecoreApiHost}/api/eon/suppliers/bids?${query}`)
            .then((response) => response.json())
            .then((result) => {
                setData(result);
                setIsLoading(false);
            });
    }, [status]);

    const getBidsTitle = () => {
        switch (status) {
            case CustomerBidsStatus.PROGRESS:
                return fields.inProgressBidsTitle?.value;
            case CustomerBidsStatus.DONE:
                return fields.doneBidsTitle?.value;
            case CustomerBidsStatus.PUBLIC:
                return fields.publicBidsTitle?.value;
            default:
                return fields.actualBidsTitle?.value;
        }
    };

    return (
        <>
            {params?.showPublicBids && data?.bids ? (
                <PublicBids bids={data.bids} isLoading={isLoading} t={t} title={getBidsTitle()} />
            ) : (
                <Bids
                    bids={data.bids}
                    isLoading={isLoading}
                    setStatus={setStatus}
                    status={status}
                    t={t}
                    title={getBidsTitle()}
                />
            )}
        </>
    );
};

export default withTranslation()(withSitecoreContext()(CustomerBids));
