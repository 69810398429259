import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import modalSize from '../../lib/ModalSizeResolver';

const ModalLink = ({ rendering, fields, params }) => {
    const modalId = 'modalId' + rendering.uid;
    const title = fields?.title?.value ?? '';
    const modalSizeParameter = params.modalSize ?? 'S';
    return (
        <div className="cta-wrapper">
            <eon-ui-link
                href="#"
                text={title}
                open-modal-id={modalId}
            ></eon-ui-link>
            <eon-ui-modal
                modal-id={modalId}
                show-closing-x="true"
                full-width="true"
                full-width-width={modalSize(modalSizeParameter)}
            >
                <Placeholder name="eon-modal" rendering={rendering} />
            </eon-ui-modal>
        </div>
    );
};

export default ModalLink;
