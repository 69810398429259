import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ProcessSteps = ({ rendering }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }
    return (
        <div className="process-steps">
            <div className="process-steps__headline">
                <Placeholder name="eon-headlines" rendering={rendering} />
            </div>
            <div className="process-steps__info-box">
                <Placeholder name="eon-infobox" rendering={rendering} />
            </div>
            <div className="process-steps-wrapper">
                <div className="process-steps__wrapper">
                    <div className="process-steps__section-one">
                        <Placeholder
                            name="eon-process-steps-section-one"
                            rendering={rendering}
                        />
                    </div>
                    <div className="process-steps__section-two">
                        <Placeholder
                            name="eon-process-steps-section-two-container"
                            rendering={rendering}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProcessSteps);
