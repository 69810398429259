import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const VotingCards = ({ sitecoreContext, rendering }) => {
    return (
        <>
            {sitecoreContext.pageEditing && <p>Voting Cards</p>}

            <div className="voting-cards">
                <div className="voting-cards-headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>

                <div className="voting-cards-tiles">
                    <Placeholder name="voting-cards-content" rendering={rendering} />
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(VotingCards);
