import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const HomePageHeaderSlider = ({ rendering, sitecoreContext, params }) => {
    return (
        <div>
            {sitecoreContext.pageEditing ? (
                <>
                    <div>Home Page Header Slider</div>
                    <Placeholder
                        name="home-page-header-slider-items"
                        rendering={rendering}
                        params={{ slider: 'true' }}
                    />
                    <br />
                    <br />
                </>
            ) : (
                <div className="home-page-header-slider">
                    <eon-ui-carousel
                        autoplay={params.autoplay * 1}
                        autoplay-mode={params.autoplayMode}
                        controls={!!(params.controls * 1)}
                        darken-controls={!!(params.darkenControls * 1)}
                        indicator-position={params.indicatorPosition}
                        indicator-size={params.indicatorSize}
                        indicators-on-image={!!(params.indicatorsOnImage * 1)}
                        pause={!!(params.pause * 1)}
                        scheme={params.scheme}
                        indicators={!!(params.indicators * 1)}
                        same-height
                    >
                        <Placeholder
                            name="home-page-header-slider-items"
                            rendering={rendering}
                            params={{ slider: 'true' }}
                        />
                    </eon-ui-carousel>
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(HomePageHeaderSlider);
