import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ContentAgregatorTile = ({ fields, sitecoreContext }) => {
    if (sitecoreContext.pageEditing && !fields) {
        return <div>Editor|EmptyDatasource</div>;
    }

    return (
        <div className="content-agregator-tile">
            <eon-ui-background color="eon-white" full-height>
                <div className="content-agregator-tile_cotainer">
                    {sitecoreContext.pageEditing && (
                        <p>Content Agregator Tile</p>
                    )}

                    <div className="content-agregator-tile__image">
                        <eon-ui-image
                            lazy
                            height="auto"
                            width="100%"
                            src={fields.image.value?.src}
                            alt={fields.image.value?.alt}
                        />
                    </div>

                    <div className="content-agregator-tile__content">
                        <div className="content-agregator-tile__heading">
                            <eon-ui-headline
                                text={fields.heading?.value}
                                size="h4"
                            />
                        </div>
                        <div className="content-agregator-tile__description">
                            <eon-ui-text
                                text-style="copy"
                                dangerouslySetInnerHTML={{
                                    __html: fields.description?.value
                                }}
                            />
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(ContentAgregatorTile);
