import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SingleProductColumnSection = ({ rendering, sitecoreContext, params }) => {
    const placeholders = Object.keys(rendering.placeholders);
    const borderColor = `var(--${params.borderColor})`;

    if (!placeholders) {
        return null;
    }

    if (
        !sitecoreContext.pageEditing &&
        params.hidden === '1' &&
        params.showHiddenRecords === '0'
    ) {
        return null;
    }

    return (
        <div className="columns-section">
            {sitecoreContext.pageEditing && (
                <p>Single Product Columns Section</p>
            )}

            {!!rendering.fields?.heading && (
                <div
                    className="columns-section__headline"
                    style={{ borderColor: borderColor }}
                >
                    <eon-ui-text text-style="copy" font-weight="bold">
                        {rendering.fields?.heading?.value}
                    </eon-ui-text>
                    <eon-ui-text text-style="copy">
                        {rendering.fields?.subheading?.value}
                    </eon-ui-text>
                </div>
            )}

            <div className="columns-section__column">
                <Placeholder
                    name="eon-single-product-section"
                    rendering={rendering}
                    params={params}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(SingleProductColumnSection);
