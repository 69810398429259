import React, { useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ListLink = ({ fields }) => {
    const defaultScheme = 'darkgrey';
    const [scheme, setScheme] = useState(defaultScheme);
    const [isHover, setHover] = useState(false);
    const icon = fields?.icon?.value;
    const linkText = fields?.linkText?.value;
    const description = fields?.description?.value;
    const linkHref = fields?.link?.value?.href;
    const target = fields?.link?.value?.target;

    return (
        <div
            className="file"
            onMouseOver={() => {
                setScheme('red');
                setHover(true);
            }}
            onMouseLeave={() => {
                setScheme(defaultScheme);
                setHover(false);
            }}
        >
            <a
                className="file__download"
                text={description}
                title={description}
                href={linkHref}
                target={target}
                rel="noopener noreferrer"
            >
                <eon-ui-link
                    text={description}
                    className="hydrated"
                    scheme={scheme}
                    size="normal"
                    force-hover-style={isHover}
                    hide-icon={true}
                />

                <eon-ui-link
                    text={linkText}
                    title={description}
                    icon={icon}
                    force-hover-style={isHover}
                    hide-line-on-hover={true}
                    hide-text-mobile={true}
                />
            </a>
        </div>
    );
};

export default withSitecoreContext()(ListLink);
