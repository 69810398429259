const OperatorTypes = {
    isEqualTo: '{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}',
    isNotEqualTo: '{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}',
    contains: '{BF8935A6-1976-43A0-ABA5-D0BC128A76EA}',
    doesNotContain: '{45AAB0FB-775B-40F5-B3B8-7CAE3ABBF643}',
    hasItems: '{321B0695-8AF0-4617-9D8D-8C28F227650F}',
    hasNoItems: '{90DD4A4E-6395-45BC-9E7E-DF1510237039}'
};

export default OperatorTypes;
