import React from 'react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import { Link } from 'react-router-dom';

import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const QuestionaryTileContent = ({
    isMobile,
    isHover,
    background,
    schemeColor,
    fields,
    params
}) => {
    const title = fields.questionaryTitle?.value;
    const description = fields.questionaryDescription?.value;
    const linkText = fields?.questionaryLink?.value?.text;
    const icon = fields.questionaryIcon?.value;
    const iconColor = params?.iconColor;
    const linkColor = params?.linkColor;

    const iconClass = icon ? 'questionary-tile-icon-margin' : '';
    return (
        <div
            className={
                'questionary-tile ' +
                (isHover ? 'display-hover' : 'display-normal')
            }
        >
            <eon-ui-background color={background}>
                <div className="questionary-tile-content">
                    <div className="questionary-tile-headline">
                        <eon-ui-headline
                            text={title}
                            size="h4"
                            scheme={schemeColor}
                        />
                    </div>
                    <div className="questionary-tile-paragraph">
                        <eon-ui-text text-style="copy" scheme={schemeColor}>
                            {description}
                        </eon-ui-text>
                    </div>
                </div>
            </eon-ui-background>
            <div className="questionary-tile-link-wrapper">
                <div className="questionary-tile-bottom-link">
                    <eon-ui-link scheme={linkColor} text={linkText} />
                </div>
                <div className={iconClass}>
                    {icon && (
                        <eon-ui-icon
                            name={icon}
                            scheme={iconColor}
                            size={isMobile ? 'small' : 'normal'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const Tile = ({ fields, params, rendering }) => {
    const tileBackground = 'eon-white';
    const defaultColor = 'white';
    const noHoverScheme = 'darkgrey';
    const isMobile = useMobileCheck();
    const hoverColor = params?.hoverColor;
    const tileWrapperClass = fields.questionaryDescription?.value
        ? ' questionary-tile-wrapper-desc-height'
        : '';

    return (
        <div className={'questionary-tile-wrapper' + tileWrapperClass}>
            <div className="questionary-tile-feature-badge">
                <Placeholder name="eon-eyecatcher" rendering={rendering} />
            </div>

            <QuestionaryTileContent
                isHover={true}
                background={hoverColor}
                schemeColor={defaultColor}
                isMobile={isMobile}
                fields={fields}
                params={params}
            />
            <QuestionaryTileContent
                isHover={false}
                background={tileBackground}
                schemeColor={noHoverScheme}
                isMobile={isMobile}
                fields={fields}
                params={params}
            />
        </div>
    );
};

const QuestionaryTile = ({ fields, rendering, sitecoreContext, params }) => {
    if (!fields) {
        return '';
    }
    const linkHref = fields?.questionaryLink?.value?.href;
    const linkTarget = fields?.questionaryLink?.value?.target;

    return (
        <>
            {sitecoreContext.pageEditing && <p>Questionary Tile Component</p>}
            {linkHref.startsWith('/') ? (
                <Link to={linkHref} target={linkTarget}>
                    <Tile
                        fields={fields}
                        rendering={rendering}
                        params={params}
                    />
                </Link>
            ) : (
                <a href={linkHref} target={linkTarget}>
                    <Tile
                        fields={fields}
                        rendering={rendering}
                        params={params}
                    />
                </a>
            )}
        </>
    );
};

export default withSitecoreContext()(QuestionaryTile);
