import React from 'react';

const Quote = ({ params, fields }) => {
    const stripeColor = params.stripeColor ?? params.background;
    const altText = fields?.image?.value?.alt ?? '';
    const imageUrl = fields?.image?.value?.src ?? '';
    const backgroundColor = params.background ?? 'eon-bordeaux';
    const iconBackgroundColor = params.iconScheme ?? '';

    return (
        <div className="quote">
            <div className="quote-image">
                {fields?.image?.value?.src && (
                    <img alt={altText} src={imageUrl} />
                )}
            </div>
            <div className="quote-box">
                <eon-ui-background color={backgroundColor} class="hydrated">
                    <div className="quote-content">
                        {fields?.icon?.value && (
                            <div className="quote-icon">
                                <eon-ui-icon
                                    name={fields.icon.value}
                                    scheme={iconBackgroundColor}
                                ></eon-ui-icon>
                            </div>
                        )}

                        {fields?.description?.value && (
                            <div className="quote-description">
                                <eon-ui-rte-renderer
                                    rte-source="aem"
                                    content={fields.description.value}
                                ></eon-ui-rte-renderer>
                            </div>
                        )}

                        {fields?.authorSignature?.value && (
                            <div className="quote-author-signature">
                                <eon-ui-text
                                    text-style="copy-small"
                                    font-weight="bold"
                                >
                                    {fields.authorSignature.value}
                                </eon-ui-text>
                            </div>
                        )}

                        {fields?.signature?.value && (
                            <div className="quote-signature">
                                <eon-ui-text
                                    text-style="copy-small"
                                    font-weight="normal"
                                >
                                    {fields.signature.value}
                                </eon-ui-text>
                            </div>
                        )}
                        <div
                            className="hightlight-bar"
                            style={
                                backgroundColor
                                    ? {
                                          backgroundColor: `var(--${stripeColor})`
                                      }
                                    : null
                            }
                        ></div>
                    </div>
                </eon-ui-background>
            </div>
        </div>
    );
};

export default Quote;
