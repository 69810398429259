import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const Twitter = ({ sitecoreContext, params, t }) => {
    if (!params.active || !params.url) {
        if (sitecoreContext.pageEditing) {
            return <div>{t('Editor|EmptyParameters')}</div>;
        }
        return '';
    }

    let currentUrl = '';

    if (typeof window !== 'undefined') {
        currentUrl = window?.location?.href;
    }

    const text = sitecoreContext.fields?.pageTitle;
    const url = params.url
        .replaceAll('{url}', encodeURIComponent(currentUrl))
        .replaceAll('{text}', text);
    const iconScheme = 'middlegrey';

    return (
        <eon-ui-link
            slot="socialmedia"
            target="_blank"
            scheme={iconScheme}
            icon="social_twitter"
            href={url}
        ></eon-ui-link>
    );
};

export default withTranslation()(withSitecoreContext()(Twitter));
