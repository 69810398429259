import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import config from '../../../../../temp/config';
import ModalOnAction, {ModalListEditor, useModal} from "../../../../ModalOnAction";

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const QmaticVisitClientType = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    stepHandler,
    qmaticHandler,
    tracker,
    errors,
    onChange,
    qmaticDataHandler,
    getQmaticData,
    rendering
}) => {
    useEffect(() => {
        ref.current.addEventListener(valueChangedEvent, handleValueChange);
        ref.current.addEventListener(blurEvent, (e) => handleBlur(e));
        ref.current.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            ref.current.removeEventListener(
                valueChangedEvent,
                handleValueChange
            );
            ref.current.removeEventListener(blurEvent, handleBlur);
            ref.current.removeEventListener(focusEvent, (e) => handleFocus(e));
        };
    }, [ref]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    const [onStateUpdated, setOnStateUpdated] = useState();

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s),
            undefined,
            (callback) => setOnStateUpdated(() => callback)
        );
    }, []);

    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [showModalId, setShowModalId] = useState(null);

    useLayoutEffect(() => {
        handleLoadData();
    }, [data]);

    const handleValueChange = (e) => {
        e.preventDefault();
        field.model.value = e.target.value;

        var selectedItem = field.model.items.filter(
            (x) => x.value == field.model.value
        )[0];

        var combinedData = e.target.value + '|' + selectedItem.subId;

        const errorMessages = [];
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }
        if (field.model.modalOnValueSelection) {
            setShowModalId(e.target.value);
        }

        onChange(field.valueField.name, combinedData, valid, errorMessages);
        conditionsHandler(field);

        field.qmaticSettings = {
            ...field.qmaticSettings,
            serviceId: field.model.value
        };
        field.qmaticSummary = {
            ...field.qmaticSummary
        };

        var moreData = getQmaticData().Data.filter(
            (x) => x[`publicId`] == e.target.value
        )[0];
        var newData = {
            ...field,
            qmaticData: moreData[`matters`]
        };

        if (selectedItem) {
            field.model.innerText = selectedItem.text;
            qmaticHandler(newData);
        }
        if (typeof field.model.nextStep !== 'undefined' && field?.model?.navigateOnValueChange) {
            stepHandler(field.model.nextStep);
        }
    };

    const handleBlur = () => {
        tracker.onBlurField(field, data.value, errors);
    };

    const handleFocus = () => {
        tracker.onFocusField(field, data.value, errors);
    };

    const ref = useRef();

    const handleLoadData = () => {
        if (data.qmatic) {
            field.qmaticSettings = data.qmaticSettings;
            field.qmaticSummary = data.qmaticSummary;
            FieldStateHelper.sendStateToForm(
                field,
                (d) => setData(d),
                (s) => stateHandler(s),
                { ...data, items: [] }
            );
            conditionsHandler(field);
            fetch(
                `${config.sitecoreApiHost}/api/eon/qmaticcalendar/services?branchid=${data.qmaticSettings.branchId}&qmaticBranchId=${data.qmaticSettings.qmaticBranchId}&sc_apikey=${config.sitecoreApiKey}`
            )
                .then((response) => response.json())
                .then((response) => {
                    qmaticDataHandler({
                        ...getQmaticData(),
                        Data: response.data
                    });
                    var newSelectItems = response.data.map((x) => ({
                        text: x[`name`],
                        value: x[`publicId`],
                        subId: x['subId']
                    }));
                    field.model.items = newSelectItems;

                    FieldStateHelper.sendStateToForm(
                        field,
                        (d) => setData(d),
                        (s) => stateHandler(s),
                        { ...data, items: newSelectItems }
                    );
                    conditionsHandler(field);
                    if (onStateUpdated) {
                        onStateUpdated();
                    }
                });
        }
    };

    const selected =
        (data &&
            field.model.items
                .filter((item) => item.value === data.value)
                .map((item) => item.value)[0]) ??
        field.model.items
            .filter((item) => item.selected)
            .map((item) => item.value)[0];


    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            {field.model.modalOnValueSelection &&
            <ModalListEditor modals={field.model.modals}/>
            }
            <eon-ui-radio-button-group
                ref={ref}
                name={field.model.name}
                label={`${field.model.title} ${
                    field.model.required && field.model.title ? '*' : ''
                    }`}
                selected={selected}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
            >
                {field.model.items.map((item, i) => {
                    return (
                        <>
                            <eon-ui-radio-button
                                key={`radio-${i}`}
                                {...(item.value === data.value
                                    ? { 'initially-checked': '' }
                                    : {})}
                                value={item.value}
                                label={item.text}
                            ></eon-ui-radio-button>
                        </>
                    );
                })}
            </eon-ui-radio-button-group>
            <div>
                {field.model.modalOnValueSelection && field.model.modals.map((item, i) => {
                    const listItem = field.model.items.find(x => x.text === item.label);
                    const isShowModal = listItem?.value === showModalId;
                    return (
                        <div>
                            <ModalOnAction
                                displayButton={false}
                                rendering={rendering}
                                uid={item?.id}
                                show={isShowModal} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default QmaticVisitClientType;
