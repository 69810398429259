import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const InfoTile = ({ fields, sitecoreContext, params }) => {
    const titleSize = params.titleSize ?? 'h2';
    const prefixSize = params.prefixSize ?? 'h3';
    const textAligment = params.textAligment ?? 'left';
    const textScheme = params.textScheme ?? '';
    const imageUrl = fields?.image?.value?.src ?? '';
    const infoText = fields?.info?.value ?? '';
    const prefix = fields?.prefix?.value ?? '';
    const sufix = fields?.sufix?.value ?? '';
    const titleText = fields?.title?.value ?? '';
    const altText = fields?.image?.value?.alt ?? '';

    return (
        <div className="info-tile">
            {sitecoreContext.pageEditing && <p>InfoTile Component</p>}
            <div className="info-tile__image-wrapper">
                {fields?.image?.value?.svg !== undefined && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: fields?.image?.value?.svg
                        }}
                    />
                )}
                {fields?.image?.value?.svg === undefined && (
                    <img
                        className="info-tile__image-wrapper--image"
                        alt={altText}
                        src={imageUrl}
                    />
                )}
            </div>
            <div className="info-tile__big-text-wrapper">
                {prefix !== '' && (
                    <eon-ui-headline
                        text={prefix}
                        size={prefixSize}
                        alignment={textAligment}
                        scheme={textScheme}
                    />
                )}
                <eon-ui-headline
                    text={titleText}
                    size={titleSize}
                    alignment={textAligment}
                    scheme={textScheme}
                />
                {sufix !== '' && (
                    <eon-ui-headline
                        text={sufix}
                        size={prefixSize}
                        alignment={textAligment}
                        scheme={textScheme}
                    />
                )}
            </div>
            <div className="info-tile__small-text">
                <eon-ui-text
                    text-style="abstract"
                    font-weight="400"
                    align="center"
                >
                    {infoText}
                </eon-ui-text>
            </div>
        </div>
    );
};

export default withSitecoreContext()(InfoTile);
