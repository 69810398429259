import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const TileWithIcon = ({ fields, params, props, sitecoreContext }) => {
    if (!fields) {
        return '';
    }

    const icon = fields.icon?.value;
    const caption = fields.caption?.value ?? '';
    const backgroundColor =
        params?.backgroundColor ?? props?.backgroundColor ?? '';
    const iconBackgroundColor = params?.iconColor ?? props?.iconColor ?? '';
    const captionScheme = params?.captionScheme ?? props?.captionScheme ?? '';
    const textSize = params?.textSize ?? props?.textSize ?? 'h5';

    return (
        <div className="tile-with-icon-tile">
            {sitecoreContext.pageEditing && <p>Tile With Icon</p>}

            <div className="tile-with-icon-tile-wrapper">
                <eon-ui-background color={backgroundColor} full-height>
                    <div className="tile-with-icon-body">
                        <div className="tile-with-icon-icon">
                            {icon && (
                                <eon-ui-icon
                                    size="help-tile"
                                    name={icon}
                                    scheme={iconBackgroundColor}
                                />
                            )}
                        </div>
                        {caption && (
                            <div className="headline-wrapper">
                                <eon-ui-headline
                                    text={caption}
                                    size={textSize}
                                    scheme={captionScheme}
                                    alignment="center"
                                />
                            </div>
                        )}
                    </div>
                </eon-ui-background>
            </div>
        </div>
    );
};

export default withSitecoreContext()(TileWithIcon);
