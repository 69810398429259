import React, { useState } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { NotificationsContainer } from './NotificationsContainer.jsx';

const FlyoutTrigger = ({ notificationsCount, onClick }) => {
    return (
        <div className="notification-bell-container" onClick={onClick}>
            <eon-ui-icon name="notification_bell" />
            { notificationsCount > 0 && <span className="notification-counter">{notificationsCount}</span> }
        </div>
    );
};

const NotificationsContainerEditor = ({ rendering }) => (
    <div className="notifications-container" style={{ position: 'relative' }}>
        <div className="title">
            <eon-ui-text text-style="subheadline">Powiadomienia</eon-ui-text>
        </div>
        <Placeholder name="eon-hight-priority-notifications" rendering={rendering} />
        <Placeholder name="eon-notifications" rendering={rendering} />
    </div>
);

const NotificationBell = ({ rendering, sitecoreContext, params }) => {
    const highPriorityNotifications = rendering.placeholders['eon-hight-priority-notifications'] || [];
    const notifications = rendering.placeholders['eon-notifications'] || [];
    const notificationsCount = notifications.length + highPriorityNotifications.length;

    const [isPanelVisible, setIsPanelVIsible] = useState(highPriorityNotifications.length > 0);
    const [isExpandDisabled, setIsExpandDisabled] = useState(!(highPriorityNotifications.length > 0 && notifications.length > 0)); 

    const notificationNumber = params?.notificationNumber ?? '5';

    return (
        <div className={`notification-bell ${isPanelVisible ? 'open' : ''}`}>
            <FlyoutTrigger
                notificationsCount={notificationsCount}
                onClick={() => setIsPanelVIsible(true)}
            />

            {sitecoreContext.pageEditing && !sitecoreContext.headerId && (
                <NotificationsContainerEditor rendering={rendering} />
            )}

            {!sitecoreContext.pageEditing &&
                sitecoreContext.headerId &&
                isPanelVisible && (
                    <NotificationsContainer
                        notifications={notifications}
                        highPriorityNotifications={highPriorityNotifications}
                        rendering={rendering}
                        notificationsCount={notificationNumber}
                        isExpandDisabled={isExpandDisabled}
                        onPanelClose={() => {
                            setIsPanelVIsible(false);
                            setIsExpandDisabled(true);
                        }}
                    />
                )}
        </div>
    );
};

export default withSitecoreContext()(NotificationBell);
