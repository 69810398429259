import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from '../../utils/debounce';

const OfferTabs = ({ fields, clearTagsAction }) => {
    const history = useHistory();
    const elementRef = useRef();

    const offerCategories = fields.offerCategories;

    useEffect(() => {
        const element = elementRef?.current;

        const ChangeTab = debounce((value) => {
            const category = offerCategories[value.detail];
            clearTagsAction();
            history.push(category.href);
        }, 200);

        if (element) {
            element.addEventListener('tabChanged', ChangeTab);

            return () => {
                element.removeEventListener('tabChanged', ChangeTab);
            };
        }
    }, [elementRef.current]);

    return (
        <div>
            <eon-ui-tabs
                active-tab-index={fields.indexOfCurrent}
                ref={elementRef}
            >
                {offerCategories
                    .map((c) => c.name)
                    .map((p, key) => (
                        <eon-ui-tab key={key} header={p} />
                    ))}
            </eon-ui-tabs>
        </div>
    );
};

export default OfferTabs;
