import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import getGridStyle from '../../lib/GridControlStyleResolver.js';

const TwoRowsInformation = ({ sitecoreContext, rendering }) => {
    const placeholders = Object.keys(rendering.placeholders);
    const columns = placeholders.filter((placeholder) =>
        placeholder.includes('column')
    );
    const hasLink = !!rendering.placeholders['eon-links']?.length;

    return (
        <div className="two-rows-information">
            {sitecoreContext && sitecoreContext.pageEditing && (
                <div>Two Rows Information Component</div>
            )}

            <div className="two-rows-information__first-row">
                <div className="two-rows-information__headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="two-rows-information__paragraph">
                    <Placeholder name="eon-paragraph" rendering={rendering} />
                </div>
                {hasLink && (
                    <div className="two-rows-information__links">
                        <Placeholder name="eon-links" rendering={rendering} />
                    </div>
                )}
            </div>

            <eon-ui-text>
                <div className="two-rows-information__line"></div>
            </eon-ui-text>

            <div className="two-rows-information__second-row">
                <eon-ui-grid-control grid={getGridStyle(columns.length)}>
                    {columns.map((p, key) => (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder name={p} rendering={rendering} />
                        </eon-ui-xx>
                    ))}
                </eon-ui-grid-control>
            </div>
        </div>
    );
};
export default withSitecoreContext()(TwoRowsInformation);
