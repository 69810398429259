import React, { useEffect, useState, useRef } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';
import { withTranslation } from 'react-i18next';

const valueChangedEvent = 'valueChanged';

const Agreement = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    errors,
    onChange,
    t
}) => {
    useEffect(() => {
        ref.current.addEventListener(valueChangedEvent, handleValueChange);

        return () => {
            ref.current.removeEventListener(
                valueChangedEvent,
                handleValueChange
            );
        };
    }, [ref]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, []);

    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [toggle, setToggle] = useState(field.model.fullTextOpen);

    const ref = useRef();

    const handleValueChange = (e) => {
        e.preventDefault();

        var newValue = true;
        if (!e.target.checked) {
            // we put here empty string, becase on BE field value in this case is processed as string
            newValue = '';
        }

        field.model.value = newValue;

        const errorMessages = [];
        let valid = true;

        if (field.model.required && !newValue) {
            valid = false;
        }
        onChange(field.valueField.name, newValue, valid, errorMessages);
        conditionsHandler(field);
    };

    const handleClick = () => {
        setToggle((prev) => !prev);
    };

    field.model.value = data.value;

    return (
        <div
            className={
                'field-wrapper agreement' + (data.hidden ? ' hidden' : '')
            }
        >
            <eon-ui-checkbox
                ref={ref}
                name={field.model.name}
                id={field.valueField.id}
                label={`${field.model.title} ${
                    field.model.required ? '*' : ''
                }`}
                value={data.value}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
                required="true"
            ></eon-ui-checkbox>
            {field.model.agreementIcon && (
                <eon-ui-icon name={field.model.agreementIcon}></eon-ui-icon>
            )}
            <div className="agreement-text">
                <eon-ui-text text-style="copy">
                    {field.model.shortTextForm}
                </eon-ui-text>
                {!toggle && (
                    <>
                        <eon-ui-link
                            hide-icon="true"
                            onClick={handleClick}
                            text={t('Content|FullContent')}
                        ></eon-ui-link>
                    </>
                )}
                {toggle && (
                    <>
                        <eon-ui-link
                            hide-icon="true"
                            onClick={handleClick}
                            text={t('Content|Hide')}
                        ></eon-ui-link>
                        <div className="fullText">
                            <eon-ui-text text-style="copy">
                                {field.model.fullText}
                            </eon-ui-text>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(Agreement);
