import React from 'react';
import Slider from 'react-slick';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LogoRotatorTile from '../LogoRotatorTile/index.js';

const LogoRotator = ({ rendering, sitecoreContext }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    const settings = {
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        infinite: true,
        swipe: true,
        autoplay: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="logo-rotator">
            {sitecoreContext.pageEditing && <p>Logo Rotator Component</p>}
            <div className="logo-rotator__headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div className="logo-rotator__paragraph">
                <Placeholder name="eon-paragraph" rendering={rendering} />
            </div>

            <div className="logo-rotator__carousel">
                {sitecoreContext.pageEditing && (
                    <div className="logo-rotator__tiles">
                        <Placeholder
                            name="eon-logo-rotator-tile"
                            rendering={rendering}
                        />
                    </div>
                )}
                {!sitecoreContext.pageEditing && (
                    <Slider {...settings}>
                        {rendering.placeholders['eon-logo-rotator-tile']?.map(
                            (slide, key) => (
                                <div key={`logo-rotator-tile-${key}`}>
                                    <LogoRotatorTile rendering={slide} />
                                </div>
                            )
                        )}
                        {/* <div className="ghost-card" /> */}
                    </Slider>
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(LogoRotator);
