import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const HelpTiles = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);
    const color = params.tilesColor;
    const hoverColor = params.hoverColor;
    const iconScheme = params.iconScheme;

    if (!placeholders) {
        return '';
    }

    return (
        <div className="help-tiles">
            <div className="help-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div className="help-tiles-wrapper">
                <eon-ui-grid-control
                    grid="grid__d3-3-3-3__t6-6-6-6__p6-6-6-6"
                    same-height="true"
                >
                    {placeholders
                        .filter((x) => x.includes('column'))
                        .map((p, key) => (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                    props={{
                                        color: color,
                                        hoverColor: hoverColor,
                                        iconScheme: iconScheme
                                    }}
                                />
                            </eon-ui-xx>
                        ))}
                </eon-ui-grid-control>
            </div>
        </div>
    );
};

export default HelpTiles;
