import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';
import DropdownListField from '../Form/Fields/DropdownListField/index.js';

const SuppliersList = ({ fields, t }) => {
    const showAllButtonRef = useRef();
    const [traders, setTraders] = useState([]);
    const [selectedCategory, setCategory] = useState(null);
    const [selectedSubCategory, setSubCategory] = useState(null);

    const subcategories =
        fields.categories?.flatMap((category) => category.subcategories) || [];

    const getTraders = async (shouldFetchAll = false) => {
        if (!fields.suppliersItem?.length) {
            return;
        }

        if (shouldFetchAll) {
            const data = await fetch(
                `${config.sitecoreApiHost}/api/eon/suppliers/traders?suppliersItemId=${fields.suppliersItem[0].id}&sc_apikey=${config.sitecoreApiKey}`
            ).then((response) => response.json());

            return setTraders(data.tradersList);
        }

        if (selectedSubCategory) {
            const data = await fetch(
                `${config.sitecoreApiHost}/api/eon/suppliers/traders?suppliersItemId=${fields.suppliersItem[0].id}&subcategoryItemId=${selectedSubCategory}&sc_apikey=${config.sitecoreApiKey}`
            ).then((response) => response.json());

            return setTraders(data.tradersList);
        }

        if (selectedCategory) {
            const data = await fetch(
                `${config.sitecoreApiHost}/api/eon/suppliers/traders?suppliersItemId=${fields.suppliersItem[0].id}&categoryItemId=${selectedCategory}&sc_apikey=${config.sitecoreApiKey}`
            ).then((response) => response.json());

            return setTraders(data.tradersList);
        }
    };

    useEffect(() => {
        const element = showAllButtonRef?.current;

        if (element) {
            const getAllTrades = () => getTraders(true);
            element.addEventListener('buttonClick', getAllTrades);
            return () => {
                element.removeEventListener('buttonClick', getAllTrades);
            };
        }
    });

    const field = useMemo(() => {
        return {
            model: {
                name: 'categories',
                title: '',
                items:
                    fields.categories?.map((category) => ({
                        value: category.id,
                        text: category.name
                    })) || [],
                conditionSettings: {}
            },
            valueField: {
                name: 'categories'
            }
        };
    });

    const handleCategoryChange = (_, value) => setCategory(value);
    const handleSubategoryChange = (_, value) => setSubCategory(value);

    useEffect(() => {
        getTraders();
    }, [selectedCategory, selectedSubCategory]);

    return (
        <div className="suppliers-list">
            <div className="suppliers-list-description">
                <eon-ui-text text-style="copy">
                    {t('Content|Suppliers|ComponentDescription')}
                </eon-ui-text>
            </div>

            <div className="suppliers-list-search-wrapper">
                <DropdownListField
                    field={field}
                    placeholder={t(
                        'Content|Suppliers|CompanyFieldsPlaceholder'
                    )}
                    errors={[]}
                    onChange={handleCategoryChange}
                />

                {!!selectedCategory ? (
                    <DropdownListField
                        field={{
                            model: {
                                name: 'sub-categories',
                                title: '',
                                items:
                                    fields.categories
                                        ?.find(
                                            ({ id }) => id === selectedCategory
                                        )
                                        .subcategories.map((subCategory) => ({
                                            value: subCategory.id,
                                            text: subCategory.name
                                        })) || [],
                                conditionSettings: {}
                            },
                            valueField: {
                                name: 'sub-categories'
                            }
                        }}
                        placeholder={t(
                            'Content|Suppliers|ChooseExtentPlaceholder'
                        )}
                        errors={[]}
                        onChange={handleSubategoryChange}
                    />
                ) : null}
            </div>

            <div className="suppliers-list-search-results">
                {traders?.map((trader) => (
                    <div key={trader.traderName} className="trader-row">
                        <div className="trader">
                            <eon-ui-headline
                                text={trader.traderName}
                                size="h5"
                            />
                            <eon-ui-text text-style="copy">
                                {t('Content|Suppliers|Phone')}{' '}
                                {trader.contactNumber}
                            </eon-ui-text>
                            <eon-ui-text text-style="copy">
                                {t('Content|Suppliers|Email')}{' '}
                                <eon-ui-link
                                    text={trader.email}
                                    href={`mailto: ${trader.email}`}
                                    hide-icon
                                />
                            </eon-ui-text>
                        </div>
                        <div className="responsibilities">
                            {subcategories
                                ?.filter((subcategory) =>
                                    trader.subcategories?.includes(
                                        subcategory.id
                                    )
                                )
                                .map((subcategory) => (
                                    <div
                                        className="subcategory"
                                        key={subcategory.id}
                                    >
                                        <eon-ui-text text-style="copy">
                                            {subcategory.name}
                                        </eon-ui-text>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}

                {selectedCategory && (
                    <div className="suppliers-list-show-all">
                        <eon-ui-button
                            ref={showAllButtonRef}
                            text={t('Content|Suppliers|ShowAll')}
                            rank="secondary"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(SuppliersList);
