import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import config from '../../temp/config';

const SearchBar = ({ rendering, t, params }) => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [suggestions, setSuggestions] = useState();
    const [dropdownActive, setDropdownState] = useState(false);
    const dropdownRef = useRef();
    const inputRef = useRef();
    const formRef = useRef();
    const submitButtonRef = useRef();
    const history = useHistory();
    const text = t('Content|SearchBar|Text');
    const queryParams = useLocation().search;
    const phrase = new URLSearchParams(queryParams).get('phrase');

    const flayoutSearch = params.flayoutSearchBar ?? false;
    const searchResultsUrl = rendering.fields.resultsPage?.value?.href ?? '';

    useEffect(() => {
        setSearchPhrase(phrase);
        inputRef.current.addEventListener('valueChanged', (e) =>
            handleChange(e)
        );
        inputRef.current.addEventListener('iconClick', (e) => iconClick(e));

        formRef.current.addEventListener('onSubmit', (e) => handleSubmit(e));

        return () => {
            inputRef.current.removeEventListener('valueChanged', (e) =>
                handleChange(e)
            );

            formRef.current.removeEventListener('onSubmit', (e) =>
                handleSubmit(e)
            );

            inputRef.current.removeEventListener('iconClick', (e) =>
                iconClick(e)
            );
        };
    }, [inputRef]);
    const handleClickOutside = (event) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setDropdownState(false);
        }
    };
    useEffect(() => {
        const element = inputRef?.current;

        const handleFocus = () => {
            setDropdownState(true);
        };

        if (element) {
            element.addEventListener('inputFocus', handleFocus);
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                element.removeEventListener('inputFocus', handleFocus);
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    }, []);
    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        let highlighted = false;
        return (
            <span>
                {parts.map((part, i) => {
                    let highlightNow = false;
                    if (!highlighted && part.toLowerCase() === highlight.toLowerCase()) {
                        highlightNow = true;
                        highlighted = true;
                    }
                    return <span
                        key={i}
                        style={
                            highlightNow
                                ? { fontWeight: 'bold', color: 'red' }
                                : {}
                        }
                    >
                        {part}
                    </span>;
                })}
            </span>
        );
    };

    const iconClick = () => {
        submitButtonRef.current.click();
    };

    const handleChange = (event) => {
        setSearchPhrase(event.target.value);
        if (event.target.value) {
            const eventPhrase = event.target.value.replaceAll('#', '');
            fetch(
                `${config.sitecoreApiHost}/api/eon/search/suggestions?phrase=${eventPhrase}&sc_apikey=${config.sitecoreApiKey}`
            )
                .then((response) => response.json())
                .then((response) =>
                    response.results.map((sugText) => {
                        return {
                            highlight: getHighlightedText(sugText, eventPhrase),
                            normal: sugText
                        };
                    })
                )
                .then((response) => {
                    setSuggestions(response);
                });
        } else {
            setSuggestions(undefined);
        }
    };

    const handleSubmit = (event) => {
        setDropdownState(false);
        const url = `${searchResultsUrl}?phrase=${
            searchPhrase ?? inputRef?.current?.value
        }`;
        history.push(url);
        event.preventDefault();

        const custEvent = new CustomEvent('updateData', {
            detail: searchPhrase
        });
        document.dispatchEvent(custEvent);
    };
    const pressKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };
    const handleFlayoutSubmit = (name) => {
        setDropdownState(false);
        const url = `${searchResultsUrl}?phrase=${name}`;
        history.push(url);

        const custEvent = new CustomEvent('updateData', {
            detail: searchPhrase
        });
        document.dispatchEvent(custEvent);
    };

    return (
        <div className="search-bar">
            <form
                className="search-bar-form"
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <eon-ui-input
                    trailing-icon="true"
                    trailing-icon-name="search"
                    clear-input-icon="true"
                    ref={inputRef}
                    label={text}
                    value={searchPhrase}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => {
                        setDropdownState(true);
                        pressKeyDown(e);
                    }}
                ></eon-ui-input>
                <button
                    ref={submitButtonRef}
                    className="search-submit-button"
                    type="submit"
                    hidden={true}
                ></button>
            </form>

            {dropdownActive && suggestions && (
                <ul className="search-dropdown" ref={dropdownRef}>
                    {suggestions.map((x, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                if (flayoutSearch) {
                                    handleFlayoutSubmit(x.normal);
                                } else {
                                    setSearchPhrase(x.normal);
                                    setDropdownState(false);
                                }
                            }}
                            className="dropdown-list-item"
                        >
                            {x.highlight}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default withTranslation()(SearchBar);
