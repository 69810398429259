import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import config from '../../temp/config';
import { useClickOutside } from '../../hooks/useClickOutside';

const LoginForm = ({
    rendering,
    fields,
    t,
    onPanelClose,
    position = 'absolute'
}) => {
    const containerRef = useRef(null);
    const loginButtonRef = useRef(null);
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validationError, setValidationError] = useState(null);

    const emailPlaceholder = fields.emailPlaceholder?.value;
    const passwordPlaceholder = fields.passwordPlaceholder?.value;
    const emailLabel = fields.emailLabel?.value ?? 'email';
    const passwordLabel = fields.passwordLabel?.value ?? 'password';
    const emailFieldAlert = fields.emailFieldAlert?.value;
    const passwordFieldAlert = fields.passwordFieldAlert?.value;
    const errorMessage = fields.errorMessage?.value;

    const confirmLogin = async () => {
        const data = await fetch(
            `${config.sitecoreApiHost}${config.graphQLEndpointPath}/account/login?sc_apikey=${config.sitecoreApiKey}`,
            {
                method: 'POST',
                body: JSON.stringify({ email, password })
            }
        ).then((response) => response.json());

        setValidationError(data.errorMessage || errorMessage);
    };

    useClickOutside(containerRef, onPanelClose);

    useEffect(() => {
        const element = emailInputRef?.current;
        const valueChange = (e) => setEmail(e.target.value);

        if (element) {
            element.addEventListener('valueChanged', valueChange);

            return () => {
                element.removeEventListener('valueChanged', valueChange);
            };
        }
    });

    useEffect(() => {
        const element = passwordInputRef?.current;
        const valueChange = (e) => setPassword(e.target.value);

        if (element) {
            element.addEventListener('valueChanged', valueChange);

            return () => {
                element.removeEventListener('valueChanged', valueChange);
            };
        }
    });

    useEffect(() => {
        const element = loginButtonRef?.current;

        if (element) {
            element.addEventListener('buttonClick', confirmLogin);

            return () => {
                element.removeEventListener('buttonClick', confirmLogin);
            };
        }
    });

    return (
        <div
            className="flyout-menu-login"
            ref={containerRef}
            style={{ position }}
        >
            {validationError && (
                <div className="validation-message">
                    <eon-ui-background color="eon-darkred">
                        <div className="validation-content">
                            <eon-ui-icon
                                alignmentX="left"
                                name="alert"
                                size="small"
                                scheme="white"
                                noSpace={false}
                            />
                            <eon-ui-text text-style="copy">
                                {validationError}
                            </eon-ui-text>
                        </div>
                    </eon-ui-background>
                </div>
            )}

            <div className="login-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <div className="field-wrapper email">
                <eon-ui-input
                    ref={emailInputRef}
                    name="email"
                    type="email"
                    message-icon=""
                    invalid={!!validationError}
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                />
            </div>

            <div className="field-wrapper password">
                <eon-ui-input
                    ref={passwordInputRef}
                    name="password"
                    type="password"
                    message-icon=""
                    invalid={!!validationError}
                    label={passwordLabel}
                    placeholder={passwordPlaceholder}
                />
            </div>

            <div className="button-wrapper">
                <eon-ui-button
                    ref={loginButtonRef}
                    text={t('Content|ConfirmLoginButton')}
                    rank="primary"
                    full-width-on-mobile
                />
            </div>

            <div className="links-wrapper">
                <Placeholder name="eon-links" rendering={rendering} />
            </div>
        </div>
    );
};

export default withTranslation()(LoginForm);
