import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const OffsetTeaserBiggerPicture = ({ rendering, params, sitecoreContext }) => {
    const isMobile = useMobileCheck();
    const backgroundColor = params.backgroundColor || '';
    const fields = rendering.fields;

    if (!fields && !fields.image?.value?.src) {
        return '';
    }

    return (
        <>
            {sitecoreContext.pageEditing && <p>Offset Teaser Bigger Picture</p>}

            <div className="offer-teaser-bigger-picture">
                <div>
                    <div
                        className="teaser-container"
                        style={
                            backgroundColor
                                ? {
                                      backgroundColor: `var(--${backgroundColor})`
                                  }
                                : {
                                      backgroundColor: `var(--white)`
                                  }
                        }
                    >
                        <div className="header">
                            <eon-ui-background
                                color={
                                    backgroundColor
                                        ? backgroundColor
                                        : 'eon-white'
                                }
                            >
                                <eon-ui-headline
                                    size="h3"
                                    text={fields.header?.value}
                                />
                            </eon-ui-background>
                        </div>
                        <div className="description">
                            <eon-ui-background
                                color={
                                    backgroundColor
                                        ? backgroundColor
                                        : 'eon-white'
                                }
                            >
                                <eon-ui-text text-style="copy">
                                    {fields.description.value}
                                </eon-ui-text>
                            </eon-ui-background>
                        </div>
                        <div className="link">
                            <eon-ui-background
                                color={
                                    backgroundColor
                                        ? backgroundColor
                                        : 'eon-white'
                                }
                            >
                                <Placeholder
                                    name="eon-links"
                                    rendering={rendering}
                                />
                            </eon-ui-background>
                        </div>
                    </div>
                </div>
                <div className="image-container">
                    <eon-ui-image
                        height="100%"
                        width="100%"
                        aspect-ratio={isMobile && 'ratio-16x9'}
                        src={fields.image.value?.src}
                        alt={fields.image.value?.alt}
                    />
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(OffsetTeaserBiggerPicture);
