import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const OffsetTeaserSquarePicture = ({
    rendering,
    params,
    fields,
    sitecoreContext
}) => {
    const isMobile = useMobileCheck();

    return (
        <div className="offset-teaser-square-picture">
            <eon-ui-offset-teaser
                variant="default"
                scheme={params.scheme}
                inner-background={`eon-${params.scheme}`}
            >
                <eon-ui-image
                    slot="image"
                    src={fields.image.value.src}
                    alt={fields.image.value.alt}
                    shadow-on-image={params.shadowOnImage ? true : false}
                />

                <div className="offset-teaser-square-headline">
                    <eon-ui-headline
                        text={fields.headline.value}
                        headline-html-tag="h2"
                        size="h2"
                        alignment={!isMobile ? 'right' : 'left'}
                    />
                </div>
                <div className="offset-teaser-content-wrapper">
                    <eon-ui-headline
                        text={fields.subheadline.value}
                        headline-html-tag="h2"
                        size="h3"
                    />
                    <div className="offset-teaser-text-wrapper">
                        <eon-ui-rte-renderer
                            rte-source="aem"
                            content={fields.text.value}
                        />
                    </div>

                    {sitecoreContext.pageEditing ? (
                        <Placeholder name="eon-links" rendering={rendering} />
                    ) : (
                        <div className="offset-teaser-links">
                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    )}
                </div>
            </eon-ui-offset-teaser>
        </div>
    );
};

export default withSitecoreContext()(OffsetTeaserSquarePicture);
