import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const TableCellContent = ({ fields, params, sitecoreContext, t, index }) => {
    const content = fields.cellContent.value;
    const scheme = params.scheme;
    if (sitecoreContext.pageEditing && !content)
        return <div>{t('Editor|EmptyDatasource')}</div>;
    else {
        return (
            <div className={`table-cell-content`}>
                <eon-ui-rte-renderer
                    rte-source="aem"
                    content={content}
                    scheme={scheme}
                ></eon-ui-rte-renderer>
            </div>
        );
    }
};
export default withTranslation()(withSitecoreContext()(TableCellContent));
