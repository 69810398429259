import Operations from './Operations/operations.js';
import Matching from './Matching/matching.js';
import Actions from './Actions/actions.js';

const Conditions = {
    initialCheckConditions: (field, fieldValues) => {
        field.model.conditionSettings.fieldConditions.forEach((fc) => {
            var results = fc.conditions.map((fcc) =>
                Operations.checkCondition(fcc, fieldValues)
            );

            const matchingResult = Matching.match(fc.matchTypeId, results);

            if (matchingResult) {
                fc.actions.forEach((action) => {
                    const resultField = Actions.performAction(
                        field,
                        action,
                        fieldValues
                    );

                    fieldValues[action.fieldId] = resultField;
                });
            }
        });
    },
    checkConditions: (field, fieldValues, fieldStates) => {
        fieldValues[field.model.conditionSettings.fieldKey].value =
            field.model.value;

        field.model.conditionSettings.fieldConditions.forEach((fc) => {
            var results = fc.conditions.map((fcc) =>
                Operations.checkCondition(fcc, fieldValues, fieldStates)
            );

            const matchingResult = Matching.match(fc.matchTypeId, results);

            if (matchingResult) {
                fc.actions.forEach((action) => {
                    const resultField = Actions.performAction(
                        field,
                        action,
                        fieldValues
                    );
                    fieldValues[action.fieldId] = resultField;

                    const stateData = {
                        ...resultField
                    };
                    if (fieldStates[action.fieldId]) {
                        fieldStates[action.fieldId].setState(stateData);
                    }
                });
            }
        });
    }
};

export default Conditions;
