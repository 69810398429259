import React, { useEffect, useState, useRef } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const getDayName = (dateStr, locale) => {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
};

const QmaticSuccessButtons = ({ t, stateHandler }) => {
    const history = useHistory();
    const [qmaticFields, setQmaticFields] = useState();
    const modalRef = useRef();

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            {
                model: { conditionSettings: { fieldKey: 'qmatic-fields' } }
            },
            (d) => setQmaticFields(d),
            (s) => stateHandler(s),
            qmaticFields
        );
    }, [stateHandler]);

    useEffect(() => {
        if (qmaticFields?.appointmentId) {
            window.scrollTo({top: 65, behavior: "smooth"});
        }
    }, [qmaticFields]);

    const handleVisitCancel = () => {
        history.push(
            qmaticFields.cancelPagePath +
                '?appointmentId=' +
                qmaticFields.appointmentId
        );
    };

    const handleCloseModal = () => {
        modalRef.current.closeModal();
    };

    return qmaticFields?.appointmentId ? (
        <div className="qmatic-success-buttons">
            <div className="cta-wrapper">
                <eon-ui-button
                    text={t('Content|Forms|Qmatic|CancelVisitButton')}
                    rank="secondary"
                    full-width-on-mobile="true"
                    toggle-full-width-on-mobile="true"
                    full-width="false"
                    open-modal-id="modalId-qmaticreschedule"
                ></eon-ui-button>
                <eon-ui-modal
                    modal-id="modalId-qmaticreschedule"
                    show-closing-x="true"
                    full-width="true"
                    full-width-width="S"
                    ref={modalRef}
                >
                    <div className={'modal-success-buttons-content'}>
                        <eon-ui-headline
                            size="h3"
                            text={t('Content|Forms|Qmatic|CancelLabel')}
                            scheme="red"
                        ></eon-ui-headline>
                        <eon-ui-headline
                            size="h5"
                            text={qmaticFields.location}
                            style={{ marginBottom: 0 }}
                        ></eon-ui-headline>
                        <eon-ui-headline
                            size="h5"
                            text={`${getDayName(
                                qmaticFields.date,
                                'pl-PL'
                            )}, godzina ${qmaticFields.hour}`}
                        ></eon-ui-headline>
                        <div className={'buttons'}>
                            <eon-ui-button
                                text={t('Content|Forms|Qmatic|CancelButton')}
                                rank="secondary"
                                onClick={handleCloseModal}
                            ></eon-ui-button>
                            <eon-ui-button
                                text={t('Content|Forms|Qmatic|ConfirmButton')}
                                rank="primary"
                                onClick={handleVisitCancel}
                            ></eon-ui-button>
                        </div>
                    </div>
                </eon-ui-modal>

                <eon-ui-button
                    text={t('Content|Forms|Qmatic|RescheduleVisitButton')}
                    rank="secondary"
                    full-width-on-mobile="true"
                    toggle-full-width-on-mobile="true"
                    full-width="false"
                    use-as-link="true"
                    href={'?rescheduleId=' + qmaticFields.appointmentId}
                ></eon-ui-button>
            </div>
        </div>
    ) : (
        ''
    );
};
export default withTranslation()(QmaticSuccessButtons);
