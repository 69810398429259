import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SingleProduct = ({ rendering }) => {
    var placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return '';
    }

    return (
        <div className="single-product">
            <div className="single-product_header">
                <div className="single-product__headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="single-product__cta">
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
            </div>
            <div className="single-product_container">
                <div className="single-product__tile">
                    <Placeholder
                        name="eon-single-product-tile"
                        rendering={rendering}
                    />
                </div>
                <div className="single-product__columns">
                    <Placeholder
                        name="eon-single-product-sections"
                        rendering={rendering}
                    />
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(SingleProduct);
