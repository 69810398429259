import FieldTypes from './Fields/fieldTypes.js';

const getSteps = (fieldStates) => {
    return Object.entries(fieldStates).filter(
        (f) => f[1].type === FieldTypes.step
    );
};

const StepNavigation = {
    markFirstSectionAsExpanded: (fieldValues) => {
        const steps = Object.entries(fieldValues).filter(
            (f) => f[1].type === FieldTypes.step
        );
        if (steps && steps.length > 0) {
            steps[0][1].expanded = true;
        }
    },

    expandAll: (fieldValues) => {
        const steps = Object.entries(fieldValues).filter(
            (f) => f[1].type === FieldTypes.step
        );

        steps.forEach((x) => {
            x[1].expanded = true;
        });

        if (steps && steps.length > 0) {
            steps[0][1].expanded = true;
        }
    },

    goToNextSection: (fieldValues, fieldStates) => {
        const steps = getSteps(fieldValues);

        for (var i = 0; i < steps.length - 1; i++) {
            const current = fieldValues[steps[i][0]];
            if (current.expanded) {
                current.expanded = false;
                current.past = true;
                fieldStates[steps[i][0]].setState({ ...current });

                const next = fieldValues[steps[i + 1][0]];
                next.expanded = true;
                fieldStates[steps[i + 1][0]].setState({ ...next });
                break;
            }
        }
    },

    gotoPreviousSection: (fieldValues, fieldStates) => {
        const steps = getSteps(fieldValues);

        for (var i = 1; i < steps.length; i++) {
            const current = fieldValues[steps[i][0]];
            if (current.expanded) {
                current.expanded = false;
                current.past = false;

                fieldStates[steps[i][0]].setState({ ...current });

                const previous = fieldValues[steps[i - 1][0]];
                previous.expanded = true;

                fieldStates[steps[i - 1][0]].setState({ ...previous });
                break;
            }
        }
    },

    goToSection: (fieldValues, fieldStates, step) => {
        const steps = getSteps(fieldValues);

        for (let i = 0; i < steps.length; i++) {
            const current = fieldValues[steps[i][0]];
            current.past = i < step;
            current.expanded = i === step;
            fieldStates[steps[i][0]].setState({ ...current });
        }
    }
};

export default StepNavigation;
