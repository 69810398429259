import React from 'react';
import ReactTooltip from 'react-tooltip';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SingleProductTooltip = ({ rendering, sitecoreContext, params }) => {
    const tooltipBackgroundColor = `var(--${params?.background})`;
    const fields = rendering.fields;
    const linkFields = fields?.link?.value;

    //remove icon field
    //add background rendering paramerters
    if (sitecoreContext.pageEditing && !fields) {
        return <div>Editor|EmptyDatasource</div>;
    }

    if (sitecoreContext.pageEditing) {
        return (
            <div className="single-product-tooltip">
                <eon-ui-background color={params?.background}>
                    <eon-ui-text>Single Product Tooltip</eon-ui-text>

                    <div className="single-product-tooltip_content">
                        <div className="single-product-tile__header">
                            <eon-ui-text>{fields.header.value}</eon-ui-text>
                        </div>

                        <div className="single-product-tile__description">
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={fields.bodyText.value}
                            />
                        </div>

                        {!!linkFields && (
                            <div>
                                <eon-ui-link
                                    text={linkFields.text}
                                    class={linkFields.class}
                                    href={linkFields.href}
                                    target={linkFields.target}
                                    hide-icon
                                />
                            </div>
                        )}
                    </div>
                </eon-ui-background>
            </div>
        );
    }

    return (
        <div className="single-product-tooltip">
            <ReactTooltip
                id={rendering.uid}
                effect="solid"
                backgroundColor={tooltipBackgroundColor}
                className="single-product-tooltip_content"
                delayHide={200}
                clickable
            >
                <eon-ui-background color={params?.background}>
                    <div className="single-product-tile__header">
                        <eon-ui-text text-style="subheadline" font-weight="700">
                            {fields.header.value}
                        </eon-ui-text>
                    </div>

                    <div className="single-product-tile__description">
                        <eon-ui-rte-renderer
                            rte-source="aem"
                            content={fields.bodyText.value}
                        />
                    </div>

                    {!!linkFields && linkFields.href && (
                        <div className="single-product-tile__link">
                            <eon-ui-link
                                text={linkFields.text}
                                class={linkFields.class}
                                href={linkFields.href}
                                target={linkFields.target}
                            />
                        </div>
                    )}
                </eon-ui-background>
            </ReactTooltip>

            <div
                className="single-product-tooltip__trigger"
                data-tip
                data-for={rendering.uid}
            >
                <eon-ui-icon size="normal" scheme="turquoise" name="info" />
            </div>
        </div>
    );
};

export default withSitecoreContext()(SingleProductTooltip);
