import ActionTypes from './actionTypes.js';

const Actions = {
    performAction: (field, action, fieldValues) => {
        const fieldValue = { ...fieldValues[action.fieldId] };

        // eslint-disable-next-line default-case
        switch (action.actionTypeId) {
            case ActionTypes.disable:
                fieldValue.disabled = true;
                break;

            case ActionTypes.enable:
                fieldValue.disabled = false;
                break;

            case ActionTypes.hide:
                fieldValue.hidden = true;
                fieldValue.value = '{$hidden}';

                break;

            case ActionTypes.show:
                fieldValue.hidden = false;
                if (fieldValue.value == '{$hidden}') {
                    fieldValue.value = '';
                }
                break;

            case ActionTypes.clearValue:
                fieldValue.value = '';
                break;
        }

        return fieldValue;
    }
};

export default Actions;
