import { useCallback, useEffect } from 'react';

export const useClickOutside = (refObject, onClose, preventClose) => {
    const handleClickOutside = useCallback(
        (e) => {
            e.stopPropagation();
            if (
                (refObject.current && refObject.current.contains(e.target)) ||
                (preventClose && preventClose(e.target, refObject))
            ) {
                return;
            }

            onClose();
        },
        [onClose, refObject]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        if (!document) {
            return;
        }

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleClickOutside, handleKeyDown]);
};
