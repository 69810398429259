import React, { useEffect, useRef, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const navigationSteps = {
    next: 1,
    previous: -1,
    submit: 0
};

const buttonClickEvent = 'buttonClick';

const SubmitButton = ({
    field,
    navigationHandler,
    fieldState,
    stateHandler
}) => {
    const ref = useRef();
    const [data, setData] = useState(fieldState);

    const handleClick = (e) => {
        if (field.model.navigationStep === navigationSteps.submit) {
            return;
        }

        e.preventDefault();
        navigationHandler(field.model.navigationStep === navigationSteps.next);
    };

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, setData, stateHandler]);

    useEffect(() => {
        const element = ref.current;

        element.addEventListener(buttonClickEvent, handleClick);

        return () => {
            element.removeEventListener(buttonClickEvent, handleClick);
        };
    }, [ref.current]);

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <div className="button-wrapper">
                <eon-ui-button
                    ref={ref}
                    text={field.model.title}
                    input-type={
                        field.model.navigationStep === navigationSteps.submit
                            ? 'submit'
                            : 'button'
                    }
                    disabled={data.disabled}
                    rank={
                        field.model.navigationStep >= 0
                            ? 'primary'
                            : 'secondary'
                    }
                    full-width-on-mobile="true"
                    toggle-full-width-on-mobile="true"
                ></eon-ui-button>
            </div>
        </div>
    );
};

export default SubmitButton;
