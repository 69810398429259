import React, { useRef } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import RouteLink from '../Route/RouteLink/index.js';
import modalSize from '../../lib/ModalSizeResolver';

const NoModalTile = ({ background, fields, schemaColor }) => {
    return (
        <eon-ui-background color={background}>
            <a href={fields.link.value.href} target={fields.link.value.target}>
                <div className="help-tile">
                    <HelpTileContent
                        fields={fields}
                        iconSchemeColor={schemaColor}
                    />
                    <div className="tile-link">
                        {fields.link.value.href && !fields.modal.value && (
                            <RouteLink
                                href={fields.link.value.href}
                                target={fields.link.value.target}
                            ></RouteLink>
                        )}
                    </div>
                </div>
            </a>
        </eon-ui-background>
    );
};

const HelpTileContent = ({ fields, iconSchemeColor }) => {
    return (
        <>
            <div className="tile-icon">
                {fields?.icon?.value !== undefined && (
                    <eon-ui-icon
                        size="help-tile"
                        name={fields?.icon?.value}
                        scheme={iconSchemeColor}
                    />
                )}
            </div>
            <div className="tile-text">
                <eon-ui-headline text={fields.text.value} size="h4" />
            </div>
        </>
    );
};

const TileWithModal = ({
    background,
    fields,
    schemeColor,
    rendering,
    params,
    uniqueSuffix
}) => {
    const modalId = `modalId-${rendering.uid}-${uniqueSuffix}`;
    const modalRef = useRef(null);
    const modalSizeParameter = params.modalSize ?? 'S';

    return (
        <>
            <eon-ui-background color={background} class="hydrated">
                <div
                    className="help-tile"
                    onClick={() =>
                        modalRef.current.setAttribute('visible', 'true')
                    }
                >
                    <HelpTileContent
                        fields={fields}
                        iconSchemeColor={schemeColor}
                    />

                    <eon-ui-link
                        text=""
                        open-modal-id={modalId}
                        class="hydrated"
                    />
                </div>

                <eon-ui-modal
                    ref={modalRef}
                    modal-id={modalId}
                    show-closing-x="true"
                    full-width="true"
                    full-width-width={modalSize(modalSizeParameter)}
                >
                    <Placeholder name="eon-modal" rendering={rendering} />
                </eon-ui-modal>
            </eon-ui-background>
        </>
    );
};

const HelpTile = ({ fields, rendering, params, props, sitecoreContext }) => {
    const tileBackground = params.tileColor ?? props.color;
    const hoverColor = params.tileHoverColor ?? props.hoverColor;
    const noHoverScheme = params.iconScheme ?? props.iconScheme;

    const modalChecked = fields.modal.value;

    return (
        <div className="help-tile">
            {!sitecoreContext.pageEditing && (
                <div className={'on-hover'}>
                    {modalChecked ? (
                        <TileWithModal
                            background={hoverColor}
                            fields={fields}
                            schemeColor={''}
                            params={params}
                            rendering={rendering}
                            uniqueSuffix={1}
                        />
                    ) : (
                        <NoModalTile
                            fields={fields}
                            background={hoverColor}
                            schemaColor={''}
                        />
                    )}
                </div>
            )}
            <div className={sitecoreContext.pageEditing ? '' : 'not-on-hover'}>
                {modalChecked ? (
                    <TileWithModal
                        background={tileBackground}
                        fields={fields}
                        schemeColor={noHoverScheme}
                        params={params}
                        rendering={rendering}
                        uniqueSuffix={2}
                    />
                ) : (
                    <NoModalTile
                        fields={fields}
                        background={tileBackground}
                        schemaColor={noHoverScheme}
                    />
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(HelpTile);
