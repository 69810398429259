import React, { useEffect, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const StepField = ({
    field,
    fieldState,
    fieldFactory,
    stateHandler,
    stepHandler
}) => {
    const stepInfo = field.model.stepDescription;
    const stepNumber = field.model.stepNumber;
    const nextStep = parseInt(stepNumber) + 1;
    const [data, setData] = useState(fieldState);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
        field.fields.map((f) => {
            f.model.nextStep = nextStep;
        });
    });

    const navigationUpdate = (e) => {
        e.preventDefault();
        stepHandler(stepNumber);
    };

    return data.expanded ? (
        <>
            <div className="step-container">
                <div className="step-number-circle-wrapper">
                    <eon-ui-background color="turquoise">
                        <div className="step-number-circle">
                            <span className="step-circle-number">
                                {stepNumber}
                            </span>
                        </div>
                    </eon-ui-background>
                </div>

                <div className="step-wrapper">
                    <h3 className="step-info">{stepInfo}</h3>
                    {field.fields.map(fieldFactory)}
                </div>
            </div>
        </>
    ) : data.past ? (
        <>
            <div className="step-container-checked">
                <div className="step-number-circle-wrapper-checked">
                    <eon-ui-background color="turquoise">
                        <div className="step-number-circle">
                            <span className="step-circle-number">
                                <eon-ui-icon
                                    name="radio_checkbox_tick"
                                    scheme="white"
                                />
                            </span>
                        </div>
                    </eon-ui-background>
                </div>
                <div className="step-wrapper-checked">
                    <h3 className="step-info">{stepInfo}</h3>
                    <div className={'answer-container'}>
                        <ul className="step-checked-answer">
                            {field.fields
                                .filter(
                                    (f) => f.model.innerText || f.model.value
                                )
                                .map((f, i) => {
                                    if (Array.isArray(f.model.value)) {
                                        return (
                                            <li key={`key-${i}`}>
                                                {f.model.value.join(', ')}
                                            </li>
                                        );
                                    }
                                    return (
                                        <li key={`key-${i}`}>
                                            {f.model.innerText ?? f.model.value}
                                        </li>
                                    );
                                })}
                        </ul>
                        <div className={'change-button'}>
                            <eon-ui-link
                                hide-icon="true"
                                href={'javascript:void(0);'}
                                onClick={navigationUpdate}
                                text={'Zmień'}
                                aria-label={'Zmień'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
                <>
                    <div className="step-container-gray">
                        <div className="step-number-circle-wrapper-gray">
                            <eon-ui-background color="turquoise">
                                <div className="step-number-circle">
                                    <span className="step-circle-number">
                                        {stepNumber}
                                    </span>
                                </div>
                            </eon-ui-background>
                        </div>
                        <div className="step-wrapper-gray future-step-gray">
                            <h3 className="step-info">{stepInfo}</h3>
                            <div hidden>{field.fields.map(fieldFactory)}</div>
                        </div>
                    </div>
                </>
            );
};

export default StepField;
