import React from 'react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HeadlineIcon = ({ params, sitecoreContext, t }) => {
    const isMobile = useMobileCheck();

    if (
        sitecoreContext.pageEditing &&
        (!params || !params.scheme || !params.icon)
    ) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyParameters')}
            </eon-ui-text>
        );
    }

    if (!params.icon) {
        return '';
    }

    return (
        <div className="headline-icon">
            {sitecoreContext.pageEditing && <p>Headline Icon Component</p>}
            <eon-ui-icon
                scheme={params.scheme}
                name={params.icon}
                size={isMobile ? "small" : "normal"}
            ></eon-ui-icon>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(HeadlineIcon));
