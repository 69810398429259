import React from 'react';
import {
    withSitecoreContext,
    Placeholder
} from '@sitecore-jss/sitecore-jss-react';

const FileList = ({ rendering, sitecoreContext }) => {
    const hasButton = !!rendering.placeholders['eon-ctabutton'].length;

    return (
        <div className="file-list">
            {sitecoreContext.pageEditing && <p>File List Component</p>}

            <div className="file-list__headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <div className="file-list__file">
                <Placeholder name="eon-file" rendering={rendering} />
            </div>

            {(sitecoreContext.pageEditing || hasButton) && (
                <div className="file-list__cta">
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(FileList);
