import React from 'react';
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import SingleLineTextField from './Fields/SingleLineTextField/index.js';
import MultiLineTextField from './Fields/MultiLineTextField/index.js';
import PasswordField from './Fields/PasswordField/index.js';
import NumberField from './Fields/NumberField/index.js';
import PhoneField from './Fields/PhoneField/index.js';
import EmailField from './Fields/EmailField/index.js';
import CheckboxField from './Fields/CheckboxField/index.js';
import CheckboxList from './Fields/CheckboxList/index.js';
import DropdownListField from './Fields/DropdownListField/index.js';
import RadioButtonListField from './Fields/RadioButtonListField/index.js';
import StepField from './Fields/StepField/index.js';
import SubmitButton from './Fields/SubmitButton/index.js';
import FieldTypes from './Fields/fieldTypes.js';
import Agreement from './Fields/Agreement/index.js';
import InfoObligation from './Fields/InfoObligation/index.js';
import GenericFormParameters from './Fields/GenericFormParameters/index.js';
import PlaceholderField from './Fields/PlaceholderField/index.js';
import QmaticVisitLocation from './Fields/Qmatic/QmaticVisitLocation/index.js';
import QmaticVisitClientType from './Fields/Qmatic/QmaticVisitClientType/index.js';
import QmaticVisitSubject from './Fields/Qmatic/QmaticVisitSubject/index.js';
import QmaticVisitDate from './Fields/Qmatic/QmaticVisitDate/index.js';
import QmaticVisitHour from './Fields/Qmatic/QmaticVisitHour/index.js';
import QmaticParameters from './Fields/Qmatic/QmaticParameters/index.js';
import FileUpload from './Fields/FileUpload/index.js';
import ParametersField from './Fields/ParametersField/index.js';
import GTMField from './Fields/GTMField/index.js';
import EmptyRendering from './Fields/EmptyRendering/index.js';
import GoogleRecaptcha from './Fields/GoogleRecaptcha/index.js';

const fieldDefinitions = [
    {
        fieldType: FieldTypes.defaultFields.SingleLineText,
        component: SingleLineTextField
    },
    {
        fieldType: FieldTypes.defaultFields.MultipleLineText,
        component: MultiLineTextField
    },
    {
        fieldType: FieldTypes.defaultFields.Password,
        component: PasswordField
    },
    {
        fieldType: FieldTypes.defaultFields.NumberField,
        component: NumberField
    },
    {
        fieldType: FieldTypes.defaultFields.Telephone,
        component: PhoneField
    },
    {
        fieldType: FieldTypes.defaultFields.Email,
        component: EmailField
    },
    {
        fieldType: FieldTypes.defaultFields.Checkbox,
        component: CheckboxField
    },
    {
        fieldType: FieldTypes.defaultFields.CheckboxList,
        component: CheckboxList
    },
    {
        fieldType: FieldTypes.defaultFields.DropdownList,
        component: DropdownListField
    },
    {
        fieldType: FieldTypes.defaultFields.RadioButtonList,
        component: RadioButtonListField
    },
    {
        fieldType: FieldTypes.step,
        component: StepField
    },
    {
        fieldType: FieldTypes.defaultFields.Button,
        component: SubmitButton
    },
    {
        fieldType: FieldTypes.defaultFields.FileUpload,
        component: FileUpload
    },
    {
        fieldType: FieldTypes.agreement,
        component: Agreement
    },
    {
        fieldType: FieldTypes.gtm,
        component: GTMField
    },
    {
        fieldType: FieldTypes.infoObligation,
        component: InfoObligation
    },
    {
        fieldType: FieldTypes.genericFormParameters,
        component: GenericFormParameters
    },
    {
        fieldType: FieldTypes.placeholder,
        component: PlaceholderField
    },
    {
        fieldType: FieldTypes.qmaticVisitLocation,
        component: QmaticVisitLocation
    },
    {
        fieldType: FieldTypes.qmaticVisitClientType,
        component: QmaticVisitClientType
    },
    {
        fieldType: FieldTypes.qmaticVisitSubject,
        component: QmaticVisitSubject
    },
    {
        fieldType: FieldTypes.qmaticVisitDate,
        component: QmaticVisitDate
    },
    {
        fieldType: FieldTypes.qmaticVisitHour,
        component: QmaticVisitHour
    },
    {
        fieldType: FieldTypes.qmaticParameters,
        component: QmaticParameters
    },
    {
        fieldType: FieldTypes.executeScript,
        component: EmptyRendering
    },
    {
        fieldType: FieldTypes.parametersField,
        component: ParametersField
    },
    {
        fieldType: FieldTypes.googleRecaptcha,
        component: GoogleRecaptcha
    }
];

const FieldFactory = {
    initFactory: (
        fieldValues,
        conditionsHandler,
        navigationHandler,
        stepHandler,
        stateHandler,
        qmaticHandler,
        sitecoreContext,
        rendering,
        qmaticDataHandler,
        qmaticSummaryHandler,
        getQmaticData
    ) => {
        const defaultFieldFactory = createDefaultFieldFactory();
        fieldDefinitions.forEach((fd) => {
            const Component = fd.component;
            defaultFieldFactory.setComponent(fd.fieldType, (props) => {
                return (
                    <Component
                        {...props}
                        fieldState={
                            fieldValues[
                                props.field.model.conditionSettings.fieldKey
                            ]
                        }
                        sitecoreContext={sitecoreContext}
                        rendering={rendering}
                        conditionsHandler={(f) => conditionsHandler(f)}
                        navigationHandler={(f) => navigationHandler(f)}
                        stepHandler={(f) => stepHandler(f)}
                        stateHandler={(f) => stateHandler(f)}
                        qmaticHandler={qmaticHandler}
                        qmaticDataHandler={(f) => qmaticDataHandler(f)}
                        qmaticSummaryHandler={(f) => qmaticSummaryHandler(f)}
                        getQmaticData={(f) => getQmaticData(f)}
                    />
                );
            });
        });

        return defaultFieldFactory;
    }
};

export default FieldFactory;
