import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import RouteLink from '../Route/RouteLink/index.js';

const CategoryTeaserTile = ({ fields, rendering, params, ratio }) => {
    const shadowOnImage = !!params.shadowOnImage;

    return (
        <div className="category-teaser-tile">
            <div className="category-teaser-tile-image">
                <div className="category-teaser-tile-image">
                    <eon-ui-image
                        slot="image"
                        src={fields.image.value?.src}
                        alt={fields.image.value?.alt}
                        shadow-on-image={shadowOnImage}
                        aspect-ratio={ratio}
                    />
                </div>

                <div className="category-teaser-tile-image-headline">
                    <eon-ui-headline
                        text={fields.imageTitle?.value}
                        size="h3"
                        scheme="white"
                        class="category-teaser-tile-image-headline"
                        wide-text
                    />
                </div>
            </div>
            <eon-ui-background color="eon-ultralightgrey">
                <div className="category-teaser-tile-links">
                    {fields.link1.value.text && (
                        <div className="category-teaser-tile-link">
                            <RouteLink
                                href={fields.link1.value?.href}
                                target={fields.link1.value?.target}
                                text={fields.link1.value?.text}
                                scheme="darkgrey"
                                red-hover-style={true}
                            />
                        </div>
                    )}
                    {fields.link2.value.text && (
                        <div className="category-teaser-tile-link">
                            <RouteLink
                                href={fields.link2.value?.href}
                                target={fields.link1.value?.target}
                                text={fields.link2.value?.text}
                                scheme="darkgrey"
                                red-hover-style={true}
                            />
                        </div>
                    )}
                    {fields.link3.value.text && (
                        <div className="category-teaser-tile-link">
                            <RouteLink
                                href={fields.link3.value?.href}
                                target={fields.link1.value?.target}
                                text={fields.link3.value?.text}
                                scheme="darkgrey"
                                red-hover-style={true}
                            />
                        </div>
                    )}
                    {fields.link4.value.text && (
                        <div className="category-teaser-tile-link">
                            <RouteLink
                                href={fields.link4.value?.href}
                                target={fields.link1.value?.target}
                                text={fields.link4.value?.text}
                                scheme="darkgrey"
                                red-hover-style={true}
                            />
                        </div>
                    )}
                    <div className="category-teaser-tile-cta">
                        <Placeholder
                            name="eon-ctabutton"
                            rendering={rendering}
                        />
                    </div>
                    {fields.bottomLink.value.text && (
                        <div className="category-teaser-title-bottom">
                            <div className="category-teaser-title-bottom-text">
                                <eon-ui-text font-weight="bold">
                                    {fields.bottomText.value}
                                </eon-ui-text>
                            </div>

                            <div className="category-teaser-title-bottom-link">
                                <eon-ui-link
                                    href={fields.bottomLink.value?.href}
                                    target={fields.link1.value?.target}
                                    text={fields.bottomLink.value?.text}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </eon-ui-background>
        </div>
    );
};

export default CategoryTeaserTile;
