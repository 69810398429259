function gridStyle(columns) {
    columns = Number(columns);
    switch (columns) {
        case 1:
            return 'grid__d12__t12';
        case 2:
            return 'grid__d6-6__t6-6';
        case 3:
            return 'grid__d4-4-4__t4-4-4';
        case 4:
            return 'grid__d3-3-3-3__t6-6-6-6';
        case 5:
            return 'grid__d2-2-2-2-2__t12';
        case 6:
            return 'grid__d2-2-2-2-2-2__t12';
        default:
            return '';
    }
}

export default gridStyle;
