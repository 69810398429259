import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const DownloadLink = ({ fields, sitecoreContext, t }) => {
    if (fields?.file?.value?.src) {
        const fileName =
            fields?.title?.value !== ''
                ? fields?.title?.value
                : fields?.file?.value?.displayName;
        return (
            <eon-ui-link
                href={fields.file.value.src}
                text={fileName}
                target="_blank"
                download="true"
            ></eon-ui-link>
        );
    } else if (sitecoreContext.pageEditing) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    } else {
        return '';
    }
};

export default withTranslation()(withSitecoreContext()(DownloadLink));
