import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { usePortalNode } from '../../hooks/usePortalNode';
import ReactDOM from 'react-dom';
import './FlyoutManageAccount.scss';
import { useClickOutside } from '../../hooks/useClickOutside';
import avatarBorder from '../../assets/icons/avatar-border.svg';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { camelCase } from 'lodash';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const ordinaryProductType = 100;
const gasProductType = 200;
const ozeProductType = 300;
const logoutButtonTestId = 'b148f23c-ecc8-4921-a534-38054cb2f89c';
const noCollapseCriteria = 8;

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key])
            }),
            {}
        );
    }
    return obj;
};

const FlyoutTrigger = ({ fields, onClick, currentPh, hasAvatar, avatar }) => {
    return (
        <div
            className="button-wrapper"
            style={{
                width: '32px',
                height: '32px',
                justifyContent: 'center',
                display: 'flex',
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            {hasAvatar ? (
                <>
                    <div
                        className={'user-selector'}
                        style={{
                            backgroundImage: hasAvatar
                                ? 'url(' + avatar + ')'
                                : ''
                        }}
                    ></div>
                    <img
                        className={'avatar-border'}
                        src={avatarBorder}
                        style={{ backgroundColor: 'transparent' }}
                    />
                </>
            ) : (
                <>
                    <img className={'avatar-border'} src={avatarBorder} />
                    <eon-ui-icon
                        size={'small'}
                        style={{ color: 'white' }}
                        name="user_outline"
                    />
                </>
            )}
        </div>
    );
};

const Modal = ({ setIsModalOpen, isModalOpen, accountResource, children }) => {
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isModalOpen]);

    return (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
            <div
                className="modal-content"
                id="flyout-manage-account-footer-line"
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    className="button-wrapper"
                    id="flyout-manage-account-header"
                    style={{
                        backgroundColor: `${
                            accountResource > 8
                                ? 'var(--eon-ultralightgrey)'
                                : 'var(--eon-white)'
                        }`
                    }}
                >
                    <button onClick={() => setIsModalOpen(false)}>
                        <eon-ui-icon
                            className={'icon'}
                            size={'normal'}
                            name="close"
                        />
                    </button>
                </div>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    );
};

const CurrentClient = ({
    openModalOpen,
    fields,
    t,
    partners,
    currentKuDisplayName,
    currentPh,
    currentKu,
    contextPartnerProductType,
    labels,
    hasAvatar,
    avatar
}) => {
    const [isSwitchingOptionVisible, setIsSwitchingOptionVisible] =
        useState(false);

    useEffect(() => {
        setIsSwitchingOptionVisible(
            partners.length +
                partners.reduce(
                    (count, current) =>
                        count +
                        (current.contractAccounts
                            ? current.contractAccounts.length
                            : 0),
                    0
                ) >
                2
                ? true
                : false
        );
    }, []);

    return (
        <div className={'current-user'}>
            <div className={'user-avatar'}>
                {
                    <>
                        {hasAvatar ? (
                            <>
                                <div
                                    className="user-avatar-img"
                                    style={{
                                        backgroundImage: 'url(' + avatar + ')'
                                    }}
                                ></div>
                                <img
                                    className={'avatar-border'}
                                    src={avatarBorder}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            </>
                        ) : (
                            <div className="avatar">
                                <img
                                    className={'avatar-border'}
                                    src={avatarBorder}
                                />
                                <eon-ui-icon
                                    size={'medium'}
                                    style={{
                                        color: 'white'
                                    }}
                                    name="user_outline"
                                />
                            </div>
                        )}
                    </>
                }

                <div className={'change-photo'}>
                    <a href={fields.changeAvatarLink?.value?.href}>
                        <eon-ui-icon
                            className={'icon'}
                            size={'small'}
                            style={{ color: 'red' }}
                            name="camera"
                        />
                    </a>
                </div>
            </div>

            <div className={'user-email'}>
                <eon-ui-text
                    color="eon-darkgrey"
                >
                    {fields.userEmail}
                </eon-ui-text>
            </div>
            {isSwitchingOptionVisible ? (
                <div className={'user-details-wrapper'}>
                    <div className={'user-details'}>
                        <div className="user-details-icon">
                            <eon-ui-icon
                                size={
                                    contextPartnerProductType ==
                                    ordinaryProductType || contextPartnerProductType == gasProductType
                                        ? 'small'
                                        : 'normal'
                                }
                                name={
                                    contextPartnerProductType ==
                                    ordinaryProductType
                                        ? 'pict_lightning'
                                        : contextPartnerProductType == gasProductType ? 'pict_natural_gas' : 'solar_panel'
                                }
                            />
                        </div>
                        <div className="user-details-personal">
                            <div className="user-details-name">
                                {currentKuDisplayName}
                            </div>
                            <div className="user-details-personal-name">
                                <eon-ui-text
                                    color="eon-darkgrey"
                                    font-weight="bold"
                                >
                                    {
                                        partners.filter(
                                            (p) => p.id == currentPh
                                        )[0].viewName
                                    }
                                </eon-ui-text>
                            </div>
                            <div className="user-details-ph-ku">
                                <eon-ui-text color="eon-darkgrey">
                                    {`${labels.phLabel} ${currentPh}`}
                                </eon-ui-text>
                            </div>
                            <div className="user-details-ph-ku">
                                <eon-ui-text color="eon-darkgrey">
                                    {`${labels.kuLabel} ${currentKu}`}
                                </eon-ui-text>
                            </div>
                        </div>
                    </div>
                    <div className="button">
                        <eon-ui-button
                            text={t('Content|Account|ChooseClient')}
                            rank="secondary"
                            open-modal-id="select-list-ph-ku"
                            size="small"
                            onClick={openModalOpen}
                        ></eon-ui-button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const Search = ({ onChange, t }) => {
    const [searchPhrasePhKu, setSearchPhrasePhKu] = useState('');
    const inputRef = useRef();
    const searchButtonRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener('valueChanged', (e) =>
            handleChange(e)
        );
        inputRef.current.addEventListener('iconClick', (e) => iconClick(e));

        return () => {
            inputRef.current.removeEventListener('valueChanged', (e) =>
                handleChange(e)
            );

            inputRef.current.removeEventListener('iconClick', (e) =>
                iconClick(e)
            );
        };
    }, [inputRef]);

    const handleChange = (event) => {
        setSearchPhrasePhKu(event.target.value);
        if (event.target.value) {
            onChange(event.target.value);
        } else {
            onChange('');
        }
    };
    const pressKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onChange(e.target.value);
        }
    };
    const iconClick = () => {
        searchButtonRef.current.click();
    };

    return (
        <div className="user-search-wrapper">
            <div className={`user-search `}>
                <eon-ui-input
                    label={t('Content|Account|ChooseClientSearch')}
                    name="search"
                    trailing-icon="true"
                    trailing-icon-name="search"
                    clear-input-icon="true"
                    ref={inputRef}
                    value={searchPhrasePhKu}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => {
                        pressKeyDown(e);
                    }}
                ></eon-ui-input>
                <button
                    ref={searchButtonRef}
                    className="search-button"
                    type="search"
                    hidden={true}
                ></button>
            </div>
        </div>
    );
};

const ModalDetails = ({ t, phCount, kuCount }) => (
    <div className={'modal-details-container'}>
        <h4
            className={'modal-details-header'}
            style={{ fontWeight: '700', fontSize: '2,2rem' }}
        >
            {t('Content|Account|ChooseClientDetails')}
        </h4>
        <div className={'modal-details-wrapper'}>
            {phCount > 1 ? (
                <p className={'modal-details-textline'}>
                    {t('Content|Account|PhClientSummary')}: {phCount}
                </p>
            ) : null}

            <p className={'modal-details-textline'}>
                {t('Content|Account|KuClientSummary')} {kuCount}
            </p>
        </div>
    </div>
);

const InitialsCircle = (props) => {
    const [initials, setInitials] = useState('');
    const {
        viewName,
        customHeight,
        customWidth,
        fontSize,
        customNode,
        customBorderRadius
    } = props;

    useEffect(() => {
        if (viewName !== undefined) {
            setInitials(viewName.charAt(0));
        }
    }, [viewName]);

    return (
        <div
            style={{
                width: `${(customWidth && customWidth) || '40px'}`,
                height: `${(customHeight && customHeight) || '40px'}`,
                fontSize: `${(fontSize && fontSize) || '22px'}`,
                borderRadius: `${
                    (customBorderRadius && customBorderRadius) || '50%'
                }`,
                background: 'red',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {customNode ? customNode : initials}
        </div>
    );
};

const Accordion = ({
    data,
    onclick,
    currentPh,
    currentKu,
    hasOze,
    labels,
    phKuNumber
}) => {
    const [activePHList, setActivePHList] = useState([]);

    useEffect(() => {
     if(phKuNumber <= noCollapseCriteria) 
        setActivePHList([...activePHList, ...data.map((item) => item.id)]);
     else
        setActivePHList([currentPh]);
    }, [phKuNumber]);
    return (
        <div>
            {data.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`accordion-item ${
                            activePHList.includes(item.id) 
                                ? 'active'
                                : ''
                        }`}
                    >
                        <div className="accordion-item-header"
                         onClick={() => {
                            if (activePHList.includes(item.id)) {
                                setActivePHList(
                                    activePHList.filter((i) => i !== item.id)
                                );
                            } else {
                                setActivePHList([...activePHList, item.id]);
                            }
                        }}
                        >
                            <div
                                className={`left-content ${
                                    activePHList.includes(item.id)
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <InitialsCircle
                                    viewName={item.viewName}
                                    customHeight="32px"
                                    customWidth="32px"
                                    customBorderRadius="16px"
                                    customNode={
                                        <eon-ui-icon
                                            size={'small'}
                                            style={{ color: 'white' }}
                                            name={
                                                item.segmentIcon ==
                                                'icon-briefcase'
                                                    ? 'briefcase'
                                                    : 'user_outline'
                                            }
                                        />
                                    }
                                />

                                <div className="left-content-data">
                                    {item.isAdministrated ? (
                                        <div className={`is-administrated`}>
                                            {`Udostępnione`}
                                        </div>
                                    ) : null}
                                    <div className="left-content-view-name">
                                        {item.viewName}
                                    </div>
                                    <div className="left-content-ph-number">
                                        {`${labels.phLabel} ${item.id}`}
                                    </div>
                                </div>
                            </div>
                            <div className="right-content">
                                    <div
                                        className={`arrow ${
                                            activePHList.includes(item.id)
                                                ? 'active'
                                                : ''
                                        } `}
                                    >
                                        <eon-ui-icon
                                            size={'small'}
                                            name="icon_chevron_bold_down"
                                        />
                                    </div>
                            </div>
                        </div>

                        {item.kuCount && item.kuCount > 0 ? (
                            <div
                                className={`accordion-item-content`}
                            >
                                {hasOze
                                    ? item.contractAccounts.map((ku, i) => {
                                          return (
                                              <div
                                                  key={i}
                                                  className={`accordion-item-content-card-oze`}
                                              >
                                                  <div
                                                      className={`ppe-list-header ${
                                                          !ku.isActive
                                                              ? 'inActive-ku'
                                                              : ''
                                                      }`}
                                                  >
                                                      <div
                                                          className={'ku-icon'}
                                                          style={{}}
                                                      >
                                                          <eon-ui-icon
                                                              size={'normal'}
                                                              name={'file'}
                                                          />
                                                      </div>
                                                      <div
                                                          className="ku-element-account-number"
                                                          style={{
                                                              textAlign: 'left'
                                                          }}
                                                      >{` ${labels.kuLabel} ${ku.kuDisplayName}`}</div>
                                                  </div>
                                                  {ku.ppeList.map((item, i) => (
                                                      <div
                                                          key={i}
                                                          className={`ppe-list-item ${
                                                              item.isCurrent
                                                                  ? 'active-ppe'
                                                                  : ''
                                                          } `}
                                                          onClick={
                                                              item.isCurrent
                                                                  ? null
                                                                  : onclick(
                                                                        item
                                                                    )
                                                          }
                                                      >
                                                          <div className="ppe-list-item-icon">
                                                              <eon-ui-icon
                                                                  style={{
                                                                      opacity:
                                                                          !item.isActive &&
                                                                          '0.5'
                                                                  }}
                                                                  size={
                                                                      item.productType ==
                                                                      ordinaryProductType || item.productType == gasProductType
                                                                          ? 'small'
                                                                          : 'normal'
                                                                  }
                                                                  name={
                                                                      item.productType ==
                                                                      ordinaryProductType
                                                                          ? 'pict_lightning'
                                                                          : item.productType == gasProductType ? 'pict_natural_gas' : 'solar_panel'
                                                                  }
                                                              />
                                                          </div>
                                                          <div
                                                              className={`ppe-list-item-content ${
                                                                  !item.isActive
                                                                      ? 'inActive-ppe'
                                                                      : ''
                                                              }`}
                                                          >
                                                              <div className="ppe-display-name">
                                                                  {
                                                                      item.ppeDisplayName
                                                                  }
                                                                  {!item.isActive && (
                                                                      <span>
                                                                          {` (${labels.inactiveAccount})`}
                                                                      </span>
                                                                  )}
                                                              </div>
                                                              {item.productType ==
                                                                  ozeProductType && (
                                                                  <div className="ppe-id">{` ${labels.ppeLabel} ${item.id}`}</div>
                                                              )}
                                                          </div>
                                                          <div className="right-content-ku">
                                                              <span
                                                                  className={`list-search__indicator ${
                                                                      item.isCurrent
                                                                          ? 'active-ppe'
                                                                          : ''
                                                                  }`}
                                                              ></span>
                                                          </div>
                                                      </div>
                                                  ))}
                                              </div>
                                          );
                                      })
                                    : item.contractAccounts.map((ku, i) => {
                                          const selected =
                                              currentKu === ku.id &&
                                              currentPh === item.id;
                                          return (
                                              <div
                                                  key={i}
                                                  className={`accordion-item-content-card ${
                                                      currentKu === ku.id
                                                          ? 'active-ku'
                                                          : ''
                                                  }`}
                                                  onClick={
                                                      selected
                                                          ? null
                                                          : onclick(ku)
                                                  }
                                              >
                                                  <div
                                                      className={`left-content-ku ${
                                                          ku.isActive
                                                              ? ' '
                                                              : 'inActive-ku'
                                                      }`}
                                                  >
                                                      <div
                                                          className={
                                                              'accordion-item-content-icon'
                                                          }
                                                          style={{}}
                                                      >
                                                          <eon-ui-icon
                                                              size={'small'}
                                                              name={
                                                                  ku.productType ==
                                                                  ordinaryProductType
                                                                      ? 'pict_lightning'
                                                                      : 'pict_natural_gas'
                                                              }
                                                          />
                                                      </div>
                                                      <div
                                                          className={
                                                              'accordion-item-content-data'
                                                          }
                                                          style={{
                                                              display: 'flex',
                                                              flexDirection:
                                                                  'column',
                                                              justifyContent:
                                                                  'flex-start',
                                                              width: '100%'   
                                                          }}
                                                      >
                                                          <div
                                                              className="ku-element-name"
                                                              style={{
                                                                  fontWeight:
                                                                      '700',
                                                                  textAlign:
                                                                      'left',
                                                                  marginBottom: `-8px`,
                                                                  maxWidth: '100%',
                                                                  overflow: 'hidden',
                                                                  textOverflow: 'ellipsis',
                                                                  whiteSpace: 'nowrap',
                                                                  marginRight: '12px',
                                                                  fontSize: '16px',
                                                                  lineHeight: '28px' 
                                                              }}
                                                          >
                                                              {ku.kuDisplayName}{' '}
                                                              {`${
                                                                  ku.isActive
                                                                      ? ' '
                                                                      : '(Nieaktywne)'
                                                              }`}
                                                          </div>
                                                          <div
                                                              className="ku-element-account-number"
                                                              style={{
                                                                  textAlign:
                                                                      'left',
                                                                  whiteSpace: 'nowrap',
                                                                  fontSize: '14px',
                                                                  lineHeight: '28px'    
                                                              }}
                                                          >{` ${labels.kuLabel} ${ku.id}`}</div>
                                                      </div>
                                                  </div>
                                                  <div className="right-content-ku">
                                                      <span
                                                          className={`list-search__indicator ${
                                                              currentKu ===
                                                              ku.id
                                                                  ? 'active-ku'
                                                                  : ''
                                                          }`}
                                                      ></span>
                                                  </div>
                                              </div>
                                          );
                                      })}
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

const ClientSelector = ({
    partners,
    t,
    fields,
    currentPh,
    currentKu,
    hasOze,
    labels,
    accountResource
}) => {
    const [search, setSearch] = useState('');
    const [tempPartners, setTempPartners] = useState(partners);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [completionOfTheDropDownList, setCompletionOfTheDropDownList] =
        useState(300);
    const [phKuNumber, setPhKuNumber] = useState(
        partners.length +
            partners.reduce(
                (count, current) =>
                    count +
                    (current.contractAccounts
                        ? current.contractAccounts.length
                        : 0),
                0
            )
    );
    const isMobile = useMobileCheck();
    const deviceHeight = window.innerHeight;

    window.addEventListener('resize', () => {
        setCompletionOfTheDropDownList(handlerCompletionOfTheDropDownList());
        deviceHeight = window.innerHeight;
    });

    const style = isMobile
        ? { maxHeight: `${deviceHeight - completionOfTheDropDownList}px` }
        : {};

    useEffect(() => {
        setCompletionOfTheDropDownList(handlerCompletionOfTheDropDownList());
        if (accountResource > 8) {
            setIsSearchVisible(true);
        }
    }, []);

    useEffect(() => {
        setCompletionOfTheDropDownList(handlerCompletionOfTheDropDownList());
    }, [isMobile, completionOfTheDropDownList]);

    useEffect(() => {
        let filteredPartners = [];
        if (search == '') {
            filteredPartners = partners;
        } else {
            filteredPartners = [];
            partners.filter((ph) => {
                const partnerMatch =
                    ph.viewName.toLowerCase().includes(search.toLowerCase()) ||
                    ph.id.includes(search.toLowerCase());
                if (partnerMatch) {
                    filteredPartners.push(ph);
                } else {
                    const phMatchContactAccounts = ph.contractAccounts.filter(
                        (ca) =>
                            ca.kuDisplayName
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                            ca.id.includes(search.toLowerCase())
                    );
                    if (phMatchContactAccounts.length) {
                        filteredPartners.push({
                            ...ph,
                            contractAccounts: phMatchContactAccounts
                        });
                    }
                }
            });
        }
        setTempPartners(filteredPartners);
        setPhKuNumber(
            filteredPartners.length +
                filteredPartners.reduce(
                    (count, current) =>
                        count +
                        (current.contractAccounts
                            ? current.contractAccounts.length
                            : 0),
                    0
                )
        );
    }, [search]);

    const onClick = (x) => {
        return () => {
            window.location = x.switchContextLink;
        };
    };
    const handlerCompletionOfTheDropDownList = () => {
        const box = document.querySelector('#flyout-manage-account-header');
        const box2 = document.querySelector('#flyout-manage-account-title');
        const box3 = document.querySelector('#flyout-manage-account-footer');
        const box4 = getComputedStyle(
            document.querySelector('#flyout-manage-account-footer-line')
        ).borderBottomWidth;
        return (
            box.offsetHeight +
            box2.offsetHeight +
            box3.offsetHeight +
            parseInt(box4)
        );
    };
    return (
        <div className={'user-selector'}>
            <div
                className="modal-header-wrapper"
                id="flyout-manage-account-title"
                style={{
                    backgroundColor: `${
                        accountResource > 8
                            ? 'var(--eon-ultralightgrey)'
                            : 'var(--eon-white)'
                    }`
                }}
            >
                {tempPartners.length ? (
                    <ModalDetails
                        t={t}
                        phCount={tempPartners.length}
                        kuCount={tempPartners.reduce(
                            (count, current) =>
                                count +
                                (current.contractAccounts
                                    ? current.contractAccounts.length
                                    : 0),
                            0
                        )}
                    />
                ) : (
                    <ModalDetails t={t} phCount={0} kuCount={0} />
                )}
                {isSearchVisible && <Search onChange={setSearch} t={t} />}
            </div>
            <div
                className={`partner-list-container ${
                    phKuNumber < 9 ? 'expanded' : ''
                }`}
                style={style}
            >
                <Accordion
                    data={tempPartners}
                    onclick={onClick}
                    currentPh={currentPh}
                    currentKu={currentKu}
                    hasOze={hasOze}
                    phKuNumber={phKuNumber}
                    labels={labels}
                />
            </div>
            <div className={'modal-footer-wrapper'}></div>
        </div>
    );
};

const ControlButtons = ({ fields }) => {
    const handleLogoutClick = async () => {
        if (fields.logoutLink?.value?.href) {
            const response = await fetch(fields.logoutLink?.value?.href, {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            });

            if (response.redirected) {
                window.location.href = response.url;
            }
        }
    };

    return (
        <div className={'control-buttons'}>
            <a href={fields.accountSettingsLink?.value?.href}>
                <eon-ui-icon
                    className={'icon'}
                    size={'normal'}
                    style={{ color: 'red' }}
                    name={fields.accountSettingsIcon.value}
                />
                {fields.accountSettingsLink?.value?.text}
            </a>
            <a
                data-test-id={logoutButtonTestId}
                href="#"
                onClick={handleLogoutClick}
            >
                <eon-ui-icon
                    className={'icon'}
                    size={'normal'}
                    style={{ color: 'red' }}
                    name={fields.logoutIcon.value}
                />
                {fields.logoutLink?.value?.text}
            </a>
        </div>
    );
};

const LoginFormPortal = ({
    isPanelVisible,
    onPanelClose,
    fields,
    t,
    partners,
    currentKuDisplayName,
    currentPh,
    currentKu,
    contextPartnerProductType,
    hasOze,
    labels,
    accountResource,
    hasAvatar,
    avatar
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCurentUserModalOpen, setIsCurentUserModalOpen] = useState(true);

    const portalElement = usePortalNode();

    const containerRef = useRef(null);
    useClickOutside(containerRef, onPanelClose);
    const currentPartner = { ...currentPh, ...currentKu };

    const handleopenModalOpen = () => {
        setIsModalOpen(true);
        setIsCurentUserModalOpen(false);
    };
    const body = (
        <div>
            <div
                style={{ position: 'absolute', right: '0px' }}
                ref={containerRef}
            >
                <div
                    className={` ${
                        isCurentUserModalOpen
                            ? 'manage-accounts'
                            : 'manage-accounts-hidden'
                    }`}
                >
                    <CurrentClient
                        isModalOpen={isModalOpen}
                        openModalOpen={() => handleopenModalOpen()}
                        onPanelClose={onPanelClose}
                        isPanelVisible={isPanelVisible}
                        fields={fields}
                        partner={currentPartner}
                        t={t}
                        partners={partners}
                        currentKuDisplayName={currentKuDisplayName}
                        currentPh={currentPh}
                        currentKu={currentKu}
                        contextPartnerProductType={contextPartnerProductType}
                        labels={labels}
                        hasAvatar={hasAvatar}
                        avatar={avatar}
                    />
                    <ControlButtons fields={fields} />
                </div>
                {isModalOpen && (
                    <div className="ph-ku-list">
                        <Modal
                            accountResource={accountResource}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                        >
                            <ClientSelector
                                partners={partners}
                                t={t}
                                fields={fields}
                                currentPh={currentPh}
                                currentKu={currentKu}
                                hasOze={hasOze}
                                labels={labels}
                                accountResource={accountResource}
                            />
                            <div
                                className="modal-footer"
                                id="flyout-manage-account-footer"
                            ></div>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );

    return ReactDOM.createPortal(body, portalElement);
};

const FlyoutManageAccount = ({ rendering, fields, t, sitecoreContext }) => {
    const [partners, setPartners] = useState([]);
    const [currentPh, setCurrentPh] = useState();
    const [currentKu, setCurrentKu] = useState();
    const [hasOze, setHasOze] = useState();
    const [contextPartnerProductType, setContextPartnerProductType] =
        useState();
    const [currentKuDisplayName, setCurrentKuDisplayName] = useState();
    const [labels, setLabels] = useState();
    const [data, setData] = useState();
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [accountResource, setAccountResource] = useState();

    const hasPartnersClass = partners?.length > 0 ? 'has-partners' : '';
    const [isPanelVisible, setIsPanelVIsible] = useState(false);
    const isPageEditing = sitecoreContext.pageEditing;
    const [hasAvatar, setHasAvatar] = useState(false);
    const [avatar, setAvatar] = useState();

    useEffect(() => {
        const controller = new AbortController();

        if (isPageEditing) {
            setDataIsLoading(false);
            return;
        }

        fetch(fields.getAvatarUrl)
            .then((response) => response.blob())
            .then((avatar) => {
                if (
                    avatar === null ||
                    avatar === undefined ||
                    avatar.size <= 0
                ) {
                    setHasAvatar(false);
                } else {
                    setHasAvatar(true);
                    setAvatar(URL.createObjectURL(avatar));
                }
            })
            .catch(() => {});

        fetch(fields.getPHListUrl, { signal: controller.signal })
            .then((response) => response.json())
            .then((response) => camelizeKeys(response))
            .then((phResult) => {
                setData(phResult);
                setPartners(phResult.partners);
                setCurrentPh(phResult.contextPartnerPhId);
                setCurrentKu(phResult.contextPartnerKuId);
                setContextPartnerProductType(
                    phResult.contextPartnerProductType
                );
                setHasOze(phResult.hasOze);
                setCurrentKuDisplayName(
                    phResult.contextPartnerKuName
                        ? phResult.contextPartnerKuName
                        : ' '
                );
                setLabels(phResult.labels);
                setAccountResource(
                    phResult.contractsCount + phResult.partners.length
                );
                setDataIsLoading(false);
            })
            .catch(() => {});

        return () => {
            controller.abort();
        };
    }, []);

    return !dataIsLoading ? (
        <div
            className={`user-menu ${hasPartnersClass} ${
                isPanelVisible ? 'open' : ''
            }`}
        >
            <FlyoutTrigger
                fields={fields}
                t={t}
                onClick={() => setIsPanelVIsible(true)}
                currentPh={currentPh}
                hasAvatar={hasAvatar}
                avatar={avatar}
            />
            {isPanelVisible && (
                <LoginFormPortal
                    onPanelClose={() => setIsPanelVIsible(false)}
                    isPanelVisible={isPanelVisible}
                    rendering={rendering}
                    fields={fields}
                    partners={partners}
                    currentPh={currentPh}
                    currentKu={currentKu}
                    hasOze={hasOze}
                    currentKuDisplayName={currentKuDisplayName}
                    contextPartnerProductType={contextPartnerProductType}
                    accountResource={accountResource}
                    labels={labels}
                    t={t}
                    hasAvatar={hasAvatar}
                    avatar={avatar}
                />
            )}
        </div>
    ) : (
        <div></div>
    );
};

export default withTranslation()(withSitecoreContext()(FlyoutManageAccount));
