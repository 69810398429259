import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const SocialMediaSharing = ({ rendering }) => {
    return (
        <>
            <Placeholder name="eon-social-media" rendering={rendering} />
        </>
    );
};

export default withSitecoreContext()(SocialMediaSharing);
