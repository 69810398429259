import React, { useEffect, useState } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import AnchorTile from '../AnchorTile';

const AnchorTiles = ({ rendering, sitecoreContext, params }) => {
    const [anchors, setAnchors] = useState([]);
    const [highlightAnchors, setHighlightAnchors] = useState([]);
    const isMobile = useMobileCheck();

    useEffect(() => {
        setAnchors(rendering.placeholders['eon-anchor-tile'].filter(a => !a.params?.redTile));
        setHighlightAnchors(rendering.placeholders['eon-anchor-tile'].filter(a => !!a.params?.redTile));
    }, [isMobile]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="anchor-tiles">
            {sitecoreContext.pageEditing && <p>AnchorTiles Component</p>}
            <div
                className={`tiles-container ${
                    params.columnsInRow === '3' ? 'three' : 'four'
                }`}
            >
                {highlightAnchors &&
                <div className={'highlight-anchors'}>
                    {!sitecoreContext.pageEditing &&
                    highlightAnchors.map((anchor) => (
                        <AnchorTile key={anchor.uid} {...anchor} />
                    ))
                    }
                </div>
                }
                <div className={'anchors'}>
                    {sitecoreContext.pageEditing ? (
                        <Placeholder name="eon-anchor-tile" rendering={rendering}/>
                    ) : (
                        anchors.map((anchor) => (
                            <AnchorTile key={anchor.uid} {...anchor} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(AnchorTiles);
