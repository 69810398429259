import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const DownloadFile = ({ fields, sitecoreContext, t }) => {
    if (!fields) {
        return '';
    }

    if (fields.file?.hasFile) {
        return (
            <div className="download-file">
                {sitecoreContext && sitecoreContext.pageEditing && (
                    <div>Download File Component</div>
                )}

                {fields?.file?.title && (
                    <div className="download-file-title">
                        <eon-ui-text>{fields?.file?.title}</eon-ui-text>
                    </div>
                )}

                <div className="download-file__wrapper__link">
                    <eon-ui-link
                        href={fields?.file?.path}
                        text={fields?.file?.name}
                        target="_blank"
                        download="true"
                    ></eon-ui-link>
                    <eon-ui-text>
                        {fields?.file?.extension} - {fields?.file?.size}
                    </eon-ui-text>
                </div>
            </div>
        );
    } else if (sitecoreContext.pageEditing) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    } else {
        return '';
    }
};

export default withTranslation()(withSitecoreContext()(DownloadFile));
