import React, { useRef, useState } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const OfferHub = ({ rendering }) => {
    const hubRef = useRef();
    const [tags, setTags] = useState([]);

    const tagsHandler = (tag) => {
        const newTags = [];
        if (tags.indexOf(tag) < 0) {
            tags.forEach((item) => newTags.push(item));
            newTags.push(tag);
        } else {
            tags.forEach((item) => {
                if (item !== tag) newTags.push(item);
            });
        }

        setTags([...newTags]);
    };
    const clearTagsHandler = () => setTags([]);

    return (
        <div className="offer-content-wrapper" ref={hubRef}>
            <Placeholder
                name="eon-offer-hub-content"
                rendering={rendering}
                hubRef={hubRef}
                tagAction={(tag) => tagsHandler(tag)}
                clearTagsAction={clearTagsHandler}
                hubTags={tags}
            />
        </div>
    );
};

export default OfferHub;
