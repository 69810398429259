import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const Headline = ({ t, sitecoreContext, params, fields, props }) => {
    let headline =
        fields?.headline && fields?.headline?.value
            ? fields?.headline?.value
            : '';

    if (!fields) {
        headline = headline ? headline : props?.headline;
    }

    if (sitecoreContext.pageEditing && !headline) {
        headline = t('Editor|TextIsEmpty');
    }

    const className = `headline${
        params.parentStyleClass ? ` ${params.parentStyleClass}` : ''
    }${params.isNarrow ? ' headline-margin' : ''}`;

    return (
        <div className={className}>
            <eon-ui-headline
                size={params.textSize}
                scheme={params.scheme}
                alignment={params.align}
                text={headline}
            ></eon-ui-headline>
        </div>
    );
};

export default withSitecoreContext()(withTranslation()(Headline));
