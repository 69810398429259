let closeNavigation = () => {};

const EonNavigation = {
    cleanEvents: function () {
        window.removeEventListener('scroll', this.scrollfuntion, true);
        let header = document.getElementById('eon-header');
        let mobileNav = document.getElementById('eon-mobile-navigation');
        header.classList.remove('fixed');
        mobileNav.classList.remove('fixed');
    },
    navigationInit: function () {
        let header = document.getElementById('eon-header');
        let metaNav = document.getElementById('meta-navigation');
        let primaryNav = document.getElementById('primary-navigation');
        let primaryNavHeight = primaryNav ? primaryNav.offsetHeight : 0;
        let metaNavHeight = metaNav ? metaNav.offsetHeight : 0;
        let primaryLinks = header.querySelectorAll('.primary-nav-item');
        let primaryIconLinks = header.querySelectorAll(
            '.primary-icon-nav-item'
        );
        let secondaryNav = document.getElementById('secondary-navigation');
        let secondaryInnerNavs =
            document.getElementsByClassName('secondary-nav');
        let secondaryIconNav = document.getElementById(
            'secondary-icon-navigation'
        );
        let secondaryIconNavInner = secondaryIconNav.querySelector(
            '.secondary-icon-nav-container-inner'
        );
        let secondaryIconInnerNavs =
            document.getElementsByClassName('secondary-icon-nav');

        let mobileNavToggleButton = document.getElementById(
            'mobile-nav-toggle-button'
        );
        let mobileNav = document.getElementById('eon-mobile-navigation');
        let mobilePrimaryNav = document.getElementById('mobile-primary-nav');
        let mobilePrimaryLinks =
            mobilePrimaryNav.querySelectorAll('.primary-nav-item');
        let mobileSecondaryNavs = document.getElementsByClassName(
            'mobile-secondary-nav'
        );
        let mobileSecondaryLinks = mobileNav.querySelectorAll(
            '.secondary-nav-item'
        );
        let mobileSecondaryNavBacklinks = document.getElementsByClassName(
            'mobile-secondary-nav-backlink'
        );

        let mouseLeaveTimeout;
        let secondaryNavHideTimeout;
        let secondaryIconNavHideTimeout;
        let resizeTimeout;
        let backLinks;

        if (!header.classList.contains('editor')) {
            initPrimaryNavigationFunctionality();
            initPrimaryIconNavigationFunctionality();
            initSecondaryNavigationFunctionality();
            initSecondaryIconNavigationFunctionality();
            initMobileNavigationToggleButton();
            initMobilePrimaryNavigationFunctionality();
            initMobileSecondaryNavigationFunctionality();

            // highlightPrimaryParent();
            checkForNavItemHeader();
            exitSecondaryNavViaKey();
            scrollFunction();
            this.scrollfuntion = scrollFunctionEvent;

            window.addEventListener('scroll', this.scrollfuntion, true);

            window.addEventListener('resize', () => {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(resizeFunction, 250);
            });
        } else {
            checkForNavItemHeader();
            initPrimaryNavigationFunctionality();
            if (primaryLinks[0]) primaryLinks[0].click();
        }

        function scrollFunctionEvent() {
            scrollFunction();
        }

        // PRIMARY
        /* ********************************************************************************************************************************* */

        /*
         * Init primary navigation link click and keydown function
         */
        function initPrimaryNavigationFunctionality() {
            for (let primaryLink of primaryLinks) {
                let relatedSecondaryNav = document.getElementById(
                    primaryLink.dataset.rel
                );
                if (relatedSecondaryNav && !primaryLink.eventsRegistered) {
                    primaryLink.eventsRegistered = true;
                    primaryLink.addEventListener('mouseover', function (e) {
                        e.preventDefault();
                        toggleNavigation(primaryLink);
                    });
                    primaryLink.addEventListener('keydown', function (e) {
                        switch (e.keyCode) {
                            case 13: // ENTER
                                e.preventDefault();
                                toggleNavigationKeyboard(primaryLink);
                                break;
                            case 32: // SPACE
                                e.preventDefault();
                                toggleNavigationKeyboard(primaryLink);
                                break;
                            default:
                                break;
                        }
                    });
                }
            }
        }

        /*
         * Init primary icon navigation link click function
         */
        function initPrimaryIconNavigationFunctionality() {
            for (let primaryIconLink of primaryIconLinks) {
                let relatedSecondaryNav = document.getElementById(
                    primaryIconLink.dataset.rel
                );
                if (relatedSecondaryNav) {
                    primaryIconLink.addEventListener('mouseover', function (e) {
                        e.preventDefault();
                        toggleIconNavigation(primaryIconLink);
                    });
                    primaryIconLink.addEventListener('keydown', function (e) {
                        switch (e.keyCode) {
                            case 13: // ENTER
                                e.preventDefault();
                                toggleIconNavigationKeyboard(primaryIconLink);
                                break;
                            case 32: // SPACE
                                e.preventDefault();
                                toggleIconNavigationKeyboard(primaryIconLink);
                                break;
                            default:
                                break;
                        }
                    });
                }
            }
        }

        function showNavigation(clickedLink) {
            let relatedSecondaryNav = document.getElementById(
                clickedLink.dataset.rel
            );

            if (secondaryNavHideTimeout) {
                clearTimeout(secondaryNavHideTimeout);
            }

            for (let primaryLink of primaryLinks) {
                primaryLink.classList.remove('act');
            }

            for (let secondaryInnerNav of secondaryInnerNavs) {
                secondaryInnerNav.classList.remove('act');
            }

            clickedLink.classList.add('act');
            secondaryNav.classList.add('act');
            relatedSecondaryNav.classList.add('act');
        }

        function hideNavigation() {
            clearTimeout(mouseLeaveTimeout);
            for (let primaryLink of primaryLinks) {
                primaryLink.classList.remove('act');
            }
            secondaryNav.classList.remove('act');
            secondaryNavHideTimeout = setTimeout(function () {
                for (let secondaryInnerNav of secondaryInnerNavs) {
                    secondaryInnerNav.classList.remove('act');
                }
            }, 600);
        }
        closeNavigation = hideNavigation;

        function toggleNavigation(primaryLink) {
            if (
                primaryLink.classList.contains('act') &&
                !header.classList.contains('editor')
            ) {
                hideNavigation();
            } else {
                showNavigation(primaryLink);
                hideIconNavigation();
                hideMobileNav();
            }
        }

        function toggleNavigationKeyboard(primaryLink) {
            if (primaryLink.classList.contains('act')) {
                hideNavigation();
            } else {
                showNavigation(primaryLink);
                hideIconNavigation();
                hideMobileNav();
                let relSecondaryNav = document.getElementById(
                    primaryLink.dataset.rel
                );
                if (
                    relSecondaryNav &&
                    relSecondaryNav.querySelectorAll('* > [tabindex]')[0]
                )
                    relSecondaryNav
                        .querySelectorAll('* > [tabindex]')[0]
                        .focus();
            }
        }

        function initSecondaryNavigationFunctionality() {
            /*
             * Init secondary navigation mouse leave function
             * Closes secondary navigation after mouse leave at the bottom (400ms)
             */
            secondaryNav.addEventListener('mouseleave', function (mouse) {
                if (closestEdge(mouse, secondaryNav) !== 'top') {
                    mouseLeaveTimeout = setTimeout(function () {
                        hideNavigation();
                    }, 400);
                }
            });

            /*
             * Init secondary navigation mouse enter function
             * Interrupts mouse leave function if reentering navigation (400ms)
             */
            secondaryNav.addEventListener('mouseenter', function () {
                if (mouseLeaveTimeout) {
                    clearTimeout(mouseLeaveTimeout);
                }
            });
        }

        function initSecondaryIconNavigationFunctionality() {
            /*
             * Init secondary navigation mouse leave function
             * Closes secondary navigation after mouse leave at the bottom (400ms)
             */
            secondaryIconNavInner.addEventListener(
                'mouseleave',
                function (mouse) {
                    if (closestEdge(mouse, secondaryIconNavInner) !== 'top') {
                        mouseLeaveTimeout = setTimeout(function () {
                            hideIconNavigation();
                        }, 400);
                    }
                }
            );

            /*
             * Init secondary navigation mouse enter function
             * Interrupts mouse leave function if reentering navigation (400ms)
             */
            secondaryIconNavInner.addEventListener('mouseenter', function () {
                if (mouseLeaveTimeout) {
                    clearTimeout(mouseLeaveTimeout);
                }
            });
        }

        /*
         * Init mobile navigation toggle link click function
         */
        function initMobileNavigationToggleButton() {
            if (!mobileNavToggleButton.eventsRegistered) {
                mobileNavToggleButton.eventsRegistered = true;
                mobileNavToggleButton.addEventListener('click', function () {
                    if (this.classList.contains('mobile-nav-visible')) {
                        hideMobileNav();
                    } else {
                        showMobileNav();
                        hideIconNavigation();
                        hideNavigation();
                    }
                });
            }
        }

        // PRIMARY ICON
        /* ********************************************************************************************************************************* */

        function showIconNavigation(clickedLink) {
            let relatedSecondaryNav = document.getElementById(
                clickedLink.dataset.rel
            );
            document.body.classList.add('icon-nav-visible');

            if (secondaryIconNavHideTimeout) {
                clearTimeout(secondaryIconNavHideTimeout);
            }

            for (let primaryIconLink of primaryIconLinks) {
                primaryIconLink.classList.remove('act');
            }

            for (let secondaryIconInnerNav of secondaryIconInnerNavs) {
                secondaryIconInnerNav.classList.remove('act');
            }

            clickedLink.classList.add('act');
            secondaryIconNav.classList.add('act');
            relatedSecondaryNav.classList.add('act');
        }

        function hideIconNavigation() {
            clearTimeout(mouseLeaveTimeout);
            document.body.classList.remove('icon-nav-visible');

            for (let primaryIconLink of primaryIconLinks) {
                primaryIconLink.classList.remove('act');
            }
            secondaryIconNav.classList.remove('act');
            secondaryIconNavHideTimeout = setTimeout(function () {
                for (let secondaryIconInnerNav of secondaryIconInnerNavs) {
                    secondaryIconInnerNav.classList.remove('act');
                }
            }, 600);
        }

        function toggleIconNavigation(primaryIconLink) {
            if (
                primaryIconLink.classList.contains('act') &&
                !header.classList.contains('editor')
            ) {
                hideIconNavigation();
            } else {
                showIconNavigation(primaryIconLink);
                hideNavigation();
                hideMobileNav();
            }
        }

        function toggleIconNavigationKeyboard(primaryIconLink) {
            if (primaryIconLink.classList.contains('act')) {
                hideIconNavigation();
            } else {
                showIconNavigation(primaryIconLink);
                hideNavigation();
                hideMobileNav();
                let relSecondaryNav = document.getElementById(
                    primaryIconLink.dataset.rel
                );
                if (
                    relSecondaryNav &&
                    relSecondaryNav.querySelectorAll('* > [tabindex]')[0]
                )
                    relSecondaryNav
                        .querySelectorAll('* > [tabindex]')[0]
                        .focus();
            }
        }

        // MOBILE
        /* ********************************************************************************************************************************* */

        /*
         * Init mobile primary navigation link click function
         */
        function initMobilePrimaryNavigationFunctionality() {
            for (let mobilePrimaryLink of mobilePrimaryLinks) {
                mobilePrimaryLink.addEventListener('click', function () {
                    showMobileSecondaryNavigation(mobilePrimaryLink);
                });
            }
        }

        /*
         * Init mobile secondary navigation backlink click function
         */
        function initMobileSecondaryNavigationFunctionality() {
            backLinks = [];
            for (let mobileSecondaryNavBacklink of mobileSecondaryNavBacklinks) {
                mobileSecondaryNavBacklink.addEventListener(
                    'click',
                    function () {
                        hideMobileSecondaryNavigation();
                    }
                );
            }

            for (let mobileSeconadryLink of mobileSecondaryLinks) {
                if (mobileSeconadryLink.dataset.rel) {
                    mobileSeconadryLink.addEventListener('click', function () {
                        showMobileNextNavigation(mobileSeconadryLink);
                    });
                } else {
                    hideMobileNav();
                }
            }
        }

        function hideMobileSecondaryNavigation() {
            //let link = backLinks.pop();
            for (var i = 0; i < mobileSecondaryNavs.length; i++) {
                let found = false;
                for (var j = 0; j < backLinks.length; j++) {
                    let link = backLinks[j];
                    if (mobileSecondaryNavs[i].contains(link)) {
                        found = true;
                    }
                }
                if (!found) {
                    mobileSecondaryNavs[i].classList.remove('act');
                }
            }

            backLinks.pop();

            if (backLinks.length === 0) {
                mobilePrimaryNav.classList.remove('scale');
                return;
            }
        }

        function showMobileSecondaryNavigation(clickedLink) {
            let activePrimaryLinkIndex;
            let clickedPrimaryLinkHasRelatedSubnavigation;

            for (var i = 0; i < mobilePrimaryLinks.length; i++) {
                if (
                    mobilePrimaryLinks[i].dataset.rel ===
                    clickedLink.dataset.rel
                )
                    activePrimaryLinkIndex = i;
            }

            let relatedSecondaryNav = document.getElementById(
                mobilePrimaryLinks[activePrimaryLinkIndex].dataset.rel
            );
            if (relatedSecondaryNav)
                clickedPrimaryLinkHasRelatedSubnavigation = true;

            if (clickedPrimaryLinkHasRelatedSubnavigation) {
                for (let mobilePrimaryLink of mobilePrimaryLinks) {
                    let relatedSecondaryNav = document.getElementById(
                        mobilePrimaryLink.dataset.rel
                    );
                    mobilePrimaryLink.classList.remove('act');
                    mobilePrimaryNav.classList.remove('scale');
                    if (relatedSecondaryNav)
                        relatedSecondaryNav.classList.remove('act');
                }

                relatedSecondaryNav.querySelector(
                    '.mobile-secondary-nav-inner'
                ).scrollTop = 0;
                mobilePrimaryLinks[activePrimaryLinkIndex].classList.add('act');
                mobilePrimaryNav.classList.add('scale');
                relatedSecondaryNav.classList.add('act');
            } else {
                hideMobileSecondaryNavigation();
            }
        }

        function showMobileNextNavigation(clickedLink) {
            let activeLinkIndex;
            let clickedPrimaryLinkHasRelatedSubnavigation;

            for (var i = 0; i < mobileSecondaryLinks.length; i++) {
                if (
                    clickedLink.dataset.rel &&
                    mobileSecondaryLinks[i].dataset.rel ===
                        clickedLink.dataset.rel
                ) {
                    activeLinkIndex = i;
                }
            }

            let relatedSecondaryNav = document.getElementById(
                mobileSecondaryLinks[activeLinkIndex].dataset.rel
            );
            if (relatedSecondaryNav) {
                clickedPrimaryLinkHasRelatedSubnavigation = true;
            }

            if (clickedPrimaryLinkHasRelatedSubnavigation) {
                backLinks.push(clickedLink);
                for (let link of mobileSecondaryLinks) {
                    link.classList.remove('act');
                    mobilePrimaryNav.classList.remove('scale');
                }

                relatedSecondaryNav.querySelector(
                    '.mobile-secondary-nav-inner'
                ).scrollTop = 0;
                mobileSecondaryLinks[activeLinkIndex].classList.add('act');
                mobilePrimaryNav.classList.add('scale');
                relatedSecondaryNav.classList.add('act');
            }
        }

        function showMobileNav() {
            window.addEventListener(
                'touchend',
                checkForMobileNavOutsideClick,
                true
            );
            window.addEventListener(
                'click',
                checkForMobileNavOutsideClick,
                true
            );
            mobilePrimaryNav.scrollTop = 0;
            mobileNavToggleButton.classList.add('mobile-nav-visible');
            document.body.classList.add('mobile-nav-visible');
            mobileNav.classList.add('act');
        }

        function hideMobileNav() {
            window.removeEventListener(
                'touchend',
                checkForMobileNavOutsideClick,
                true
            );
            window.removeEventListener(
                'click',
                checkForMobileNavOutsideClick,
                true
            );
            mobileNavToggleButton.classList.remove('mobile-nav-visible');
            document.body.classList.remove('mobile-nav-visible');
            mobileNav.classList.remove('act');
            setTimeout(hideMobileSecondaryNavigation, 250);
        }

        /* ********************************************************************************************************************************* */

        function exitSecondaryNavViaKey() {
            for (let secondaryInnerNav of secondaryInnerNavs) {
                let childrenWithTabindex =
                    secondaryInnerNav.querySelectorAll('* > [tabindex]');

                childrenWithTabindex[0].addEventListener(
                    'keydown',
                    function (e) {
                        switch (e.keyCode) {
                            case 9: // TAB
                                if (e.shiftKey) {
                                    if (
                                        document.querySelector(
                                            '.primary-nav-item.act'
                                        )
                                    )
                                        document
                                            .querySelector(
                                                '.primary-nav-item.act'
                                            )
                                            .focus();
                                    hideNavigation();
                                    e.preventDefault();
                                }
                                break;
                            default:
                                break;
                        }
                    }
                );

                childrenWithTabindex[
                    childrenWithTabindex.length - 1
                ].addEventListener('keydown', function (e) {
                    switch (e.keyCode) {
                        case 9: // TAB
                            if (!e.shiftKey) {
                                if (
                                    document.querySelector(
                                        '.primary-nav-item.act'
                                    )
                                )
                                    document
                                        .querySelector('.primary-nav-item.act')
                                        .focus();
                                hideNavigation();
                            }
                            break;
                        default:
                            break;
                    }
                });
            }
        }

        /*
         * Check if all columns of a secondary navigation do not have a nav item header
         * If secondary nav has no nav item header, then remove top spacing of all nav-items.
         */
        function checkForNavItemHeader() {
            for (let secondaryInnerNav of secondaryInnerNavs) {
                let columns = secondaryInnerNav.querySelectorAll(
                    '.secondary-nav-column'
                );
                let navItemHeader = false;
                for (let column of columns) {
                    if (column.querySelector('.secondary-nav-item-header')) {
                        navItemHeader = true;
                    }
                }

                if (!navItemHeader)
                    secondaryInnerNav.classList.add('no-item-header');
            }
        }

        function closestEdge(mouse, elem) {
            var elemBounding = elem.getBoundingClientRect();

            var elementLeftEdge = elemBounding.left;
            var elementTopEdge = elemBounding.top;
            var elementRightEdge = elemBounding.right;
            var elementBottomEdge = elemBounding.bottom;

            var mouseX = mouse.pageX;
            var mouseY = mouse.pageY;

            var topEdgeDist = Math.abs(elementTopEdge - mouseY);
            var bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
            var leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
            var rightEdgeDist = Math.abs(elementRightEdge - mouseX);

            var min = Math.min(
                topEdgeDist,
                bottomEdgeDist,
                leftEdgeDist,
                rightEdgeDist
            );

            // eslint-disable-next-line default-case
            switch (min) {
                case leftEdgeDist:
                    return 'left';
                case rightEdgeDist:
                    return 'right';
                case topEdgeDist:
                    return 'top';
                case bottomEdgeDist:
                    return 'bottom';
            }
        }

        function scrollFunction() {
            let top = window.pageYOffset || document.documentElement.scrollTop;
            const detectMobileOrDesktop =
                metaNav.getBoundingClientRect().height === 0
                    ? primaryNavHeight
                    : metaNavHeight;
            if (top >= detectMobileOrDesktop) {
                if (header && !header.classList.contains('fixed')) {
                    header.classList.add('fixed');
                    mobileNav.classList.add('fixed');
                    document.dispatchEvent(
                        new CustomEvent('hideAlert', { detail: 'hide' })
                    );
                }
            } else {
                if (header && header.classList.contains('fixed')) {
                    header.classList.remove('fixed');
                    mobileNav.classList.remove('fixed');
                    document.dispatchEvent(
                        new CustomEvent('hideAlert', { detail: 'show' })
                    );
                }
            }
        }

        function resizeFunction() {
            const vw = Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
            );
            if (vw > 1000 && mobileNav.classList.contains('act')) {
                hideMobileNav();
            }
        }

        function checkForMobileNavOutsideClick(e) {
            if (
                !document
                    .getElementById('eon-mobile-navigation')
                    .contains(e.target) &&
                !document.getElementById('eon-header').contains(e.target)
            ) {
                if (mobileNav.classList.contains('act')) hideMobileNav();
            }
        }
    },
    locationChanged() {
        closeNavigation();
    }
};
export default EonNavigation;
