import { useLocation } from 'react-router-dom';

const QmaticParameters = ({ field }) => {
    const location = useLocation();

    if (location?.search) {
        const params = `${location.search}`;
        field.model.value = params;
    }

    return '';
};

export default QmaticParameters;
