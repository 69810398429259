import React, { useRef, useEffect, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';
import ModalOnAction from "../../../ModalOnAction";

const valueChangedEvent = 'valueTouched';

const RadioButtonListField = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    stepHandler,
    errors,
    onChange,
    rendering
}) => {
    const ref = useRef();
    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [showModalId, setShowModalId] = useState(null);

    const handleValueChange = (e) => {
        e.preventDefault();

        field.model.value = e.target.value;
        field.model.innerText = e.target.innerText;

        const errorMessages = [];
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }
        onChange(field.valueField.name, e.target.value, valid, errorMessages);
        conditionsHandler(field);
        if (field.model.modalOnValueSelection) {
            setShowModalId(e.target.getAttribute("itemid"));
        }
        if (typeof field.model.nextStep !== 'undefined' && field?.model?.navigateOnValueChange) {
            stepHandler(field.model.nextStep);
        }
    };

    useEffect(() => {
        const element = ref.current;

        element.addEventListener(valueChangedEvent, handleValueChange);

        return () => {
            element.removeEventListener(valueChangedEvent, handleValueChange);
        };
    }, [ref.current]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, setData, stateHandler]);

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <p>
                {`${field.model.title} ${
                    field.model.required && field.model.title ? '*' : ''
                    }`}
            </p>
            <eon-ui-radio-button-group
                ref={ref}
                name={field.model.name}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
            >
                {field.model.items.map((item, i) => {
                    return (
                        <>
                            <ModalOnAction displayButton={field.model.modalOnValueSelection} rendering={rendering} uid={item.itemId} buttonTitle={'Modal on radio changed'} show={item.itemId == showModalId} />
                            <eon-ui-radio-button
                                key={`radio-${i}`}
                                {...(item.value === data.value
                                    ? { 'initially-checked': '' }
                                    : {})}
                                value={item.value}
                                label={item.text}
                                itemId={item.itemId}
                            ></eon-ui-radio-button>
                        </>
                    );
                })}
            </eon-ui-radio-button-group>
        </div>
    );
};

export default RadioButtonListField;
