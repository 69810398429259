import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import NavigationHelper from '../NavigationHelper.js';
import NavigationItem from '../NavigationItem/index.js';

const FlyoutMenuSection = ({ fields, sitecoreContext, t }) => {
    const mainlinks = fields.mainLink;
    const links = fields.links;

    if (sitecoreContext.pageEditing && !mainlinks && links) {
        return (
            <eon-ui-text text-style="copy">{t('Editor|NoLinks')}</eon-ui-text>
        );
    }

    return (
        <div className="link-wrapper">
            <div className="link-wrapper-inner">
                {sitecoreContext.pageEditing &&
                    (!mainlinks || mainlinks.length === 0) &&
                    (!links || links.length === 0) && (
                        <eon-ui-text text-style="copy">
                            {t('Editor|NoLinks')}
                        </eon-ui-text>
                    )}

                {mainlinks.map((mainlink) => {
                    return (
                        <React.Fragment key={mainlink.id}>
                            <NavigationItem
                                className={`secondary-nav-item-header ${NavigationHelper.getActiveClass(
                                    sitecoreContext,
                                    mainlink.id
                                )}`}
                                href={mainlink.href}
                                target={mainlink.target}
                                tabIndex="0"
                            >
                                <eon-ui-icon
                                    size="small"
                                    name="chevron_small_right"
                                    scheme="red"
                                />
                                {mainlink.text}
                            </NavigationItem>

                            {links.map((link, j) => {
                                return (
                                    <NavigationItem
                                        className={`secondary-nav-item ${NavigationHelper.getActiveClass(
                                            sitecoreContext,
                                            link.id
                                        )}`}
                                        href={link.href}
                                        target={link.target}
                                        tabIndex="0"
                                        key={`link${j}`}
                                    >
                                        {link.text}
                                    </NavigationItem>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(FlyoutMenuSection));
