import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const Paragraph = ({ fields, params, sitecoreContext, t }) => {
    const content = fields.content.value;
    const scheme = params.scheme;
    if (sitecoreContext.pageEditing && !content)
        return <div>{t('Editor|EmptyDatasource')}</div>;
    else {
        return (
            <div className="paragraph-placeholder">
                <eon-ui-rte-renderer
                    rte-source="aem"
                    content={content}
                    scheme={scheme}
                ></eon-ui-rte-renderer>
            </div>
        );
    }
};

export default withTranslation()(withSitecoreContext()(Paragraph));
