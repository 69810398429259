import React, { useState, useEffect } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import gridStyle from '../../lib/GridControlStyleResolver.js';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const QuestionaryTiles = ({ rendering, sitecoreContext }) => {
    const isMobile = useMobileCheck();
    const [eyecatcherExists, setEyecatcherExists] = useState(false);

    useEffect(() => {
        if (sitecoreContext.pageEditing) {
            return;
        }

        placeholdersKeys
            .filter(
                (key) =>
                    key.includes('column') &&
                    rendering.placeholders[key].length > 0 &&
                    rendering.placeholders[key][0].placeholders[
                        'eon-eyecatcher'
                    ]
            )
            .forEach((key) => {
                if (
                    rendering.placeholders[key][0].placeholders[
                        'eon-eyecatcher'
                    ].length > 0
                ) {
                    setEyecatcherExists(true);
                    return;
                }
            });
    });

    var placeholdersKeys = Object.keys(rendering.placeholders);
    if (!placeholdersKeys) {
        return '';
    }

    var columns = placeholdersKeys.filter((x) => x.includes('column'));

    return (
        <div className="questionary-tiles">
            <div className="questionary-tiles-headline">
                <Placeholder name="eon-headlines" rendering={rendering} />
            </div>

            <eon-ui-grid-control
                grid={gridStyle(columns.length)}
                style={eyecatcherExists ? { paddingTop: 32 } : null}
                same-height={String(!sitecoreContext.pageEditing)}
                remove-outer-spacings={!isMobile}
            >
                {columns.map((p, key) => {
                    return (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder name={p} rendering={rendering} />
                        </eon-ui-xx>
                    );
                })}
            </eon-ui-grid-control>
        </div>
    );
};

export default withSitecoreContext()(QuestionaryTiles);
