import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import getGridStyle from '../../lib/GridControlStyleResolver.js';

const RelatedArticleTeasers = ({ rendering }) => {
    const placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    const columns = placeholders.filter((placeholder) =>
        placeholder.includes('column')
    );
    const gridStyle = columns.length === 3 ? getGridStyle(3) : getGridStyle(2);

    return (
        <div className="related-article-teasers">
            <div className="related-article-teasers-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <eon-ui-grid-control grid={gridStyle} stretch-grid>
                {columns.map((p, key) => (
                    <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                        <Placeholder name={p} rendering={rendering} />
                    </eon-ui-xx>
                ))}
            </eon-ui-grid-control>

            <div className="related-article-teasers-cta">
                <Placeholder name="eon-ctabutton" rendering={rendering} />
            </div>
        </div>
    );
};

export default RelatedArticleTeasers;
