import { useRef, useLayoutEffect } from 'react';

export function usePortalNode(id) {
    const node = useRef(document?.createElement('div'));

    useLayoutEffect(() => {
        if (!document) {
            return;
        }

        const currentNode = node.current;
        if (currentNode) {
            if (id) {
                currentNode.id = `${id}-portal-container`;
            }
            document
                .querySelector('.primary-nav-container-inner')
                .appendChild(currentNode);
        }

        return () => {
            document
                .querySelector('.primary-nav-container-inner')
                .removeChild(currentNode);
        };
    }, [id]);

    return node.current;
}
