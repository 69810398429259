import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

const CardTeaserSlide = (props) => {
    const fields = props.rendering.fields;
    const params = props.rendering.params;
    const cardInfo = fields?.info?.value ?? '';
    const cardTitle = fields?.title?.value ?? '';
    const cardImage = fields?.image?.value?.src ?? '';
    const cardImageAlt = fields?.image?.value?.alt ?? '';
    const cta = fields?.cta?.value?.href ?? '';
    const ctatarget = fields?.cta?.value?.target ?? '';
    const ctaText = fields?.cta?.value?.text ?? '';
    const cardBackgroundColor = params?.background ?? '';

    const Card = () => (
        <eon-ui-card-teaser
            headline-text={cardTitle}
            class="hydrated"
            scheme={cardBackgroundColor}
        >
            <eon-ui-image
                slot="contentImage"
                src={cardImage}
                alt={cardImageAlt}
                use-as-background="true"
                class="hydrated"
            ></eon-ui-image>

            <eon-ui-text slot="content" class="hydrated">
                {cardInfo}
            </eon-ui-text>
            <eon-ui-link text={ctaText} class="hydrated"></eon-ui-link>
        </eon-ui-card-teaser>
    );

    return (
        <div className="card-teaser-wrapper">
            <div className="card-teaser">
                {cta.startsWith('/') && (
                    <Link
                        className="card-teaser-link"
                        to={cta}
                        target={ctatarget}
                    >
                        <Card />
                    </Link>
                )}
                {!cta.startsWith('/') && (
                    <a
                        className="card-teaser-link"
                        href={cta}
                        target={ctatarget}
                    >
                        <Card />
                    </a>
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(CardTeaserSlide);
