import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const IndustryTiles = ({ rendering }) => {
    return (
        <div className="industry-tiles">
            <div>
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div>
                <Placeholder
                    name="eon-industry-tiles-rows"
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(IndustryTiles);
