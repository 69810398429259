import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

const VerticalSlide = ({ fields, props, sitecoreContext }) => {
    const windowSize = useWindowSize();

    const imageUrl = fields?.image?.value?.src ?? '';
    const previewImageAlt = fields?.image?.value?.alt ?? '';

    if (sitecoreContext.pageEditing) {
        return (
            <div className="vertical-slide">
                <eon-ui-grid-control
                    grid="grid__d4-8__t4-8"
                    content-position-col-2="center"
                >
                    <eon-ui-xx slot={'column-1'}>
                        <div
                            className="vertical-slide-text-list"
                            id="vertical-slide-text-list"
                        >
                            <div className="vertical-slide-text">
                                <eon-ui-headline
                                    text={fields?.title?.value ?? ''}
                                    size="h4"
                                />
                            </div>
                        </div>
                    </eon-ui-xx>
                    <eon-ui-xx slot={'column-2'}>
                        <div className="desktop-pc">
                            <div className="desktop-pc-top">
                                <div className="desktop-pc-top-main">
                                    <eon-ui-image
                                        alt={previewImageAlt}
                                        title="Poster title"
                                        slot="image"
                                        src={imageUrl}
                                        object-fit="cover"
                                        height={'356px'}
                                    />
                                </div>
                                <div className="desktop-pc-top-lid" />
                            </div>
                            <div className="desktop-pc-bottom">
                                <div className="desktop-pc-bottom-base">
                                    <div className="desktop-pc-bottom-groove"></div>
                                </div>
                                <div className="desktop-pc-bottom-curve"></div>
                                <div className="desktop-pc-bottom-shadow"></div>
                            </div>
                        </div>
                    </eon-ui-xx>
                </eon-ui-grid-control>
            </div>
        );
    }

    return (
        <div className="vertical-slide">
            <eon-ui-grid-control
                grid="grid__d4-8__t4-8"
                content-position-col-2="center"
            >
                <eon-ui-xx
                    slot={windowSize.width > 900 ? 'column-1' : 'column-2'}
                >
                    {windowSize.width > 900 ? (
                        <div
                            className="vertical-slide-text-list"
                            id="vertical-slide-text-list"
                        >
                            {props.slides.map((subText, index) => (
                                <div
                                    className="vertical-slide-text"
                                    key={'vertical-slide-text-' + index}
                                >
                                    <eon-ui-headline
                                        text={
                                            subText?.fields?.title?.value ?? ''
                                        }
                                        size="h4"
                                    ></eon-ui-headline>
                                </div>
                            ))}
                            <div className="vertical-slide-text-background">
                                <eon-ui-background
                                    full-height
                                    color={props.activeSlideColor}
                                    class="hydrated"
                                />
                            </div>
                            <div className="vertical-slide-text-background-second">
                                <eon-ui-background
                                    full-height
                                    color={props.detailColor}
                                    class="hydrated"
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className="vertical-slide-text-list"
                            id="vertical-slide-text-list"
                        >
                            {props.slides.map((subText, index) => (
                                <div
                                    className="vertical-slide-text"
                                    key={'vertical-slide-' + index}
                                >
                                    <eon-ui-headline
                                        text={
                                            subText?.fields?.title?.value ?? ''
                                        }
                                        size="h4"
                                    ></eon-ui-headline>
                                </div>
                            ))}
                            <div className="vertical-slide-text-background">
                                <eon-ui-background
                                    full-height
                                    color={props.activeSlideColor}
                                    class="hydrated"
                                />
                            </div>
                            <div className="vertical-slide-text-background-second">
                                <eon-ui-background
                                    full-height
                                    color={props.detailColor}
                                    class="hydrated"
                                />
                            </div>
                            <div className="vertical-slide-text-background-gradient-linear" />
                        </div>
                    )}
                    <div className="cta-button">
                        <Placeholder
                            name="eon-ctabutton"
                            rendering={props.rendering}
                        />
                    </div>
                </eon-ui-xx>
                <eon-ui-xx
                    slot={windowSize.width > 900 ? 'column-2' : 'column-1'}
                >
                    <div className="desktop-pc">
                        <div className="desktop-pc-top">
                            <div className="desktop-pc-top-main">
                                <eon-ui-image
                                    alt={previewImageAlt}
                                    title="Poster title"
                                    slot="image"
                                    src={imageUrl}
                                    object-fit="cover"
                                    height={
                                        windowSize.width > 900
                                            ? '356px'
                                            : '155px'
                                    }
                                ></eon-ui-image>
                            </div>
                            <div className="desktop-pc-top-eyecatcher">
                                <Placeholder
                                    name="eon-eyecatcher"
                                    rendering={props.rendering}
                                />
                            </div>
                            <div className="desktop-pc-top-lid" />
                        </div>
                        <div className="desktop-pc-bottom">
                            <div className="desktop-pc-bottom-base">
                                <div className="desktop-pc-bottom-groove"></div>
                            </div>
                            <div className="desktop-pc-bottom-curve"></div>
                            <div className="desktop-pc-bottom-shadow"></div>
                        </div>
                    </div>
                </eon-ui-xx>
            </eon-ui-grid-control>
        </div>
    );
};

export default withSitecoreContext()(VerticalSlide);
