import React from 'react';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../../temp/config';
import { dataFetcher } from '../../dataFetcher';
import DataLayer from '../../DataLayer';

const Notification = ({ fields, params }) => {
    const trackingApiOptions = {
        host: config.sitecoreApiHost,
        querystringParams: {
            sc_apikey: config.sitecoreApiKey
        },
        fetcher: dataFetcher
    };
    
    DataLayer.pushToDataLayer({
        event: 'notyfikacja',
        action: 'view',
        name: fields.ganame.value,
        value: fields.gavalue.value
    });

    const clickEvent = () => {
        trackingApi
            .trackEvent(
                [{ eventId: fields.event.fields.Name.value, url: '/' }],
                trackingApiOptions
            )
            .then(() => {})
            .catch((error) => console.error(error));

            DataLayer.pushToDataLayer({
                event: 'notyfikacja',
                action: 'click',
                name: fields.ganame.value,
                value: fields.gavalue.value
            });

    };

    return (
        <a
            className="notification"
            href={fields?.link?.value?.href ?? ''}
            target={fields?.link?.value?.target ?? ''}
            onClick={clickEvent}
        >
            <eon-ui-background color={`eon-${fields?.scheme?.value || 'lightgrey'}`}>
                <div className="notification-content">
                    <eon-ui-text text-style="copy">
                        {fields?.text?.value}
                    </eon-ui-text>
                    {fields.icon && <eon-ui-icon name={fields.icon.value} />}
                </div>
            </eon-ui-background>
        </a>
    );
};

export default Notification;
