import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const MediaWithDescription = ({ rendering, params, fields }) => {
    const caption =
        fields?.text && fields?.text?.value ? fields?.text?.value : '';
    const isVideo = rendering.placeholders['eon-media-with-description']?.find(
        (x) => {
            return x.componentName?.startsWith('Video');
        }
    );

    let mediaSize = 'xlarge-size';
    if (params.imageSize === 'S') {
        mediaSize = 'small-size';
    } else if (params.imageSize === 'M') {
        mediaSize = 'medium-size';
    } else if (params.imageSize === 'L') {
        mediaSize = 'large-size';
    }

    return (
        <div className={'media-main ' + params.align}>
            <div className={'media-content center ' + mediaSize}>
                <div>
                    <Placeholder
                        name="eon-media-with-description"
                        rendering={rendering}
                    />
                </div>
                {caption && (
                    <div className={'caption'}>
                        <eon-ui-icon
                            name={isVideo ? 'camera_video' : 'camera'}
                            scheme="middlegray"
                            size="normal"
                        ></eon-ui-icon>
                        <div>
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={caption}
                            ></eon-ui-rte-renderer>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediaWithDescription;
