import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ProductDescriptionTile = ({ rendering, sitecoreContext }) => {
    const fields = rendering.fields;
    if (sitecoreContext.pageEditing && !fields)
        return <div>Editor|EmptyDatasource</div>;
    else {
        return (
            <div className="product-description-tile__wrapper">
                {sitecoreContext.pageEditing && <p>Product Description Tile</p>}
                <div className="product-description-tile__inner">
                    <div className="product-description-tile__description">
                        <eon-ui-rte-renderer
                            rte-source="aem"
                            content={fields.description.value}
                        ></eon-ui-rte-renderer>
                    </div>
                </div>
            </div>
        );
    }
};

export default withSitecoreContext()(ProductDescriptionTile);
