const apiUrl = '/mojeon/api/sitecore/DayAheadMarket/GetDailyRates';

export const fetchData = (date) => {
    const formData = new FormData();
    if (date) {
        formData.append('SessionDate', date);
    }

    return fetch(apiUrl, {
        method: 'POST',
        body: formData
    });
};

export const formatDate = (date, isPL = false) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    if (isPL) {
        return `${year}-${month}-${day}`;
    }
    return `${day}.${month}.${year}`;
};

export const formatNumber = (value) => {
    const number = parseFloat(value);

    if (isNaN(number)) {
        return '-';
    }

    const formattedNumber = number.toFixed(2);

    return formattedNumber.replace('.', ',');
};
