import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import getGridStyle from '../../lib/GridControlStyleResolver.js';

const ProductTeaserPresentation = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return null;
    }

    return (
        <div className="product-teaser-presentation">
            <div className="product-teaser-presentation__headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div className="product-teaser-presentation__description">
                <Placeholder
                    name="eon-product-teaser-presentation-paragraph"
                    rendering={rendering}
                />
            </div>
            <eon-ui-grid-control grid={getGridStyle(rendering.params.columns)}>
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder
                                name={p}
                                rendering={rendering}
                                params={params}
                                props={params}
                            />
                        </eon-ui-xx>
                    ))}
            </eon-ui-grid-control>

            <div className="product-teaser-presentation__cta">
                <Placeholder name="eon-ctabutton" rendering={rendering} />
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductTeaserPresentation);
