import React, { useRef, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Notification from '../Notification';
import { useClickOutside } from '../../hooks/useClickOutside';
import { usePortalNode } from '../../hooks/usePortalNode';
import DataLayer from '../../DataLayer';

export const NotificationsContainer = ({
    notifications,
    highPriorityNotifications,
    isExpandDisabled,
    rendering,
    notificationsCount,
    onPanelClose
}) => {
    const portalElement = usePortalNode();
    const containerRef = useRef(null);
    const [isCollapsed, setIsCollapsed ] = useState(true);
    const notificationCountInt =
        parseInt(notificationsCount) !== NaN ? parseInt(notificationsCount) : 5;
    
    const expandCountInt = notifications.length;
    const expandAll = (event) => {
        setIsCollapsed(!isCollapsed);
        event.stopPropagation();
        DataLayer.pushToDataLayer({
            event: 'notyfikacja',
            action: 'click',
            name: 'rozwiń',
        });
    };
    useClickOutside(containerRef, onPanelClose, (target, ref) => target.localName === 'span');

    if (!isExpandDisabled && expandCountInt > 0) {
        DataLayer.pushToDataLayer({
            event: 'notyfikacja',
            action: 'view',
            name: 'rozwiń',
        }); 
    }

    const body = useMemo(
        () => (
            <div className="notifications-container" ref={containerRef}>
                <div className="title">
                    <eon-ui-text text-style="subheadline">
                        Powiadomienia
                    </eon-ui-text>
                    <div className="close-button" onClick={onPanelClose}>
                        <eon-ui-icon name="close" />
                    </div>
                </div>
                {(notifications.length === 0 && highPriorityNotifications.length === 0)  &&
                    <eon-ui-text>
                            Nie masz nowych powiadomień
                    </eon-ui-text> 
                }
                {highPriorityNotifications.length > 0 && highPriorityNotifications
                    .slice(0, notificationCountInt)
                    .map((notification) => (
                        <Notification
                            key={notification.uid}
                            rendering={rendering}
                            params={notification.params}
                            fields={notification.fields}
                        />
                    ))}
                {(!isCollapsed || isExpandDisabled) && notifications
                    .slice(0, notificationCountInt)
                    .map((notification) => (
                        <Notification
                            key={notification.uid}
                            rendering={rendering}
                            params={notification.params}
                            fields={notification.fields}
                        />
                    ))}
                {(!isExpandDisabled && expandCountInt > 0) &&
                <div className={`notifications-container__expand-more ${isCollapsed ? "expand-active" : ""}`} onClick={expandAll}>
                    {isCollapsed && <span >Rozwiń wszystkie ({expandCountInt})</span>}
                    {!isCollapsed && <span>Zwiń</span>}
                    <div class="chevron-container">
                        <svg width="16" height="10" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"><path d="M0 1A1 1 0 011.828.44C4.046 3.712 6.12 6.212 8 7.875 9.88 6.21 11.956 3.71 14.172.439a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001C4.837 7.809 2.62 5.176.17 1.56a.998.998 0 01-.164-.44L0 1z" fill-rule="evenodd"></path></svg>
                    </div>
                </div>
                }
            </div>
        ),
        [notifications, highPriorityNotifications, isExpandDisabled, isCollapsed]
    );

    return ReactDOM.createPortal(body, portalElement);
};
