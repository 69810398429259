import React, { useEffect } from 'react';
import {
    Placeholder,
    VisitorIdentification
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';

// styles
import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.css';
import './assets/scss/_main.scss';
import 'eon-ui-components/dist/eon-ui-components/eon-ui-components.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ScriptComponent from './components/ScriptComponent';
import DataLayer from './DataLayer';

const Metadata = ({ sitecoreContext }) => {
    const title = sitecoreContext.pageTitleMetaData;
    const description = sitecoreContext.descriptionMetaData;
    const robots = sitecoreContext.robotsMetaData;
    const dynamicMetadataFields = sitecoreContext.dynamicMetadataFields;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content={robots} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
            />

            {dynamicMetadataFields &&
                Object.keys(dynamicMetadataFields).map((key, i) => (
                    <meta
                        key={`meta-${i}`}
                        name={key}
                        content={dynamicMetadataFields[key]}
                    />
                ))}
        </Helmet>
    );
};

const OpenGraphMetadata = ({ route, sitecoreContext }) => {
    const title = sitecoreContext.pageTitleMetaData;
    const description = sitecoreContext.descriptionMetaData;
    const image =
        route.fields &&
        route.fields.metaDataImage &&
        route.fields.metaDataImage.value?.src;

    return (
        <Helmet>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
        </Helmet>
    );
};

const CanonicalUrl = ({ route }) => {
    const canonical = route.fields?.canonicalUrl?.value;
    return (
        <Helmet>
            {canonical && <link rel="canonical" href={canonical} />}
        </Helmet>
    );
};

const GoogleDataLayer = ({ sitecoreContext }) => {
    useEffect(() => {
        if (sitecoreContext?.pageEditing) {
            return '';
        }

        var dataLayer = {
            event: 'content_view',
            'content-view': sitecoreContext.itemPath,
            'content-view-name': sitecoreContext.navigationTitle,
            'logged-in': sitecoreContext.userLoggedIn
        };
        DataLayer.pushToDataLayer(dataLayer);

        return () => {};
    }, [sitecoreContext.itemPath]);

    return '';
};

const Layout = ({ route, header, footer, sitecoreContext }) => {
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            window.dispatchEvent(new Event('pagechange'));
        });
    }, [history]);
    return (
        <React.Fragment>
            {route.placeholders['eon-scripts']?.map((script, index) => (
                <ScriptComponent key={index} rendering={script} fields={script.fields} />
            ))}

            {/* <Placeholder name="eon-scripts" rendering={route} /> */}
            <CanonicalUrl route={route} />
            <Metadata sitecoreContext={sitecoreContext} />
            <OpenGraphMetadata
                route={route}
                sitecoreContext={sitecoreContext}
            />
            <GoogleDataLayer sitecoreContext={sitecoreContext} />
            <VisitorIdentification />
            <div
                className={`wrapper ${
                    sitecoreContext.pageEditing ? 'ee-mode' : ''
                }`}
            >
                <div className="header-wrapper">
                    {header && <Header rendering={header} />}
                    {!header && (
                        <Placeholder name="eon-header" rendering={route} />
                    )}
                </div>
                <div className="content-wrapper">
                    <Placeholder name="eon-content" rendering={route} />
                </div>
                <div className="footer-wrapper">
                    {footer && (
                        <Footer
                            rendering={footer}
                            sitecoreContext={sitecoreContext}
                        />
                    )}
                    {!footer && (
                        <Placeholder name="eon-footer" rendering={route} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default withSitecoreContext()(Layout);
