import React from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RouteButton from '../Route/RouteButton';

const ProcessStepsSectionOne = ({ rendering, sitecoreContext, params }) => {
    const pageEditor = sitecoreContext.pageEditing;
    const background = params.background;
    const fields = rendering.fields;
    const stepNumber = fields.stepNumber?.value ?? '';
    const stepName = fields.stepName?.value ?? '';
    const ctaText = fields.cta?.value?.text ?? '';
    const ctaHref = fields.cta?.value?.href ?? '';

    return (
        <div
            className={`process-steps-section-one`}
            style={background && { backgroundColor: `var(--${background})` }}
        >
            {pageEditor && <p>Process Step Section One</p>}

            <div
                className="process-steps-section-one__wrapper"
                inner-background={background}
            >
                <div className="process-steps-section-one__number">
                    <eon-ui-headline
                        text={stepNumber}
                        size="h1"
                        alignment="left"
                    />
                </div>
                <div className="process-steps-section-one__name">
                    <eon-ui-rte-renderer
                        rte-source="quill"
                        content={stepName}
                    />
                </div>
                <div className="process-steps-section-one__cta">
                    <RouteButton
                        text={ctaText}
                        href={ctaHref}
                        target="_self"
                        use-as-link
                        full-width
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ProcessStepsSectionOne));
