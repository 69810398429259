import React from 'react';

const FooterSocialIcons = ({ fields }) => {
    const icon = fields.icon;
    const urlCTA = fields.CTA;
    return (
        <eon-ui-link
            slot="socialmedia"
            target="_blank"
            scheme="darkgrey"
            icon={icon.value}
            href={urlCTA.value.url}
        ></eon-ui-link>
    );
};

export default FooterSocialIcons;
