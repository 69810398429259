import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TextTeaserInColumns = ({ rendering, params, sitecoreContext }) => {
    let columnsArry = rendering.placeholders['eon-text-teaser-columns'].filter(
        (sub) => sub.componentName === 'TextTeaserColumn'
    );
    return (
        <div className="text-teaser-tiles">
            {sitecoreContext.pageEditing && <p>Text Teaser in Columns</p>}
            <div className="text-teaser-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div className="text-teaser-in-columns-grid">
                <Placeholder
                    name={'eon-text-teaser-columns'}
                    rendering={rendering}
                    params={{
                        stepColor: params.stepColor || '',
                        stepFontSize: params.stepFontSize || 'h3',
                        stepIcon: params.stepIcon || 'chevron_bold_right'
                    }}
                    props={{ columnsArry }}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(TextTeaserInColumns);
