import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const RelatedOfferTeaser = ({
    fields,
    rendering,
    sitecoreContext,
    props,
    t
}) => {
    if (!fields) {
        return '';
    }

    if (
        sitecoreContext.pageEditing &&
        (fields.teaserTitle === undefined ||
            fields.teaserDescription === undefined ||
            fields.teaserImage === undefined)
    ) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|IncorrectDatasourceForRelatedOfferTeaser')}
            </eon-ui-text>
        );
    }

    const relatedOfferTitle = fields.teaserTitle?.value;
    const relatedOfferDescription = fields.teaserDescription?.value;
    const image = fields.teaserImage?.value;
    const tags = fields.tags;

    if (sitecoreContext.pageEditing && !image) {
        return (
            <eon-ui-text text-style="copy">TeaserImage is empty</eon-ui-text>
        );
    }

    if (!image) {
        return '';
    }

    return (
        <div className="related-offer-teaser">
            <eon-ui-background
                color={props?.backgroundColor ?? 'eon-white'}
                full-height
            >
                <div className="related-offer-background-content">
                    <div className="image-container">
                        <eon-ui-image
                            slot="image"
                            src={image.src}
                            alt={image.alt}
                            title="Image title"
                            aspect-ratio="ratio-16x9"
                        />
                    </div>
                    <div className="related-offer-content">
                        <div className="tags-container">
                            {tags.map((tag, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="offer-teaser-tag"
                                    >
                                        {tag.fields.value.value}
                                    </div>
                                );
                            })}
                        </div>
                        <eon-ui-headline
                            text={relatedOfferTitle}
                            scheme="darkgrey"
                            size="h3"
                            headline-html-tag="h1"
                        />

                        <div className="teaser-paragraph">
                            <eon-ui-text text-style="copy" color="eon-darkgrey">
                                {relatedOfferDescription}
                            </eon-ui-text>
                        </div>

                        <div className="button-container">
                            <Placeholder
                                name="eon-ctabutton"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(RelatedOfferTeaser));
