import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ColorBarHeader = ({ sitecoreContext }) => (
    <>
        {sitecoreContext.pageEditing && (
            <>
                <p>Color Bar Header Component</p>
            </>
        )}
        <div className="color-bar">
            <div className="turquoise"></div>
            <div className="lime-yellow"></div>
            <div className="red"></div>
        </div>
    </>
);

export default withSitecoreContext()(ColorBarHeader);
