import React, { useState, useEffect } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { FieldEditFrame } from '../../FieldEditFrame/FieldEditFrame.jsx';

const OfferTeaserSecondary = ({ rendering, item, hubTags }) => {
    const placeholders = Object.keys(rendering.placeholders).filter((s) =>
        s.includes(item.id)
    );
    const linkPlaceholder = placeholders[0];
    const data = item;
    const image = data.fields.teaserImage.value;
    const tags = data.fields.tags;
    const teaserTitle = data.fields.teaserTitle.value;
    const fieldNames = [
        'teaserImage',
        'teaserTitle',
        'teaserDescription',
        'showTeaser',
        'tags'
    ];

    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if (hubTags.length > 0) {
            let flag = false;
            tags.forEach((tag) => {
                if (hubTags.indexOf(tag.fields.value.value) > -1) {
                    flag = true;
                }
            });
            setDisplay(flag);
        } else {
            setDisplay(true);
        }
    }, [hubTags, tags]);

    return (
        <div className={`offer-teaser-secondary ${!display ? 'hide' : ''}`}>
            <FieldEditFrame itemId={item.id} fields={fieldNames}>
                <div className="image-container">
                    <eon-ui-image
                        slot="image"
                        src={image.src}
                        alt={image.alt}
                        title="Image title"
                        aspect-ratio="ratio-16x9"
                    />
                </div>
                <div className="offer-teaser-content">
                    <div className="tags-container">
                        {tags.map((tag, index) => {
                            return (
                                <div key={index} className="offer-teaser-tag">
                                    {tag.fields.value.value}
                                </div>
                            );
                        })}
                    </div>
                    <eon-ui-headline
                        text={teaserTitle}
                        scheme="darkgrey"
                        size="h3"
                        headline-html-tag="h1"
                    />
                    <div className="teaser-paragraph">
                        <eon-ui-text text-style="copy" color="eon-darkgrey">
                            {data.fields.teaserDescription.value}
                        </eon-ui-text>
                    </div>
                    <div className="button-container">
                        <Placeholder
                            name={linkPlaceholder}
                            rendering={rendering}
                        />
                    </div>
                </div>
            </FieldEditFrame>
        </div>
    );
};

export default withSitecoreContext()(OfferTeaserSecondary);
