import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const ArticleTeaser = ({ rendering, sitecoreContext }) => {
    const description =
        sitecoreContext?.route?.fields?.teaserDescription?.value;
    const image = sitecoreContext?.route?.fields.teaserImage?.value?.src;
    const imageAltText = sitecoreContext?.route?.fields.teaserImage?.value?.alt;
    const headline =
        sitecoreContext?.route?.fields?.teaserTitle?.value ??
        sitecoreContext?.route?.name;
    const readingTime = sitecoreContext?.route?.fields?.readingTime?.value;
    const publicationDate = rendering?.fields?.publicationDateDescription;
    const showImage = sitecoreContext?.route?.fields?.showImage?.value;

    return (
        <div className="article-teaser">
            <div className="process-tiles-headline">
                <Placeholder
                    name="eon-headline"
                    rendering={rendering}
                    props={{ headline: headline }}
                />
            </div>
            <div className="social-wrapper">
                <div className="article-info">
                    {publicationDate && (
                        <div className="article-info-date">
                            <p>{publicationDate}</p>
                        </div>
                    )}
                    {readingTime && (
                        <div className="article-info-reading-time">
                            <p>Czas czytania: {readingTime}</p>
                        </div>
                    )}
                </div>
                <div className="icons-wrapper">
                    <Placeholder
                        name="eon-social-media-sharing"
                        rendering={rendering}
                    />
                </div>
            </div>
            {showImage && (
                <div className="image-container">
                    <eon-ui-image
                        height="auto"
                        aspect-ratio="ratio-16x9"
                        src={image}
                        alt={imageAltText}
                    />
                </div>
            )}
            <eon-ui-text text-style="abstract">{description}</eon-ui-text>
        </div>
    );
};

export default withSitecoreContext()(ArticleTeaser);
