import React from 'react';
import RouteWrapper from '../RouteWrapper/index.js';

const RouteButton = (params) => {
    return (
        <RouteWrapper attributes={params} tag="eon-ui-button"></RouteWrapper>
    );
};

export default RouteButton;
