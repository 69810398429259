import React, { useEffect, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../../../temp/config';

const InfoObligation = ({
    field,
    fieldState,
    stateHandler,
    sitecoreContext
}) => {
    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, []);

    const [data, setData] = useState(fieldState);

    const infoObligationQueryParams = {
        version: field.model.version,
        channel: field.model.channel,
        sc_apikey: config.sitecoreApiKey,
        lang: sitecoreContext.language
    };

    const query = Object.keys(infoObligationQueryParams)
        .map((key) => `${key}=${infoObligationQueryParams[key]}`)
        .join('&');

    const url = `${config.sitecoreApiHost}/api/eon/infoobligation/file?${query}`;

    field.model.value = data.value;

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <div className="infoobligation">
                <eon-ui-rte-renderer
                    rte-source="aem"
                    content={field?.model?.shortTextForm?.replace(
                        field.model.replaceText,
                        `<eon-ui-link target="_blank" hide-icon="true" href=${url} text=${field.model.replaceText}>`
                    )}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(InfoObligation);
