import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const OffsetTeaserTwo = ({ rendering, fields, params }) => {
    const offsetImage = fields?.image?.value?.src;
    const imageAltText = fields?.image?.value?.alt;

    const imageHighlightColor = params.imageHighlightColor;
    const highlightColor = params.highlightColor;
    const backgroundColor = params.backgroundColor;

    const ImageLeftSide = () => {
        return (
            <div className="offset-teaser-two">
                <div className="offset-teaser-two-content-wrapper">
                    <div className="offset-teaser-two-image">
                        <eon-ui-background color={imageHighlightColor}>
                            <div
                                className="hightlight-bar"
                                style={
                                    imageHighlightColor
                                        ? {
                                              backgroundColor: `var(--${imageHighlightColor})`
                                          }
                                        : null
                                }
                            />
                        </eon-ui-background>
                        {fields?.image?.value?.svg !== undefined && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: fields?.image?.value?.svg
                                }}
                            />
                        )}
                        {fields?.image?.value?.svg === undefined && (
                            <img
                                src={offsetImage}
                                alt={imageAltText}
                                title="Image title"
                                width="100%"
                            />
                        )}
                    </div>

                    <div className="offset-teaser-two-content">
                        <eon-ui-background color={backgroundColor}>
                            <div className="offset-teaser-two-text-wrapper">
                                {fields.title.value && (
                                    <div className="offset-teaser-two-title">
                                        <eon-ui-headline
                                            text={fields.title.value}
                                            size="h3"
                                        />
                                    </div>
                                )}

                                <div className="offset-teaser-two-content-placeholder">
                                    <Placeholder
                                        name="eon-offset-teaser-content"
                                        rendering={rendering}
                                    />
                                </div>
                                <div className="offset-teaser-links-wrapper">
                                    <Placeholder
                                        name="eon-links"
                                        rendering={rendering}
                                    />
                                </div>

                                <div
                                    className="hightlight-bar"
                                    style={
                                        backgroundColor
                                            ? {
                                                  backgroundColor: `var(--${highlightColor})`
                                              }
                                            : null
                                    }
                                />
                            </div>
                        </eon-ui-background>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="offset-teaser-two">
            <div className="offset-teaser-two-wrapper">{<ImageLeftSide />}</div>
        </div>
    );
};

export default OffsetTeaserTwo;
