import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const OfferBenefits = ({ fields, params, sitecoreContext, t }) => {
    const iconScheme = params.iconScheme ? params.iconScheme : 'red';
    const fontWeight = params?.fontWeight || '400';

    if (!fields?.icon?.value && !sitecoreContext.pageEditing) {
        return null;
    }

    if (
        sitecoreContext.pageEditing &&
        fields.title?.value == '' &&
        fields.description?.value == ''
    ) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    }

    return (
        <div className="offer-benefit">
            <eon-ui-bullet-list-item role="listitem" scheme="darkgrey">
                <eon-ui-icon scheme={iconScheme} name={fields.icon.value} />
                <div className="teaser-feature-content">
                    {fields.title?.value && (
                        <eon-ui-text
                            text-style="abstract"
                            font-weight={fontWeight}
                            dangerouslySetInnerHTML={{
                                __html: fields.title?.value
                            }}
                        />
                    )}
                    {fields.description.value && (
                        <eon-ui-text text-style="copy-small">
                            {fields.description.value}
                        </eon-ui-text>
                    )}
                </div>
            </eon-ui-bullet-list-item>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(OfferBenefits));
