import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import ValueNegative from '../../assets/icons/value_negative.svg';

const SingleProductCell = ({ rendering, params }) => {
    const placeholders = Object.keys(rendering.placeholders);
    const isIncluded = params?.state === 'Included';
    const isExcluded = params?.state === 'Excluded';
    const isOptional = params?.state === 'Optional';
    const isAbsent = !params?.state || params?.state === 'Absent';
    const borderColor = `var(--${params.borderColor})`;

    const isMobile = useMobileCheck();
    const desktopHeight = params.desktopHeight;
    const mobileHeight = params.mobileHeight ?? 66;

    if (!placeholders) {
        return '';
    }

    return (
        <div
            className="single-product-column"
            style={{
                borderColor: borderColor,
                height: isMobile
                    ? parseFloat(mobileHeight)
                    : parseFloat(desktopHeight)
            }}
        >
            {!isAbsent && (
                <div className="single-product-column__state">
                    {isIncluded && (
                        <eon-ui-icon
                            size="normal"
                            scheme="turquoise"
                            name="value_proposition"
                        />
                    )}
                    {isExcluded && <img src={ValueNegative} alt="React Logo" />}
                    {isOptional && (
                        <eon-ui-text color="eon-middlegrey">Opcja</eon-ui-text>
                    )}
                </div>
            )}
            <div
                className={`single-product-column__description ${
                    isExcluded ? 'excluded' : ''
                }`}
            >
                <eon-ui-rte-renderer
                    rte-source="aem"
                    content={rendering.fields?.description?.value}
                />
            </div>
            <div className="single-product-column__tooltip">
                <Placeholder
                    name="eon-single-product-tooltip"
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(SingleProductCell);
