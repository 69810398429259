import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import RouteLink from '../Route/RouteLink/index.js';

const ProcessTile = ({ params, rendering, sitecoreContext, t }) => {
    const scheme = params.scheme || '';
    const color = 'eon-' + scheme;
    const fields = rendering.fields;
    if (
        sitecoreContext.pageEditing &&
        !fields.icon.value &&
        !fields.iconCopy.value &&
        !fields.description.value &&
        !fields.cta.value.href
    ) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    }
    return (
        <div className="process-tile-wrapper">
            <div className="process-tile">
                <div className="icon-container">
                    <div className="icon-image">
                        <eon-ui-icon
                            name={fields.icon.value}
                            scheme={scheme}
                            size="custom"
                        />
                    </div>
                    <div className="copy-text">
                        <eon-ui-text
                            text-style="rtf"
                            font-weight="bold"
                            color={color}
                        >
                            {fields.iconCopy.value}
                        </eon-ui-text>
                    </div>
                </div>
                <div className="tile-contents">
                    <div className="tile-title">
                        <eon-ui-headline
                            text={fields.iconTitle.value}
                            size="h4"
                            scheme="darkgrey"
                        />
                    </div>
                    <div className="tile-text">
                        <eon-ui-text text-style="copy" color="darkgrey">
                            {fields.description.value}
                        </eon-ui-text>
                    </div>
                    <div className="tile-link">
                        {fields.cta.value.href && (
                            <RouteLink
                                href={fields.cta.value.href}
                                text={fields.cta.value.text}
                                target={fields.cta.value.target}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ProcessTile));
