import React, { useState, useEffect } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import PromoArticle from './PromoArticle/index';
import Article from './Article/index';
import config from '../../temp/config';
import { withTranslation } from 'react-i18next';

const PRIMARY_MODULO = 5;
const SECONDARY_MODULO = 3;

const ArticleList = ({ rendering, sitecoreContext, t }) => {
    const [allPromoArticles, setAllPromoArticles] = useState(
        rendering.fields?.articles.promoArticles
    );
    const [allNormalArticles, setAllNormalArticles] = useState(
        rendering.fields?.articles.articles
    );
    const [filteredPromoArticles, setFilteredPromoArticles] = useState(
        rendering.fields?.articles.promoArticles
    );
    const [filteredNormalArticles, setFilteredNormalArticles] = useState(
        rendering.fields?.articles.articles
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isEvenLoad, setEvenLoad] = useState(false);
    const [allTags, setAllTags] = useState([]);
    const [tags, setTags] = useState([]);

    const tagsHandler = (tag) => {
        if (tags.indexOf(tag) < 0) {
            return setTags([...tags, tag]);
        }

        setTags(tags.filter((t) => t !== tag));
    };

    useEffect(() => {
        setAllPromoArticles(rendering.fields?.articles.promoArticles);
    }, [rendering.fields?.articles.promoArticles]);

    useEffect(() => {
        setAllNormalArticles(rendering.fields?.articles.articles);
    }, [rendering.fields?.articles.articles]);

    const handleLoadMore = async () => {
        setIsLoading(true);

        const data = await fetch(
            `${config.sitecoreApiHost}/api/eon/articles/teasers?itemId=${
                sitecoreContext.itemId
            }&language=${sitecoreContext.language}&promotedArticles=${
                rendering.params.promotedArticles ?? ''
            }&isEvenLoad=${isEvenLoad}&sc_apikey=${
                config.sitecoreApiKey
            }&skipArticles=${allNormalArticles.length || 0}&skipPromo=${
                allPromoArticles.length || 0
            }`
        ).then((response) => response.json());

        setIsLoading(false);
        setEvenLoad(!isEvenLoad);
        setAllPromoArticles([...allPromoArticles, ...data.promoArticles]);
        setAllNormalArticles([...allNormalArticles, ...data.articles]);
    };

    useEffect(() => {
        if (tags.length) {
            setFilteredPromoArticles(
                allPromoArticles.filter((article) =>
                    article.tags?.some((tag) => tags.includes(tag))
                )
            );
            setFilteredNormalArticles(
                allNormalArticles.filter((article) =>
                    article.tags?.some((tag) => tags.includes(tag))
                )
            );
        } else {
            setFilteredPromoArticles(allPromoArticles);
            setFilteredNormalArticles(allNormalArticles);
        }
    }, [tags.length, allNormalArticles.length, allPromoArticles.length]);

    useEffect(() => {
        const hubTags = [];

        allNormalArticles.forEach((article) => {
            if (article.showTags && article.tags) {
                article.tags.forEach((tag) => {
                    if (hubTags.indexOf(tag) < 0) {
                        hubTags.push(tag);
                    }
                });
            }
        });
        allPromoArticles.forEach((article) => {
            if (article.showTags && article.tags) {
                article.tags.forEach((tag) => {
                    if (hubTags.indexOf(tag) < 0) {
                        hubTags.push(tag);
                    }
                });
            }
        });

        setAllTags(hubTags);
    }, [isLoading]);

    if (sitecoreContext.pageEditing) {
        return (
            <div className="article-list">
                <p>ArticleList Component</p>
                <Placeholder name="eon-eyecatcher" rendering={rendering} />
            </div>
        );
    }

    const articlesCount = rendering.fields?.articles.count ?? 0;
    const loadedArticlesCount =
        allNormalArticles.length + allPromoArticles.length;
    const filteredArticlesCount =
        filteredNormalArticles.length + filteredPromoArticles.length;
    const filterText = t('Content|ArticleList|FilterBy') ?? '';

    let modulo = PRIMARY_MODULO;
    let promoIndex = 0;
    let counter = 1;

    return (
        <div className="article-list">
            <div className="article-list-wrapper">
                <div className="offer-tag-pill-group">
                    {allTags.length > 0 && (
                        <span className="filter-with-text">{filterText}</span>
                    )}
                    <eon-ui-tag-pill-group scheme="eon-red">
                        {allTags.map((item, i) => (
                            <eon-ui-tag-pill
                                key={i}
                                filter-name={`link${i}`}
                                text={item}
                                onClick={() => tagsHandler(item)}
                                is-selected={tags.indexOf(item) > -1}
                            ></eon-ui-tag-pill>
                        ))}
                    </eon-ui-tag-pill-group>
                </div>
                <div className="article-list-content">
                    {filteredPromoArticles[promoIndex] && (
                        <PromoArticle
                            promoIndex={1}
                            rendering={rendering}
                            article={filteredPromoArticles[promoIndex++]}
                        />
                    )}
                    {filteredNormalArticles.map((art, index) => {
                        const showNextPromo =
                            counter % modulo === 0 &&
                            filteredPromoArticles[promoIndex];

                        if (showNextPromo) {
                            if (modulo === PRIMARY_MODULO) {
                                modulo = SECONDARY_MODULO;
                            } else if (modulo === SECONDARY_MODULO) {
                                modulo = PRIMARY_MODULO;
                            }
                            counter = 0;
                        }
                        counter++;

                        return (
                            <>
                                <Article
                                    key={`article-${index}`}
                                    article={art}
                                />
                                {showNextPromo && (
                                    <PromoArticle
                                        promoIndex={promoIndex + 1}
                                        rendering={rendering}
                                        article={
                                            filteredPromoArticles[promoIndex++]
                                        }
                                    />
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
            <p className="article-amount-text">{`Widzisz ${filteredArticlesCount} z ${articlesCount} artykułów`}</p>
            {loadedArticlesCount < articlesCount && (
                <div className="button-wrapper">
                    <eon-ui-button
                        text="Pokaż więcej"
                        rank="secondary"
                        disabled={isLoading}
                        toggle-full-width-on-mobile
                        onClick={handleLoadMore}
                    />
                </div>
            )}
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(ArticleList));
