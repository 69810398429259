import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const HeaderEditMode = ({ rendering }) => {
    return (
        <div>
            <div className="alert-wrapper edit-mode">
                <Placeholder name="eon-section-alert" rendering={rendering} />
            </div>
            <div className="eon-header edit-mode">
                <div className="meta-nav-container ">
                    <div className="meta-nav-container-inner">
                        <div className="meta-nav"></div>
                        <div className="icon-container hide-on-big-screen">
                            <div className="toolbar-container">
                                <div className="toolbar-sticky">
                                    <Placeholder
                                        name="eon-header-toolbar-sticky"
                                        rendering={rendering}
                                        isSmall={true}
                                    />
                                </div>
                                <div className="toolbar-static">
                                    <Placeholder
                                        name="eon-header-toolbar-static"
                                        rendering={rendering}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="company-logo">
                            <a
                                href={rendering.fields.logoLink.value.href}
                                target="_self"
                                itempropName=""
                            >
                                <img
                                    src={rendering.fields.logo.value.src}
                                    alt="eon logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Placeholder
                    name="eon-header-navigation"
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default HeaderEditMode;
