import React from 'react';
import { Link } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Breadcrumbs = ({ fields, params }) => {
    const breadcrumbs = fields?.breadcrumbs ?? [];

    return (
        <div className="breadcrumbs">
            <eon-ui-breadcrumb
                scheme={params?.scheme}
                selected={breadcrumbs[breadcrumbs.length - 1].name}
            >
                {breadcrumbs.map((breadcrumb, key) =>
                    breadcrumb.href ? (
                        <Link key={key} to={breadcrumb.href}>
                            {breadcrumb.name}
                        </Link>
                    ) : (
                        <span key={key}>{breadcrumb.name}</span>
                    )
                )}
            </eon-ui-breadcrumb>
        </div>
    );
};

export default withSitecoreContext()(Breadcrumbs);
