import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ContactIllustrationsTiles = ({ rendering }) => {
    let placeholdersKeys = Object.keys(rendering.placeholders);
    if (!placeholdersKeys) {
        return '';
    }

    return (
        <div className="contact-illustration-tiles">
            <div className="contact-illustration-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <div className="contact-illustration-tiles-container">
                <Placeholder
                    name="eon-contact-illustrations-tiles-rows"
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default ContactIllustrationsTiles;
