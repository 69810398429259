import React from 'react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const Eyecatcher = ({ fields, params }) => {
    const isMobile = useMobileCheck();

    const desktopSize = params.desktopSize ?? 'medium';
    const mobileSize = params.mobileSize ?? 'medium';
    const scheme = params.scheme ?? '';
    const textColor = params.textColor ?? '';
    const textAlign = params.textAlign ?? 'center';
    const textPosition = params.textPosition ?? 'center';

    return (
        <div>
            <eon-ui-eyecatcher
                divider="false"
                align={textAlign}
                scheme={scheme}
                textcolor={textColor}
                icon={fields.icon?.value?.src}
                size={isMobile ? mobileSize : desktopSize}
                headline={fields?.headline?.value}
                textposition={textPosition}
            />
        </div>
    );
};

export default Eyecatcher;
