import React from 'react';
import Slider from 'react-slick';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import TileWithIcon from '../TileWithIcon';

const TilesWithIcon = ({ rendering, sitecoreContext }) => {
    const isMobile = useMobileCheck();

    const placeholdersKeys = Object.keys(rendering.placeholders);

    if (!placeholdersKeys) {
        return '';
    }

    const rows = rendering.placeholders['eon-tiles-with-icon-row'];

    const settings = {
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        dots: true,
        infinite: false
    };

    return (
        <div className="tiles-with-icon">
            {sitecoreContext.pageEditing && <p>Tiles with Icon component</p>}
            <div className="tiles-with-icon-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            {isMobile && rows?.length && (
                <Slider {...settings}>
                    {rows
                        .flatMap((row) =>
                            Object.values(row?.placeholders)?.flatMap(
                                (column) => column
                            )
                        )
                        .map((tile, key) => (
                            <div key={`content-agregator-slide-${key}`}>
                                <TileWithIcon
                                    {...tile}
                                    props={
                                        rendering.placeholders[
                                            'eon-tiles-with-icon-row'
                                        ]?.[0].params
                                    }
                                />
                            </div>
                        ))}
                    <div className="ghost-card" />
                </Slider>
            )}
            {!isMobile && (
                <div className="tiles-with-icon-body">
                    <Placeholder
                        name="eon-tiles-with-icon-row"
                        rendering={rendering}
                    />
                </div>
            )}

            <div className="tiles-with-icon-footnote">
                <Placeholder name="eon-footnote" rendering={rendering} />
            </div>
        </div>
    );
};

export default withSitecoreContext()(TilesWithIcon);
