import React, { useState, useEffect, useRef } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { debounce } from '../../utils/debounce';

const ProductDetailsTabs = ({ rendering, sitecoreContext, params }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const tabRef = useRef();
    const [isHidden, setIsHidden] = useState(false);
    const scheme = params.scheme;

    const tabRendering = {
        ...rendering,
        placeholders: {
            'eon-product-details-tabs': [
                rendering.placeholders['eon-product-details-tabs'][tabIndex]
            ]
        }
    };
    const tabs = rendering.placeholders['eon-product-details-tabs'];

    useEffect(() => {
        if (sitecoreContext.pageEditing) {
            return;
        }

        const element = tabRef?.current;

        const ChangeTab = debounce((event) => {
            setIsHidden(true);
            setTabIndex(event.detail);
            setTimeout(() => setIsHidden(false), 1000);
        }, 200)

        if (element) {
            element.addEventListener('tabChanged', ChangeTab);

            return () => {
                element.removeEventListener('tabChanged', ChangeTab);
            }
        }
    }, [tabRef]); //eslint-disable-line react-hooks/exhaustive-deps

    if (sitecoreContext.pageEditing) {
        return (
            <div className="product-details-tabs">
                <div className="product-details-headline">
                    <div className="headline-wrapper">
                        <Placeholder
                            name="eon-headline"
                            rendering={rendering}
                        />
                    </div>
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
                <Placeholder
                    name="eon-product-details-tabs"
                    rendering={rendering}
                />
            </div>
        );
    }

    return (
        <div className="product-details-tabs">
            <div className="product-details-headline">
                <div className="headline-wrapper">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <Placeholder name="eon-ctabutton" rendering={rendering} />
            </div>
            <eon-ui-tabs
                ref={tabRef}
                active-tab-index={tabIndex}
                scheme={scheme}
            >
                {tabs.map((tab, index) => {
                    return (
                        <eon-ui-tab
                            key={`tab-${index}`}
                            header={tab.fields.title.value}
                        ></eon-ui-tab>
                    );
                })}
            </eon-ui-tabs>
            <div className={`tab${isHidden ? ' hidden' : ''}`}>
                <Placeholder
                    name="eon-product-details-tabs"
                    rendering={tabRendering}
                    tabIndex={tabIndex}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductDetailsTabs);
