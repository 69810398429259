import { useLayoutEffect, useState } from 'react';

export function useWindowSize() {
    const isClient = typeof window === 'object';
    const navigator = isClient ? window?.navigator : null;

    const [windowSize, setWindowSize] = useState({
        width: 1200,
        height: 1200
    });

    useLayoutEffect(() => {
        if (!navigator) return;

        const handleWindowResize = () =>
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [navigator]);

    return windowSize;
}
