import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import getGridStyle from '../../lib/GridControlStyleResolver.js';

const ImagesInRow = ({ sitecoreContext, rendering, params }) => {
    let placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    const gridStyle = getGridStyle(rendering.params.columns);

    return (
        <>
            {sitecoreContext.pageEditing && <p>Images in Row</p>}

            <div className="images-in-row">
                <div className="images-in-row-headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div>
                    <eon-ui-grid-control grid={gridStyle} stretch-grid>
                        {placeholders
                            .filter((x) => x.includes('column'))
                            .map((p, key) => (
                                <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                    <Placeholder
                                        name={p}
                                        rendering={rendering}
                                        params={params}
                                        columns={params?.columns}
                                    />
                                </eon-ui-xx>
                            ))}
                    </eon-ui-grid-control>
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(ImagesInRow);
