export const getTableStyle = (columns) => {
    switch (columns) {
        case 2:
            return 'grid__d6-6__t6-6';
        case 3:
            return 'grid__d4-4-4__t4-4-4';
        case 4:
            return 'grid__d3-3-3-3__t6-6-6-6';
        default:
            return '';
    }
};

export const splitArrayIntoChunks = (array, chunkSize) => {
    const result = [];

    for (var i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
};
