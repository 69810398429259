import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const Image = ({ fields, params, sitecoreContext, t, props }) => {
    const isMobile = useMobileCheck();
    const imageUrl = fields?.image?.value?.src ?? '';
    const imageAlt = fields?.image?.value?.alt ?? '';
    const hideOnMobileClass = params.hideOnMobile ? ' hide-on-mobile' : '';
    const ratio = params?.aspectRatio || props?.ratio || '';
    const mobileRatio = params?.mobileAspectRatio || props?.ratio || '';

    if (imageUrl)
        return (
            <div className={`image${hideOnMobileClass}`}>
                <eon-ui-image
                    alt={imageAlt}
                    title="Poster title"
                    slot="image"
                    src={imageUrl}
                    aspect-ratio={isMobile ? mobileRatio : ratio}
                />
            </div>
        );
    else if (sitecoreContext.pageEditing) {
        return (
            <eon-ui-text text-style="copy">
                {t('Editor|EmptyDatasource')}
            </eon-ui-text>
        );
    } else {
        return '';
    }
};

export default withTranslation()(withSitecoreContext()(Image));
