import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ShortLinks = ({ sitecoreContext }) => {
    if (sitecoreContext.pageEditing) {
        return (
            <eon-ui-text text-style="copy">Short Links Component</eon-ui-text>
        );
    }

    return '';
};

export default withSitecoreContext()(ShortLinks);
