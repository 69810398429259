import { GetAllFields, MapValidationErrorsAsJson } from './FormAnalytics.js';

let dataLayerObject = {
    event: 'formSubmit'
};

const availablePages = {
    '/formularz-upust': {
        '^Category_.*$': 'formSubject',
        '^Topics_.*': 'clientType'
    },
    '/formularz-kontaktowy': {
        '^Category_.*$': 'formSubject',
        '^Topics_.*': 'subjectType'
    }
};

function AnalyticsForFields(sitecoreContext, fields, validationErrors) {
    const validations = MapValidationErrorsAsJson(validationErrors);
    fields = GetAllFields(fields, FieldModelValidator);

    if (validations.length > 0) return;
    var currentRoute = sitecoreContext.itemPath;

    if (!(currentRoute in availablePages)) return;

    const mapName = availablePages[currentRoute];
    for (const model of fields.map((f) => f.model)) {
        const name = model.name;
        const value = model.innerText;
        console.log(model);

        if (value !== undefined && value !== '') {
            const newName = MapFieldName(name, mapName);
            dataLayerObject[newName] = value;
        }
        if (model.files !== undefined) {
            dataLayerObject['fileAttached'] = 'Tak';
        }
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push(dataLayerObject);
    }
}

function FieldModelValidator(model) {
    return (
        model &&
        model.required !== undefined &&
        model.value !== undefined &&
        (model.skipInGtm === undefined || !model.skipInGtm)
    );
}

function MapFieldName(fieldName, rules) {
    for (const regex in rules) {
        const pattern = new RegExp(regex);
        if (pattern.test(fieldName)) {
            return rules[regex];
        }
    }
    return fieldName;
}

export default AnalyticsForFields;
