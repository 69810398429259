import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const TextTeaserColumn = ({
    rendering,
    fields,
    params,
    props,
    sitecoreContext
}) => {
    const isMobile = useMobileCheck();

    var stepFontSize = params.stepFontSize;
    var stepColor = params.stepColor;
    var stepIcon = params.stepIcon;
    let columnsArry = props.columnsArry;

    function checkLast() {
        if (columnsArry[columnsArry.length - 1].uid === rendering.uid)
            return true;
        if (columnsArry.length < 5) return false;
        for (let i = 4; i < columnsArry.length - 1; i += 5) {
            if (columnsArry[i].uid === rendering.uid) return true;
        }
        return false;
    }
    return (
        <div
            className={
                columnsArry.length > 4
                    ? 'text-teaser-column-big'
                    : 'text-teaser-column'
            }
        >
            {sitecoreContext.pageEditing && <p>Text Teaser Column</p>}
            <div className="text-teaser-column-wrapper">
                <div className="text-teaser-column-step">
                    <div className="text-teaser-column-step-description">
                        <eon-ui-headline
                            text={fields.stepDescription?.value ?? ''}
                            size={stepFontSize}
                            scheme={stepColor}
                        />
                    </div>
                    {!isMobile && !checkLast() && (
                        <div className="text-teaser-column-step-icon">
                            <eon-ui-icon name={stepIcon}></eon-ui-icon>
                        </div>
                    )}
                </div>

                <div className="text-teaser-column-title">
                    <eon-ui-headline
                        text={fields.title?.value ?? ''}
                        size="h4"
                    />
                </div>
                <div className="text-teaser-column-description">
                    <eon-ui-text text-style="copy">
                        {fields.description?.value ?? ''}
                    </eon-ui-text>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(TextTeaserColumn);
