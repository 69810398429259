import React, { useState, useEffect } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { FieldEditFrame } from '../../FieldEditFrame/FieldEditFrame.jsx';
import { useMobileCheck } from '../../../hooks/useMobileCheck';

const OfferTeaserMain = ({ rendering, item, hubTags }) => {
    const isMobile = useMobileCheck();

    const placeholders = Object.keys(rendering.placeholders).filter((s) =>
        s.includes(item.id)
    );
    const linkPlaceholder = placeholders[0];
    const data = item;
    const image = data.fields.teaserImage.value;
    const tags = data.fields.tags;
    const teaserTitle = data.fields.teaserTitle.value;
    const shadowOnImage = !!data.fields.shadowOnImage.value;

    const fieldNames = [
        'teaserImage',
        'teaserTitle',
        'teaserDescription',
        'showTeaser',
        'tags',
        'shadowOnImage'
    ];

    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if (hubTags.length > 0) {
            let flag = false;
            tags.forEach((tag) => {
                if (hubTags.indexOf(tag.fields.value.value) > -1) {
                    flag = true;
                }
            });
            setDisplay(flag);
        } else {
            setDisplay(true);
        }
    }, [hubTags, tags]);

    return (
        <div className={`offer-teaser-main ${!display ? 'hide' : ''}`}>
            <FieldEditFrame itemId={item.id} fields={fieldNames}>
                <eon-ui-offset-teaser
                    scheme="ultralightgrey"
                    variant="variant_two"
                    inner-background="eon-ultralightgrey"
                    highlight="true"
                    reduced-padding="true"
                    image-aspect-ratio={isMobile ? 'ratio-2x1' : 'ratio-1x2'}
                >
                    <div slot="image">
                        <eon-ui-image
                            src={image.src}
                            alt={image.alt}
                            title="Image title"
                            shadow-on-image={shadowOnImage}
                        />
                        <div className="feature-badge">
                            <Placeholder
                                name="eon-eyecatcher"
                                rendering={rendering}
                            />
                        </div>
                    </div>

                    <div slot="content">
                        <div className="tags-container">
                            {tags.map((tag, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="offer-teaser-tag"
                                    >
                                        {tag.fields.value.value}
                                    </div>
                                );
                            })}
                        </div>

                        <eon-ui-headline
                            text={teaserTitle}
                            scheme="darkgrey"
                            size="h3"
                            headline-html-tag="h1"
                        />
                        <eon-ui-text text-style="copy" color="eon-darkgrey">
                            {data.fields.teaserDescription.value}
                        </eon-ui-text>
                        <div className="button-container">
                            <Placeholder
                                name={linkPlaceholder}
                                rendering={rendering}
                            />
                        </div>
                        <div className="teaser-list">
                            <Placeholder
                                name="eon-offer-benefits"
                                rendering={rendering}
                                params={{ fontWeight: '900' }}
                            />
                        </div>
                    </div>
                </eon-ui-offset-teaser>
            </FieldEditFrame>
        </div>
    );
};

export default withSitecoreContext()(OfferTeaserMain);
