import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ProductDetailsWithGridRow = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }
    var pos = params.pos;
    return (
        <div className="product-details-with-grid-row">
            {placeholders.map((row, index) => (
                <div
                    className="product-details-with-grid-column"
                    key={'productdetailtile - ' + index}
                >
                    <Placeholder
                        name={row}
                        rendering={rendering}
                        params={params}
                        props={{
                            index: index,
                            border:
                                rendering.params.columns > index + 1
                                    ? rendering.placeholders[row].filter(
                                          (x) =>
                                              x.componentName ===
                                              'ProductDetailsTile'
                                      ).length !== 0 &&
                                      rendering.placeholders[
                                          'eon-product-details-columns-' +
                                              (index + 2)
                                      ].filter(
                                          (x) =>
                                              x.componentName ===
                                              'ProductDetailsTile'
                                      ).length !== 0
                                        ? true
                                        : false
                                    : false,
                            borderMobile:
                                (index === 0 && pos === 'first') ||
                                (index === placeholders.length - 1 &&
                                    pos === 'last')
                                    ? false
                                    : true
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default ProductDetailsWithGridRow;
