import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const VideoFile = ({ fields, sitecoreContext }) => {
    const fileUrl = fields?.file?.value?.src ?? '';
    const imageUrl = fields?.previewImage?.value?.src ?? '';
    const previewImageAlt = fields?.previewImage?.value?.alt ?? '';
    const videoUrl = fields?.video?.videoUrl;
    const videoTitle = fields?.video?.videoTitle;

    return (
        <div>
            {sitecoreContext.pageEditing && <p>Video File </p>}
            <eon-ui-videoplayer
                poster-image="true"
                scheme="white"
                src={fileUrl}
                type="file"
                class="hydrated"
                video-title={videoTitle}
                video-url={videoUrl}
            >
                <eon-ui-image
                    alt={previewImageAlt}
                    title="Poster title"
                    src={imageUrl}
                ></eon-ui-image>
            </eon-ui-videoplayer>
        </div>
    );
};

export default withSitecoreContext()(VideoFile);
