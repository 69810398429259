import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import TableInformationMobileColumn from '../TableInformationMobileColumn/index.js';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const TableInformation = ({ rendering }) => {
    const isMobile = useMobileCheck();
    const repeatingFields =
        rendering.placeholders['eon-table-information-columns'][0].placeholders;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        mobileFirst: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    swipe: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    swipe: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    swipe: true
                }
            }
        ]
    };

    if (!isMobile) {
        return (
            <div className="table-information">
                <div className="headline-wrap">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="table-wrap">
                    <Placeholder
                        name="eon-table-information-columns"
                        rendering={rendering}
                    />
                </div>
            </div>
        );
    }
    if (isMobile) {
        return (
            <div className="table-information">
                <div className="headline-wrap">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="table-wrap">
                    <Slider {...settings}>
                        {rendering.placeholders?.[
                            'eon-table-information-columns'
                        ]?.map((slide, index) =>
                            slide.placeholders['eon-table-cell-columns-1']
                                .length > 0 ? (
                                <div key={`card-teaser-slide-${index}`}>
                                    <TableInformationMobileColumn
                                        rendering={slide}
                                        index={index}
                                        repeatingFields={repeatingFields}
                                    />
                                </div>
                            ) : null
                        )}
                        <div className="ghost-card"></div>
                    </Slider>
                </div>
            </div>
        );
    }
};

export default TableInformation;
