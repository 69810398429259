import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { withTranslation } from 'react-i18next';

const QmaticCancelVisit = ({ t, fields, rendering, sitecoreContext }) => (
    <div className="qmatic-cancelation">
        {sitecoreContext.pageEditing && (
            <>
                <p>QmaticCancelVisit Component</p>
                <div className="before-message">
                    <Placeholder
                        name="eon-form-success"
                        rendering={rendering}
                    />
                </div>
                <eon-ui-headline
                    scheme={'red'}
                    size={'h2'}
                    text={t('Content|Forms|Qmatic|CancelVisitSuccess')}
                />
                <div className="after-message">
                    <Placeholder
                        name="eon-form-success-bottom"
                        rendering={rendering}
                    />
                </div>
            </>
        )}
        {!sitecoreContext.pageEditing && (
            <>
                {fields.resolveError && (
                    <eon-ui-headline
                        text={t('Content|Forms|Qmatic|CancelVisitError')}
                    />
                )}
                {!fields.resolveError && (
                    <>
                        <div className="before-message">
                            <Placeholder
                                name="eon-form-success"
                                rendering={rendering}
                            />
                        </div>
                        <eon-ui-headline
                            scheme={'red'}
                            size={'h2'}
                            text={t('Content|Forms|Qmatic|CancelVisitSuccess')}
                        />
                        <div className="after-message">
                            <Placeholder
                                name="eon-form-success-bottom"
                                rendering={rendering}
                            />
                        </div>
                    </>
                )}
            </>
        )}
    </div>
);

export default withSitecoreContext()(withTranslation()(QmaticCancelVisit));
