import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const InfoBox2 = ({ rendering, params, fields, sitecoreContext }) => {
    const isPlaceholderEmpty =
        !sitecoreContext.pageEditing &&
        rendering.placeholders['eon-links'].length > 0;
    return (
        <div className="info-box-2">
            <eon-ui-background color={params.background} class="hydrated">
                <div className="background">
                    <div className="box-inner">
                        <div className="content">
                            <div className="icon">
                                <eon-ui-icon
                                    name={fields.icon.value}
                                    scheme={params.iconScheme}
                                    size="small"
                                ></eon-ui-icon>
                            </div>
                            {fields?.description?.value && (
                                <div className="description">
                                    <eon-ui-rte-renderer
                                        rte-source="aem"
                                        content={fields.description.value}
                                    ></eon-ui-rte-renderer>
                                </div>
                            )}
                        </div>
                        {isPlaceholderEmpty && (
                            <div className="link">
                                <Placeholder
                                    name="eon-links"
                                    rendering={rendering}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(InfoBox2);
