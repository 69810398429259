import React, { useEffect, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
const PlaceholderField = ({
    field,
    fieldState,
    rendering,
    sitecoreContext,
    stateHandler
}) => {

    const [data, setData] = useState(fieldState);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, setData, stateHandler]);

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            {sitecoreContext.pageEditing && (
                <p>Placeholder field: {field.model.name}</p>
            )}
            <div>
                <Placeholder
                    name={`eon-placeholder-field-dynamic-{${field.model.itemId}}`}
                    rendering={rendering}
                />
            </div>
        </div>
    );
};

export default PlaceholderField;
