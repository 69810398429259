const ACTUAL = 1;
const PROGRESS = 2;
const DONE = 3;
const ACHIVED = 4;
const PUBLIC = 5;

function isActual(status) {
    return status === ACTUAL;
}

function inProgress(status) {
    return status === PROGRESS;
}

function isDone(status) {
    return status === DONE;
}

function isAchived(status) {
    return status === ACHIVED;
}

function isPublic(status) {
    return status === PUBLIC;
}

export default {
    ACTUAL,
    PROGRESS,
    DONE,
    ACHIVED,
    PUBLIC,
    isActual,
    inProgress,
    isDone,
    isAchived,
    isPublic
};
