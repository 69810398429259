import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const ProgressBar = ({ rendering, fields, sitecoreContext, t }) => {
    const processes = fields?.processes;
    const isDocument = typeof document === 'object';
    const body = isDocument ? document?.body : null;

    const hideBar = rendering.params?.hideBar
        ? rendering.params?.hideBar
        : false;

    useEffect(() => {
        if (!body) return;
        document.body.style.paddingTop = 0;
    }, [body]);

    if (!processes?.length) {
        return sitecoreContext.pageEditing ? (
            <div className="progress-bar">
                <p>Progress Bar Component</p>
            </div>
        ) : (
            ''
        );
    }

    if (!processes.some((process) => process.id === sitecoreContext.itemId)) {
        if (sitecoreContext.pageEditing) {
            return (
                <eon-ui-text text-style="copy">
                    {t('Editor|ProcessPageNotSetUpOnProgressBarDataSource')}
                </eon-ui-text>
            );
        } else {
            return null;
        }
    }

    const closeButtonCTA = fields.closeCta?.value?.href ?? '';
    const previousDescription = fields.previousDescription?.value;

    const index = processes.findIndex((x) => x.id === sitecoreContext.itemId);
    const hasPreviousPage = index !== 0;
    const previousPageUrl = hasPreviousPage
        ? processes[index - 1]?.url
        : closeButtonCTA;
    const currentPercentage = Math.floor(
        (100 / processes.length) * (index + 1)
    );

    return (
        <>
            {sitecoreContext.pageEditing && <p>Progress Bar Component</p>}

            <div className="progress-bar">
                <eon-ui-background
                    color={hideBar ? null : 'eon-ultralightgrey'}
                    full-height
                >
                    <div className="progress-bar-wrapper">
                        <div className="progress-bar-content">
                            <div className="progress-bar-back-button">
                                <Link to={previousPageUrl}>
                                    <eon-ui-icon name="chevron_small_left" />
                                    <eon-ui-text
                                        text-style="abstract"
                                        font-weight="700"
                                    >
                                        {previousDescription}
                                    </eon-ui-text>
                                </Link>
                            </div>
                            <div
                                className={`progress-bar-container ${
                                    hideBar ? 'hide' : ''
                                }`}
                            >
                                <div className="progress-bar-bar">
                                    <div
                                        className="progress-bar-progress"
                                        style={{
                                            width: `${currentPercentage}%`
                                        }}
                                    />
                                </div>
                                <div className="progress-bar-progress-description">
                                    <eon-ui-text
                                        text-style="abstract"
                                        font-weight="400"
                                    >
                                        {currentPercentage}&nbsp;%
                                    </eon-ui-text>
                                </div>
                            </div>
                            <div className="progress-bar-close-button">
                                <Link to={closeButtonCTA}>
                                    <eon-ui-icon
                                        name="close"
                                        alignment-y="top"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </eon-ui-background>
            </div>
        </>
    );
};

export default withTranslation()(withSitecoreContext()(ProgressBar));
