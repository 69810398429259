import React from 'react';
import {
    withSitecoreContext,
    Placeholder
} from '@sitecore-jss/sitecore-jss-react';

const AlertSection = ({ rendering, params }) => {
    const backgroundColor = params?.backgroundColor ?? 'eon-white';

    return (
        <div className="alerts-section">
            <eon-ui-background color={backgroundColor} class="hydrated">
                <div className="alerts-section-content">
                    <Placeholder name="eon-alert" rendering={rendering} />
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(AlertSection);
