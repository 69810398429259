import React from 'react';
import { withTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const SearchContainerEditor = ({ rendering, t }) => (
    <div className="search-container editor">
        <div className="search-bar">
            <Placeholder name="eon-flyout-search-bar" rendering={rendering} />
        </div>
        <div className="links-container">
            <eon-ui-text text-style="copy">
                {t('Content|FlyoutSearchLinks')}
            </eon-ui-text>
            <Placeholder name="eon-flyout-search-links" rendering={rendering} />
        </div>
    </div>
);

export default withTranslation()(SearchContainerEditor);
