import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ImageResponsive from "../ImageResponsive";

const Stage = ({ rendering, sitecoreContext }) => {
    const placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return '';
    }

    const headingText = rendering.fields?.heading.value;
    const subheadingText = rendering.fields?.subheading.value;

    return (
        <div className="stage-component">
            <eon-ui-stage
                subheadline-text={subheadingText}
                class="hydrated"
                content-position="bottom"
            >
                <div
                    className="stage-component__breadcrumbs"
                    slot="breadcrumbs"
                >
                    <Placeholder
                        name="eon-breadcrumbs"
                        rendering={rendering}
                        params={{ scheme: 'white' }}
                    />
                </div>

                <eon-ui-headline
                    slot="headline"
                    text={headingText}
                    size="h1"
                    text-shadow=""
                    scheme="white"
                    alignment="right"
                    class="hydrated"
                />
                <ImageResponsive
                    fields={rendering.fields}
                    slot={'image'}
                    height=""
                    shadow-on-image="false"
                    aspect-ratio="ratio-1x1"
                    use-as-background="true"
                    className="hydrated"
                />
                {sitecoreContext.pageEditing ? (
                    <div
                        className="stage-component__button-container-editor"
                        slot="cta"
                    >
                        <Placeholder
                            name="eon-ctabutton"
                            rendering={rendering}
                        />
                    </div>
                ) : (
                    <Placeholder
                        name="eon-ctabutton"
                        rendering={rendering}
                        render={(components) =>
                            components.map((component, index) => (
                                <div
                                    className="stage-component__button-container"
                                    slot="cta"
                                    key={index}
                                >
                                    {component}
                                </div>
                            ))
                        }
                    />
                )}
            </eon-ui-stage>
        </div>
    );
};

export default withSitecoreContext()(Stage);
