//Wbrew nazwie HeaderMobile ma wpływ również na manu na desktopie. Trzeba uważać, co się gdzie dodaje (patrz ticket NASA-684)
import React, { useState, useEffect } from 'react';
import NavigationHelper from '../NavigationHelper.js';
import NavigationItem from '../NavigationItem/index.js';

const FindOutMore = 'Dowiedz się więcej';

const AccordionNested = ({ mainLink, links, actualLinkArray }) => {
    const [activeMainLink, setActiveMainLink] = useState(false);
    const [activeLinks, setActiveLinks] = useState([]);
    const [isExtendedMenu, setIsExtendedMenu] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(new URL(window.location.href));

    useEffect(() => {}, [actualLinkArray, isExtendedMenu]);

    if (
        links.length > 0 &&
        links.filter((link) => mainLink[0].href === link.href).length === 0 &&
        mainLink[0].hasRedirect != 'True'
    ) {
        links.unshift({
            id: mainLink[0].id,
            text: FindOutMore,
            href: mainLink[0].href,
            linkType: mainLink[0].linkType
        });
    }

    useEffect(() => {
        const currentUrl = window.location.href;
        const updatedActiveLinks = links
            .filter((link) => currentUrl.includes(link.href))
            .map((link) => link.id);
        setActiveLinks(updatedActiveLinks);
        const activeNestedAccordion = sessionStorage.getItem(
            'activeNestedAccordion'
        );
        if (activeNestedAccordion === mainLink[0].id) {
            setActiveMainLink(true);
        }
    }, [links, mainLink]);

    const handleLinkClick = (id) => {
        setActiveLinks((prev) => [...prev, id]);
    };

    const toggleMainLinkAccordion = () => {
        setIsExtendedMenu(!isExtendedMenu);
        setActiveMainLink(!activeMainLink);
    };

    if (links.length > 0) {
        return (
            <div
                className={`accordion-container ${
                    mainLink[0].href ===
                    `/${actualLinkArray[1]}/${actualLinkArray[2]}/${actualLinkArray[3]}`
                        ? !isExtendedMenu
                            ? 'path'
                            : 'rolled-up'
                        : ''
                }`}
            >
                <div
                    className={`accordion ${activeMainLink ? 'active' : ''}`}
                    onClick={toggleMainLinkAccordion}
                >
                    {mainLink[0].text}
                    {links.length > 0 && (
                        <eon-ui-icon
                            name="chevron_small_down"
                            style={{
                                color: '#39393a',
                                transform: activeMainLink
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)'
                            }}
                        ></eon-ui-icon>
                    )}
                </div>
                {isExtendedMenu && (
                    <div
                        className={`accordion-content ${
                            activeMainLink ? 'active' : ''
                        }`}
                    >
                        {links.map((link) => (
                            <a
                                key={link.id}
                                href={link.href}
                                className={`accordion-text accordion-content__item ${
                                    activeLinks.includes(link.id)
                                        ? link.href.split('/').length ===
                                          currentUrl.pathname.split('/').length
                                            ? 'final-element'
                                            : ''
                                        : ''
                                } `}
                                onClick={() => handleLinkClick(link.id)}
                            >
                                {link.text}
                            </a>
                        ))}
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <a
                key={mainLink[0].id}
                href={mainLink[0].href}
                className={`accordion-text accordion-content__item ${
                    mainLink[0].href === currentUrl.pathname
                        ? 'final-element'
                        : ''
                }`}
                onClick={() => handleLinkClick(mainLink[0].id)}
            >
                {mainLink[0].text}
            </a>
        );
    }
};

const InfoBar = ({ content }) => {
    return (
        <div class={`info-bar info-bar__info`}>
            <eon-ui-icon
                size={'small'}
                class="info-bar__icon"
                name="info"
            ></eon-ui-icon>
            <div class="info-bar__content">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

const Accordion = ({
    text,
    nestedSections,
    hasFlyout,
    href,
    id,
    actualLinkArray
}) => {
    const [active, setActive] = useState(false);
    const [isFinalElement, setIsFinalElement] = useState(false);
    const [isExtendedMenu, setIsExtendedMenu] = useState(false);

    if (
        hasFlyout &&
        nestedSections.filter((ns) => ns.mainLink[0].href === href).length === 0
    ) {
        nestedSections.unshift({
            mainLink: Array({
                id: id,
                text: FindOutMore,
                href: href
            }),
            links: Array()
        });
    }
    useEffect(() => {}, [actualLinkArray, isExtendedMenu]);

    useEffect(() => {
        const activeAccordion = sessionStorage.getItem('activeAccordion');
        if (activeAccordion === id) {
            setActive(true);
        }
    }, [id]);

    useEffect(() => {
        const currentUrl = window.location.href;
        if (currentUrl.includes(href)) {
            setIsFinalElement(true);
        }
    }, [href]);

    const toggleAccordion = () => {
        setActive(!active);
        setIsExtendedMenu(!isExtendedMenu);
    };

    const handleTextClick = () => {
        if (!hasFlyout) {
            setIsFinalElement(true);
        }
    };

    const renderText = () => {
        const classNames = ['accordion-text', 'accordion-content__item'];
        if (
            isFinalElement &&
            (!nestedSections || nestedSections.length === 0)
        ) {
            classNames.push('final-element');
        }
        return (
            <a
                href={hasFlyout ? null : href}
                className={classNames.join(' ')}
                onClick={handleTextClick}
            >
                {text}
                {(hasFlyout ||
                    (nestedSections && nestedSections.length > 0)) && (
                    <eon-ui-icon
                        name="chevron_small_down"
                        style={{
                            color: '#39393a',
                            transform: active
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)'
                        }}
                    ></eon-ui-icon>
                )}
            </a>
        );
    };

    return (
        <div
            className={`accordion-container ${
                href === `/${actualLinkArray[1]}/${actualLinkArray[2]}`
                    ? !isExtendedMenu
                        ? 'path'
                        : 'rolled-up'
                    : ''
            }`}
        >
            <div
                id={id}
                className={`accordion ${
                    !hasFlyout &&
                    isFinalElement &&
                    (!nestedSections || nestedSections.length === 0)
                        ? 'final-element'
                        : ''
                } ${active ? 'active' : ''}`}
                onClick={hasFlyout ? toggleAccordion : null}
            >
                {renderText()}
            </div>
            {isExtendedMenu && nestedSections && (
                <div className={`accordion-content ${active ? 'active' : ''}`}>
                    {nestedSections.map((nestedSection, index) => (
                        <AccordionNested
                            key={`nestedSection-${index}`}
                            id={`nestedSection-${index}`}
                            mainLink={nestedSection.mainLink}
                            links={nestedSection.links}
                            actualLinkArray={actualLinkArray}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const RenderSubItemFirstLevel = ({
    id,
    href,
    linkType,
    displayOrderOnMobile,
    text
}) => {
    const [classNames, setClassNames] = useState(['']);

    useEffect(() => {
        const _classNames = ['accordion-text', 'accordion-content__item'];
        handleIsFinalElement(href) && _classNames.push('final-element');
        setClassNames(_classNames);
    }, []);

    const handleIsExternalElement = (href) => {
        //Rozważyć dodanie poniższej tablicy linków zwenętrznych z backendu
        const externalLinksArray = [
            'https://przejmijlicznik.eon.pl/',
            'https://szkoda-pradu.eon.pl/'
        ];
        const shortExternalLinks = externalLinksArray.map(
            (url) => url.split('/')[2]
        );

        if (shortExternalLinks.includes(href.split('/')[2])) {
            const x = document.querySelectorAll('.final-element');
            Array.from(x).forEach((element) => {
                element.classList.remove('final-element');
            });
            setClassNames([
                'accordion-text',
                'accordion-content__item',
                'final-element'
            ]);
        }
    };
    const handleIsFinalElement = (href) => {
        const currentUrl = window.location.href;
        if (currentUrl.includes(href)) {
            return (
                href.split('/').length ===
                    new URL(currentUrl).pathname.split('/').length && true
            );
        } else return false;
    };

    return (
        <a
            key={id}
            id={id}
            href={href}
            target={linkType === 'external' ? '_blank' : '_self'}
            className={classNames.join(' ')}
            style={{ order: displayOrderOnMobile }}
            onClick={() => handleIsExternalElement(href)}
        >
            {text}
        </a>
    );
};

const Tabs = ({ items, actualLinkArray }) => {
    const [activeTab, setActiveTab] = useState(items[0].id);

    items.forEach((item) => {
        if (
            item.subItems.length > 0 &&
            item.href != '/dla-biznesu' &&
            !item.href.includes('mojeon') &&
            item.subItems.filter((subItem) => item.href === subItem.href)
                .length === 0
        ) {
            item.subItems.unshift({
                displayOrderOnMobile: 1,
                hasFlyout: false,
                href: item.href,
                id: item.id,
                isHiddenOnMobile: false,
                isHiddenOnDesktop: true,
                linkType: 'internal',
                text: FindOutMore
            });
        }
    });

    const handleLocation = (items, actualLinkArray) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].href === `/${actualLinkArray[1]}`) {
                setActiveTab(items[i].id);
            }
        }
    };

    useEffect(() => {
        handleLocation(items, actualLinkArray);
    }, [actualLinkArray]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    const renderSubItems = (subItems) => {
        return subItems.map((subItem) => {
            if (!subItem.isHiddenOnMobile) {
                if (subItem.hasFlyout) {
                    return (
                        <Accordion
                            key={subItem.id}
                            id={subItem.id}
                            text={subItem.text}
                            nestedSections={subItem.nestedSections}
                            hasFlyout={subItem.hasFlyout}
                            href={subItem.href}
                            style={{ order: subItem.displayOrderOnMobile }}
                            actualLinkArray={actualLinkArray}
                        />
                    );
                } else if (
                    subItem.isDisable != undefined &&
                    subItem.isDisable
                ) {
                    return (
                        <div
                            class="accordion-text accordion-content__item disabled"
                            key={subItem.id}
                            id={subItem.id}
                            style={{ order: subItem.displayOrderOnMobile }}
                        >
                            <span class="name">{subItem.text}</span>
                            {!!subItem.tooltip && (
                                <InfoBar content={subItem.tooltip} />
                            )}
                        </div>
                    );
                } else {
                    return (
                        <RenderSubItemFirstLevel
                            id={subItem.id}
                            href={subItem.href}
                            linkType={subItem.linkType}
                            displayOrderOnMobile={subItem.displayOrderOnMobile}
                            text={subItem.text}
                        />
                    );
                }
            }
        });
    };

    return (
        <div className="tabs-container">
            <div className="tabs">
                {items.map(
                    (item) =>
                        !item.isHiddenOnMobile && (
                            <div
                                key={item.id}
                                id={item.id}
                                className={`tab ${
                                    item.id === activeTab ? 'active' : ''
                                }`}
                                onClick={() => handleTabClick(item.id)}
                                style={{ order: item.displayOrderOnMobile }}
                            >
                                {item.text}
                            </div>
                        )
                )}
            </div>
            {items.map((item) => (
                <>
                    <div
                        key={item.id}
                        className={`tab-content ${
                            item.id === activeTab ? 'active' : ''
                        }`}
                    >
                        {renderSubItems(item.subItems)}
                        {item.subItems[0]?.generalDisabledInfo != null && (
                            <InfoBar
                                content={item.subItems[0].generalDisabledInfo}
                            />
                        )}
                    </div>
                </>
            ))}
        </div>
    );
};

const HeaderMobile = ({ links, sitecoreContext }) => {
    const [actualLinkArray, setActualLinkArray] = useState([]);

    const handleActualLink = () => {
        const currentUrl = window.location.href;
        var _currentUrl = new URL(currentUrl).pathname.split('/');

        if (_currentUrl.length) {
            setActualLinkArray(_currentUrl);
        } else {
        }
    };
    useEffect(() => {
        handleActualLink();
    }, []);

    return (
        <>
            <div
                id="eon-mobile-navigation"
                className="eon-mobile-nav"
                style={{ width: '100%', zIndex: '800' }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: '100vw',
                        height: '100vh',
                        display: 'block',
                        zIndex: '99',
                        background: '#fff',
                        paddingTop: '64px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Tabs items={links} actualLinkArray={actualLinkArray} />
                </div>
                <div
                    id="mobile-primary-nav"
                    className="mobile-primary-nav dasdasdasdasdsa"
                    style={{ visibility: 'hidden' }}
                >
                    <div className="mobile-primary-nav-inner">
                        {/* first lvl of mobile nav */}
                        {links.map((link) => (
                            <span
                                data-rel={`mobile-sec-nav-${link.id}`}
                                className={`prime-nav-mobile primary-nav-item ${NavigationHelper.getActiveClass(
                                    sitecoreContext,
                                    link.id
                                )}`}
                                key={`mobile-primary-${link.id}`}
                            >
                                {link.text}
                                <eon-ui-icon
                                    scheme="darkgrey"
                                    name="chevron_small_right"
                                    role="img"
                                ></eon-ui-icon>
                            </span>
                        ))}
                    </div>
                </div>

                {/* second lvl of mobile nav */}
                {links.map((link) => (
                    <React.Fragment key={link.id}>
                        <div
                            id={`mobile-sec-nav-${link.id}`}
                            className="second-nav mobile-secondary-nav"
                            style={{ visibility: 'hidden' }}
                        >
                            <div className="mobile-secondary-nav-backlink">
                                Cofnij
                                <eon-ui-icon
                                    scheme="darkgrey"
                                    name="chevron_small_left"
                                    role="img"
                                ></eon-ui-icon>
                            </div>
                            <div className="mobile-secondary-nav-inner">
                                <NavigationItem
                                    className="secondary-nav-item-header secondary-nav-item"
                                    href={link.href}
                                    target={link.target}
                                >
                                    {link.text}
                                    <div className="mobile-icon-wrapper">
                                        <eon-ui-icon
                                            scheme="darkgrey"
                                            name="chevron_small_right"
                                            role="img"
                                        ></eon-ui-icon>
                                    </div>
                                </NavigationItem>
                                {link.subItems.map((subItem) => {
                                    return subItem.nestedSections ? (
                                        <span
                                            className={`second-nav secondary-nav-item ${NavigationHelper.getActiveClass(
                                                sitecoreContext,
                                                subItem.id
                                            )}`}
                                            data-rel={`mobile-sec-nav-${subItem.id}`}
                                            key={`mobile-sec-nav-${subItem.id}`}
                                        >
                                            {subItem.text}
                                            <div className="mobile-icon-wrapper">
                                                <eon-ui-icon
                                                    scheme="darkgrey"
                                                    name="chevron_small_right"
                                                    role="img"
                                                ></eon-ui-icon>
                                            </div>
                                        </span>
                                    ) : (
                                        <span
                                            className="mobile-nav-level-two"
                                            key={`mobile-sec-nav-${subItem.id}`}
                                        >
                                            <NavigationItem
                                                className={`second-nav secondary-nav-item ${NavigationHelper.getActiveClass(
                                                    sitecoreContext,
                                                    subItem.id
                                                )}`}
                                                href={subItem.href}
                                                target={subItem.target}
                                                data-rel={`mobile-sec-nav-${subItem.id}`}
                                            >
                                                {subItem.text}
                                                <div className="mobile-icon-wrapper">
                                                    <eon-ui-icon
                                                        scheme="darkgrey"
                                                        name="chevron_small_right"
                                                        role="img"
                                                    ></eon-ui-icon>
                                                </div>
                                            </NavigationItem>
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                        {/* third level of mobile nav */}
                        {link.subItems.map((subItem) => (
                            <React.Fragment
                                key={`mobile-sec-nav-${subItem.id}`}
                            >
                                <div
                                    id={`mobile-sec-nav-${subItem.id}`}
                                    className="mobile-secondary-nav"
                                    style={{ visibility: 'hidden' }}
                                >
                                    <div className="mobile-secondary-nav-backlink">
                                        Cofnij
                                        <eon-ui-icon
                                            scheme="darkgrey"
                                            name="chevron_small_left"
                                            role="img"
                                        ></eon-ui-icon>
                                    </div>
                                    <div className="mobile-secondary-nav-inner">
                                        <NavigationItem
                                            className="secondary-nav-item-header secondary-nav-item"
                                            href={subItem.href}
                                            target={subItem.target}
                                            data-rel={`mobile-sec-nav-${subItem.id}`}
                                        >
                                            {subItem.text}
                                        </NavigationItem>
                                        {subItem.nestedSections &&
                                            subItem.nestedSections
                                                .filter(
                                                    (ns) => ns && ns.mainLink
                                                )
                                                .map((section) =>
                                                    section.mainLink.map(
                                                        (ml) => (
                                                            <span
                                                                className={`secondary-nav-item ${NavigationHelper.getActiveClass(
                                                                    sitecoreContext,
                                                                    ml.id
                                                                )}`}
                                                                data-rel={`mobile-sec-nav-${ml.id}`}
                                                                key={`mobile-sec-nav-${ml.id}`}
                                                            >
                                                                {ml.text}
                                                                <div className="mobile-icon-wrapper">
                                                                    <eon-ui-icon
                                                                        scheme="darkgrey"
                                                                        name="chevron_small_right"
                                                                        role="img"
                                                                    ></eon-ui-icon>
                                                                </div>
                                                            </span>
                                                        )
                                                    )
                                                )}
                                    </div>
                                </div>
                                {/* fourth level of mobile nav */}
                                {subItem.nestedSections &&
                                    subItem.nestedSections
                                        .filter(
                                            (section) =>
                                                section &&
                                                section.mainLink &&
                                                section.mainLink.length > 0
                                        )
                                        .map((section) => {
                                            const mainLink =
                                                section.mainLink[0];
                                            return (
                                                <div
                                                    id={`mobile-sec-nav-${mainLink.id}`}
                                                    className="mobile-secondary-nav"
                                                    key={`sec-nav-${mainLink.id}`}
                                                    style={{
                                                        visibility: 'hidden'
                                                    }}
                                                >
                                                    <div className="mobile-secondary-nav-backlink">
                                                        Cofnij
                                                        <eon-ui-icon
                                                            scheme="darkgrey"
                                                            name="chevron_small_left"
                                                            role="img"
                                                        ></eon-ui-icon>
                                                    </div>
                                                    <div className="mobile-secondary-nav-inner">
                                                        <NavigationItem
                                                            className="secondary-nav-item-header secondary-nav-item third-nav-item"
                                                            href={mainLink.href}
                                                            target={
                                                                mainLink.target
                                                            }
                                                        >
                                                            {mainLink.text}
                                                        </NavigationItem>
                                                        {section.links.map(
                                                            (link) => (
                                                                <NavigationItem
                                                                    className={`third-level-secondary secondary-nav-item ${NavigationHelper.getActiveClass(
                                                                        sitecoreContext,
                                                                        link.id
                                                                    )}`}
                                                                    href={
                                                                        link.href
                                                                    }
                                                                    target={
                                                                        link.target
                                                                    }
                                                                    data-rel={`mobile-sec-nav-${link.id}`}
                                                                    key={`mobile-sec-nav-${link.id}`}
                                                                >
                                                                    {link.text}
                                                                    <div className="mobile-icon-wrapper">
                                                                        <eon-ui-icon
                                                                            scheme="darkgrey"
                                                                            name="chevron_small_right"
                                                                            role="img"
                                                                        ></eon-ui-icon>
                                                                    </div>
                                                                </NavigationItem>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

//Wbrew nazwie HeaderMobile ma wpływ również na manu na desktopie. Trzeba uważać, co się gdzie dodaje (patrz ticket NASA-684)
export default HeaderMobile;
