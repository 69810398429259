import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const ContactIllustrationsTileRow = ({
    rendering,
    sitecoreContext,
    params
}) => {
    let placeholdersKeys = Object.keys(rendering.placeholders);
    const displayAsTile = params?.displayAsTile ?? '0';
    if (!placeholdersKeys) {
        return '';
    }
    return (
        <div>
            {sitecoreContext.pageEditing && (
                <p>Contact Illustrations Tite Row</p>
            )}
            {displayAsTile === '0' && (
                <eon-ui-grid-control grid="grid__d6-6__t6-6" same-height="true">
                    {placeholdersKeys
                        .filter((x) => x.includes('column'))
                        .map((p, key) => (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder name={p} rendering={rendering} />
                            </eon-ui-xx>
                        ))}
                </eon-ui-grid-control>
            )}
            {displayAsTile === '1' && (
                <eon-ui-grid-control grid="grid__d12__t12" same-height="true">
                    {placeholdersKeys
                        .filter((x) => x.includes('column'))
                        .map((p, key) => (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder name={p} rendering={rendering} />
                            </eon-ui-xx>
                        ))}
                </eon-ui-grid-control>
            )}
        </div>
    );
};

export default withSitecoreContext()(ContactIllustrationsTileRow);
