import React from 'react';

const Separator = ({ params }) => {
    const bgColor = '--' + (params?.color ?? 'eon-white');

    return (
        <div
            className="separator"
            style={{
                backgroundColor: 'var(' + bgColor + ')'
            }}
        ></div>
    );
};

export default Separator;
