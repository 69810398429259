export function getMeonMenu() {
    try {
        return fetch('/mojeon/api/GetMainMenu')
            .then((response) => response.json())
            .then((response) => {
                if (response === null || response.length < 1) {
                    return [];
                }
                return formatAnswer(response[0]);
            })
            .catch((e) => []);
    } catch (error) {
        return Promise.resolve([]);
    }
}

function formatAnswer(response) {
    let subItems = [];
    if (response == null || response.Children === [] || response.Children.length < 1) return [];

    response.Children.forEach(function(child, i) {
        subItems.push({
            id: i,
            text: child.Label,
            nestedSections: [],
            href: child.Url,
            hasFlyout: false,
            displayOrderOnMobile: -1,
            isDisable: child.IsDisable,
            tooltip: child.Tooltip,
            generalDisabledInfo: response.Tooltip,
            isHiddenOnMobile: false,
        });
    });

    return subItems;
}