import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const InfoBox = ({ rendering, params, fields }) => {
    const stripeColor = params.stripeColor ?? params.background;
    const hasLink = !!rendering.placeholders['eon-links'].length;

    return (
        <div className="info-box">
            <eon-ui-background color={params.background} class="hydrated">
                <div className="background">
                    <div
                        className="stripe"
                        style={{
                            backgroundColor: `var(--${stripeColor})`
                        }}
                    >
                        &nbsp;
                    </div>
                    <div className="box-inner">
                        <div>
                            <div className="icon">
                                <eon-ui-icon
                                    name={fields.icon.value}
                                    scheme={params.iconScheme}
                                />
                            </div>
                            <div className="content">
                                {fields?.headline?.value && (
                                    <div className="headline">
                                        <eon-ui-headline
                                            text={fields.headline.value}
                                            headline-html-tag="h5"
                                            size="h5"
                                        />
                                    </div>
                                )}
                                {fields?.description?.value && (
                                    <div className="description">
                                        <eon-ui-rte-renderer
                                            rte-source="aem"
                                            content={fields.description.value}
                                        />
                                    </div>
                                )}

                                {hasLink && (
                                    <div className="link">
                                        <Placeholder
                                            name="eon-links"
                                            rendering={rendering}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default InfoBox;
