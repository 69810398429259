import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import gridStyle from '../../lib/GridControlStyleResolver.js';

const ProductDescription = ({ rendering, params }) => {
    var placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return null;
    }

    return (
        <div className="product-description">
            <eon-ui-grid-control grid={gridStyle(params.columns)}>
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder
                                name={p}
                                rendering={rendering}
                                params={params}
                            />
                        </eon-ui-xx>
                    ))}
            </eon-ui-grid-control>
        </div>
    );
};

export default ProductDescription;
