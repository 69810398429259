import React, { useRef, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { usePortalNode } from '../../hooks/usePortalNode';

export const SearchContainer = ({ onPanelClose, rendering, t, setIsPanelVisible }) => {
    const portalElement = usePortalNode();
    const containerRef = useRef(null);

    useClickOutside(containerRef, onPanelClose);
    
    const handleClick = (e) => { 
      if(e.target.classList.contains("search-submit-button")){
        setTimeout(() => {
          setIsPanelVisible(false)
        }, 100);
      }
    }

    const handleKeyDown = (e) => { 
      if (e.keyCode === 13) {
        setIsPanelVisible(false);
      }
    }

    useEffect(() => {
      if (!document) {
        return;
      }
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const body = useMemo(
        () => (
            <div className="search-container" ref={containerRef}>
                <div className="search-bar" onClick={handleClick}>
                    <Placeholder
                        name="eon-flyout-search-bar"
                        rendering={rendering}
                        params={{ flayoutSearchBar: true }}
                    />
                </div>
                <div className="links-container">
                    <eon-ui-text text-style="copy">
                        {t('Content|FlyoutSearchLinks')}
                    </eon-ui-text>
                    <div onClick={()=>setIsPanelVisible(false)}>
                    <Placeholder
                        name="eon-flyout-search-links"
                        rendering={rendering}
                        />
                    </div>
                </div>
            </div>
        ),
        []
    );

    return ReactDOM.createPortal(body, portalElement);
};

export default withTranslation()(SearchContainer);
