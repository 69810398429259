import React, { useState, useRef, useEffect } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import DataLayer from '../../DataLayer';
import { printType } from 'graphql';

const MapComponent = ({ fields, rendering }) => {
    const infoLayerZoomLevel = fields.infoLayerZoomLevel?.value ?? 9;

    const [position, setPosition] = useState({
        lat: fields.latitude?.value,
        long: fields.longitude?.value
    });
    const [markerFilter, setMarkerFilter] = useState('');
    const [zipCode, setZipCode] = useState('');

    const inputRef = useRef(null);
    const checkButtonRef = useRef(null);
    const inputSearchRef = useRef(null);
    const directionButtonRef = useRef(null);

    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        const initMarkers = () => {
            const newMarkers = [];
            const markerNames = [];
            fields.showroom.forEach((room) => {
                if (markerNames.indexOf(room.category) < 0) {
                    newMarkers.push({
                        name: room.category,
                        url: room.mapMarker
                    });
                    markerNames.push(room.category);
                }
            });
            setMarkers([...newMarkers]);
        };
        initMarkers();
    }, [fields.showroom]);

    const handleShowOnMap = (lat, long) => {
        setPosition({
            lat: lat,
            long: long
        });

        const event = new CustomEvent('showOnMapClicked', {
            detail: { lat, long }
        });
        document.dispatchEvent(event);
    };

    const handleMapFilter = (filter) => {
        let newFilter;
        let filterArray;
        if (filter === markerFilter) {
            newFilter = '';
            filterArray = ['salon', 'kasa'];
        } else {
            newFilter = filter;
            filterArray = [newFilter];
        }

        const event = new CustomEvent('mapCategoryFilterClick', {
            detail: filterArray
        });
        document.dispatchEvent(event);

        setMarkerFilter(newFilter);
    };

    const handleMakeAnAppointment = (category, description) => {
        const dataLayer = {
            event: 'map_pin',
            eventCategory: 'gmap',
            eventAction: 'button-Umow wizytę',
            eventLabel: category + '/' + description
        };
        DataLayer.pushToDataLayer(dataLayer);
    };

    const handleClickPin = (pin) => {
        const dataLayer = {
            event: 'map_pin',
            eventCategory: 'gmap',
            eventAction: 'map_pin_click',
            eventLabel: pin?.category + '/' + pin?.description
        };
        DataLayer.pushToDataLayer(dataLayer);
    };
    const handleShowTheRoute = (category, description) => {
        const dataLayer = {
            event: 'map_pin',
            eventCategory: 'gmap',
            eventAction: 'Pokaż trasę dojazdu',
            eventLabel: category + '/' + description
        };
        DataLayer.pushToDataLayer(dataLayer);
    };

    const handleZipSearch = () => {
        const markers = fields.showroom.filter(
            (showroom) =>
                showroom.description?.includes(zipCode) ||
                showroom.zipcode?.includes(zipCode)
        );

        if (markers.length > 0) {
            const marker = markers[0];
            return handleShowOnMap(marker.latitude, marker.longitude);
        }

        const searchCode = parseInt(zipCode.replace(/\D/g, ''));
        const roomCodesDiff = fields.showroom.reduce((acc, showroom) => {
            const roomCode = parseInt(showroom.zipcode.replace(/\D/g, ''));
            const result = searchCode - roomCode;
            let code = 0;
            if (result < 0) code = result * -1;
            else code = result;

            return [...acc, code];
        }, []);

        const min = Math.min(...roomCodesDiff.filter(Boolean));
        const closestIndex = roomCodesDiff.indexOf(min);
        const marker = fields.showroom[closestIndex || 0];

        handleShowOnMap(marker.latitude, marker.longitude);
    };

    useEffect(() => {
        const element = inputRef?.current;

        if (element) {
            element.addEventListener('valueChanged', (e) => {
                setZipCode(e.target.value);
            });
            document.addEventListener('googleMapMarkerClick', (e) => {
                handleClickPin(e.detail.marker);
            });
            document.addEventListener('googleRouteClick', (e) => {
                handleShowTheRoute(e.detail.category, e.detail.description);
            });
            document.addEventListener('mapInfoLayerButtonClick', (e) => {
                handleMakeAnAppointment(
                    e.detail.category,
                    e.detail.description
                );
            });
            return () => {
                element.removeEventListener('valueChanged', (e) => {
                    setZipCode(e.target.value);
                });
                document.removeEventListener('googleMapMarkerClick', (e) => {
                    handleClickPin(e.detail.marker);
                });
                document.removeEventListener('googleRouteClick', (e) => {
                    handleShowTheRoute(e.detail.category, e.detail.description);
                });
                document.removeEventListener('mapInfoLayerButtonClick', (e) => {
                    handleMakeAnAppointment(
                        e.detail.category,
                        e.detail.description
                    );
                });
            };
        }
    }, []);

    useEffect(() => {
        const element = checkButtonRef?.current;

        if (element) {
            element.addEventListener('buttonClick', handleZipSearch);

            return () => {
                element.removeEventListener('buttonClick', handleZipSearch);
            };
        }
    });

    if (!fields?.apiKey?.value || !fields?.enabled?.value) {
        return '';
    }

    return (
        <div className="MapComponent">
            <Placeholder name="eon-headline" rendering={rendering} />

            <div className="map-filter-wrapper">
                <div className="map-tags-wrapper">
                    {markers.map((marker) => (
                        <div
                            className="map-tag"
                            key={marker.name}
                            onClick={() => {
                                handleMapFilter(marker.name.toLowerCase());
                            }}
                        >
                            <img src={marker.url} alt="" />{' '}
                            <eon-ui-text text-style="quote" font-weight="bold">
                                {marker.name}
                            </eon-ui-text>
                        </div>
                    ))}
                </div>
                <div className="map-search-wrapper">
                    <div className="map-search-text">
                        <eon-ui-text text-style="copy" font-weight="bold">
                            {fields.mapDescr?.value}
                        </eon-ui-text>
                    </div>
                    <div className="inputContainer">
                        <eon-ui-input
                            ref={inputRef}
                            type="text"
                            pattern="00-000"
                            className="search-field"
                            scheme="darkgreyOnLimeyellow"
                            placeholder="Wpisz kod pocztowy"
                            value={zipCode}
                        />
                    </div>
                    <eon-ui-button
                        ref={checkButtonRef}
                        text="Sprawdź"
                        rank="secondary"
                        full-width-on-mobile="true"
                        toggle-full-width-on-mobile="true"
                    ></eon-ui-button>
                </div>
            </div>

            <eon-ui-map
                api-key={fields.apiKey.value}
                lat={position?.lat}
                lng={position?.long}
                info-layer-zoom-level={infoLayerZoomLevel}
                fit-bounds={fields.fitBounds?.value}
                clusters={fields.clusters?.value}
                class="hydrated"
            >
                {fields.showroom
                    .filter((s) => s.latitude && s.longitude && s.enabled)
                    .map((s, key) => (
                        <eon-ui-map-marker
                            key={`eon-ui-map-marker${key}`}
                            lat={s.latitude}
                            lng={s.longitude}
                            mapmarkerimg={s?.mapMarker}
                            category={s?.category}
                            description={s?.headerTitle}
                        >
                            <eon-ui-map-info-layer
                                filter-name={s.category}
                                filter-text={s.category}
                                header-title={s.category}
                                scheme={s.schema}
                                small={s.small}
                                location={s.location}
                                lat={s.latitude}
                                lng={s.longitude}
                                class="hydrated map-info-layer"
                                description={s?.headerTitle}
                            >
                                <eon-ui-background color="eon-white">
                                    <div className="map-info-layer-content">
                                        {s.title && (
                                            <div>
                                                <eon-ui-text
                                                    color="eon-darkgrey"
                                                    text-style="subheadline"
                                                    font-weight="900"
                                                >
                                                    {s.headerTitle}
                                                </eon-ui-text>
                                            </div>
                                        )}

                                        <div
                                            className="street"
                                            style={{ marginBottom: '16px' }}
                                        >
                                            <eon-ui-text
                                                color="eon-darkgrey"
                                                text-style="subheadline"
                                                font-weight="400"
                                            >
                                                {s?.street}
                                            </eon-ui-text>
                                        </div>

                                        {s.descriptionOnMap && (
                                            <div
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <eon-ui-rte-renderer
                                                    rte-source="quill"
                                                    content={s.descriptionOnMap}
                                                />
                                            </div>
                                        )}

                                        {s.bookVisitLink.url &&
                                            s.bookVisitLink.text && (
                                                <div
                                                    style={{
                                                        marginBottom: '16px'
                                                    }}
                                                >
                                                    <eon-ui-button
                                                        href={
                                                            s.bookVisitLink.url
                                                        }
                                                        target={
                                                            s.bookVisitLink
                                                                .target
                                                        }
                                                        text={
                                                            s.bookVisitLink.text
                                                        }
                                                        use-as-link="true"
                                                        scheme={
                                                            s.schema ||
                                                            'darkgrey'
                                                        }
                                                    />
                                                </div>
                                            )}

                                        <div style={{ marginBottom: '8px' }}>
                                            <eon-ui-text color="eon-darkgrey">
                                                Wyznacz trasę z adresu
                                            </eon-ui-text>
                                        </div>

                                        <div style={{ marginBottom: '8px' }}>
                                            <eon-ui-input
                                                scheme="darkgreyOnLimeyellow"
                                                useRef={inputSearchRef}
                                                type="text"
                                                className="search-field"
                                                placeholder="Ulica, miasto"
                                            />
                                        </div>
                                        <eon-ui-link
                                            useRef={directionButtonRef}
                                            text="Pokaż trasę dojazdu"
                                            force-hover-style="true"
                                            hide-icon="true"
                                            scheme={
                                                s?.schema ? s.schema : 'ron-red'
                                            }
                                            full
                                        >
                                            <div
                                                onClick={() => {
                                                    handleShowTheRoute(s);
                                                }}
                                            ></div>
                                        </eon-ui-link>
                                    </div>
                                </eon-ui-background>
                            </eon-ui-map-info-layer>
                        </eon-ui-map-marker>
                    ))}
            </eon-ui-map>

            <div className="map-component-tiles-wrapper">
                {fields.highlightShowroom
                    .filter((s) => s.enabled)
                    .map((s, key) => (
                        <div className="map-component-tile" key={key}>
                            <div className="map-component-tile-content">
                                {s.title && (
                                    <eon-ui-headline
                                        text={s.title}
                                        scheme="darkgrey"
                                        size="h5"
                                        headline-html-tag="h1"
                                    />
                                )}

                                {s.description && (
                                    <div className="map-tile-paragraph">
                                        <eon-ui-rte-renderer
                                            rte-source="quill"
                                            content={s.description}
                                        />
                                    </div>
                                )}
                                <div className="tile-buttons-wrapper">
                                    <div
                                        className="button-container"
                                        onClick={() =>
                                            handleShowOnMap(
                                                s.latitude,
                                                s.longitude
                                            )
                                        }
                                    >
                                        <eon-ui-link
                                            text="Pokaż na mapie"
                                            hide-icon="true"
                                        ></eon-ui-link>
                                    </div>
                                    {s.link?.url && s.link?.text && (
                                        <div
                                            className="button-container"
                                            onClick={() =>
                                                handleMakeAnAppointment(
                                                    s?.category,
                                                    s?.title
                                                )
                                            }
                                        >
                                            <eon-ui-link
                                                href={s.link.url}
                                                target={s.link.target}
                                                text={s.link.text}
                                            ></eon-ui-link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(MapComponent));
