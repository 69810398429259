import React, { useEffect, useState } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import EonNavigation from './eonNavigation.js';
import NavigationHelper from './NavigationHelper.js';
import HeaderEditMode from './HeaderEditMode/index.js';
//Wbrew nazwie HeaderMobile ma wpływ również na manu na desktopie. Trzeba uważać, co się gdzie dodaje (patrz ticket NASA-684)
import HeaderMobile from './HeaderMobile/index.js';
import NavigationItem from './NavigationItem/index.js';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import { useLocation } from 'react-router-dom';
import InfoPopup from './InfoPopup/index.js';
import { getMeonMenu } from './HeaderMobile/MeonMenuService.js';

const MetaNav = ({ links, rendering, sitecoreContext }) => {
    return (
        <div id="meta-navigation" className="meta-nav-container ">
            <div className="meta-nav-container-inner">
                <div className="meta-nav current-path">
                    {links.map((pageLink, i) => {
                        if (!pageLink.isHiddenOnDesktop)
                            return (
                                <NavigationItem
                                    className={`meta-nav-item ${NavigationHelper.getActiveClass(
                                        sitecoreContext,
                                        pageLink.id
                                    )}`}
                                    href={pageLink.href}
                                    target={pageLink.target}
                                    key={`pageLink${i}`}
                                >
                                    {pageLink.text}
                                </NavigationItem>
                            );
                    })}
                    <InfoPopup />
                    <div className="toolbar-container">
                        <div className="toolbar-sticky">
                            <Placeholder
                                name="eon-header-toolbar-sticky"
                                rendering={rendering}
                                isSmall={true}
                            />
                        </div>
                        <div className="toolbar-static">
                            <Placeholder
                                name="eon-header-toolbar-static"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
                <div className="icon-container hide-on-big-screen" />
            </div>
        </div>
    );
};

const PrimaryNav = ({ links, rendering, sitecoreContext, isMobile }) => {
    const primaryNavUnderline = `${
        links.filter(
            (link) =>
                !sitecoreContext.activePages ||
                sitecoreContext.activePages.includes(link.id)
        ).length > 0
            ? 'primary-nav-container-inner with-underline'
            : 'primary-nav-container-inner'
    }`;
    return (
        <div id="primary-navigation" className="primary-nav-container">
            <div className={primaryNavUnderline}>
                <div className="primary-nav">
                    {links
                        .filter(
                            (link) =>
                                !sitecoreContext.activePages ||
                                sitecoreContext.activePages.includes(link.id)
                        )
                        .map((l) =>
                            l.subItems.map((subItem, sl) => {
                                if (!subItem.isHiddenOnDesktop) {
                                    return subItem.hasFlyout ? (
                                        <a
                                            data-rel={`sec-nav-${subItem.id}`}
                                            className={`primary-nav-item ${NavigationHelper.getActiveClass(
                                                sitecoreContext,
                                                subItem.id
                                            )}`}
                                            tabIndex="0"
                                            href={subItem.href}
                                            target={subItem.target}
                                            key={`second-level-${sl}`}
                                        >
                                            {subItem.text}
                                        </a>
                                    ) : (
                                        <NavigationItem
                                            className={`primary-nav-item ${NavigationHelper.getActiveClass(
                                                sitecoreContext,
                                                subItem.id
                                            )}`}
                                            tabIndex="0"
                                            key={`second-level-${sl}`}
                                            href={subItem.href}
                                            target={subItem.target}
                                        >
                                            {subItem.text}
                                        </NavigationItem>
                                    );
                                };
                            })
                        )}
                </div>
                <div className="primary-icon-nav">
                    <span
                        id="mobile-nav-toggle-button"
                        className="toggle-mobile-nav"
                        tabIndex="0"
                    >
                        <span id="hamburger-icon">
                            <span className="line line-1"></span>
                            <span className="line line-2"></span>
                            <span className="line line-3"></span>
                        </span>
                    </span>
                </div>
                {isMobile && (
                    <div className="toolbar-container">
                        <div className="toolbar-sticky">
                            <Placeholder
                                name="eon-header-toolbar-sticky"
                                rendering={rendering}
                                isSmall={true}
                            />
                        </div>
                        <div className="toolbar-static">
                            <Placeholder
                                name="eon-header-toolbar-static"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                )}
                {!isMobile && (
                    <div className="toolbar-container-sticky">
                        <div className="toolbar-sticky">
                            <Placeholder
                                name="eon-header-toolbar-sticky"
                                rendering={rendering}
                                isSmall={true}
                            />
                        </div>
                    </div>
                )}
                {rendering.fields.logo.value?.src && (
                    <div className="company-logo">
                        <a
                            href={rendering.fields.logoLink?.value?.href}
                            target="_self"
                            itemProp="url"
                        >
                            <img
                                src={rendering.fields.logo.value.src}
                                alt="eon logo"
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

const SecondaryNav = ({ links, navSections }) => {
    return (
        <div id="secondary-navigation" className="secondary-nav-container">
            <div className="secondary-nav-container-inner">
                {links.map((l) =>
                    l.subItems.map((sl, i) => {
                        const placeholderName = `eon-navigation-flyout-tabs-{${sl.id}}`;
                        if (!sl.isHiddenOnDesktop) {
                            return sl.hasFlyout ? (
                                <Placeholder
                                    name={placeholderName}
                                    rendering={
                                        navSections.filter(
                                            (section) =>
                                                section.fields.link.id === l.id
                                        )[0]
                                    }
                                    params={{
                                        flyoutId: `sec-nav-${sl.id}`,
                                        linkHref: sl.href,
                                        linkText: sl.text
                                    }}
                                    key={`ph-${i}`}
                                />
                            ) : (
                                ''
                            );
                        };
                    })
                )}
            </div>
        </div>
    );
};

const Header = ({ rendering, sitecoreContext }) => {
    const isMobile = useMobileCheck();
    const location = useLocation();

    const handleAlertBoxEvent = ({ detail }) => {
        if (detail) {
            document.querySelector('#eon-header') &&
                document
                    .querySelector('#eon-header')
                    .classList.add('alert-hidden');
        } else {
            document.querySelector('#eon-header') &&
                detail !== null &&
                document
                    .querySelector('#eon-header')
                    .classList.remove('alert-hidden');
        }
    };

    const handleAlertBox = ({ detail }) => {
        if (detail === 'show') {
            document.querySelector('.alert-wrapper') &&
                document
                    .querySelector('.alert-wrapper')
                    .classList.remove('behind-header');
        }
        if (detail === 'hide') {
            document.querySelector('.alert-wrapper') &&
                document
                    .querySelector('.alert-wrapper')
                    .classList.add('behind-header');
        }
    };

    useEffect(() => {
        document.addEventListener('alertBoxEvent', handleAlertBoxEvent);
        document.addEventListener('hideAlert', handleAlertBox);

        return () => {
            document.removeEventListener('alertBoxEvent', handleAlertBoxEvent);
            document.removeEventListener('hideAlert', handleAlertBox);
        };
    });

    useEffect(() => {
        if (!sitecoreContext.pageEditing || sitecoreContext.headerId) {
            EonNavigation.navigationInit();
        }
        return () => {
            if (!sitecoreContext.pageEditing || sitecoreContext.headerId) {
                EonNavigation.cleanEvents();
            }
        };
    });

    useEffect(() => {
        EonNavigation.locationChanged();
    }, [location]);

    const navSections =
        sitecoreContext.pageEditing && !sitecoreContext.headerId
            ? []
            : rendering.placeholders['eon-header-navigation'];

    //maps elements to convinient structure
    const [links, setLinks] = useState(navSections.map(
        //1st and 2nd level
        (section) => {
            var flyoutPhs = section.placeholders
                ? Object.keys(section.placeholders)
                : [];
            section.fields.link.subItems.forEach((si) => {
                const flyoutPh = `eon-navigation-flyout-tabs-{${si.id}}`;
                if (flyoutPhs.includes(flyoutPh)) {
                    si.nestedSections = section.placeholders[flyoutPh].flatMap(
                        (flyout) =>
                            //gets flyout columns
                            Object.keys(flyout.placeholders)
                                .filter(
                                    (columnPh) =>
                                        flyout.placeholders[columnPh] &&
                                        flyout.placeholders[columnPh].length > 0
                                )
                                .map(
                                    (columnPh) =>
                                        flyout.placeholders[columnPh][0].fields
                                )
                    );
                }
            });

            return section.fields.link;
        }
    ));

    useEffect(() => {
        if(links && links.length > 0){
            getMeonMenu().then(x => {
                links[links.length - 1].subItems = x;
                setLinks([...links])
            })
        }
    }, [rendering.placeholders]);

    if (sitecoreContext.pageEditing && !sitecoreContext.headerId) {
        return <HeaderEditMode rendering={rendering} />;
    }

    return (
        <div className={'header-container'}>
            <div className="alert-wrapper">
                <Placeholder name="eon-section-alert" rendering={rendering} />
            </div>
            <>
                <header id="eon-header" className="eon-header">
                    <div id="nav-wrapper">
                        <div className="color-bar">
                            <div className="turquoise"></div>
                            <div className="lime-yellow"></div>
                            <div className="red"></div>
                        </div>
                    </div>
                    <MetaNav
                        links={links}
                        rendering={rendering}
                        sitecoreContext={sitecoreContext}
                    />
                    <PrimaryNav
                        links={links}
                        rendering={rendering}
                        sitecoreContext={sitecoreContext}
                        isMobile={isMobile}
                    />
                    <SecondaryNav links={links} navSections={navSections} />
                    <div
                        id="secondary-icon-navigation"
                        className="secondary-icon-nav-container"
                    >
                        <div className="secondary-icon-nav-container-inner">
                            <div
                                id="sec-nav-user"
                                className="secondary-icon-nav"
                            >
                                <eon-ui-button text="login"></eon-ui-button>
                            </div>
                            <div
                                id="sec-nav-lang"
                                className="secondary-icon-nav"
                            >
                                <eon-ui-headline
                                    size="h4"
                                    text="Language"
                                    scheme="darkgrey"
                                />
                            </div>
                        </div>
                    </div>
                </header>
                <HeaderMobile links={links} sitecoreContext={sitecoreContext} />
            </>
        </div>
    );
};

export default withSitecoreContext()(Header);
