import React from 'react';

const backgroundClass = 'sitemap__columns--background';
let i = 1;

const Sitemap = ({ fields }) => {
    const map = (list) =>
        list?.map((p, parentKey) => (
            <div className="sitemap__columns main" key={parentKey}>
                <div className={i++%2 ? backgroundClass : null}></div>
                <div className="sitemap__columns--wrapper">
                    <h2><a className="sitemap__link" href={p.url}>{p.name}</a></h2>
                    <ul className="sitemap__list">
                        {p.children && mapChildren(p.children)}
                    </ul>
                </div>
            </div>
        ));

    const mapChildren = (list) =>
        list?.map((p, parentKey) => (
            <ul className="sitemap__list children" key={parentKey}>
                <li key={parentKey}>
                    <a className="sitemap__link leaf" href={p.url}>{p.name}</a>
                    {p.children && mapChildren(p.children)}
                </li>
            </ul>
        ));

    return (
        <div className="sitemap">
            <h1>Mapa serwisu</h1>

            {map(fields?.groupedPages)}

            <div className="sitemap__columns other">
                <div className={i++%2 ? backgroundClass : null}></div>
                <div className="sitemap__columns--wrapper">
                    <h2>{fields?.otherPagesTitle?.value}</h2>
                    <div className="sitemap__list--other">
                        {mapChildren(fields?.otherPages)}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Sitemap;
