import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const TextTiles = ({ rendering, sitecoreContext }) => (
    <div className="text-tiles">
        {sitecoreContext.pageEditing && <p>TextTiles Component</p>}
        <Placeholder name="eon-headline" rendering={rendering} />
        <Placeholder name="eon-text-tiles-rows" rendering={rendering} />
    </div>
);

export default withSitecoreContext()(TextTiles);
