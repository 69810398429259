import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TextTeaser = ({ rendering, params, fields, sitecoreContext }) => {
    const scheme = params.scheme;
    const pictogramColor = params.pictogramColor;

    const ImageWrapper = () => {
        const isImage = rendering.placeholders?.['eon-image'].some(
            (x) => x.name !== 'code'
        );
        if (sitecoreContext.pageEditing && !isImage) {
            return (
                <div className="image-wrapper">
                    <Placeholder
                        name="eon-image"
                        rendering={rendering}
                        props={{ ratio: 'ratio-2x1' }}
                    />
                </div>
            );
        } else {
            return (
                <Placeholder
                    name="eon-image"
                    rendering={rendering}
                    props={{ ratio: 'ratio-2x1' }}
                />
            );
        }
    };

    return (
        <div className="text-teaser">
            <eon-ui-text-teaser
                pictogram={fields?.pictogram?.value}
                align="left"
                style={{ width: '100%' }}
                scheme={scheme}
                pictogram-color={pictogramColor}
            >
                <div className="headline-wrapper">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                {fields?.description?.value && (
                    <div className="description-wrapper">
                        <eon-ui-rte-renderer
                            rte-source="aem"
                            content={fields?.description?.value}
                        />
                    </div>
                )}
                <div className="link-wrapper" slot="button">
                    <Placeholder name="eon-links" rendering={rendering} />
                </div>
            </eon-ui-text-teaser>
            <ImageWrapper />
        </div>
    );
};

export default withSitecoreContext()(TextTeaser);
