import React, { useEffect, useRef, useState } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';
const noop = () => {};

const DropdownListField = ({
    field,
    fieldState,
    conditionsHandler = noop,
    stateHandler = noop,
    tracker,
    errors,
    onChange,
    placeholder
}) => {
    const ref = useRef();
    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);

    useEffect(() => {
        const element = ref.current;

        element.addEventListener(valueChangedEvent, handleValueChange);
        element.addEventListener(blurEvent, (e) => handleBlur(e));
        element.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            element.removeEventListener(valueChangedEvent, (e) =>
                handleValueChange(e)
            );
            element.addEventListener(blurEvent, (e) => handleBlur(e));
            element.addEventListener(focusEvent, (e) => handleFocus(e));
        };
    }, [ref.current]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, stateHandler, setData]);

    const handleValueChange = (e) => {
        e.preventDefault();
        field.model.value = e.target.value;

        const errorMessages = [];
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }
        onChange(field.valueField.name, e.target.value, valid, errorMessages);
        conditionsHandler(field);
    };

    const handleBlur = () => {
        if (tracker) {
            tracker.onBlurField(field, data.value, errors);
        }
    };

    const handleFocus = () => {
        if (tracker) {
            tracker.onFocusField(field, data.value, errors);
        }
    };

    const selected =
        (data &&
            field.model.items
                .filter((item) => item.value === data.value)
                .map((item) => item.value)[0]) ??
        field.model.items
            .filter((item) => item.selected)
            .map((item) => item.value)[0];

    return (
        <div className={'field-wrapper' + (data?.hidden ? ' hidden' : '')}>
            <eon-ui-dropdown
                ref={ref}
                name={field.model.name}
                label={`${field.model.title} ${
                    field.model.required ? '*' : ''
                }`}
                selected={selected}
                disabled={data?.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
                placeholder={placeholder}
            >
                {field.model.items.map((item, i) => (
                    <eon-ui-dropdown-option
                        key={`item${i}`}
                        value={item.value}
                        label={item.text}
                    ></eon-ui-dropdown-option>
                ))}
            </eon-ui-dropdown>
        </div>
    );
};

export default DropdownListField;
