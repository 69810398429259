import React, { useEffect, useState, useRef } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { scrollTo } from '../../utils/scrollTo.js';
import { useLocation } from 'react-router-dom';
import { useInterval } from '../../hooks/useInterval.js';

const Section = ({ rendering, params, sitecoreContext }) => {
    const partiallyFilledBackground = params.partiallyFilledBackground;

    const [isScrolled, setScrolled] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [offset, setOffset] = useState();

    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        setScrolled(false);
        if (
            !document ||
            !window ||
            !params?.dataAnchorId ||
            sitecoreContext.pageEditing ||
            !ref?.current
        ) {
            return;
        }
        const url = window?.location.href || '';
        if (url.includes('#') && url.split('#').pop() === params.dataAnchorId) {
            setIsActive(true);
            return;
        }
        setIsActive(false);
    }, [location]);

    useEffect(() => {
        if (isActive && offset && !isScrolled) {
            scrollTo(offset, window).then(() =>
                setTimeout(() => setScrolled(true), 1000)
            );
        }
    });

    useInterval(
        () => {
            var newOffset = ref.current.offsetTop;

            //prevents to set offset when just header is rendered
            if (newOffset < 150) {
                return;
            }

            if (!offset || Math.abs(offset - newOffset) > 10) {
                setOffset(newOffset);
            }
        },
        300,
        isActive
    );

    let sectionSize = 'l-size';
    if (params.size === 'S') {
        sectionSize = 's-size';
    } else if (params.size === 'M') {
        sectionSize = 'm-size';
    }

    return (
        <div
            className="section"
            data-anchor-id={params.dataAnchorId}
            data-anchor-title={params.dataAnchorTitle}
            ref={ref}
        >
            {partiallyFilledBackground === '1' && (
                <div className="white-background"> </div>
            )}
            <eon-ui-background color={params.color} class="hydrated">
                <div
                    className={`inner-content ${sectionSize}${
                        params?.customclass ? ` ${params?.customclass}` : ''
                    }`}
                >
                    <Placeholder
                        name="eon-section-content"
                        rendering={rendering}
                        props={{ backgroundColor: params.color }}
                    />
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(Section);
