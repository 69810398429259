import React, { useState } from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useEffect, useRef } from 'react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const Alert = ({ t, sitecoreContext, rendering, fields }) => {
    const isMobile = useMobileCheck();
    const informationRef = useRef(null);
    const icon = fields?.icon?.value || 'alert';
    const information = fields?.information.value || '';
    const collapsedByDefault = fields?.collapsedByDefault?.value !== undefined ? fields.collapsedByDefault.value : true;
    const showAlert = fields?.show || false;
    const headerId = rendering.dataSource;
    const currentDate = new Date();
    // 'never' as in 100 years
    let neverExpireDate = new Date();
    neverExpireDate.setFullYear(currentDate.getFullYear() + 100);

    const [cookies, setCookie] = useCookies(['Alert']);
    const handleCookies = () =>
        setCookie(`Alert-${headerId}`, false, {
            path: '/',
            expires: neverExpireDate
        });

    const [isVisible, setIsVisible] = useState(true);
    const [isCollapsable, setIsCollapsable] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(false)

    const changeIsVisible = () =>
        setIsVisible((prevValue) => {
            const customEvent = new CustomEvent('alertBoxEvent', {
                detail: prevValue
            });
            document.dispatchEvent(customEvent);
            return !prevValue;
        });

    const handleAlertClose = () => {
        handleCookies();
        changeIsVisible();
    };
    const cookieKey = `Alert-${headerId}`;
    const cookie = cookies[cookieKey];

    useEffect(() => {
        const custEvent = new CustomEvent('alertBoxEvent', {
            detail: cookie
        });
        document.dispatchEvent(custEvent);
    });

    useEffect(()=> {
        if (!informationRef.current) return;

        const observer = new ResizeObserver(() => {
            const maxHeight = 3 * 24 + 8;  // 3 linijki + margin p
            if (informationRef.current.clientHeight > 0) {
                if (isMobile && informationRef.current.clientHeight > maxHeight) {
                    setIsCollapsable(collapsedByDefault);
                    setIsCollapsed(collapsedByDefault);
                }
                observer.disconnect();
            }
        });
        observer.observe(informationRef.current);

        return () => {
            observer.disconnect();
        };
    }, [informationRef.current])

    const alertContent = (
        <>
            <div className="alert">
                <div className="alert-content">
                    <div className="alert-icon">
                        <eon-ui-icon name={icon}></eon-ui-icon>
                    </div>
                    <div className="alert-paragraph-wrapper">
                        <div ref={informationRef} className={`alert-paragraph ${isCollapsed ? "collapsed" : ""}`}>
                            <eon-ui-rte-renderer
                                rte-source="aem"
                                content={information}
                                />
                        </div>
                        {isCollapsable && (
                            <div className="alert-collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>{t(`Content|Alert|${isCollapsed ? 'Expand' : 'Collapse'}`)}</div>
                        )}
                    </div>
                    <div className="icon-container">
                        <div className="alert-icon-close">
                            <eon-ui-icon
                                onClick={() => handleAlertClose()}
                                name="close"
                            ></eon-ui-icon>
                        </div>
                    </div>
                    <div className={`alert-links ${isCollapsable ? "collapsable" : ""}`} hidden={isCollapsed}>
                        <Placeholder name="eon-links" rendering={rendering} />
                    </div>
                    {!showAlert && sitecoreContext.pageEditing && (
                        <div className="alertTime">
                            <eon-ui-text text-style="copy">
                                {t('Editor|AlertOutOfTimeWindow')}
                            </eon-ui-text>
                        </div>
                    )}
                </div>
            </div>
            <eon-ui-text class="alert-separator">
                <div className="separator"></div>
            </eon-ui-text>
        </>
    );

    return isVisible
        ? sitecoreContext.pageEditing
            ? alertContent
            : !cookie && showAlert && alertContent
        : '';
};

export default withTranslation()(withSitecoreContext()(Alert));
