import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { Link } from 'react-router-dom';

const CardTeaserBulletPoint = ({ rendering }) => {
    if (!rendering) {
        return '';
    }
    const cardTitle = rendering.fields?.title?.value ?? '';
    const cardImage = rendering.fields?.image?.value?.src ?? '';
    const cardImageSVG = rendering.fields?.image?.value?.svg ?? '';
    const cardImageAlt = rendering.fields?.image?.value?.alt ?? '';
    const cta = rendering.fields?.link?.value?.href ?? '';
    const ctaTarget = rendering.fields?.link?.value?.target ?? '';
    const ctaText = rendering.fields?.link?.value?.text ?? '';

    const Card = () => (
        <div className="card-teaser-bullet-point-background">
            <div className="card-teaser-bullet-point-image">
                {cardImageSVG !== undefined && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: cardImageSVG
                        }}
                    />
                )}
                {cardImage !== undefined && (
                    <eon-ui-image
                        slot="contentImage"
                        src={cardImage}
                        alt={cardImageAlt}
                        class="hydrated image"
                    />
                )}
            </div>
            <eon-ui-headline size="h3" text={cardTitle} />
            <div className="card-teaser-bullet-point-bullets">
                <Placeholder name="eon-card-content" rendering={rendering} />
            </div>
            {cta && (
                <div className="card-teaser-bullet-point-link">
                    <eon-ui-link text={ctaText} class="hydrated" />
                </div>
            )}
        </div>
    );

    return (
        <div className="card-teaser-bullet-point">
            {!cta && <Card />}
            {cta && cta.startsWith('/') && (
                <Link to={cta} target={ctaTarget}>
                    <Card />
                </Link>
            )}
            {cta && !cta.startsWith('/') && (
                <a href={cta} target={ctaTarget}>
                    <Card />
                </a>
            )}
        </div>
    );
};

export default withSitecoreContext()(CardTeaserBulletPoint);
