import React from 'react';

const PollRoot = ({ fields, params }) => {
    const id = fields?.identifier?.value ?? '';
    const title = fields?.title?.value ?? '';
    const minH = params?.minHeight ?? '1500px';
    return (
        <div className="section">
            <div className="inner-content s-size">
                <h1>{title}</h1>
                <div id={id} style={{ minHeight: minH }}></div>
            </div>
        </div>
    );
};

export default PollRoot;
