import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const RouteWrapper = ({ tag, attributes, sitecoreContext }) => {
    const history = useHistory();
    const ref = useRef();

    const handleClick = (e) => {
        history.push(attrs.href);
        e.preventDefault();
    };

    useEffect(() => {
        const element = ref?.current;
        if (!sitecoreContext.pageEditing) {
            if (element && attrs.href?.startsWith('/')) {
                element.addEventListener('click', handleClick, true);
                return () => {
                    element.removeEventListener('click', handleClick, true);
                };
            }
        }
    }, []);

    const attrs = { ...attributes };

    if (!attrs || !attrs.href) {
        return '';
    }

    if (sitecoreContext.pageEditing) {
        delete attrs.href;
        delete attrs.target;
    }

    const childParams = { ...attrs };
    delete childParams.slot;

    const wrapperParams = {
        ...(attrs.slot && { slot: attrs.slot })
    };

    const Tag = tag;

    return (
        <div className="cta-wrapper" {...wrapperParams}>
            <Tag ref={ref} {...childParams}></Tag>
        </div>
    );
};

export default withSitecoreContext()(RouteWrapper);
