import OperatorTypes from './operatorTypes.js';

const Operations = {
    checkCondition: (condition, fieldValues, fieldState) => {
        const value = fieldValues[condition.fieldId]?.value?.toString() ?? '';
        const stateData = fieldState && fieldState[condition.fieldId]?.data;
        switch (condition.operatorId) {
            case OperatorTypes.isEqualTo:
                return value === condition.value;

            case OperatorTypes.isNotEqualTo:
                return value !== condition.value;

            case OperatorTypes.contains:
                return value.indexOf(condition.value) > -1;

            case OperatorTypes.doesNotContain:
                return value.indexOf(condition.value) < 0;

            case OperatorTypes.hasItems:
                return stateData?.items && stateData.items.length > 0;

            case OperatorTypes.hasNoItems:
                return !stateData?.items || stateData.items.length == 0;

            default:
                return false;
        }
    }
};

export default Operations;
