import React from 'react';
import {
    withSitecoreContext,
    Placeholder
} from '@sitecore-jss/sitecore-jss-react';

const ProductTeaserPresentationTile = ({
    rendering,
    sitecoreContext,
    props
}) => {
    const fields = rendering.fields;
    const addPlus = props.addPlus;
    const plusColor = props.plusColors;
    if (sitecoreContext.pageEditing && !fields) {
        return <div>Editor|EmptyDatasource</div>;
    }

    return (
        <div className="product-teaser-presentation-tile">
            {sitecoreContext.pageEditing && (
                <div>
                    <p>Product Teaser Presentation Tile</p>
                    <br />
                    <p>Eyecatcher:</p>
                    <div>
                        <Placeholder
                            name="eon-eyecatcher"
                            rendering={rendering}
                        />
                    </div>
                </div>
            )}
            {!sitecoreContext.pageEditing && (
                <div className="product-teaser-presentation-tile__badge">
                    <Placeholder name="eon-eyecatcher" rendering={rendering} />
                </div>
            )}
            {fields?.image?.value?.svg !== undefined && (
                <div className="product-teaser-presentation-tile__image">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: fields?.image?.value?.svg
                        }}
                    />
                    <div className="product-teaser-presentation-tile__inner">
                        <div className="product-teaser-presentation-tile__description">
                            <eon-ui-headline
                                size="h4"
                                text={fields.signature.value}
                                alignment="center"
                            />
                        </div>
                    </div>
                    {addPlus === '1' && plusColor === 'white' && (
                        <div className="product-teaser-presentation-tile__image__plus white">
                            +
                        </div>
                    )}
                    {addPlus === '1' && plusColor === 'gray' && (
                        <div className="product-teaser-presentation-tile__image__plus gray">
                            +
                        </div>
                    )}
                </div>
            )}
            {fields?.image?.value?.svg === undefined && (
                <div className="product-teaser-presentation-tile__image">
                    <img
                        src={fields.image.value?.src}
                        alt={fields.image.value?.alt}
                    />
                    <div className="product-teaser-presentation-tile__inner">
                        <div className="product-teaser-presentation-tile__description">
                            <eon-ui-headline
                                size="h4"
                                text={fields.signature.value}
                                alignment="center"
                            />
                        </div>
                    </div>
                    {addPlus === '1' && plusColor === 'white' && (
                        <div className="product-teaser-presentation-tile__image__plus white">
                            +
                        </div>
                    )}
                    {addPlus === '1' && plusColor === 'gray' && (
                        <div className="product-teaser-presentation-tile__image__plus gray">
                            +
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(ProductTeaserPresentationTile);
