import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import modalSize from '../../lib/ModalSizeResolver';

const QuickLink = ({ rendering, params }) => {
    const modalId = 'modalId' + rendering.uid;
    const fields = rendering.fields;
    const modalSizeParameter = params.modalSize ?? 'S';

    return (
        <div className="quick-link">
            <eon-ui-headline size="h5" text={fields.title.value} />
            <eon-ui-link text="" open-modal-id={modalId} class="hydrated" />
            <eon-ui-modal
                modal-id={modalId}
                show-closing-x
                full-width="true"
                full-width-width={modalSize(modalSizeParameter)}
            >
                <Placeholder name="eon-modal" rendering={rendering} />
            </eon-ui-modal>
        </div>
    );
};

export default withSitecoreContext()(QuickLink);
