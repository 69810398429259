import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RouteLink from '../Route/RouteLink/index.js';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const FooterSection = ({ fields, sitecoreContext }) => {
    const isMobile = useMobileCheck();

    const mainlinks = fields.mainLink;
    const links = fields.links;

    return (
        <div className="footer-section">
            {sitecoreContext.pageEditing && <p>FooterSection Component</p>}
            {mainlinks.map((mainlink, i) => {
                return (
                    <eon-ui-sitelink
                        pane-title={mainlink.text}
                        key={`footerLink${i}`}
                    >
                        {!isMobile && (
                            <RouteLink
                                slot="headline-link"
                                scheme="darkgrey"
                                text={mainlink.text}
                                href={mainlink.href}
                                target={mainlink.target}
                                hide-icon
                            />
                        )}
                        {links.map((link, j) => {
                            return (
                                <RouteLink
                                    key={`link${j}`}
                                    slot="links"
                                    scheme="darkgrey"
                                    text={link.text}
                                    href={link.href}
                                    target={link.target}
                                    size="small"
                                    hide-icon
                                />
                            );
                        })}
                    </eon-ui-sitelink>
                );
            })}
        </div>
    );
};

export default withSitecoreContext()(FooterSection);
