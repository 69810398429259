function GetAllFields(fields, validator) {
    const allFields = [];

    fields.forEach((field) => {
        if (field.model && validator(field.model)) {
            allFields.push(field);
        } else if (field.fields) {
            //some fields has nested fields
            allFields.push(
                ...field.fields
                    .map((x) => x)
                    .filter((field) => validator(field.model))
            );
        }
    });

    return allFields;
}

function GetEventCategory(sitecoreContext, rendering) {
    return `${rendering?.fields?.fields?.metadata?.name}/${
        rendering?.params?.productName ?? sitecoreContext?.navigationTitle
    }`;
}

function MapValidationErrorsAsJson(validationErrors) {
    if (!validationErrors) {
        return [];
    }

    return Object.keys(validationErrors).map((key) => ({
        name: key,
        value: validationErrors[key]
    }));
}

// Validates if the model has the basic fields in other to be sent to Analytics
function FieldModelValidator(model) {
    return (
        model &&
        model.required !== undefined &&
        model.value !== undefined &&
        (model.skipInGtm === undefined || !model.skipInGtm)
    );
}

// Validates if the GTM model has the basic fields in other to be sent to Analytics
function GtmModelValidator(model) {
    return (
        model &&
        model.gtmKey !== undefined &&
        model.formElementName !== undefined &&
        (model.skipInGtm === undefined || !model.skipInGtm)
    );
}

function GenerateTransactionId() {
    const date = new Date();
    const year = date.getFullYear() + '';
    const month = getTwoDigitString(date.getMonth() + 1);
    const day = getTwoDigitString(date.getDate());
    const hour = getTwoDigitString(date.getHours());
    const minute = getTwoDigitString(date.getMinutes());
    const second = getTwoDigitString(date.getSeconds());
    const rand = Math.floor(Math.random() * 1000 + 1) + '';

    function getTwoDigitString(value) {
        return (value < 10 ? '0' : '') + value;
    }

    return year + month + day + '|' + hour + minute + second + '|' + rand;
}

export {
    GetAllFields,
    GetEventCategory,
    MapValidationErrorsAsJson,
    FieldModelValidator,
    GtmModelValidator,
    GenerateTransactionId
};
