import React from 'react';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import {
    isExperienceEditorActive,
    dataApi,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from './dataFetcher';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

class RouteHandler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notFound: true,
            defaultLanguage: config.defaultLanguage
        };

        const routeData = this.extractRouteData();

        // route data from react-router - if route was resolved, it's not a 404
        if (routeData !== null) {
            this.state.notFound = false;
        }

        // if we have an initial SSR state, and that state doesn't have a valid route data,
        // then this is a 404 route.
        if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
            this.state.notFound = true;
        }

        // if we have an SSR state, and that state has language data, set the current language
        // (this makes the language of content follow the Sitecore context language cookie)
        // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
        if (routeData && routeData.context && routeData.context.language) {
            this.state.defaultLanguage = routeData.context.language;
        }

        this.componentIsMounted = false;
        this.languageIsChanging = false;

        // tell i18next to sync its current language with the route language
        this.updateLanguage();
    }

    componentDidMount() {
        const routeData = this.extractRouteData();

        // if no existing routeData is present (from SSR), get Layout Service fetching the route data
        if (!routeData || this.props.ssrRenderComplete) {
            this.updateRouteData();
        } else if (routeData) {
            if (this.props.sitecoreContext.headerId) {
                this.updateHeaderData(this.props.sitecoreContext.headerId);
            }
            if (this.props.sitecoreContext.footerId) {
                this.updateFooterData(this.props.sitecoreContext.footerId);
            }
        }

        // once we initialize the route handler, we've "used up" the SSR data,
        // if it existed, so we want to clear it now that it's in react state.
        // future route changes that might destroy/remount this component should ignore any SSR data.
        // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
        // (once to find GraphQL queries that need to run, the second time to refresh the view with
        // GraphQL query results)
        // We test for SSR by checking for Node-specific process.env variable.
        if (
            typeof window !== 'undefined' &&
            !this.props.ssrRenderComplete &&
            this.props.setSsrRenderComplete
        ) {
            this.props.setSsrRenderComplete(true);
        }

        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    extractRouteData = () => {
        if (!this.props.sitecoreContext) return null;

        const { route, ...context } = this.props.sitecoreContext;

        return {
            sitecore: {
                route,
                context
            }
        };
    };

    /**
     * Loads route data from Sitecore Layout Service into state.routeData
     */
    updateRouteData(prevRoute) {
        if (window) {
            window.dispatchEvent(new Event('routeChanged'));
        }

        let sitecoreRoutePath =
            this.props.route.match.params.sitecoreRoute || '/';
        if (!sitecoreRoutePath.startsWith('/')) {
            sitecoreRoutePath = `/${sitecoreRoutePath}`;
        }

        const language =
            this.props.route.match.params.lang || this.state.defaultLanguage;

        // get the route data for the new route
        getRouteData(sitecoreRoutePath, language).then((routeData) => {
            if (
                routeData !== null &&
                routeData.sitecore &&
                routeData.sitecore.route
            ) {
                if (
                    prevRoute &&
                    routeData.sitecore.route?.fields?.forceRedirect?.value &&
                    prevRoute !== sitecoreRoutePath
                ) {
                    window.location.href = sitecoreRoutePath;
                    return;
                }

                let oldHeaderId =
                    this.props.sitecoreContext &&
                    this.props.sitecoreContext.headerId;

                let oldFooterId =
                    this.props.sitecoreContext &&
                    this.props.sitecoreContext.footerId;

                // set the sitecore context data and push the new route
                this.props.updateSitecoreContext({
                    route: routeData.sitecore.route,
                    itemId: routeData.sitecore.route.itemId,
                    ...routeData.sitecore.context
                });
                this.setState({ notFound: false });

                if (oldHeaderId !== this.props.sitecoreContext.headerId) {
                    this.updateHeaderData(this.props.sitecoreContext.headerId);
                }

                if (oldFooterId !== this.props.sitecoreContext.footerId) {
                    this.updateFooterData(this.props.sitecoreContext.footerId);
                }
            } else {
                this.setState({ notFound: true }, () =>
                    this.props.updateSitecoreContext(routeData.sitecore.context)
                );
            }
        });
    }

    updateHeaderData(headerId) {
        if (!headerId) {
            this.setState({ header: null });
            return;
        }

        const language =
            this.props.route.match.params.lang || this.state.defaultLanguage;

        getPlaceholderData('eon-header', headerId, language).then(
            (placeholderData) => {
                if (placeholderData !== null && placeholderData.elements) {
                    this.setState({ header: placeholderData.elements[0] });
                } else {
                    console.log('not found');
                }
            }
        );
    }

    updateFooterData(footerId) {
        if (!footerId) {
            this.setState({ footer: null });
            return;
        }

        const language =
            this.props.route.match.params.lang || this.state.defaultLanguage;

        getPlaceholderData('eon-footer', footerId, language).then(
            (placeholderData) => {
                if (placeholderData !== null && placeholderData.elements) {
                    this.setState({ footer: placeholderData.elements[0] });
                } else {
                    console.log('not found');
                }
            }
        );
    }

    /**
     * Updates the current app language to match the route data.
     */
    updateLanguage() {
        const newLanguage =
            this.props.route.match.params.lang || this.state.defaultLanguage;

        if (i18n.language !== newLanguage) {
            this.languageIsChanging = true;

            i18n.changeLanguage(newLanguage, () => {
                this.languageIsChanging = false;

                // if the component is not mounted, we don't care
                // (next time it mounts, it will render with the right language context)
                if (this.componentIsMounted) {
                    // after we change the i18n language, we need to force-update React,
                    // since otherwise React won't know that the dictionary has changed
                    // because it is stored in i18next state not React state
                    this.forceUpdate();
                }
            });
        }
    }

    componentDidUpdate(previousProps) {
        const existingRoute = previousProps.route.match.url;
        const newRoute = this.props.route.match.url;

        // don't change state (refetch route data) if the route has not changed
        if (existingRoute === newRoute) {
            return;
        }

        // if in experience editor - force reload instead of route data update
        // avoids confusing Sitecore's editing JS
        if (isExperienceEditorActive()) {
            window.location.assign(newRoute);
            return;
        }

        this.updateLanguage();
        this.updateRouteData(existingRoute);
    }

    render() {
        const { notFound } = this.state;
        const { header, footer } = this.state;

        const routeData = this.extractRouteData();

        // no route data for the current route in Sitecore - show not found component.
        // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
        // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
        if (notFound && routeData) {
            return (
                <div>
                    <Helmet>
                        <title>{i18n.t('Page not found')}</title>
                    </Helmet>
                    <NotFound
                        context={
                            routeData.sitecore && routeData.sitecore.context
                        }
                    />
                </div>
            );
        }

        // Don't render anything if the route data or dictionary data is not fully loaded yet.
        // This is a good place for a "Loading" component, if one is needed.
        if (!routeData || this.languageIsChanging) {
            return null;
        }

        // Render the app's root structural layout
        return (
            <Layout
                route={routeData.sitecore.route}
                header={header}
                footer={footer}
            />
        );
    }
}

export default withSitecoreContext({ updatable: true })(RouteHandler);

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
    const fetchOptions = {
        layoutServiceConfig: { host: config.sitecoreApiHost },
        querystringParams: {
            sc_lang: language,
            sc_apikey: config.sitecoreApiKey
        },
        fetcher: dataFetcher
    };

    if (window?.location) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams?.entries());
        if (params) {
            Object.assign(fetchOptions.querystringParams, params);
        }
    }

    return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
        if (
            error.response &&
            error.response.status === 404 &&
            error.response.data
        ) {
            return error.response.data;
        }

        console.error('Route data fetch error', error, error.response);

        return null;
    });
}

function getPlaceholderData(placeholder, id, language) {
    const fetchOptions = {
        layoutServiceConfig: { host: config.sitecoreApiHost },
        querystringParams: {
            sc_lang: language,
            sc_apikey: config.sitecoreApiKey,
            sc_mode: 'normal'
        },
        fetcher: dataFetcher
    };

    //export declare function fetchPlaceholderData(placeholderName: string, itemPath: string, options: LayoutServiceRequestOptions<PlaceholderData>): Promise<PlaceholderData>;

    return dataApi
        .fetchPlaceholderData(placeholder, id, fetchOptions)
        .catch((error) => {
            if (
                error.response &&
                error.response.status === 404 &&
                error.response.data
            ) {
                return error.response.data;
            }

            console.error('Route data fetch error', error, error.response);

            return null;
        });
}
