import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SingleProductTile = ({ rendering, sitecoreContext, params }) => {
    const fields = rendering.fields;
    const titleSize = params?.titleSize || 'h4';

    if (sitecoreContext.pageEditing && !fields) {
        return <div>Editor|EmptyDatasource</div>;
    }

    return (
        <div className="single-product-tile">
            <div>
                {sitecoreContext.pageEditing && <p>Single Product Tile</p>}

                {fields.image?.value?.src && (
                    <div className="single-product-tile__image">
                        <img
                            src={fields.image?.value?.src}
                            alt={fields.image?.value?.alt}
                            width="auto"
                        />
                    </div>
                )}

                <eon-ui-background color={params?.background}>
                    <div
                        className={`single-product-tile__container ${
                            !!fields.image?.value?.src && 'with-image'
                        }`}
                    >
                        <div>
                            <div className="single-product-tile__eyecatcher">
                                <Placeholder
                                    name="eon-eyecatcher"
                                    rendering={rendering}
                                />
                            </div>

                            <div className="single-product-tile__title">
                                <eon-ui-headline
                                    size={titleSize}
                                    scheme={params?.titleColor}
                                    text={rendering.fields.title?.value}
                                />
                            </div>

                            <div className="single-product-tile__subtitle">
                                <eon-ui-rte-renderer
                                    rte-source="aem"
                                    content={fields?.subtitle?.value}
                                />
                            </div>
                        </div>
                    </div>
                </eon-ui-background>
            </div>
        </div>
    );
};

export default withSitecoreContext()(SingleProductTile);
