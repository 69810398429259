import React, { useRef, useState, useEffect } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';

const valueChangedEvent = 'valueChanged';

const CheckboxField = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    errors,
    onChange,
    rendering
}) => {
    const ref = useRef();
    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [firstLoad, setFirstLoad] = useState(true);

    const handleValueChange = (e) => {
        e.preventDefault();
        var value = e.target.checked;
        field.model.value = value;

        const errorMessages = [];
        let valid = true;
        if (field.model.required && !value) {
            valid = false;
        }
        onChange(field.valueField.name, value, valid, errorMessages);
        conditionsHandler(field);
    };

    useEffect(() => {
        const element = ref.current;

        element.addEventListener(valueChangedEvent, handleValueChange);

        return () => {
            element.removeEventListener(valueChangedEvent, handleValueChange);
        };
    }, [ref.current]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (firstLoad) {
            setErrors([]);
            setFirstLoad(false);
            return;
        }

        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, setData, stateHandler]);

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            <eon-ui-checkbox
                ref={ref}
                name={field.model.name}
                id={field.valueField.id}
                label={`${field.model.title}${field.model.required ? '*' : ''}`}
                value={data.value}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
            ></eon-ui-checkbox>
        </div>
    );
};

export default CheckboxField;
