import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const RealizationsCardTeaser = ({ rendering, sitecoreContext, fields }) => {
    if (!fields) {
        return '';
    }

    if (sitecoreContext.pageEditing && !fields.image) {
        return <eon-ui-text text-style="copy">Card Image is empty</eon-ui-text>;
    }

    const image = fields.image.value;
    const logo = fields.logo?.value?.src;
    const logoDescription = fields.logoDescription?.value;
    const title = fields.title?.value;
    const description = fields.description?.value;

    return (
        <div className="realizations-card-teaser">
            <eon-ui-background
                color="eon-ultralightgrey"
                class="hydrated"
                full-height
            >
                <div className="realizations-card-teaser_card">
                    <div className="realizations-card-teaser_image">
                        <eon-ui-image
                            src={image.src}
                            title="My image"
                            alt={image.alt}
                            aspect-ratio="ratio-2x1"
                            lazy
                        />
                    </div>

                    <div className="realizations-card-teaser_content">
                        {(logo || logoDescription) && (
                            <div className="realizations-card-teaser_logo">
                                {!!logo ? (
                                    <eon-ui-image
                                        lazy
                                        height="32px"
                                        width="auto"
                                        src={logo}
                                        title="My image"
                                    />
                                ) : (
                                    <eon-ui-headline
                                        text={logoDescription}
                                        size="copy"
                                        scheme="turquoise"
                                    />
                                )}
                            </div>
                        )}
                        <div className="realizations-card-teaser_header">
                            <eon-ui-headline text={title} size="h4" />
                        </div>
                        <div className="realizations-card-teaser_description">
                            <eon-ui-text
                                text-style="copy"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                            />
                        </div>
                        <div className="realizations-card-teaser_link">
                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(RealizationsCardTeaser);
