import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const OffsetTeaserFive = ({ rendering, sitecoreContext, params }) => {
    const isMobile = useMobileCheck();
    const headlineScheme =
        params?.scheme === 'ultralightgrey' ? 'red' : 'darkgrey';

    return (
        <div className="offset-teaser-five">
            {sitecoreContext.pageEditing && <p>OffsetTeaserFive Component</p>}

            <div className="offset-teaser-container">
                {!isMobile && (
                    <div className="offset-teaser-left-side">
                        <div className="offset-teaser-headline">
                            <eon-ui-headline
                                text={rendering.fields?.headline?.value}
                                scheme={headlineScheme}
                                alignment="right"
                                size="h2"
                                headline-html-tag="h2"
                            />
                        </div>
                    </div>
                )}
                <div className="offset-teaser-right-side">
                    <eon-ui-background
                        color={`eon-${params?.scheme}`}
                        full-height
                    >
                        <div className="offset-teaser-content">
                            {isMobile && (
                                <div className="offset-teaser-headline">
                                    <eon-ui-headline
                                        text={rendering.fields?.headline?.value}
                                        scheme={headlineScheme}
                                        size="h2"
                                        headline-html-tag="h2"
                                    />
                                </div>
                            )}

                            <div className="offset-teaser-headline">
                                <eon-ui-headline
                                    text={rendering.fields?.subheadline?.value}
                                    scheme={headlineScheme}
                                    size="h3"
                                    headline-html-tag="h3"
                                />
                            </div>

                            <eon-ui-rte-renderer
                                slot="content"
                                rte-source="aem"
                                content={rendering.fields?.text?.value}
                            />

                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    </eon-ui-background>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(OffsetTeaserFive);
