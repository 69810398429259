import React from 'react';
import { withTranslation } from 'react-i18next';
import { formatNumber } from '../utils';

const MobileTable = ({ t, data }) => {
    return (
        <div className="mobile-data">
            {data.HourlyRates.map((rate, index) => (
                <div
                    key={index}
                    className={`table-container ${index % 2 ? 'even' : ''}`}
                >
                    <table className="mobile">
                        <tbody>
                            <tr>
                                <td className="highlight">
                                    {t('DayAheadMarket|Header|Time')}
                                </td>
                                <td>{rate.Hour}</td>
                            </tr>
                            <tr>
                                <td className="highlight" colSpan="2">
                                    {t('DayAheadMarket|FixingName')}
                                </td>
                            </tr>
                            <tr>
                                <td className="highlight">
                                    {t('DayAheadMarket|Header|NetRate')}
                                </td>
                                <td>{formatNumber(rate.NetRate)}</td>
                            </tr>
                            <tr>
                                <td className="highlight">
                                    {t('DayAheadMarket|Header|GrossRate')}
                                </td>
                                <td>{formatNumber(rate.GrossRate)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default withTranslation()(MobileTable);
