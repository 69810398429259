import { useState, useLayoutEffect } from 'react';

export const useMobileCheck = () => {
    const isClient = typeof window === 'object';
    const navigator = isClient ? window?.navigator : null;

    const [windowWidth, setWindowWidth] = useState(1200);

    useLayoutEffect(() => {
        if (!navigator) return;

        const handleWindowResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [navigator]);

    return windowWidth < 1024;
};
