function modalSize(size) {
    switch (size) {
        case 'S':
            return '584px';
        case 'M':
            return '992px';
        case 'L':
            return '1264px';
        default:
            return '';
    }
}

export default modalSize;
