import React from "react";
import {useMobileCheck} from "../../hooks/useMobileCheck";

const ImageResponsive = ({fields, slot, title, className, ...props}) => {
    const isMobile = useMobileCheck();
    let imageUrl = fields?.image?.value?.src ?? '';
    let altText = fields?.image?.value?.alt ?? '';
    title = title ?? fields?.image?.value?.title ?? '';
    if (isMobile && fields?.mobileImage?.value?.src) {
        imageUrl = fields?.mobileImage?.value?.src ?? '';
        altText = fields?.mobileImage?.value?.alt ?? '';
        title = title ?? fields?.mobileImage?.value?.title ?? '';
    }

    slot = slot || 'header-image';
    title = title || 'Page Header';
    className = className || 'page-header-image';

    return <eon-ui-image
        slot={slot}
        src={imageUrl}
        alt={altText}
        title={title}
        class={className}
        {...props}
    />;
}

export default ImageResponsive;