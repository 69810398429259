import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { usePortalNode } from '../../hooks/usePortalNode';
import { useMobileCheck } from '../../hooks/useMobileCheck';
import LoginForm from './LoginForm.jsx';
import { LoginFormEditor } from './LoginFormEditor.jsx';

const LoginFormPortal = (params) => {
    const portalElement = usePortalNode();
    const body = <LoginForm {...params} />;

    return ReactDOM.createPortal(body, portalElement);
};

const FlyoutTrigger = ({ t, onClick }) => {
    const isMobile = useMobileCheck();

    return (
        <div className="button-wrapper">
            {isMobile ? (
                <div onClick={onClick}>
                    <eon-ui-icon name="user_outline" />
                </div>
            ) : (
                <eon-ui-button
                    text={t('Content|LoginButton')}
                    rank="primary"
                    size="small"
                    onClick={onClick}
                />
            )}
        </div>
    );
};

const FlyoutMenuLogin = ({ sitecoreContext, rendering, fields, t }) => {
    const [isPanelVisible, setIsPanelVIsible] = useState(false);

    if (!fields) {
        return '';
    }

    return (
        <div className={`login-menu ${isPanelVisible ? 'open' : ''}`}>
            <FlyoutTrigger t={t} onClick={() => setIsPanelVIsible(true)} />

            {sitecoreContext.pageEditing && !sitecoreContext.headerId && (
                <LoginFormEditor rendering={rendering} fields={fields} t={t} />
            )}

            {!sitecoreContext.pageEditing &&
                sitecoreContext.headerId &&
                isPanelVisible && (
                    <LoginFormPortal
                        onPanelClose={() => setIsPanelVIsible(false)}
                        rendering={rendering}
                        fields={fields}
                        t={t}
                    />
                )}
        </div>
    );
};

export default withSitecoreContext()(withTranslation()(FlyoutMenuLogin));
