import React from 'react';
import ImageResponsive from "../ImageResponsive";

const PageHeader = ({ fields, params }) => {
    const titleText = fields?.title?.value ?? '';
    const content = fields?.content?.value ?? '';
    const ctaLink = fields?.ctaLink?.value?.href ?? '';
    const ctaTarget = fields?.ctaLink?.value?.target ?? '';
    const ctaText = fields?.ctaLink?.value?.text ?? '';

    return (
        <div className="home-page-header">
            <eon-ui-page-header
                scheme={params.scheme}
                show-content={true}
                headline-position="bottom"
                headline-size={params.headlineSize}
                color-bars={params.colorBars}
                color-bar-offset={!!(params.colorBarOffset * 1)}
                is-slider-item={params.slider === 'true'}
                wide-shade={params.wideShade === '1'}
                narrow-shade={params.narrowShade === '1'}
            >
                <ImageResponsive fields={fields} shadow-on-image={params?.shadowOnImage === '1'}/>
                <eon-ui-headline
                    slot="header-headline"
                    scheme="white"
                    size={params.headlineSize}
                    text={titleText}
                    alignment="left"
                    class="hide-desktop"
                />
                <eon-ui-text
                    slot="header-content"
                    text-style="abstract"
                    class="header-text"
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                />
                <eon-ui-headline
                    slot="header-subheadline"
                    size={params.headlineSize}
                    text={titleText}
                    alignment="left"
                    class="hide-mobile header-text"
                />
                {ctaLink && (
                    <eon-ui-button
                        slot="header-cta"
                        class="cta-button"
                        text={ctaText}
                        href={ctaLink}
                        target={ctaTarget}
                        rank={params.ctaType}
                        use-as-link
                        full-width-on-mobile
                        toggle-full-width-on-mobile
                    />
                )}
            </eon-ui-page-header>
        </div>
    );
};

const Slider = ({ fields, params }) => (
    <eon-ui-carousel-item>
        <PageHeader fields={fields} params={params} />
    </eon-ui-carousel-item>
);

const HomePageHeader = ({ fields, params }) => {
    if (params.slider === 'true')
        return <Slider fields={fields} params={params} />;
    else return <PageHeader fields={fields} params={params} />;
};

export default HomePageHeader;
