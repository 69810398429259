import animateScrollTo from 'animated-scroll-to';

const options = {
    // Indicated if scroll animation should be canceled on user action (scroll/keypress/touch)
    // if set to "false" user input will be disabled until scroll animation is complete
    cancelOnUserAction: false,

    // Animation easing function, with "easeOutCubic" as default
    easing: (t) => t,

    // DOM element that should be scrolled
    // Example: document.querySelector('#element-to-scroll'),
    // elementToScroll: window,

    // Horizontal scroll offset
    // Practical when you are scrolling to a DOM element and want to add some padding
    horizontalOffset: 0,

    // Maximum duration of the scroll animation
    maxDuration: 1500,

    // Minimum duration of the scroll animation
    minDuration: 500,

    // Duration of the scroll per 1000px
    speed: 500,

    // Vertical scroll offset
    // Practical when you are scrolling to a DOM element and want to add some padding
    verticalOffset: 0
};

export function scrollTo(offset, window) {
    options.elementToScroll = window;
    return animateScrollTo(offset - 150, options);
}
