import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TableInformationMobileColumn = ({ rendering, repeatingFields }) => {
    const titles = repeatingFields;

    return (
        <div className="table-information-mobile-row">
            {rendering?.placeholders['eon-table-cell-columns-2'][0].fields
                .cellContent?.value && (
                <div className="table-information-mobile-row main">
                    <Placeholder
                        name="eon-table-cell-columns-1"
                        rendering={rendering}
                    />
                </div>
            )}
            <div className="table-information-mobile-row secondary">
                <eon-ui-rte-renderer
                    content={
                        titles['eon-table-cell-columns-2'][0].fields.cellContent
                            .value
                    }
                ></eon-ui-rte-renderer>
                <Placeholder
                    name="eon-table-cell-columns-2"
                    rendering={rendering}
                />
            </div>
            <div className="table-information-mobile-row secondary">
                <eon-ui-rte-renderer
                    content={
                        titles['eon-table-cell-columns-3'][0].fields.cellContent
                            .value
                    }
                ></eon-ui-rte-renderer>
                <Placeholder
                    name="eon-table-cell-columns-3"
                    rendering={rendering}
                />
            </div>
            {rendering.params.rows > 4 && (
                <div className="table-information-mobile-row secondary">
                    <eon-ui-rte-renderer
                        content={
                            titles['eon-table-cell-columns-4'][0].fields
                                .cellContent.value
                        }
                    ></eon-ui-rte-renderer>
                    <Placeholder
                        name="eon-table-cell-columns-4"
                        rendering={rendering}
                    />
                </div>
            )}
        </div>
    );
};

export default TableInformationMobileColumn;
