import React, { useRef, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useClickOutside } from '../../hooks/useClickOutside';
import config from '../../temp/config';

const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return (
        <div>
            {parts.map((part, i) => (
                <span
                    key={i}
                    style={
                        part.toLowerCase() === highlight.toLowerCase()
                            ? { fontWeight: 'bold', color: 'red' }
                            : {}
                    }
                >
                    {part}
                </span>
            ))}
        </div>
    );
};

const SearchResults = ({ fields, t }) => {
    const queryParams = useLocation().search;
    const phrase = new URLSearchParams(queryParams).get('phrase');
    const page = new URLSearchParams(queryParams).get('page');

    const [maxPage, setMaxPage] = useState(1);
    const filterRef = useRef(null);
    const pageSizeDropdown = useRef();
    const [data, setData] = useState(data);
    const [menuActive, setMenuState] = useState(true);
    const [dropdownActive, setDropDownState] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [filled, setActiveFill] = useState(undefined);

    const [active, setActive] = useState(null);
    const [searchQuery, setSearchQuery] = useState({
        phrase: phrase,
        page: page,
        sc_apikey: config.sitecoreApiKey,
        page: 1
    });

    useClickOutside(pageSizeDropdown, () => setDropDownState(false));

    useEffect(() => {
        handleLoad();
    }, [searchQuery]);

    useEffect(() => {
        const handleDataUpdate = ({ detail }) => {
            const tt = {
                phrase: detail,
                page: page,
                sc_apikey: config.sitecoreApiKey,
                page: 1
            };
            const query = Object.keys(tt)
                .map((key) => `${key}=${tt[key]}`)
                .join('&');
            updateSearch(query);
        };

        document.addEventListener('updateData', handleDataUpdate);

        return () => {
            document.removeEventListener('updateData', handleDataUpdate);
        };
    });

    const allText = t('Content|SearchResults|All');
    const filterResultsText = t('Content|SearchResults|Filter') ?? '';
    const fromText = t('Content|SearchResults|From') ?? '';
    const ofResultsText = t('Content|SearchResults|OfResults') ?? '';
    const resultsForPhraseText =
        t('Content|SearchResults|ResultsForPhrase') ?? '';
    const resultsOnPageText = t('Content|SearchResults|ResultsOnPage') ?? '';
    const filesText = t('Content|SearchResults|Files') ?? '';
    const pagesText = t('Content|SearchResults|Pages') ?? '';

    const handleLoad = async () => {
        searchQuery.phrase = searchQuery.phrase.replaceAll('#', '');
        const query = Object.keys(searchQuery)
            .map((key) => `${key}=${searchQuery[key]}`)
            .join('&');

        fetch(`${config.sitecoreApiHost}/api/eon/search?${query}`)
            .then((response) => response.json())
            .then((response) => {
                if (response.results === null) {
                    return;
                }

                setMaxPage(response.allPages);
                response.results.map((elem, index) => {
                    elem.highlightTitle = getHighlightedText(
                        elem.title,
                        searchQuery['phrase']
                    );
                    if (elem.highlightTitle == '') {
                        elem.highlightTitle = elem.title;
                    }
                });
                setData(response);
            })
            .catch((e) => console.log(e));
    };

    const categoryClickHandler = (e, index) => {
        const q = { ...searchQuery, categoryId: e };
        setSearchQuery(q);
        setActiveIndex(index);
        setActive();
    };

    const typeClickHandler = (e) => {
        const q = { ...searchQuery, type: e };
        setSearchQuery(q);
        setActiveFill(e);
    };

    const pageSizeClickHandler = (e) => {
        const q = { ...searchQuery, pageSize: e };
        setSearchQuery(q);
    };

    const pageClickHandler = (e) => {
        if (searchQuery.page + e <= maxPage && searchQuery.page + e > 0) {
            const q = { ...searchQuery, page: searchQuery.page + e };
            setSearchQuery(q);
        }
    };

    const updateSearch = (detail) => {
        let phrase = new URLSearchParams(detail).get('phrase');
        const query = { ...searchQuery, phrase };
        setSearchQuery(query);
    };

    const handleMenuToggle = () => setMenuState(!menuActive);
    const handleDropdownToggle = () => setDropDownState(!dropdownActive);

    return (
        <div>
            {data && (
                <div className="search-results">
                    <div className="search-results-text-filter">
                        <p className="results-number">
                            {resultsForPhraseText
                                .replaceAll('{results}', data.allResults)
                                .replaceAll('{phrase}', phrase)}
                        </p>
                        <div
                            ref={filterRef}
                            className="search-filter"
                            onClick={handleMenuToggle}
                        >
                            <eon-ui-icon
                                name="filter"
                                scheme="red"
                            ></eon-ui-icon>
                            <p className="filter-result-text">
                                {filterResultsText}
                            </p>
                            {!menuActive && (
                                <span className="chevron-up ">
                                    <eon-ui-icon
                                        scheme="red"
                                        name="chevron_small_down"
                                    ></eon-ui-icon>
                                </span>
                            )}
                            {menuActive && (
                                <span className="chevron-down ">
                                    <eon-ui-icon
                                        scheme="red"
                                        name="chevron_small_up"
                                    ></eon-ui-icon>
                                </span>
                            )}
                        </div>
                    </div>
                    {menuActive && (
                        <div>
                            <div className="search-categories">
                                <button
                                    className={`search-category  ${
                                        activeIndex === active
                                            ? 'activeTab'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        categoryClickHandler(undefined)
                                    }
                                >
                                    {allText}
                                </button>
                                {fields &&
                                    fields.categories &&
                                    fields.categories.map((c, index) => (
                                        <button
                                            key={`category-${c.id}`}
                                            onClick={() =>
                                                categoryClickHandler(
                                                    c.id,
                                                    index
                                                )
                                            }
                                            className={`search-category ${
                                                activeIndex === index
                                                    ? 'activeTab'
                                                    : ''
                                            }`}
                                        >
                                            {c.name}
                                        </button>
                                    ))}
                                <div className="border"></div>
                            </div>
                            <div className="search-types">
                                <button
                                    className={`search-type-item ${
                                        filled === undefined ? 'filled' : ''
                                    }`}
                                    onClick={() => typeClickHandler(undefined)}
                                >
                                    {allText}
                                </button>
                                <button
                                    className={`search-type-item ${
                                        filled === 2 ? 'filled' : ''
                                    }`}
                                    onClick={() => typeClickHandler(2)}
                                >
                                    {filesText}
                                </button>
                                <button
                                    className={`search-type-item ${
                                        filled === 1 ? 'filled' : ''
                                    }`}
                                    onClick={() => typeClickHandler(1)}
                                >
                                    {pagesText}
                                </button>
                            </div>
                        </div>
                    )}
                    <ul className="search-result-cards">
                        {data.results &&
                            data.results.map((r, index) => {
                                const breadcrumbs = r.breadcrumbs.filter(
                                    (n) => n
                                );
                                const lastCrumb = breadcrumbs.pop();

                                return (
                                    <li className="result-card" key={index}>
                                        {r.type === 1 && lastCrumb && (
                                            <div className="results-breadcrumbs">
                                                <div className="results-card-header">
                                                    {breadcrumbs.map(
                                                        (el, key) => (
                                                            <span
                                                                key={key}
                                                                className="breads"
                                                            >
                                                                {el}
                                                                <eon-ui-icon
                                                                    scheme="darkgrey"
                                                                    name="chevron_small_right"
                                                                    size="small"
                                                                ></eon-ui-icon>
                                                            </span>
                                                        )
                                                    )}
                                                    <a
                                                        href={r.url}
                                                        className="breads"
                                                    >
                                                        {lastCrumb}
                                                    </a>
                                                </div>
                                                <p className="result-card-header">
                                                    <a
                                                        href={r.url}
                                                        target={r.target}
                                                        className="page"
                                                    >
                                                        {r.highlightTitle}
                                                    </a>
                                                </p>
                                                <p
                                                    className="result-card-content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: r.content
                                                    }}
                                                ></p>
                                            </div>
                                        )}
                                        {r.type === 2 && (
                                            <div className="results-breadcrumbs">
                                                <div className="results-card-header document">
                                                    <span>
                                                        <eon-ui-icon
                                                            scheme="darkgrey"
                                                            name="icon_file"
                                                            size="small"
                                                        ></eon-ui-icon>
                                                    </span>

                                                    <a
                                                        href={r.url}
                                                        target={r.target}
                                                        className="document"
                                                    >
                                                        {r.highlightTitle}
                                                    </a>
                                                </div>
                                                <div
                                                    className="results-card-content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: r.content
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                    <div className="search-result-page-configuration">
                        <div className="result-page-text">
                            <div
                                className="result-page-text-wrapper"
                                ref={pageSizeDropdown}
                                onClick={handleDropdownToggle}
                            >
                                {resultsOnPageText}:
                                <span className="result-page-size-number">
                                    {data.pageSize}
                                    {dropdownActive && (
                                        <div className="page-size-options">
                                            <button
                                                className="page-size-option"
                                                onClick={() =>
                                                    pageSizeClickHandler(5)
                                                }
                                            >
                                                5
                                            </button>
                                            <button
                                                className="page-size-option"
                                                onClick={() =>
                                                    pageSizeClickHandler(10)
                                                }
                                            >
                                                10
                                            </button>
                                            <button
                                                className="page-size-option"
                                                onClick={() =>
                                                    pageSizeClickHandler(15)
                                                }
                                            >
                                                15
                                            </button>
                                        </div>
                                    )}
                                </span>
                                <span className="icon-dropdown">
                                    <eon-ui-icon
                                        scheme="middlegrey"
                                        name="chevron_small_down"
                                    ></eon-ui-icon>
                                </span>
                            </div>
                        </div>
                        <div className="result-page-size mobile-invisible">
                            <p>
                                {(data.currentPage - 1) * data.pageSize}-
                                {Math.min(
                                    data.currentPage * data.pageSize,
                                    data.allResults
                                )}
                                <span className="result-from-text">
                                    {fromText}
                                </span>
                                {data.allResults} {ofResultsText}
                            </p>
                        </div>

                        <div className="result-current-page">
                            <button
                                className="current-page-arrow-button left"
                                onClick={() => pageClickHandler(-1)}
                            >
                                <eon-ui-icon
                                    scheme="white"
                                    name="chevron_small_left"
                                ></eon-ui-icon>
                            </button>
                            <p>
                                <span className="result-current-page-number">
                                    {data.currentPage}
                                </span>
                                {fromText}
                                <span className="result-from-pages">
                                    {data.allPages}
                                </span>
                            </p>
                            <button
                                className="current-page-arrow-button right"
                                onClick={() => pageClickHandler(1)}
                            >
                                <eon-ui-icon
                                    scheme="white"
                                    name="chevron_small_right"
                                ></eon-ui-icon>
                            </button>
                        </div>
                        <div className="result-page-size mobile-visible">
                            <p>
                                {(data.currentPage - 1) * data.pageSize}-
                                {Math.min(
                                    data.currentPage * data.pageSize,
                                    data.allResults
                                )}
                                <span className="result-from-text">
                                    {fromText}
                                </span>
                                {data.allResults} {ofResultsText}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(SearchResults);
