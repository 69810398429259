import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const CategoryTeaserTiles = ({ rendering, params }) => {
    let placeholdersKeys = Object.keys(rendering.placeholders);
    const isMobile = useMobileCheck();

    if (!placeholdersKeys) {
        return '';
    }
    return (
        <div className="category-teaser-tiles">
            <div className="category-teaser-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <eon-ui-grid-control
                grid="grid__d3-3-3-3__t6-6-6-6"
                same-height="true"
            >
                {placeholdersKeys
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder
                                name={p}
                                rendering={rendering}
                                params={params}
                                ratio={isMobile ? 'ratio-16x9' : 'ratio-1x1'}
                            />
                        </eon-ui-xx>
                    ))}
            </eon-ui-grid-control>
        </div>
    );
};

export default CategoryTeaserTiles;
