import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const TableInformationColumn = ({ rendering }) => {
    return (
        <div>
            {rendering.placeholders['eon-table-cell-columns-1'].length > 0 ? (
                <div className="table-information-row main">
                    <Placeholder
                        name="eon-table-cell-columns-1"
                        rendering={rendering}
                    />
                </div>
            ) : null}

            <div className="table-information-row secondary">
                <Placeholder
                    name="eon-table-cell-columns-2"
                    rendering={rendering}
                />
            </div>
            <div className="table-information-row secondary">
                <Placeholder
                    name="eon-table-cell-columns-3"
                    rendering={rendering}
                />
            </div>
            {rendering.params.rows > 4 && (
                <div className="table-information-row secondary">
                    <Placeholder
                        name="eon-table-cell-columns-4"
                        rendering={rendering}
                    />
                </div>
            )}
        </div>
    );
};

export default withSitecoreContext()(TableInformationColumn);
