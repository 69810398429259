import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useDayAheadMarket } from './useDayAheadMarket.jsx';
import { formatDate } from './utils';
import DesktopTable from './components/DesktopTable.jsx';
import MobileTable from './components/MobileTable.jsx';

const DayAheadMarket = ({ t }) => {
    const {
        dateRef,
        date,
        minDate,
        maxDate,
        data,
        isLoading,
        isError,
        isDatePickerDisabled,
        isPrevSessionDisabled,
        isNextSessionDisabled,
        handleNextSession,
        handlePreviousSession
    } = useDayAheadMarket();

    if (isError) {
        return (
            <eon-ui-form-message
                message={t('DayAheadMarket|Error')}
                type="error"
            />
        );
    }

    return (
        <div className="day-ahead-market">
            <div className="form-container">
                <div className="date-picker-container">
                    <span className="date-picker-header">
                        {t('DayAheadMarket|DatePicker|Header')}
                    </span>
                    <span className="date-picker-input">
                        <eon-ui-date-picker
                            ref={dateRef}
                            label={t('DayAheadMarket|DatePicker|Label')}
                            lang="pl"
                            date-format="yyyy-MM-dd"
                            min={minDate ? formatDate(minDate) : ''}
                            max={maxDate ? formatDate(maxDate) : ''}
                            selected-date={date ? formatDate(date) : ''}
                            disabled={isDatePickerDisabled}
                        />
                    </span>
                </div>
                <div className="buttons-container">
                    <eon-ui-button
                        type="button"
                        text={t('DayAheadMarket|DatePicker|PreviousButton')}
                        disabled={isPrevSessionDisabled}
                        full-width-on-mobile="true"
                        toggle-full-width-on-mobile="true"
                        onClick={handlePreviousSession}
                    />
                    <eon-ui-button
                        type="button"
                        text={t('DayAheadMarket|DatePicker|NextButton')}
                        disabled={isNextSessionDisabled}
                        full-width-on-mobile="true"
                        toggle-full-width-on-mobile="true"
                        onClick={handleNextSession}
                    />
                </div>
            </div>

            <div className="subheading">
                <h3>{t('DayAheadMarket|Subheading')}</h3>
                {data && (
                    <span>
                        {t('DayAheadMarket|Subheading|DeliveryInfo')}{' '}
                        {data.DeliveryDate}
                    </span>
                )}
            </div>

            {isLoading ? (
                <div className="preloader-wrapper">
                    <eon-ui-preloader-circle />
                </div>
            ) : (
                data && (
                    <>
                        <DesktopTable data={data} />
                        <MobileTable data={data} />
                    </>
                )
            )}
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(DayAheadMarket));
