import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ProductTableSection = ({ rendering, sitecoreContext, params }) => {
    const placeholders = Object.keys(rendering.placeholders);

    if (!placeholders) {
        return '';
    }

    return (
        <div className="product-table-section">
            {sitecoreContext.pageEditing && <p>Product Table Section</p>}
            <div className="single-product-section__cells">
                <Placeholder
                    name="eon-product-table-section"
                    rendering={rendering}
                    params={params}
                />
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductTableSection);
