const FieldStateHelper = {
    sendStateToForm(field, setStateHandler, sendToForm, data, onStateUpdated) {
        const state = {
            fieldId: field.model.conditionSettings.fieldKey,
            setState: (d) => setStateHandler(d),
            data: data,
            onStateUpdated
        };
        sendToForm(state);
    }
};

export default FieldStateHelper;
