import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const QuickLinks = ({ rendering, fields }) => {
    const buttonText = fields?.cta?.value?.text ?? '';
    const buttonHref = fields?.cta?.value?.href ?? '';
    const buttonTarget = fields?.cta?.value?.target ?? '';

    const showButton =
        buttonText && buttonHref ? (
            <eon-ui-button
                text={buttonText}
                href={buttonHref}
                target={buttonTarget}
                use-as-link
                full-width-on-mobile
            />
        ) : (
            ''
        );

    return (
        <div className="quick-links">
            <eon-ui-grid-control
                grid="grid__d3-9__t4-8"
                content-position-col-2="center"
            >
                <eon-ui-xx slot="column-1">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </eon-ui-xx>
                <eon-ui-xx slot="column-2">
                    <eon-ui-background color="eon-ultralightgrey">
                        <div className="quick-link-array">
                            <Placeholder
                                name="eon-quick-links-section"
                                rendering={rendering}
                            />
                        </div>
                        {showButton}
                    </eon-ui-background>
                </eon-ui-xx>
            </eon-ui-grid-control>
        </div>
    );
};

export default QuickLinks;
