import React, { useRef, useState, useEffect } from 'react';
import FieldStateHelper from '../fieldStateHelper.js';
import ModalOnAction from "../../../ModalOnAction";

const valueChangedEvent = 'valueChanged';

const CheckboxList = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    errors,
    onChange,
    rendering
}) => {
    const ref = useRef();
    const checkedRef = useRef();
    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [showModalId, setShowModalId] = useState(null);
    const [checked, setChecked] = useState([]);
    checkedRef.current = checked || [];

    const handleValueChange = (e) => {
        e.preventDefault();
        if (e.target.checked) {
            setChecked([...checkedRef.current, e.target.value] );
        } else {
            setChecked(checkedRef.current.filter(function(e) { return e !== e.target.value }));
        }
        if (field.model.modalOnValueSelection) {
            setShowModalId(e.target.getAttribute("itemid"));
        }
    };

    useEffect(() => {
        var value = [...checked];
        field.model.value = value;

        const errorMessages = [];
        let valid = true;
        if (field.model.required && value.length === 0) {
            valid = false;
        }
        onChange(field.valueField.name, checked, valid, errorMessages);
        conditionsHandler(field);
    }, [checked]);

    useEffect(() => {
        const elements = ref.current.querySelectorAll('eon-ui-checkbox') || [];
        elements.forEach(element => element.addEventListener(valueChangedEvent, handleValueChange));

        return () => {
            elements.forEach(element => element.removeEventListener(valueChangedEvent, handleValueChange));
        };
    }, [ref.current]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s)
        );
    }, [field, setData, stateHandler]);

    return (
        <div ref={ref} className={'field-wrapper d-flex flex-column' + (data.hidden ? ' hidden' : '')}>

            {field.model.items.map((item, i) => {
                return (
                    <>
                        <ModalOnAction displayButton={field.model.modalOnValueSelection} rendering={rendering} uid={item.itemId} buttonTitle={'Modal on radio changed'} show={item.itemId == showModalId} />
                        <eon-ui-checkbox
                            name={field.valueField.name}
                            id={field.valueField.name + i}
                            label={item.text}
                            value={item.value}
                            disabled={item.disabled ?? false}
                            invalid={errorMsgs.length > 0}
                            error-message={errorMsgs}
                            itemId={item.itemId}
                        ></eon-ui-checkbox>
                    </>
                );
            })}
        </div>
    );
};

export default CheckboxList;
