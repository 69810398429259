import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const OffsetTeaser = ({ rendering, fields, params }) => {
    const isMobile = useMobileCheck();

    const offsetImage = fields?.image?.value?.src;
    const imageAltText = fields?.image?.value?.alt;
    const imagePosition = params.imagePosition;
    const highlightColor = params.highlightColor;
    const backgroundColor = params.backgroundColor;

    const Image = ({ rightAlign }) => (
        <div
            className={`offset-teaser-image ${
                rightAlign ? 'image-right' : 'image-left'
            }`}
        >
            <eon-ui-background color={highlightColor} full-height>
                <div
                    className="hightlight-bar"
                    style={
                        backgroundColor
                            ? {
                                  backgroundColor: `var(--${highlightColor})`
                              }
                            : null
                    }
                />
            </eon-ui-background>
            {fields?.image?.value?.svg !== undefined && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: fields?.image?.value?.svg
                    }}
                />
            )}
            {fields?.image?.value?.svg === undefined && (
                <eon-ui-image
                    src={offsetImage}
                    alt={imageAltText}
                    title="Image title"
                    width="100%"
                    height="auto"
                />
            )}
        </div>
    );

    const ImageLeftSide = () => {
        return (
            <>
                <div className="offset-teaser-head">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="offset-teaser-container">
                    <Image />

                    <div className="offset-teaser-text-content content-right">
                        <eon-ui-background color={backgroundColor} full-height>
                            <div className="offset-teaser-content">
                                <div className="offset-teaser-headline">
                                    {fields.title.value && (
                                        <eon-ui-headline
                                            text={fields.title.value}
                                            size="h3"
                                        />
                                    )}
                                </div>

                                <div className="offset-teaser-bullet-list">
                                    <Placeholder
                                        name="eon-offset-teaser-content"
                                        rendering={rendering}
                                    />
                                </div>

                                <div className="offset-teaser-links-wrapper">
                                    <Placeholder
                                        name="eon-links"
                                        rendering={rendering}
                                    />
                                </div>
                            </div>
                        </eon-ui-background>
                    </div>
                </div>
            </>
        );
    };

    const ImageRightSide = () => {
        return (
            <>
                <div className="offset-teaser-head">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="offset-teaser-container">
                    <div className="offset-teaser-text-content content-left">
                        <eon-ui-background color={backgroundColor} full-height>
                            <div className="offset-teaser-content">
                                <div className="offset-teaser-headline">
                                    {fields.title.value && (
                                        <eon-ui-headline
                                            text={fields.title.value}
                                            size="h3"
                                        />
                                    )}
                                </div>

                                <div className="offset-teaser-bullet-list">
                                    <Placeholder
                                        name="eon-offset-teaser-content"
                                        rendering={rendering}
                                    />
                                </div>

                                <div className="offset-teaser-links-wrapper">
                                    <Placeholder
                                        name="eon-links"
                                        rendering={rendering}
                                    />
                                </div>
                            </div>
                        </eon-ui-background>
                    </div>

                    <Image rightAlign />
                </div>
            </>
        );
    };

    const MobileView = () => {
        return (
            <>
                <div className="mobile-teaser-head">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <eon-ui-background
                    class="mobile-teaser"
                    color={backgroundColor}
                >
                    <div className="mobile-teaser-image">
                        <eon-ui-background color={highlightColor}>
                            <div
                                className="mobile-highlight-bar"
                                style={
                                    backgroundColor
                                        ? {
                                              backgroundColor: `var(--${highlightColor})`
                                          }
                                        : null
                                }
                            ></div>
                        </eon-ui-background>
                        {fields?.image?.value?.svg !== undefined && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: fields?.image?.value?.svg
                                }}
                            />
                        )}
                        {fields?.image?.value?.svg === undefined && (
                            <eon-ui-image
                                slot="image"
                                src={offsetImage}
                                alt={imageAltText}
                                title="Image title"
                                aspect-ratio="ratio-1x1"
                            />
                        )}
                    </div>

                    <div
                        className="mobile-teaser-content"
                        style={
                            backgroundColor
                                ? {
                                      backgroundColor: `var(--${backgroundColor})`
                                  }
                                : null
                        }
                    >
                        <div className="offset-teaser-headline">
                            {fields.title.value && (
                                <eon-ui-headline
                                    text={fields.title.value}
                                    size="h3"
                                />
                            )}
                        </div>
                        <Placeholder
                            name="eon-offset-teaser-content"
                            rendering={rendering}
                        />
                        <div className="mobile-teaser-links-wrapper">
                            <Placeholder
                                name="eon-links"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </eon-ui-background>
            </>
        );
    };

    return (
        <div className="offset-teaser">
            <div className="offset-teaser-wrapper">
                {imagePosition !== 'right' && !isMobile && <ImageLeftSide />}

                {imagePosition === 'right' && !isMobile && <ImageRightSide />}

                {isMobile && <MobileView />}
            </div>
        </div>
    );
};

export default OffsetTeaser;
