import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const CardTeaserBulletPoints = ({ rendering }) => {
    var placeholders = Object.keys(rendering.placeholders);
    if (!placeholders) {
        return '';
    }

    return (
        <div className="card-teaser-bullet-points">
            <div className="card-teaser-bullet-points-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            <div className="card-teser-bullet-column-wrapper">
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <div
                            className="card-teaser-bullet-column-grow"
                            key={key}
                        >
                            <Placeholder name={p} rendering={rendering} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default withSitecoreContext()(CardTeaserBulletPoints);
