import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';

const ParametersField = ({ field, rendering, sitecoreContext }) => {
    const location = useLocation();

    const params = {
        conversionId: rendering.fields.conversionId,
        campaignId: rendering.params.campaignId,
        productOrganizationalUnit: rendering.params.productOrganizationalUnit,
        itemId: sitecoreContext.itemId
    };

    let combinedParams = Object.keys(params)
        .filter((key) => params[key])
        .map((key) => `${key}=${params[key]}`)
        .join('&');

    if (location?.search) {
        combinedParams = `${location.search}&${combinedParams}`;
    }

    field.model.value = combinedParams;

    return '';
};

export default withSitecoreContext()(ParametersField);
