import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const MainNavigationSection = ({ rendering }) => {
    //this component is used only in edit mode, in normal mode data is being taken directly from placeholders
    const EditMode = () => {
        return (
            <div className="eon-header edit-mode">
                {rendering.fields.link && (
                    <>
                        <div className="meta-nav-container ">
                            <div className="meta-nav-container-inner">
                                <div className="meta-nav">
                                    <a
                                        className="meta-nav-item "
                                        href={rendering.fields.link.href}
                                        target={rendering.fields.link.target}
                                    >
                                        {rendering.fields.link.text}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="primary-nav-container fixed">
                            <div className="primary-nav-container-inner">
                                <div className="primary-nav">
                                    {rendering.fields.link.subItems.map(
                                        (si, i) => {
                                            if (!si.isHiddenOnDesktop) {
                                                return (
                                                    <a
                                                        className="primary-nav-item"
                                                        href={si.href}
                                                        target={si.target}
                                                        key={`$subitem${i}`}
                                                    >
                                                        {si.text}
                                                    </a>
                                                );
                                            }
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        {rendering.fields.link.subItems.map((si, i) => {
                            if (!si.isHiddenOnDesktop) {
                                return (
                                    si.hasFlyout && (
                                        <div key={`flyout-${si.id}`}>
                                            <p>{`eon-navigation-flyout-tabs-{${si.id}}`}</p>
                                            <Placeholder
                                                name={`eon-navigation-flyout-tabs-{${si.id}}`}
                                                rendering={rendering}
                                                params={{
                                                    flyoutId: `sec-nav-${i + 1}`,
                                                    linkHref: si.href,
                                                    linkTarget: si.target,
                                                    linkText: si.text
                                                }}
                                            />
                                        </div>
                                    ) && (
                                        <div
                                            id="secondary-navigation"
                                            className="secondary-nav-container act"
                                        >
                                            <div className="secondary-nav-container-inner">
                                                <p></p>
                                                <Placeholder
                                                    name={`eon-navigation-flyout-tabs-{${si.id}}`}
                                                    rendering={rendering}
                                                />
                                            </div>
                                        </div>
                                    )
                                );
                            }
                        })}
                    </>
                )}
            </div>
        );
    };

    return <EditMode />;
};

export default withSitecoreContext()(withTranslation()(MainNavigationSection));
