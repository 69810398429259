import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ProcessStepsSectionTwoContainer = ({
    rendering,
    params,
    sitecoreContext
}) => {
    var placeholders = Object.keys(rendering.placeholders);
    const background = params.background;
    const pageEditor = sitecoreContext.pageEditing;

    if (!placeholders) {
        return '';
    }
    return (
        <div className={`process-steps-container ${background}`}>
            {pageEditor && <p>Process Step Section Two Container</p>}
            <eon-ui-background color={background} full-height>
                <div className="process-steps-container__wrapper">
                    <div className="process-steps-container__section-two-headline">
                        <Placeholder
                            name="eon-headline"
                            rendering={rendering}
                        />
                    </div>
                    <div className="process-steps-container__section-two">
                        <Placeholder
                            name="eon-process-steps-section-two"
                            rendering={rendering}
                        />
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(ProcessStepsSectionTwoContainer);
