import React, { useEffect, useState } from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import modalSizeFunction from '../../lib/ModalSizeResolver';

const ModalOnAction = ({ uid, name, rendering, modalSize, sitecoreContext, buttonTitle, show, displayButton }) => {
    const modalSizeParameter = modalSize ?? 'S';
    name = name || `eon-modal-dynamic-${uid ? uid.toLowerCase() : ''}` || 'eon-modal';
    const onShow = () => {
        const openModalElement = document.querySelector("[modal-id=\"" + uid + "\"]");
        if (openModalElement) {
            openModalElement.setAttribute("visible", "true");
        }
    };

    useEffect(() => {
        if (show) {
            onShow();
        }
    }, [show]);


    if (!rendering.placeholders[name] || (!sitecoreContext.pageEditing && !rendering.placeholders[name].length > 0)) {
        return '';
    }

    return (
        <div className="modal-wrapper">
            {sitecoreContext.pageEditing &&
                <>
                    {!buttonTitle && displayButton !== false &&
                        <>Action was not configured. This modal cannot work without action. Try ModalTimeout instead.</>
                    }
                    {displayButton &&
                        <eon-ui-button
                            text={buttonTitle}
                            rank={'primary'}
                            full-width-on-mobile="true"
                            toggle-full-width-on-mobile="true"
                            open-modal-id={uid}
                        ></eon-ui-button>
                    }
                </>
            }
            <eon-ui-modal
                modal-id={uid}
                show-closing-x="true"
                full-width="true"
                full-width-width={modalSizeFunction(modalSizeParameter)}
            >
                <Placeholder name={name} rendering={rendering} />
            </eon-ui-modal>
        </div>
    );
};

export const ModalListEditor = withSitecoreContext()(({sitecoreContext, modals}) => {
    const [selectedId, setSelectedId] = useState();
    const onChange = (e) => {
        setSelectedId(e.target.value);
    }
    const selectedText = selectedId ? modals.find(modal => modal.id === selectedId)?.label : '';
    if (!sitecoreContext.pageEditing) {
        return '';
    }
    return <div className='d-flex'>
        <span className={'pb-5'}/>
        <select name={'test'} onChange={onChange} className={'mr-3'}>
            <option value={''}>---</option>
            {
                modals.map((modal) => {
                    return <option value={modal.id}>{modal.label}</option>
                })
            }
        </select>
        {selectedId &&
        <eon-ui-button
            text={`Show modal for placeholder ${selectedText}`}
            rank={'primary'}
            full-width-on-mobile="true"
            toggle-full-width-on-mobile="true"
            open-modal-id={selectedId}
        ></eon-ui-button>
        }
    </div>
});

export const useModal = (defaultModalState, oneTime) => {
    const [isShow, setShow] = useState(defaultModalState ?? false);
    useEffect(() => {
        if (isShow && oneTime === false) {
            const timeoutId = setTimeout(() => setShow(false), 100);
            return () => clearTimeout(timeoutId);
        }
    }, [isShow]);
    const showModal = () => {
        setShow(true);
    }

    return {
        showModal,
        isShow
    }
}

export default withSitecoreContext()(ModalOnAction);
