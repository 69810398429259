import React, { useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const File = ({ fields }) => {
    const defaultScheme = 'darkgrey';

    const [scheme, setScheme] = useState(defaultScheme);
    const [isHover, setHover] = useState(false);
    const linkHref = fields?.file?.value?.src;
    const altText = fields?.file?.value?.title;
    const icon = fields?.icon?.value;
    const description =
        fields?.description?.value !== ''
            ? fields?.description?.value
            : fields?.file?.value?.displayName;
    const downloadText = fields?.downloadText?.value ?? '';
    return (
        <div
            className="file"
            onMouseOver={() => {
                setScheme('red');
                setHover(true);
            }}
            onMouseLeave={() => {
                setScheme(defaultScheme);
                setHover(false);
            }}
        >
            <a
                className="file__download"
                text={altText}
                title={description}
                href={linkHref}
                target="_blank"
                rel="noopener noreferrer"
            >
                <eon-ui-link
                    text={description}
                    class="hydrated"
                    scheme={scheme}
                    size="normal"
                    force-hover-style={isHover}
                    hide-icon={true}
                />
                <eon-ui-link
                    text={downloadText}
                    title={description}
                    icon={icon}
                    force-hover-style={isHover}
                    hide-line-on-hover={true}
                    hide-text-mobile={true}
                />
            </a>
            <hr></hr>
        </div>
    );
};

export default withSitecoreContext()(File);
