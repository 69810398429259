import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export const LoginFormEditor = ({ rendering, fields, t }) => {
    if (!fields) {
        return '';
    }

    const emailPlaceholder = fields.emailPlaceholder?.value;
    const passwordPlaceholder = fields.passwordPlaceholder?.value;
    const emailLabel = fields.emailLabel?.value ?? 'email';
    const passwordLabel = fields.passwordLabel?.value ?? 'password';

    return (
        <div className="flyout-menu-login" style={{ position: 'relative' }}>
            <div className="login-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <div className="field-wrapper email">
                <eon-ui-input
                    name="email"
                    type="email"
                    message-icon=""
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                />
            </div>

            <div className="field-wrapper password">
                <eon-ui-input
                    name="password"
                    type="password"
                    message-icon=""
                    label={passwordLabel}
                    placeholder={passwordPlaceholder}
                />
            </div>

            <div className="button-wrapper">
                <eon-ui-button
                    text={t('Content|ConfirmLoginButton')}
                    rank="primary"
                />
            </div>

            <div className="links-wrapper">
                <Placeholder name="eon-links" rendering={rendering} />
            </div>
        </div>
    );
};
