import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gridStyle from '../../lib/GridControlStyleResolver.js';
import Link from '../Link/index.js';

const Footer = ({ sitecoreContext, rendering }) => {
    const fields = rendering.fields;
    const logoImage = fields.logo.value;
    const copyrightText = fields.copyrights.value;
    const pageEditing = sitecoreContext && sitecoreContext.pageEditing;

    const placeholdersKeys = Object.keys(rendering.placeholders);
    const columns = placeholdersKeys?.filter((x) => x.includes('column')) || [];

    return (
        <div className="footer">
            <div className="footerSection">
                <div className="footerSectionContainer">
                    <eon-ui-grid-control grid={gridStyle(columns.length)} same-height remove-horizontal-gutter>
                        {columns.map((p, key) => (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                />
                            </eon-ui-xx>
                        ))}
                    </eon-ui-grid-control>
                </div>
            </div>
            <eon-ui-footer-bar copyright={copyrightText} edit={true}>
                {pageEditing && (
                    <>
                        <div className="social-icons" slot="social-placeholder">
                            <Placeholder
                                name="eon-footer-social-icons"
                                rendering={rendering}
                            />
                        </div>
                        <div className="bottom-links" slot="links">
                            <Placeholder
                                name="eon-footer-links"
                                rendering={rendering}
                                size="small"
                                hideIcon={true}
                                scheme="darkgrey"
                                slot="links"
                            />
                        </div>
                    </>
                )}
                {!pageEditing && (
                    <>
                        <Placeholder
                            name="eon-footer-social-icons"
                            rendering={rendering}
                        />
                        {rendering.placeholders['eon-footer-links'].map(
                            (link, key) => (
                                <Link
                                    slot="links"
                                    key={`footer-link-${key}`}
                                    fields={link.fields}
                                    sitecoreContext={sitecoreContext}
                                    size="small"
                                    hideIcon={true}
                                    scheme="darkgrey"
                                />
                            )
                        )}
                    </>
                )}

                <eon-ui-image
                    src={logoImage.src}
                    alt={logoImage.alt}
                    slot="logo"
                />
            </eon-ui-footer-bar>
        </div>
    );
};

export default withSitecoreContext()(Footer);
