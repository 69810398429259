import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const TextTeaserTiles = ({ rendering, params }) => {
    const isMobile = useMobileCheck();
    const placeholders = Object.keys(rendering.placeholders);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        arrows: false
    };

    if (!placeholders) {
        return '';
    }
    return (
        <div className="text-teaser-tiles">
            <div className="headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>
            {!isMobile && (
                <div className="text-teaser-tiles-grid">
                    {placeholders
                        .filter((x) => x.includes('-columns'))
                        .map((p, key) => (
                            <Placeholder
                                key={key}
                                name={p}
                                rendering={rendering}
                                params={params}
                            />
                        ))}
                </div>
            )}
            {isMobile && (
                <Slider {...settings}>
                    {placeholders
                        .filter((x) => x.includes('-columns'))
                        .map((p, key) => (
                            <div key={`text-teaser-tile-part-slide-${key}`}>
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                    params={params}
                                />
                            </div>
                        ))}
                    <div className="ghost-card" />
                </Slider>
            )}
        </div>
    );
};

export default withSitecoreContext()(TextTeaserTiles);
