import React from 'react';
import { useMobileCheck } from '../../../hooks/useMobileCheck';
import RouteLink from '../../Route/RouteLink/index.js';
import config from '../../../temp/config';

const Article = ({ article }) => {
    const isMobile = useMobileCheck();

    const img =
        article.imageHref ||
        `${config.sitecoreApiHost}/-/media/Feature/Articles/ArticleTeaserDefaultImage.ashx?h=506&iar=0&w=800&hash=2FF280A3AD55242A224690166C16290B`;

    return (
        <div className="article">
            <eon-ui-image
                object-fit="cover"
                aspect-ratio={isMobile ? 'ratio-2x1' : 'ratio-16x9'}
                src={img}
                alt={article.imgAlt}
            />
            <div className="content-wrapper">
                {article.showTags && article.tags && (
                    <div className="tags-container">
                        {article.tags.map((tag, index) => {
                            return (
                                <div key={index} className="offer-teaser-tag">
                                    {tag}
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className="headline-wrapper">
                    <eon-ui-headline text={article.title} size="h4" />
                </div>
                <RouteLink href={article.href} text="Zobacz"></RouteLink>
            </div>
        </div>
    );
};

export default Article;
