import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const VideoYouTube = ({ sitecoreContext, fields }) => {
    const videoUrl = fields?.youTubeId?.value ?? fields?.video?.videoUrl;
    const videoTitle = fields?.video?.videoTitle;
    const previewImageUrl = fields?.previewImage?.value?.src ?? '';
    const previewImageAlt = fields?.previewImage?.value?.alt ?? '';

    return (
        <div>
            {sitecoreContext.pageEditing && <p>YouTube File </p>}
            <eon-ui-videoplayer
                src={videoUrl}
                type="youtube"
                poster-image="true"
                video-title={videoTitle}
                video-url={videoUrl}
            >
                <eon-ui-image
                    alt={previewImageAlt}
                    src={previewImageUrl}
                ></eon-ui-image>
            </eon-ui-videoplayer>
        </div>
    );
};

export default withSitecoreContext()(VideoYouTube);
