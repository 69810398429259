import React from 'react';
const ImageInRow = ({ fields, columns }) => {
    if (!fields) {
        return '';
    }
    const image = fields.image?.value;
    const fullName = fields.fullName?.value;
    const position = fields.position?.value;
    const link = fields.link?.value;

    if (!image.src) {
        return '';
    }

    return (
        <div className="image-in-row">
            <div
                className={`image-in-row_image ${
                    columns > 3 ? 'four' : 'three'
                }`}
            >
                <eon-ui-image
                    src={image.src}
                    title="My image"
                    alt={image.alt}
                    aspect-ratio={columns > 3 ? 'ratio-3x4' : 'ratio-1x1'}
                    lazy
                />
            </div>

            <div className="image-in-row_content">
                <div className="image-in-row_header">
                    <eon-ui-headline size="h4" text={fullName} />
                </div>
                <div className="image-in-row_description">
                    <eon-ui-text
                        text-style="rtf"
                        font-weight="500"
                        dangerouslySetInnerHTML={{
                            __html: position
                        }}
                    />
                </div>

                {link?.text && (
                    <div className="image-in-row_link">
                        <eon-ui-link
                            href={link?.href}
                            target={link?.target}
                            text={link?.text}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageInRow;
