import React from 'react';
import {
    withSitecoreContext,
    Placeholder
} from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

export const getTableStyle = (columns) => {
    switch (columns) {
        case 1:
            return 'grid__d12__t12';
        case 2:
            return 'grid__d6-6__t6-6_';
        case 3:
            return 'grid__d4-4-4__t4-4-4';
        case 4:
            return 'grid__d3-3-3-3__t6-6-6-6__p6-6-6-6';
        default:
            return '';
    }
};

const InfoTiles = ({ rendering, params, sitecoreContext }) => {
    const isMobile = useMobileCheck();

    const placeholders = Object.keys(rendering.placeholders);
    const gridStyle = getTableStyle(Number(params.columns));
    const mobileTextAlignment = params.mobileTextAlignment ?? 'left';

    return (
        <div className="info-tiles">
            {sitecoreContext.pageEditing && <p>Info Tiles Component</p>}

            <div className="info-tiles-headline">
                <Placeholder name="eon-headline" rendering={rendering} />
            </div>

            <eon-ui-grid-control grid={gridStyle} full-height>
                {placeholders
                    .filter((x) => x.includes('column'))
                    .map((p, key) => (
                        <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                            <Placeholder
                                name={p}
                                rendering={rendering}
                                params={params}
                            />
                        </eon-ui-xx>
                    ))}
            </eon-ui-grid-control>

            <div
                className={`info-tiles-description ${
                    isMobile ? mobileTextAlignment : 'center'
                }`}
            >
                <Placeholder name="eon-paragraph" rendering={rendering} />
            </div>
        </div>
    );
};

export default withSitecoreContext()(InfoTiles);
