import React, {useEffect, useState} from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ModalOnAction, {useModal} from "../ModalOnAction";

const ModalTimeout = ({ rendering, sitecoreContext, params }) => {
    const {showModal, isShow} = useModal(false, true);
    const modalId = 'modalId' + rendering.uid;
    const modalSizeParameter = params.modalSize ?? 'S';
    const timeout = params.timeout * 1000;
    let timeoutId;

    useEffect(() => {
        if (!sitecoreContext.pageEditing) {
            timeoutId = setTimeout(showModal, timeout);
            return () => {
                clearTimeout(timeoutId);
            }
        }
    }, []);

    return <ModalOnAction displayButton={sitecoreContext.pageEditing} name={'eon-modal'} rendering={rendering} uid={modalId} modalSize={modalSizeParameter} buttonTitle={'Timeout modal edit'} show={isShow}/>
};

export default withSitecoreContext()(ModalTimeout);
