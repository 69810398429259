import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { Form as JssForm } from '@sitecore-jss/sitecore-jss-react-forms';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import FieldFactory from './fieldFactory.js';
import QmaticSuccess from './Fields/Qmatic/QmaticSuccess/index.js';
import Conditions from './Conditions/conditions.js';
import StepNavigation from './stepNavigation.js';
import { FormErrors } from './FormErrors.jsx';
import AnalyticsForFields from './FormAnalyticsFields.js';
import AnalyticsForFormSubmittedSuccessfully from './FormAnalyticsSubmission.js';
import QmaticSuccessButtons from './Fields/Qmatic/QmaticSuccessButtons';

const executeJsScript = (jsScript) => {
    if (!jsScript.functionName) {
        return;
    }
    const script = document.createElement('script');
    script.innerHTML = jsScript.scriptBody;
    document.body.appendChild(script);

    eval(jsScript.functionName)(jsScript.params);
};

function parseValidationErrorsForScroll(validationErrors) {
    let topmostElement = null;
    Object.keys(validationErrors).forEach((id) => {
        const domId = id.replace(/[^a-zA-Z0-9\-]/g, '_');
        const item = document.getElementById(domId);
        if (
            item &&
            (topmostElement === null ||
                topmostElement.offsetTop > item.offsetTop)
        ) {
            topmostElement = item;
        }
    });
    if (topmostElement !== null) {
        topmostElement.scrollIntoView({ behavior: 'smooth' });
    }
}
const disableSubmit = (formHolderRef, isDisabled) => {
    const formButton = formHolderRef.current.querySelector(
        'eon-ui-button[input-type=submit]'
    );

    if (formButton) {
        formButton.setAttribute('disabled', isDisabled);
    }
};

const Form = ({ fields, history, rendering, sitecoreContext }) => {
    const formHolderRef = useRef();
    const conversionId = fields.conversionId;
    fields = fields.fields;

    const getFlattenFields = (field) => {
        if (field?.fields) {
            var results = field.fields.flatMap((f) => getFlattenFields(f));
            if (field.model) {
                results.push(field);
            }

            return results;
        }

        return field || [];
    };

    let qmaticData;
    let qmaticSummary;

    const getFieldsBaseInfo = (fields) =>
        Object.assign(
            {},
            ...fields.map((f) => {
                if (!f) return;
                return {
                    [f.model.conditionSettings.fieldKey]: {
                        value: f.model.value ?? '',
                        type: f.model.fieldTypeItemId
                    }
                };
            })
        );

    const [showSuccess, setShowSuccess] = useState(false);

    const flattenFields = getFlattenFields(fields);
    const initialFieldValues = getFieldsBaseInfo(flattenFields);

    if (!sitecoreContext.pageEditing) {
        StepNavigation.markFirstSectionAsExpanded(initialFieldValues);
    } else {
        StepNavigation.expandAll(initialFieldValues);
    }
    if (!sitecoreContext.pageEditing) {
        flattenFields.forEach((field) =>
            Conditions.initialCheckConditions(field, initialFieldValues)
        );
    }

    const formFetcher = (formData, endpoint) => {
        disableSubmit(formHolderRef, true);
        return fetch(endpoint, {
            body: formData.toMultipartFormData(),
            method: 'post',
            // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
            credentials: 'include'
            // Browser set 'Content-Type' automatically with multipart/form-data;
            //boundary
        })
            .then((res) => {
                disableSubmit(formHolderRef, false);
                if (res.status === 200) {
                    //Sends form data no analytics in case the form was submitted successfully
                    AnalyticsForFormSubmittedSuccessfully(
                        sitecoreContext,
                        rendering,
                        conversionId,
                        fields.fields,
                        formData
                    );

                    setShowSuccess(true);
                }

                return res.json();
            })
            .then((json) => {
                const results = { ...json, nextForm: fields };
                if (qmaticSummary) {
                    fieldStates['qmatic-summary'].setState(qmaticSummary);

                    fieldStates['qmatic-fields'].setState({
                        appointmentId: json.additionalFields.appointmentId,
                        cancelPagePath: json.additionalFields.cancelPagePath,
                        ...qmaticSummary
                    });
                }

                if (json.success) {
                    executeJsScript({
                        functionName: json.additionalFields.functionName,
                        params: [
                            json.additionalFields.param1,
                            json.additionalFields.param2,
                            json.additionalFields.param3
                        ],
                        scriptBody: json.additionalFields.scriptBody
                    });
                }

                //Sends each field to GTM in any cases
                AnalyticsForFields(
                    sitecoreContext,
                    fields.fields,
                    results.validationErrors
                );
                parseValidationErrorsForScroll(results.validationErrors);

                return results;
            })
            .catch(() => {
                return {
                    success: false,
                    errors: ['Coś poszło nie tak. Proszę spróbować później']
                };
            });
    };

    const fieldStates = {};
    const stateHandler = (obj) => {
        if (!sitecoreContext.pageEditing) {
            fieldStates[obj.fieldId] = { ...obj };
        }
    };

    const conditionsHandler = (f) => {
        if (!sitecoreContext.pageEditing) {
            Conditions.checkConditions(f, initialFieldValues, fieldStates);
        }
    };

    const qmaticHandler = (obj, callback) => {
        var referencedFieldName = obj.model.referenceFieldName;
        var field = flattenFields.filter(
            (item) => item.model.name === referencedFieldName
        );
        var id = field[0]?.model?.conditionSettings?.fieldKey;
        if (id) {
            var found = fieldStates[id];
            if (found) {
                var data = {
                    ...found.data,
                    qmaticSettings: obj.qmaticSettings,
                    previousFieldValue: obj.model.value,
                    qmatic: true,
                    qmaticData: obj.qmaticData,
                    qmaticSummary: obj.qmaticSummary
                };

                if (found.onStateUpdated) {
                    found.onStateUpdated(callback);
                }
                found.setState(data);
            }
        }
    };

    const qmaticDataHandler = (data) => {
        qmaticData = data;
    };

    const getQmaticData = () => qmaticData;

    const qmaticSummaryHandler = (data) => {
        qmaticSummary = data;
    };

    const navigationHandler = (moveToNextSection) => {
        if (!sitecoreContext.pageEditing) {
            if (moveToNextSection) {
                StepNavigation.goToNextSection(initialFieldValues, fieldStates);
                return;
            }

            // go back
            StepNavigation.gotoPreviousSection(initialFieldValues, fieldStates);
        }
    };

    const stepHandler = (sectionNumber) => {
        if (!sitecoreContext.pageEditing) {
            fields.fields.forEach((f, index) => {
                if (f.fields && isNextQuestion(index, sectionNumber)) {
                    resetFormAfterCurrentQuestion(f.fields);
                }
            });
            sectionNumber -= 1; // string to array index conversion
            StepNavigation.goToSection(
                initialFieldValues,
                fieldStates,
                sectionNumber
            );
        }
    };

    const isNextQuestion = (index, sectionNumber) => {
        return index > sectionNumber;
    };

    const resetFormAfterCurrentQuestion = (fields) => {
        fields.forEach((f) => {
            if (f.model.value) {
                f.model.value = null;
            }
            if (f.model.innerText) {
                f.model.innerText = '';
            }
        });
    };

    const factory = FieldFactory.initFactory(
        initialFieldValues,
        conditionsHandler,
        navigationHandler,
        stepHandler,
        stateHandler,
        qmaticHandler,
        sitecoreContext,
        rendering,
        qmaticDataHandler,
        qmaticSummaryHandler,
        getQmaticData
    );

    if (sitecoreContext.pageEditing) {
        return (
            <>
                <div className="form-wrapper">
                    <div className="form" ref={formHolderRef}>
                        <JssForm
                            form={fields}
                            sitecoreApiHost={sitecoreApiHost}
                            sitecoreApiKey={sitecoreApiKey}
                            fieldFactory={factory}
                            onRedirect={(url) => history.push(url)}
                            formFetcher={formFetcher}
                            errorComponent={FormErrors}
                        />
                    </div>
                    <div className="form-success">
                        <p>Success message placeholder</p>
                        <div>
                            <Placeholder
                                name="eon-form-success"
                                rendering={rendering}
                            />
                        </div>
                        <div>
                            <Placeholder
                                name="eon-form-success-middle"
                                rendering={rendering}
                            />
                        </div>
                        <div>
                            <Placeholder
                                name="eon-form-success-bottom"
                                rendering={rendering}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="form-wrapper">
                <div
                    className={`form ${showSuccess ? 'hidden' : ''}`}
                    ref={formHolderRef}
                >
                    <JssForm
                        form={fields}
                        sitecoreApiHost={sitecoreApiHost}
                        sitecoreApiKey={sitecoreApiKey}
                        fieldFactory={factory}
                        onRedirect={(url) => history.push(url)}
                        formFetcher={formFetcher}
                        errorComponent={FormErrors}
                    />
                </div>
                <div className={`form-success ${!showSuccess ? 'hidden' : ''}`}>
                    <Placeholder
                        name="eon-form-success"
                        rendering={rendering}
                    />
                    <QmaticSuccess stateHandler={stateHandler} />
                    <Placeholder
                        name="eon-form-success-middle"
                        rendering={rendering}
                    />
                    <QmaticSuccessButtons stateHandler={stateHandler} />
                    <Placeholder
                        name="eon-form-success-bottom"
                        rendering={rendering}
                    />
                </div>
            </div>
        </>
    );
};

export default withSitecoreContext()(withRouter(Form));
