import React from 'react';
import { Link } from 'react-router-dom';

const NavigationItem = (params) => {
    if (!params || !params.href) {
        return '';
    }

    if (params.href.startsWith('/')) {
        return (
            <Link to={params.href} target={params.target} {...params}>
                {params.children}
            </Link>
        );
    }

    return (
        <a href={params.href} target={params.target} {...params}>
            {params.children}
        </a>
    );
};

export default NavigationItem;
