import { useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ScriptComponent = ({ sitecoreContext, fields, rendering }) => {
    useEffect(() => {
        if (sitecoreContext?.pageEditing) {
            return;
        }

        if (!fields?.enabled?.value) {
            return;
        }

        if (!document) {
            return;
        }

        const scripts = document.querySelectorAll(
            `[datasource*="${rendering.dataSource}"]`
        );

        if (!scripts.length) {
            addScript();
        }

        return () => {
            if (document) {
                removeScript();
            }
        };
    }, []);

    const addScript = () => {
        const scriptValue = fields.script.value;
        const htmlElement = fields.htmlElement?.value ?? 'headScripts';

        const scriptSection = document.getElementById(htmlElement);

        let scriptTag;
        if (fields.isNoScriptTag?.value) {
            scriptTag = document.createElement('noscript');
        } else {
            scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
        }

        if (fields.parameters?.value) {
            const params = new URLSearchParams(
                decodeURIComponent(fields.parameters.value)
            );
            for (const [key, value] of params.entries()) {
                scriptTag.setAttribute(key, value);
            }
        }

        scriptTag.setAttribute('datasource', rendering.dataSource);
        const script = document.createTextNode(scriptValue);

        scriptTag.appendChild(script);
        scriptTag.className = 'eon-script';
        if (scriptSection?.parentNode) {
            scriptSection.parentNode.insertBefore(scriptTag, scriptSection);
        }
    };

    const removeScript = () => {
        const scripts = document.querySelectorAll(
            `[datasource*="${rendering.dataSource}"]`
        );
        scripts.forEach((element) => {
            element.parentNode.removeChild(element);
        });
    };

    return '';
};

export default withSitecoreContext()(ScriptComponent);
