import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import modalSize from '../../lib/ModalSizeResolver';

const ModalButton = ({ rendering, fields, params, fullWidth }) => {
    const modalId = 'modalId' + rendering.uid;
    const title = fields?.text?.value ?? '';
    const modalSizeParameter = params.modalSize ?? 'S';
    const full = fullWidth === 'true';
    const style = params?.rank ?? 'primary';

    return (
        <div className="cta-wrapper">
            <eon-ui-button
                text={title}
                rank={style}
                full-width-on-mobile="true"
                toggle-full-width-on-mobile="true"
                full-width={full}
                open-modal-id={modalId}
            ></eon-ui-button>
            <eon-ui-modal
                modal-id={modalId}
                show-closing-x="true"
                full-width="true"
                full-width-width={modalSize(modalSizeParameter)}
            >
                <Placeholder name="eon-modal" rendering={rendering} />
            </eon-ui-modal>
        </div>
    );
};

export default ModalButton;
