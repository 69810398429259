import React, { useMemo, useState } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getTableStyle, splitArrayIntoChunks } from './helpers';
import { useWindowSize } from '../../hooks/useWindowSize';

const PRODUCT_PRESENTATION_MAX_WIDTH = 324;

const ProductTable = ({ rendering, params, sitecoreContext }) => {
    const placeholders = Object.keys(rendering.placeholders);
    const columns = placeholders?.filter((x) => x.includes('column')) || [];

    const isShowMoreOptionVisible = Object.values(rendering.placeholders).some(
        (placeholder) =>
            placeholder.some(
                (subComponent) =>
                    subComponent.componentName === 'ProductTableSection' &&
                    subComponent.placeholders?.[
                        'eon-product-table-section'
                    ]?.some(
                        (subSubComponent) =>
                            subSubComponent.componentName ===
                                'SingleProductColumnSection' &&
                            subSubComponent?.params?.hidden === '1'
                    )
            )
    );

    const [showHiddenRecords, setShowHiddenRecords] = useState(false);
    const { width } = useWindowSize();
    const tableConfig = useMemo(
        () =>
            splitArrayIntoChunks(
                columns,
                Math.floor(width / PRODUCT_PRESENTATION_MAX_WIDTH)
            ),
        [width, columns]
    );
    const toggleHiddenRecordsVisibility = () =>
        setShowHiddenRecords(!showHiddenRecords);

    if (!placeholders) {
        return '';
    }

    return (
        <div className="product-table">
            <div className="product-table_header">
                <div className="product-table__headline">
                    <Placeholder name="eon-headline" rendering={rendering} />
                </div>
                <div className="product-table__cta">
                    <Placeholder name="eon-ctabutton" rendering={rendering} />
                </div>
            </div>

            <div
                className={`product-table_table ${columns.length === 2 ? 'two-columns' : ''} ${
                    tableConfig.length > 1 ? 'with-carousel' : ''
                }`}
            >
                {sitecoreContext.pageEditing ? (
                    <eon-ui-grid-control
                        grid={getTableStyle(columns.length)}
                        same-height="true"
                    >
                        {columns.map((p, key) => (
                            <eon-ui-xx key={key} slot={`column-${key + 1}`}>
                                <Placeholder
                                    name={p}
                                    rendering={rendering}
                                    params={{ showHiddenRecords: showHiddenRecords ? '1' : '0' }}
                                />
                            </eon-ui-xx>
                        ))}
                    </eon-ui-grid-control>
                ) : (
                    <eon-ui-carousel
                        indicator-size="small"
                        controls={false}
                        indicators={tableConfig.length > 1}
                        same-height
                    >
                        {tableConfig.map((columns, carouselKey) => (
                            <eon-ui-carousel-item key={carouselKey}>
                                <eon-ui-grid-control
                                    same-height="true"
                                    grid={getTableStyle(columns.length)}
                                >
                                    {columns.map((p, key) => (
                                        <eon-ui-xx
                                            key={key}
                                            slot={`column-${key + 1}`}
                                        >
                                            <Placeholder
                                                name={p}
                                                rendering={rendering}
                                                params={{ showHiddenRecords: showHiddenRecords ? '1' : '0' }}
                                            />
                                        </eon-ui-xx>
                                    ))}
                                </eon-ui-grid-control>
                                {isShowMoreOptionVisible && (
                                    <div
                                        className="product-table_show-more"
                                        onClick={toggleHiddenRecordsVisibility}
                                    >
                                        <eon-ui-icon
                                            size="small"
                                            scheme="red"
                                            name={
                                                showHiddenRecords
                                                    ? 'chevron_bold_up'
                                                    : 'chevron_bold_down'
                                            }
                                        />
                                        <span>
                                            {showHiddenRecords
                                                ? 'Pokaż mniej'
                                                : 'Pokaż więcej'}
                                        </span>
                                    </div>
                                )}
                            </eon-ui-carousel-item>
                        ))}
                    </eon-ui-carousel>
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductTable);
