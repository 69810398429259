import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const LogoRotatorTile = ({ sitecoreContext, rendering }) => {
    const imageUrl = rendering.fields?.logo?.value?.src ?? '';
    const altText = rendering.fields?.logo?.value?.alt ?? '';
    const url = rendering.fields?.link?.value?.href ?? '';
    const target = rendering.fields?.link?.value?.target ?? '_self';

    return (
        <div className="logo-rotator-tile">
            <eon-ui-background color="eon-white" full-height>
                <div className="logo-rotator-tile__content">
                    {sitecoreContext.pageEditing && (
                        <p>Logo Rotator Tile Component</p>
                    )}

                    {url ? (
                        <a href={url} target={target} itempropName="">
                            <img
                                className="logo-rotator-tile__image-wrapper--image"
                                alt={altText}
                                src={imageUrl}
                            />
                        </a>
                    ) : (
                        <img
                            className="logo-rotator-tile__image-wrapper--image"
                            alt={altText}
                            src={imageUrl}
                        />
                    )}
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(LogoRotatorTile);
