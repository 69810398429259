import DataLayer from '../../DataLayer';
import {
    GetAllFields,
    GetEventCategory,
    FieldModelValidator,
    GtmModelValidator,
    GenerateTransactionId
} from './FormAnalytics.js';

function AnalyticsForFormSubmittedSuccessfully(
    sitecoreContext,
    rendering,
    conversionId,
    fields,
    formData
) {
    const name = GetEventCategory(sitecoreContext, rendering);
    const dataLayer = {
        event: 'formAction',
        eventCategory: name,
        eventAction: 'sent',
        conversionId: conversionId ?? '',
        transactionId: GenerateTransactionId(),
        transactionTotal: '0',
        transactionProducts: [
            {
                sku: name,
                name: name,
                price: 0,
                quantity: 1
            }
        ]
    };

    const allFields = GetAllFields(fields, FieldModelValidator);
    const gtmFields = GetAllFields(fields, GtmModelValidator);

    // Adds to Data Layer object fields that are mapped on Custom GTM field
    gtmFields
        .map((field) => field.model)
        .forEach((model) => {
            const key = model.gtmKey;
            let value;

            if (!model.formElementName && model.defaultValue) {
                value = model.defaultValue;
            } else {
                // Custom GTM field has an attribute called Form Element Name which is a reference for a single field from the Submitted Form.
                const field = allFields.filter(
                    (field) => field?.model?.name === model.formElementName
                )[0];

                if (field) {
                    // Gets the value from model. If empty, it will retrive the value from Form Data based on Item Id
                    value = field.model?.value
                        ? field?.model?.value
                        : formData.data.filter(
                              (x) =>
                                  x.key.includes(field?.model?.itemId) &&
                                  x.value !== field?.model?.itemId
                          )[0]?.value;

                    if (value) {
                        if (field.model?.innerText) {
                            value = field.model?.innerText;
                        }
                    }

                    value = value ?? model.defaultValue;

                    if (model.defaultValue) {
                        console.log(model.defaultValue);
                        console.log(value);
                    }

                    if (value !== '{$hidden}') {
                        if (value?.name) {
                            value = value.name;
                        }
                    }
                }
            }

            if (value) {
                dataLayer[key] = value;
            }
        });

    DataLayer.pushToDataLayer(dataLayer);
}

export default AnalyticsForFormSubmittedSuccessfully;
