import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const AnchorTile = ({ fields, params, sitecoreContext }) => {
    const isMobile = useMobileCheck();
    const classes = [
        params?.redTile && isMobile ? 'full-width' : '',
        params?.redTile ? 'red-tile' : '',

    ].filter((value) => !!value);

    if (sitecoreContext.pageEditing && !fields) {
        return <div>Editor|EmptyDatasource</div>;
    }

    const link = fields.link?.value;

    return (
        <div
            className={`anchor-tile ${classes.join(' ')}`}
        >
            <eon-ui-background
                color={params?.redTile ? 'eon-red' : 'eon-ultralightgrey'}
                full-height
            >
                <div className="anchor-tile-content">
                    <eon-ui-background
                        color={
                            params?.redTile
                                ? 'eon-bordeaux'
                                : params?.ribbonColor || 'eon-middlegrey'
                        }
                        full-height
                    >
                        <div className="anchor-tile-stripe" />
                    </eon-ui-background>

                    <div className="anchor-tile-main">
                        <div className="anchor-tile-header">
                            <eon-ui-text
                                text-style={isMobile ? 'copy' : 'subheadline'}
                                font-weight={700}
                            >
                                {fields.header.value}
                            </eon-ui-text>
                        </div>
                        <div className="anchor-tile-link">
                            {!!link?.href && (
                                <eon-ui-link
                                    href={link?.href}
                                    target={link?.target}
                                    text={link?.text}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </eon-ui-background>
        </div>
    );
};

export default withSitecoreContext()(AnchorTile);
