import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useMobileCheck } from '../../hooks/useMobileCheck';

const PreviewAndDownloadTile = ({ fields, sitecoreContext, params }) => {
    const isMobile = useMobileCheck();

    let aspectRatio = isMobile
        ? params.imageSize === 'large'
            ? 'ratio-3x4'
            : 'ratio-2x1'
        : params.imageSize === 'large'
            ? 'ratio-3x4'
            : 'ratio-16x9';

    const file = fields?.file?.value;
    const fileSize = `plik ${file?.extension.toUpperCase()} ${(fields?.file?.value?.size / 1000000).toFixed(2)} MB`;

    return (
        <div className="preview-and-download-tile">
            {sitecoreContext.pageEditing && (
                <p>PreviewAndDownloadTile Component</p>
            )}

            {fields?.image?.value?.src && (
                <div className="preview-and-download-tile__image">
                    <eon-ui-image
                        src={fields?.image?.value?.src}
                        alt={fields?.image?.value?.alt}
                        aspect-ratio={aspectRatio}
                    />
                </div>
            )}

            <div className="preview-and-download-tile-wrapper">
                <eon-ui-background
                    color="eon-ultralightgrey"
                    class="hydrated"
                    full-height
                >
                    <div className="preview-and-download-tile-content">
                        <div className="title">
                            <eon-ui-headline
                                text={fields?.header?.value}
                                size="h4"
                            />
                        </div>

                        <div className="description">
                            <eon-ui-text test-style="copy">
                                {fileSize}
                            </eon-ui-text>
                        </div>

                        <div className="link">
                            <eon-ui-link
                                text={fields?.buttonTitle?.value}
                                href={fields?.file?.value?.src}
                                target="_blank"
                                download
                            />
                        </div>
                    </div>
                </eon-ui-background>
            </div>
        </div>
    );
};

export default withSitecoreContext()(PreviewAndDownloadTile);
