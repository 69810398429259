import React from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './InfoPopupTile.scss';

const InfoPopupTile = ({ t }) => {
    return (
        <div className={'for-bussines-modal'}>
            <eon-ui-headline
                size="h3"
                scheme="red"
                text={t('Content|InfoPopup|KindOfBussinesYouRun')}
            ></eon-ui-headline>
            <div className="for-bussines-modal__container">
                <div className="container--left">
                    <eon-ui-icon
                        name="pict_led_inside"
                        size={'big'}
                        style={{ color: '#1EA2B1', marginBottom: '30px' }}
                    />
                    <br />
                    <h4
                        style={{
                            color: 'red',
                            fontWeight: '900',
                            fontSize: '26px'
                        }}
                    >
                        {t('Content|InfoPopup|MicroCompanies')}
                    </h4>
                    <div className="text-eon-line">
                        <eon-ui-text
                            text-style="copy"
                            style={{ marginTop: '30px' }}
                        >
                            <div className="text-line">
                                <strong>
                                    {t('Content|InfoPopup|UpTo9999PLN')}
                                </strong>{' '}
                                {t('Content|InfoPopup|AvgMonthlyPayments')}
                            </div>
                            <div className="text-line">
                                <strong>
                                    {t('Content|InfoPopup|UpTo199MWh')}
                                </strong>{' '}
                                {t(
                                    'Content|InfoPopup|AvgYearlyElectrConsumption'
                                )}
                            </div>
                        </eon-ui-text>
                    </div>
                    <br />
                    <div className="text-eon-line-2">
                        <eon-ui-text text-style="copy">
                            {t('Content|InfoPopup|ForInstance')}
                        </eon-ui-text>
                    </div>
                    <a href="mikrofirmy" className="link">
                        <eon-ui-icon
                            name="icon_arrow_right"
                            size={'normal'}
                            style={{ color: 'red', height: '10px' }}
                        />
                        {t('Content|InfoPopup|GoToMicroCompanies')}
                    </a>
                </div>
                <div className="container--right">
                    <eon-ui-icon
                        name="pict_led_industry"
                        size={'normal'}
                        style={{ color: '#1EA2B1', marginBottom: '30px' }}
                    />
                    <br />
                    <h4
                        style={{
                            color: 'red',
                            fontWeight: '900',
                            fontSize: '26px'
                        }}
                    >
                        {t('Content|InfoPopup|CompaniesAndInstitutions')}
                    </h4>
                    <div className="text-eon-line">
                        <div>
                            <div className="text-line">
                                <strong>
                                    {t('Content|InfoPopup|Over10000PLN')}
                                </strong>{' '}
                                {t('Content|InfoPopup|AvgMonthlyPayments')}
                            </div>
                            <div className="text-line">
                                <strong>
                                    {t('Content|InfoPopup|Over200MWh')}
                                </strong>{' '}
                                {t(
                                    'Content|InfoPopup|AvgYearlyElectrConsumption'
                                )}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="text-eon-line-2">
                        <eon-ui-text text-style="copy">
                            {t('Content|InfoPopup|DevelopersEtc')}
                        </eon-ui-text>
                    </div>
                    <a href="firmy-i-instytucje" className="link">
                        <eon-ui-icon
                            name="icon_arrow_right"
                            size={'normal'}
                            style={{ color: 'red', height: '10px' }}
                        />
                        {t('Content|InfoPopup|GoToCompInst')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(withSitecoreContext()(InfoPopupTile));
