import React, { useEffect, useRef, useState } from 'react';
import FieldStateHelper from '../../fieldStateHelper.js';
import ModalOnAction, {ModalListEditor, useModal} from "../../../../ModalOnAction";

const valueChangedEvent = 'valueChanged';
const blurEvent = 'inputBlur';
const focusEvent = 'inputFocus';

const QmaticVisitSubject = ({
    field,
    fieldState,
    conditionsHandler,
    stateHandler,
    stepHandler,
    qmaticHandler,
    tracker,
    errors,
    onChange,
    rendering
}) => {
    useEffect(() => {
        ref.current.addEventListener(valueChangedEvent, handleValueChange);
        ref.current.addEventListener(blurEvent, (e) => handleBlur(e));
        ref.current.addEventListener(focusEvent, (e) => handleFocus(e));

        return () => {
            ref.current.removeEventListener(
                valueChangedEvent,
                handleValueChange
            );
            ref.current.removeEventListener(blurEvent, handleBlur);
            ref.current.removeEventListener(focusEvent, (e) => handleFocus(e));
        };
    }, [ref]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        FieldStateHelper.sendStateToForm(
            field,
            (d) => setData(d),
            (s) => stateHandler(s),
            data
        );
    }, []);
    const [data, setData] = useState(fieldState);
    const [errorMsgs, setErrors] = useState(errors);
    const [showModalId, setShowModalId] = useState(null);

    const handleValueChange = (e) => {
        e.preventDefault();
        field.model.value = e.target.value;
        const errorMessages = [];
        let valid = true;
        if (field.model.required && !e.target.value) {
            valid = false;
            errorMessages.push(`Pole ${field.model.title} jest wymagane`);
        }

        field.model.qmatic = {
            branchId: field.model.value
        };
        field.qmaticSummary = {
            ...field.qmaticSummary
        };
        onChange(field.valueField.name, e.target.value, valid, errorMessages);

        field.qmaticSettings = { ...field.qmaticSettings };
        qmaticHandler(field);
        conditionsHandler(field);
        if (field.model.modalOnValueSelection) {
            setShowModalId(e.target.value);
        }
        if (typeof field.model.nextStep !== 'undefined' && field?.model?.navigateOnValueChange) {
            stepHandler(field.model.nextStep);
        }
    };

    const handleBlur = () => {
        tracker.onBlurField(field, data.value, errors);
    };

    const handleFocus = () => {
        tracker.onFocusField(field, data.value, errors);
    };

    const ref = useRef();

    useEffect(() => {
        if (data.qmatic && data.qmaticData) {
            field.qmaticSummary = data.qmaticSummary;
            field.qmaticSettings = data.qmaticSettings;
            var newSelectItems = data.qmaticData.map((x) => ({
                text: x[`externalName`],
                value: x[`internalName`]
            }));
            field.model.items = newSelectItems;
        }
    }, [data]);

    const selected =
        (data &&
            field.model.items
                .filter((item) => item.value === data.value)
                .map((item) => item.value)[0]) ??
        field.model.items
            .filter((item) => item.selected)
            .map((item) => item.value)[0];

    return (
        <div className={'field-wrapper' + (data.hidden ? ' hidden' : '')}>
            {field.model.modalOnValueSelection &&
            <ModalListEditor modals={field.model.modals}/>
            }
            <eon-ui-radio-button-group
                ref={ref}
                name={field.model.name}
                label={`${field.model.title} ${
                    field.model.required && field.model.title ? '*' : ''
                    }`}
                selected={selected}
                disabled={data.disabled ?? false}
                invalid={errorMsgs.length > 0}
                error-message={errorMsgs}
            >
                {field.model.items.map((item, i) => {
                    return (
                        <eon-ui-radio-button
                            key={`radio-${i}`}
                            {...(item.value === data.value
                                ? { 'initially-checked': '' }
                                : {})}
                            value={item.value}
                            label={item.text}
                        ></eon-ui-radio-button>
                    );
                })}
            </eon-ui-radio-button-group>
            <div>
                {field.model.modalOnValueSelection && field.model.modals.map((item, i) => {
                    const listItem = field.model.items.find(x => x.text === item.label);
                    const isShowModal = listItem?.value === showModalId;
                    return (
                        <div>
                            <ModalOnAction
                                displayButton={false}
                                rendering={rendering}
                                uid={item?.id}
                                show={isShowModal} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default QmaticVisitSubject;
