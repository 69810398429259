import React from 'react';
import InfoPopupTile from './InfoPopupTile';
import './InfoPopup.scss';

const InfoPopup = ({ t }) => {
    const visible = window.location.pathname.endsWith('/dla-biznesu')
        ? true
        : false;

    return (
        <>
            <eon-ui-modal
                visible={visible}
                modal-id="info-popup-tiles"
                show-closing-x="true"
                full-width="true"
                class="custom-eon-modal"
            >
                <InfoPopupTile t={t} />
            </eon-ui-modal>
        </>
    );
};

export default InfoPopup;
