import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomerBidsStatus from './CustomerBidsStatus.js';
import SingleBid from './SingleBid.jsx';

const Bids = ({ bids, isLoading, setStatus, status, t, title }) => {
    return (
        <div className="customer-bids">
            <eon-ui-tag-pill-group scheme="eon-red">
                <div>
                    <eon-ui-tag-pill
                        text={t('Content|Bids|Statuses|Actual')}
                        onClick={() => setStatus(CustomerBidsStatus.ACTUAL)}
                        is-selected={CustomerBidsStatus.isActual(status)}
                    ></eon-ui-tag-pill>
                    <eon-ui-tag-pill
                        text={t('Content|Bids|Statuses|InProgress')}
                        onClick={() => setStatus(CustomerBidsStatus.PROGRESS)}
                        is-selected={CustomerBidsStatus.inProgress(status)}
                    ></eon-ui-tag-pill>
                    <eon-ui-tag-pill
                        text={t('Content|Bids|Statuses|Done')}
                        onClick={() => setStatus(CustomerBidsStatus.DONE)}
                        is-selected={CustomerBidsStatus.isDone(status)}
                    ></eon-ui-tag-pill>
                </div>
            </eon-ui-tag-pill-group>

            <div className="bids-title">
                <eon-ui-headline size="h3" text={title} />
            </div>

            <div className="bids-list">
                {isLoading && (
                    <div className="bids-list-spinner">
                        <eon-ui-preloader-circle />
                    </div>
                )}
                {!isLoading && bids && (
                    <eon-ui-accordion mode="single">
                        {bids.map((bid) => (
                            <SingleBid t={t} key={bid.number} bid={bid} />
                        ))}
                    </eon-ui-accordion>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(Bids);
