import React from 'react';
import {
    Placeholder,
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';

const BulletPointWrapper = ({ rendering, sitecoreContext, params }) => {
    const iconScheme = params?.iconScheme;
    return (
        <div className="bullet-point-wrapper">
            {sitecoreContext.pageEditing && <p>BulletPointWrapper Component</p>}
            <div>
                <div className="bullet-point-container">
                    <div className="bullet-point-icon-wrapper">
                        <eon-ui-icon
                            name="value_proposition"
                            scheme={iconScheme}
                        ></eon-ui-icon>
                    </div>
                    <div className="bullet-placeholder-wrapper">
                        <Placeholder
                            name="eon-bullet-point"
                            rendering={rendering}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(BulletPointWrapper);
