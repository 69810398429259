import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SearchContainerEditor from './SearchContainerEditor.jsx';
import SearchContainer from './SearchContainer.jsx';

const FlyoutTrigger = ({ onClick }) => {
    return (
        <div className="flyout-search-trigger" onClick={onClick}>
            <eon-ui-icon name="search" />
        </div>
    );
};

const FlyoutSearch = ({ rendering, sitecoreContext }) => {
    const [isPanelVisible, setIsPanelVisible] = useState(false);

    return (
        <div className={`flyout-search ${isPanelVisible ? 'open' : ''}`}>
            <FlyoutTrigger onClick={() => setIsPanelVisible(true)} />

            {sitecoreContext.pageEditing && !sitecoreContext.headerId && (
                <SearchContainerEditor rendering={rendering} />
            )}

            {!sitecoreContext.pageEditing &&
                sitecoreContext.headerId &&
                isPanelVisible && (
                    <SearchContainer
                        rendering={rendering}
                        onPanelClose={() => setIsPanelVisible(false)}
                        setIsPanelVisible={setIsPanelVisible}
                    />
                )}
        </div>
    );
};

export default withSitecoreContext()(withTranslation()(FlyoutSearch));
