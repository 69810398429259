import React from 'react';
import { withTranslation } from 'react-i18next';
import { formatNumber } from '../utils';

const DesktopTable = ({ t, data }) => {
    return (
        <table className="desktop">
            <thead>
                <tr>
                    <th className="noborder"></th>
                    <th colSpan="2">{t('DayAheadMarket|FixingName')}</th>
                </tr>
                <tr>
                    <th>{t('DayAheadMarket|Header|Time')}</th>
                    <th>{t('DayAheadMarket|Header|NetRate')}</th>
                    <th>{t('DayAheadMarket|Header|GrossRate')}</th>
                </tr>
            </thead>
            <tbody>
                {data.HourlyRates.map((rate, index) => (
                    <tr key={index}>
                        <td>{rate.Hour}</td>
                        <td>{formatNumber(rate.NetRate)}</td>
                        <td>{formatNumber(rate.GrossRate)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default withTranslation()(DesktopTable);
